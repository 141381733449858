import React,{useState, useEffect} from 'react'

import { ChevronRight, SideBarShrink } from '../layout/Icons'
import axios from '../../api/axios'
import { CategorylogoStorage_LINK, GETEXTRATASKDETAILS_URL, UPDATECHECKLIST_URL, UPDATENOTES_URL } from '../../api/ApiUrl'
import Assignee from '../layout/Asignee/Assignee'

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import AssigneeLoader from '../layout/Asignee/AssigneeLoader'
import { useDispatch, useSelector } from 'react-redux'
import Checklist from '../layout/sortableChecklist/Checklist'
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react'
import CustomEditor from '../layout/CustomEditor'
import { updateTaskStatus } from '../../features/Tasks'
import Avatar from '../layout/Avatar'
import Moment from 'react-moment'
import { notify, requireRefresh } from '../../config/firebaseConfig'
import WatchTask from './WatchTask'

const TaskSideBar = ({sideTaskId, sideTask, setSideTaskId, employeelist}) => {
    const dispatch=useDispatch();
    const categories=useSelector(state=>state.category.value);
    const user=useSelector(state=>state.user.value)
    const [history, setHistory]=useState([]);

    const [assigneeList, setAssigneeList]=useState([]);
    const [TaskChecklists, setTaskChecklists]=useState([]);
    const [activities, setActivities]=useState([]);
    
    const [isChecklist, setIsChecklist]=useState(false);
    const [IsChecklistModified, setIsChecklistModified]=useState(false);

    const [note,setNote]=useState(null);
    const [rawNote, setRawNote]=useState(null);

    const [isNote, setIsNote]=useState(false);
    const [isNoteModified, setIsNoteModified]=useState(false);
    useEffect(()=>{
        
        const getExtraDetails= async(id)=>{
        const res=await axios.get(`${GETEXTRATASKDETAILS_URL}${id}`);
        setAssigneeList(res.data.assignees);
        setTaskChecklists(res.data.checklists);
        setActivities(res.data.activities);
        var check=categories.filter(c=>c.id===sideTask.categoryId)[0]
        setIsChecklist(check.isChecklist);
        setIsNote(check.isNote);
        
        if(sideTask.travel){
          setHistory(JSON.parse(`[${sideTask.history}]`));
        }

        if(check.isNote){
            if(sideTask.notes!=null){
              const contentBlock = htmlToDraft(sideTask.notes);
              if(contentBlock){
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setNote(EditorState.createWithContent(contentState));
              }else{
                setNote(EditorState.createEmpty());
              }
            }
            
        }

        }
        if(sideTaskId!=-1){
            getExtraDetails(sideTaskId)
        }
        return(()=>{
            setHistory([])
            setAssigneeList([])
            setTaskChecklists([])
            setIsChecklistModified(false)
            setIsNote(false);
            setNote(null);
            setRawNote(null);
            setIsNoteModified(false);
            setActivities([])

        })
    },[sideTaskId])


    const updateChecklist = async(options)=>{
        try{
          
          var checkData=options.filter((ch)=>(ch.name==''))
          if(checkData.length>0){
            toast.error('please type your option in checklist');
            return
          }
    
          var data=options.map(c=>({
            id:c.id,
            name:c.name,
            checked:c.checked,
            default:c.default,
            jobId:sideTaskId,
            }
          ))
          await axios.post(UPDATECHECKLIST_URL, {checks:JSON.stringify(data), taskId:sideTaskId,  userId:user.id,}).then((res)=>{
            setTaskChecklists(res.data.checklists);
            setActivities(res.data.activities)

            const toSend=res.data.assignees;

            toSend.filter(a=>a.userId!==user.id).map(s=>{
              var title=`Checklist updated for Task [#${sideTaskId}]`
              var body=`${user.Fname} ${user.Lname} made changes to the checklist`
              var onClickLink=`/category/${s.compId}/${s.catId}/${sideTaskId}`
              var receiverId=s.userId
              var cat=categories.filter(c=>c.id==s.catId)[0]
              var iconLink=''
              var iconType=-1
              if(cat.icon!=null){
                iconLink=`${cat.icon}`
                iconType=0
              }else{
                iconLink=`${cat.name}`
                iconType=1
              }
              notify(title, body, onClickLink, receiverId, iconLink, iconType)
            })

         

            toast.success('Checklist Updated!');
            setIsChecklistModified(false)
          })
    
        }catch(e){
          if(!e?.response){ 
            toast.error("No Server Response!");
            console.log(e);
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
      

      const updateTextDescription = async (state) => {
        await setNote(state);
        setRawNote(draftToHtml(convertToRaw(note.getCurrentContent())));
        setIsNoteModified(true);
      };
    
      const updateNote=async()=>{
        try{
          var data=JSON.stringify({
            notes:rawNote,
            jobId:sideTaskId,
            userId:user.id,
          })
          await axios.post(UPDATENOTES_URL,data).then((res)=>{
            dispatch(updateTaskStatus({reference:sideTask.referenceId, task:res.data.task}))
            setActivities(res.data.activities)
            const toSend=res.data.assignees;

            const refresh=res.data.needsRefresh;

            toSend.filter(a=>a.userId!==user.id).map(s=>{
              var title=`Changes to notes of Task [#${sideTaskId}]`
              var body=`${user.Fname} ${user.Lname} changed notes of  Task [#${sideTaskId}]`
              var onClickLink=`/category/${s.compId}/${s.catId}/${sideTaskId}`
              var receiverId=s.userId
              var cat=categories.filter(c=>c.id==s.catId)[0]
              var iconLink=''
              var iconType=-1
              if(cat.icon!=null){
                iconLink=`${cat.icon}`
                iconType=0
              }else{
                iconLink=`${cat.name}`
                iconType=1
              }
              notify(title, body, onClickLink, receiverId, iconLink, iconType)
            })
            refresh.filter(r=>r!=user.id).map(ref=>{
                  requireRefresh(ref, true, false, false)
            })
    
            toast.success('Notes Updated!');
            setIsNoteModified(false)
          })
        }catch(e){
          if(!e?.response){
            toast.error("No Server Response!");
            console.log(e);
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }

return (
    <div>
    <div className='flex items-center justify-between gap-4 mb-4'>
        <div className='flex items-center justify-start gap-4'>
        <button className='transition-all duration-150 ease-in-out scale-125 text-slate-600 dark:text-slate-400 hover:text-orange-500 dark:hover:text-orange-600' 
          onClick={()=>setSideTaskId(-1)}>
          <SideBarShrink iconClass={'w-6 h-6'}/>
          
        </button>
        <h4 className='text-xl font-bold text-slate-600 dark:text-slate-400'>Task [#{sideTask.id}]</h4>
        </div>
    </div>
    
    <p className='my-1 font-semibold text-slate-700 dark:text-slate-400'>
              {sideTask.qty}x {sideTask.jobDesc} | {sideTask.taskSet} set
    </p> 

    {sideTask.travel?
        <div className='p-1 mb-2 rounded-md bg-slate-200 dark:bg-slate-700'>
        
        <p className='mb-1 text-sm font-semibold text-slate-600 dark:text-slate-300'>Forward History:</p>
        <div className='flex items-center justify-start gap-1'>
        {history.map(h=>
          <div key={h.jobId} className='flex items-center justify-start gap-1 text-slate-600 dark:text-slate-300'> 
            <p  className='text-xs pl-0.5 pr-1 -mr-1 font-semibold rounded-l-sm bg-slate-300 dark:bg-slate-900'>#{h.jobId}</p>
            {categories.filter(op=>op.id==h.catId).map(op=>
              <div key='categoryIcon' className='relative group'>
                <p className='popUp group-hover:scale-100'>{op.name}</p>
                <div className='w-6 h-6 rounded-full ring-2 ring-slate-400 dark:ring-slate-900'>
              {op.icon?
                <img src={`${CategorylogoStorage_LINK}${op.icon}`} className='w-6 h-6 rounded-full '/>
                :
                <Avatar value={op.name} width={'6'} height={'6'}/>
              }
              </div>

              </div>
              
            )}
          <ChevronRight iconClass={'w-4 h-4'}/>
        </div>
        )}

        {categories.filter(op=>op.id==sideTask.categoryId).map(op=>
        <div key='categoryIcon2' className='relative group'>
          <span className='popUp group-hover:scale-100'>{op.name}</span>
          <div className='w-6 h-6 rounded-full ring-2 ring-slate-400 dark:ring-slate-900'>
              {op.icon?
                <img src={`${CategorylogoStorage_LINK}${op.icon}`} className='w-6 h-6 rounded-full'/>
                :
                <Avatar value={op.name} width={'6'} height={'6'}/>
              }
              </div>
        </div>
        )}
        </div>
        </div>
        :
        null
      }
  

  {assigneeList.length>0?
    <WatchTask
      key={assigneeList.length}
      assigneeList={assigneeList} 
      taskId={sideTask.id}
    />
    :
    <AssigneeLoader/>
    }
    
    {assigneeList.length>0?
    <Assignee  
    assigneeList={assigneeList}
    employeelist={employeelist}  
    jobId={sideTaskId} 
    catId={sideTask.categoryId} 
    compId={sideTask.companyId}
    user={user} 
    activities={activities} 
    setActivities={setActivities}
    />
    :
    <AssigneeLoader/>
    }
    
    {isChecklist?
    <>
    <div className='flex items-center justify-between gap-1 mb-2 border-b border-slate-400 dark:border-slate-700'>
      <h4 className='text-lg font-semibold text-slate-600 dark:text-slate-400 '>Checklist</h4>
      <Transition
          show={IsChecklistModified}
          enter="transition-all translate duration-150"
          enterFrom="opacity-0 translate-x-6"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all translate duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-6"
          >
          <button className='accept-btn' onClick={()=>{updateChecklist()}}>save</button>
      </Transition>
    </div>

   
      <Checklist 
      options={TaskChecklists} 
      setOptions={setTaskChecklists} 
      isCreate={false} 
      isEdit={true}
      updateData={updateChecklist}
      setIsChecklistModified={setIsChecklistModified}/>
  
    </>
    :
    null}
    
    
    {isNote?
    <>
    <div className='flex items-center justify-between gap-1 mb-2 border-b border-slate-400 dark:border-slate-700'>
      <h4 className='text-lg font-semibold text-slate-600 dark:text-slate-400 '>Notes</h4>
      <Transition
          show={isNoteModified}
          enter="transition-all translate duration-150"
          enterFrom="opacity-0 translate-x-6"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all translate duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-6"
          >
          <button className='accept-btn' onClick={()=>{updateNote()}}>save</button>
      </Transition>
    </div>
    <div className='p-1 rounded-md bg-slate-200 dark:bg-slate-800'>
    <CustomEditor data={note} updateData={updateTextDescription}/>
    </div>
    </>
    :
    null}

    <h4 className='text-lg font-semibold text-slate-600 dark:text-slate-400'>Activity</h4>
    <div className='w-full min-h-auto max-h-[30vh] overflow-y-scroll text-xs'>
      {activities.length>0?
      <div className='p-1 rounded-md bg-slate-300 dark:bg-slate-900 text-slate-600 dark:text-slate-400'>
      {activities.map((act)=>(
        <div key={act.id} className='flex flex-wrap items-center justify-between gap-1 p-1 transition-all duration-150 ease-in-out bg-transparent border-b rounded-md group border-slate-200 dark:border-slate-700 hover:bg-slate-200 dark:hover:bg-slate-800 dark:bg-transparent'>
          <p  className=''><i className='font-medium'>{act.userName}</i> {act.action}  &rarr; <strong>{act.activity}</strong></p>
          <Moment className='hidden group-hover:block' date={act.created_at} fromNow />
        </div>
      ))}
      </div>
      :
      null}
    </div>

    </div>
  )

}

export default TaskSideBar