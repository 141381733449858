import React from 'react';

const Background = ( { children } ) =>

{
    return (
        <div className="w-auto h-auto min-h-screen transition-all duration-150 ease-in-out min-w-screen bg-slate-300/60 dark:bg-[#0B0F1C]">
            {children}
        </div>
    )
}

export default Background;