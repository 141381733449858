import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from '@headlessui/react'
import { useNavigate} from "react-router-dom";

import { Tune, Template, Group, BackSpace, Delete, Image, Eye, Pencil, Close, DotsCircle  } from '../layout/Icons';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useParams } from 'react-router-dom';
import Avatar from '../layout/Avatar';
import Select from "react-select";
import { v4 as uuidv4 } from 'uuid';
import {ThemeContext} from '../layout/ThemeContext';

import OptionList from '../layout/sortableOptions/OptionList';
import Checklist from '../layout/sortableChecklist/Checklist';
import toast from 'react-hot-toast';
import axios from "../../api/axios";

import { avatarStorage_LINK, CATEGORY_URL,
  EMPLOYEES_URL, 
  GETDATA_URL, 
  CategorylogoStorage_LINK,
  GENERALUPDATE_URL,
  UPDATEOPTION_URL,
  UPDATEVARIABLE_URL,
  UPDATENOTTE_URL,
  ACCESS_URL,
  DELETECATEG_URL} from '../../api/ApiUrl';

import { setCategory } from '../../features/categories';
import CustomEditor from '../layout/CustomEditor';
import { requireRefresh } from '../../config/firebaseConfig';

const EditCategory = () => {
  const dispatch=useDispatch();
  const user=useSelector((state)=>(state.user.value));
  const categs=useSelector((state)=>state.category.value);
  let {catId} = useParams();
  const navigate = useNavigate();
  const [askPass, setAskPass]=useState(false);
  const [pass, setPass]=useState('');

  const [searchEmp, setSearchEmp]=useState('');
  const [searchEmployeeData, setSearchEmployeeData]=useState([]);

  useEffect(()=>{
    if(searchEmp.length>0){
      const data = employees.filter((em) => {
        if (
          em.Fname.toLowerCase().includes(searchEmp.toLowerCase()) ||
          em.Lname.toLowerCase().includes(searchEmp.toLowerCase()) ||
          em.email.toLowerCase().includes(searchEmp.toLowerCase()) ||
          em.designation.toLowerCase().includes(searchEmp.toLowerCase())
          ) {
            return em;
          } else {
            return null;
          }
        });
        setSearchEmployeeData(data);
    }else{
      setSearchEmployeeData(employees);
    }
  
  }, [searchEmp])

  const [category, setCateg]=useState(null);
  const [jobStatus, setJobStatus]=useState([]);
  const [paymentOption, setPaymentOption]=useState([]);
  const [paymentMethod, setPaymentMethod]=useState([]);
  const [JobType, setJobType]=useState([]);
  const [shipment, setShipment]=useState([]);
  const [production, setProduction]=useState([]);
  const [quote, setQuote]=useState([]);
  const [checklist, setChecklist]=useState([]);
  const [employees,setEmployees]=useState([]);
  const [access, setAccess]=useState([])


  //category information//
 const [icon, setIcon]=useState(null);
 const [iconUrl,setIconUrl]=useState(null);
 const [catName,setCatName]=useState('');
 const [catDesc, setCatDesc]=useState('')
 const [isPrice,setIsprice]=useState(false);
 const [isNote, setIsnote]=useState(false);
 const [isChecklist, setIschecklist]=useState(false);
 const [isRepeat,setIsrepeat]=useState(false);
 const [isJobType, setIsjobtype]=useState(false);
 const [isShipment, setIsshipment]=useState(false);
 const [isProd,setIsprod]=useState(false);
 const [isQuote, setIsquote]=useState(false);

 const [note,setNote]=useState(null);
 const [rawNote, setRawNote]=useState(null);
 

 const [selectCompanyData, setSelectCompanyData]=useState([]);
 const [selectedCompany, setSelectedCompany]=useState(null);
 const [defaultCompany, setDefaultCompany]=useState(null);


const getEmployees = async (id)=>{
  await axios.get(`${EMPLOYEES_URL}${id}`).then((res)=>{
      setEmployees(res.data.employees);
      setSearchEmployeeData(res.data.employees);
  })
}
useEffect(()=>{
  getEmployees(selectedCompany);
  return ()=>{
    setEmployees([])
  }
},[selectedCompany])


useEffect(()=>{

const getSingleCategory=async(catId)=>{
  try{
      const resCat=await axios.get(`${CATEGORY_URL}${catId}`)
      const cat=resCat.data.category
      setCateg(cat);
      setCatName(cat.name);
      setCatDesc(cat.description);
      setIsprice(cat.isPrice);
      setIsnote(cat.isNote);
      setIschecklist(cat.isChecklist);
      setIsrepeat(cat.isRepeat);
      setIsjobtype(cat.isJobType);
      setIsshipment(cat.isShipment);
      setIsprod(cat.isProduction);
      setIsquote(cat.isQuote);
      setSelectedCompany(cat.companyId);
      
      const resComp=await axios.get(`/api/getCompany/${user.id}`);
      const selectData = resComp.data.companies.map((c)=>(
        {value:c.id,
        label:c.name
      }))
      setDefaultCompany(selectData.filter((co)=>co.value===cat.companyId)[0])
      setSelectCompanyData(selectData);
      
      const resData=await axios.post(GETDATA_URL, {ids:[catId]})

      if(cat.defNotes!=null){
        const contentBlock = htmlToDraft(cat.defNotes);
          if(contentBlock){
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setNote(EditorState.createWithContent(contentState));
          }
        }else{
          setNote(EditorState.createEmpty());
        }

      var status=resData.data.statuses;
      status=status.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      mathFunc:data.mathFunc
      }))
      setJobStatus(status)
      var patmentOption=resData.data.paymentOptions;
      patmentOption=patmentOption.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      mathFunc:data.mathFunc
      }))
      setPaymentOption(patmentOption)

      var paymentMethod=resData.data.paymentMethods;
      paymentMethod=paymentMethod.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      }))
      setPaymentMethod(paymentMethod)


      var job=resData.data.jobTypes;
      job=job.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      }))
      setJobType(job)


      var shipment=resData.data.shipments;
      shipment=shipment.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      }))
      setShipment(shipment)


      var production=resData.data.productions;
      production=production.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      }))
      setProduction(production)


      var quote=resData.data.quotes;
      quote=quote.map((data)=>({
      id:uuidv4(),
      name:data.label,
      color:data.color,
      track:data.value,
      }))
      setQuote(quote)


      var checklist=resData.data.checklists;
      checklist=checklist.map((data)=>({
      id:uuidv4(),
      name:data.name,
      checked:data.checked,
      default:false,
      track:data.value,
      }))
      setChecklist(checklist)


      var employ=resData.data.employees;
      employ=employ.map((data)=>({
      id:data.value,
      role:data.roleId,
      }))
      setAccess(employ)
  
  }catch(e){
    if(!e?.response){
        toast.error("No Server Response!");
        console.log(e);
    }else{
        console.log(e);
        toast.error("Oops! An error occured!");
    }
}

return ()=>{
    setCategory([]);
    setJobStatus([]);
    setPaymentOption([]);
    setPaymentMethod([]);
    setJobType([]);
    setShipment([]);
    setProduction([]);
    setQuote([]);
    setChecklist([]);
    setEmployees([]);
    setNote(null);
    setDefaultCompany(null)
    setSelectCompanyData([])
    setAccess([])
}
} 

getSingleCategory(catId);

}, [catId])

const { theme } = React.useContext(ThemeContext); //to apply darkmode in elements

const giveAccess=(id)=>{
  if(id===user.id){
    toast('can not change owner roles', {icon:'🙅‍♂️'});
    return
  }
  const find=access.filter((ac)=>ac.id===id)
  

  if(find.length>0){
    var change=null;
    if(find[0].role===0){
      change = access.map((i)=>
      i.id===id?
      {
        ...i,
        role:1,

      }:i
      )
      setAccess(change)
    }else if(find[0].role===1){
      change = access.map((i)=>
      i.id===id?
      {
        ...i,
        role:2,

      }:i
      )
      setAccess(change)
    }else if(find[0].role===2){
      change = access.map((i)=>
      i.id===id?
      {
        ...i,
        role:-1,

      }:i
      )
      setAccess(change)
    }else if(find[0].role===-1){
      change = access.map((i)=>
      i.id===id?
      {
        ...i,
        role:0,

      }:i
      )
      setAccess(change)
    }
  }else{
    const data ={id:id, role:0}
    setAccess([...access, data]);
  }

}


const updateTextDescription = async (state) => {
  await setNote(state);
  setRawNote(draftToHtml(convertToRaw(note.getCurrentContent())));
};

const uploadFile=(e)=>{
  if(e.target.files && e.target.files[0]){
    setIcon(e.target.files[0]);
    setIconUrl(URL.createObjectURL(e.target.files[0]))
    
  }
}

const customStyles = {
  menu: (provided, state) => {
    let backgroundColor = "#f1f5f9";
    if (theme === "dark") {
      backgroundColor = "#334155";
    }

    return {
      ...provided,
      padding: 0,
      fontSize: 12,
      margin: 2,
      backgroundColor,
    };
  },
  option: (provided, state) => ({
    ...provided,
    padding: 3,
    color: state.isSelected ? "orangered" : "#64748b",
    backgroundColor: state.isSelected ? "#cbd5e1" : "",
  }),

  control: (provided) => ({
    ...provided,
    fontSize: 13,
    padding: 1,
    // height: 24,
    minHeight: 14,
    border: 1,
    borderRadius: "0.375rem",
    boxShadow: "none",
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(148 163 184)",
  }),
  valueContainer: () => ({
    fontSize: 13,
    padding: 0,
    margin: 0,
    minHeight: 17,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  }),

  IndicatorsContainer: () => ({
    padding: 0,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: () => () => null,

  dropdownIndicator: () => ({
    paddingLeft: 2,
    opacity: 0.5,
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: 1,
    padding: 1,
    height: 24,
    backgroundColor: "#cbd5e1",
    justifyContent: "space-between",
  }),
  MultiValueContainer: (provided) => ({
    ...provided,
    minHeight: 14,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: 1,
    height: 20,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    padding: 0,
    height: 20,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    let color = "rgb(71 85 105)";

    if (theme === "dark") {
      color = "rgb(148 163 184)";
    }

    return { ...provided, opacity, transition, color };
  },
};


const generalUpdate= async()=>{
  const validate = toast.loading('Updating data...');
    try{

      const formData = new FormData();
    formData.append("companyId", selectedCompany);
    formData.append("name", catName);
    formData.append("description", catDesc);
    formData.append("catId",category.id);
    formData.append("userId",user.id);
    //icon
    if(icon!=null){
      formData.append("icon", icon);
    }else{
      formData.append("icon", '');
    }

    await axios.post(GENERALUPDATE_URL, formData).then((res)=>{
      toast.dismiss(validate);
      toast.success('Category Updated!');
      dispatch(setCategory(res.data.categories));
    })

    }catch(e){
      if(!e?.response){
          toast.error("No Server Response!");
          toast.dismiss(validate);
          console.log(e);
      }else{
          console.log(e);
          toast.error("Oops! An error occured!");
          toast.dismiss(validate);
      }
    }
}

const optionsUpdate= async()=>{
  const validate = toast.loading('validating Options...', {icon: '📃'});
  try{
    var data=JSON.stringify({
      isPrice:isPrice,
      isNote:isNote,
      isChecklist:isChecklist,
      isRepeat:isRepeat,
      isJobType:isJobType,
      isShipment:isShipment,
      isProduction:isProd,
      isQuote:isQuote,
      catId:category.id,
      userId:user.id
    }) 

    await axios.post(UPDATEOPTION_URL, data).then((res)=>{
      toast.dismiss(validate);
      toast.success('Updated Options!');
      dispatch(setCategory(res.data.categories));
    })

  }catch(e){
    if(!e?.response){
      toast.error("No Server Response!");
      toast.dismiss(validate);
      console.log(e);
  }else{
      console.log(e);
      toast.error("Oops! An error occured!");
      toast.dismiss(validate);
  }
  }
}

const updateVariableOptions = async(list, id, isMathFunc)=>{
  
  const validate = toast.loading('Updating data...');

  try{
  
  var checkIfEmpty=list.filter((st)=>st.name.length===0);
  
  if(checkIfEmpty.length>0){
    toast.error('There are empty options in the list!')
    toast.dismiss(validate);
    return
  }
  
  var formattedList=null;
  if(id===8){
    formattedList=list.map((l)=>({
      name:l.name,
      checked:l.checked,
      default:l.default,
      track:l.track,
    }))

  }else{
    if(isMathFunc){
      formattedList=list.map((l)=>({
        name:l.name,
        color:l.color,
        track:l.track,
        mathFunc:l.mathFunc
      }))
    }else{
      formattedList=list.map((l)=>({
        name:l.name,
        color:l.color,
        track:l.track,
      }))
    }


  }
  


  var data=JSON.stringify({
    catId:category.id,
    list:JSON.stringify(formattedList),
    listId:id,
  })

  await axios.post(UPDATEVARIABLE_URL, data).then((res)=>{
    toast.dismiss(validate);
    
    if(id===1){
      var status=res.data.list;
          status=status.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
                  mathFunc:data.mathFunc
              }))
      setJobStatus(status)
    }

    if(id===2){
      var paymentOptions=res.data.list;
          paymentOptions=paymentOptions.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
                  mathFunc:data.mathFunc
              }))
      setPaymentOption(paymentOptions)
    }

    if(id===3){
      var paymentMethod=res.data.list;
          paymentMethod=paymentMethod.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
              }))
      setPaymentMethod(paymentMethod)
    }

    if(id===4){
      var jobType=res.data.list;
          jobType=jobType.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
              }))
      setJobType(jobType)
    }

    if(id===5){
      var shipment=res.data.list;
          shipment=shipment.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
              }))
      setShipment(shipment)
    }

    if(id===6){
      var production=res.data.list;
          production=production.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
              }))
      setProduction(production)
    }

    if(id===7){
      var quote=res.data.list;
          quote=quote.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  color:data.color,
                  track:data.id,
              }))
      setQuote(quote)
    }
    if(id===8){
      var checklist=res.data.list;
          checklist=checklist.map((data)=>({
                  id:uuidv4(),
                  name:data.name,
                  checked:data.checked,
                  default:false,
                  track:data.id,
              }))
      setChecklist(checklist)
    }

    toast.success('Options Updated!');

  });

  }catch(e){
    if(!e?.response){
      toast.error("No Server Response!");
      toast.dismiss(validate);
      console.log(e);
  }else{
      console.log(e);
      toast.error("Oops! An error occured!");
      toast.dismiss(validate);
  }
  }
}


const savenote = async()=>{

  const validate = toast.loading('Updating Notes...');
  try{
    var data=JSON.stringify({
      catId:category.id,
      note:rawNote,
    })

    await axios.post(UPDATENOTTE_URL, data).then((res)=>{
      toast.dismiss(validate);
      toast.success('Notes Updated!');
    })

  }catch(e){
    if(!e?.response){
      toast.error("No Server Response!");
      toast.dismiss(validate);
      console.log(e);
  }else{
      console.log(e);
      toast.error("Oops! An error occured!");
      toast.dismiss(validate);
  }
  }
}

const updateAccess = async()=>{
  const validate = toast.loading('Updating Access...');

  try{
    var data=JSON.stringify({
      catId:category.id,
      access:JSON.stringify(access),
    })

  await axios.post(ACCESS_URL, data).then((res)=>{
    toast.dismiss(validate);
    toast.success(res.data.msg);
    const refresh=res.data.needsRefresh;
   
    refresh.filter(r=>r!=user.id).map(ref=>{
      requireRefresh(ref, true, true, false)
    })     
  })

  }catch(e){
    if(!e?.response){
        toast.error("No Server Response!");
        toast.dismiss(validate);
        console.log(e);
    }else{
        console.log(e);
        toast.error("Oops! An error occured!");
        toast.dismiss(validate);
    }

  }
}


const goback=()=>{
  if(category){
      navigate(`/category/${category.companyId}/${category.id}`)
  }
}


const deleteCategory = async (e)=>{
  e.preventDefault();
  const validate = toast.loading('Deleting category...');
  try{
    var data=JSON.stringify({
      userId:user.id,
      pass:pass,
      catId:category.id,
    })

   await axios.post(DELETECATEG_URL, data).then((res)=>{
    toast.dismiss(validate);
    var type=res.data.type;
    if(type===0){
      toast.error(res.data.msg)
    }else{
      
      toast('Category Deleted!', {icon:'❌'})
      navigate('/jobs')
      var newCateg=categs.filter((cat)=>(cat.id!==category.id))
      dispatch(setCategory(newCateg));
    }
   })

  }catch(e){
    if(!e?.response){
      toast.error("No Server Response!");
      toast.dismiss(validate);
      console.log(e);
    }else{
        console.log(e);
        toast.error("Oops! An error occured!");
        toast.dismiss(validate);
    }
  }
}



  return category!=null?(
    <div className='main-bodyLayout'>
        <Tab.Group manual vertical>
        <div className='grid grid-cols-1 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8'>
          <Tab.List className={'tabList'}>
            <div className={'tabList'}>
              <div className='items-center justify-start hidden gap-2 p-1 m-2 md:flex rounded-3xl bg-slate-50 dark:bg-slate-900'>
                {category.icon?
                <img src={`${CategorylogoStorage_LINK}${category.icon}`} className='w-10 h-10 rounded-full' alt='icon' />
                :
                <Avatar value={category.name} width='10' height='10' color={'bg-orange-300 dark:bg-orange-800/70'}/>
                }
                <h4 className='font-bold text-slate-600 dark:text-slate-400 '>{category.name}</h4>
              </div>
              <button className='tabOptions' onClick={()=>{goback()}}><BackSpace iconClass='w-4 h-4'/> <span className='hidden md:block'>Go back</span> </button>
              <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'}><Template iconClass={'w-4 h-4'}/><span className='hidden md:block'>General</span> </Tab>
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'} ><Tune iconClass={'w-4 h-4'}/><span className='hidden md:block'>Options</span></Tab>
                
                {category.roleId>=1?
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'} ><Group iconClass={'w-4 h-4'}/><span className='hidden md:block'>Accessibility</span></Tab>
                :
                null
                }
                {category.roleId>=1?
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'} ><Delete iconClass={'w-4 h-4'}/><span className='hidden md:block'>Delete</span></Tab>
                :
                null}

            </div>
          </Tab.List>
          <div className='md:col-span-4 lg:col-span-5 xl:col-span-6 2xl:col-span-7'>
          <Tab.Panels className={'tabPanel'}>
              <Tab.Panel>
              <h4 className='px-4 my-2 text-xl font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>General</h4>
              <div className='container p-2 my-2'>
              <h4 className='mb-4 text-lg font-semibold text-slate-600 dark:text-slate-400'>Edit Category</h4>
              <div className='flex flex-col items-center justify-center mb-2'>
              
              <input type='file' accept='image/jpg, image/png, image/jpeg, image/gif' onChange={(e)=>uploadFile(e)} className='hidden' id="imgUpload"/>
                <label htmlFor='imgUpload' className='w-16 h-16 transition-all duration-150 ease-in-out scale-100 border rounded-md cursor-pointer border-slate-400 text-slate-400 dark:border-slate-600 dark:text-slate-600 hover:scale-105'>
                  {iconUrl!=null?
                  <img src={iconUrl} alt='icon'/>
                  :
                  <>
                  {category.icon?
                  <img src={`${CategorylogoStorage_LINK}${category.icon}`} alt='icon'/>
                  :
                  <Image iconClass={'w-14 h-14 min-h-full m-auto'}/>
                    }
                  </>
                  }
                  </label>
                  <span className='text-xs font-semibold text-slate-400 dark:text-slate-600'>Upload icon</span>
                  <span className='text-xs font-semibold text-slate-400 dark:text-slate-600'>image (200x200) - .jpg, .png, .jpeg, .gif </span>
              </div>

              <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                <p className='font-semibold lg:text-right text-slate-600 dark:text-slate-400'>*Title:</p>
                <input type='text' value={catName} onChange={(e)=>setCatName(e.target.value)} className='input-field' placeholder='Category Title' required/>
              </div>

              <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                  <p className='font-semibold lg:text-right text-slate-600 dark:text-slate-400'>*Company:</p>
                  <Select
                   defaultValue={defaultCompany}
                   onChange={(op)=>{setSelectedCompany(op.value)}} 
                   className='input-field' 
                   isSearchable={false} 
                   styles={customStyles} 
                   options={selectCompanyData} required/>
                </div>
              
              <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                <p className='font-semibold lg:text-right text-slate-600 dark:text-slate-400'>Description:</p>
                <textarea type='text' defaultValue={catDesc} onChange={(e)=>setCatDesc(e.target.value)} className='input-field' placeholder='description'/>
              </div>
              
              <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                    <div></div>
                    <div className='flex items-center justify-end gap-2 my-5'>
                        <button className='default-btn' onClick={()=>generalUpdate()} disabled={ !(catName.length>2 && selectedCompany!=null) }>Save</button>
                    </div>  
              </div>

              </div>
              </Tab.Panel>
              {/* Tab 2 */}
              <Tab.Panel className={'p-4'}>
              <h4 className='px-4 my-2 text-xl font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Options</h4>
              <h4 className='px-4 my-2 font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Primary Options</h4>
              <p className='text-sm text-slate-500'>Please check the options you need for this category to create a task.</p> 
              <div className='grid items-center grid-cols-2 gap-2 p-2 border rounded-md md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 border-slate-400 dark:border-slate-700'>
                  

              <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isJobType} className='checkbox-checklist' id='jobType' onChange={()=>setIsjobtype(!isJobType)} />
                    <label htmlFor='jobType' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Job Type</label>
                  </div>
                 
                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isShipment} className='checkbox-checklist' id='shipment' onChange={()=>setIsshipment(!isShipment)} />
                    <label htmlFor='shipment' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Shipment</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isProd} className='checkbox-checklist' id='prod' onChange={()=>setIsprod(!isProd)} />
                    <label htmlFor='prod' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Production</label>
                  </div>
                  
                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isQuote} className='checkbox-checklist' id='quote' onChange={()=>setIsquote(!isQuote)} />
                    <label htmlFor='quote' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Quote</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isPrice} className='checkbox-checklist' id='price' onChange={()=>setIsprice(!isPrice)} />
                    <label htmlFor='price' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Price</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isNote} className='checkbox-checklist' id='note' onChange={()=>setIsnote(!isNote)}/>
                    <label htmlFor='note' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Notes</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isChecklist} className='checkbox-checklist' id='check' onChange={()=>setIschecklist(!isChecklist)}/>
                    <label htmlFor='check' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>checklist</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' checked={isRepeat} className='checkbox-checklist' id='repeat' onChange={()=>setIsrepeat(!isRepeat)} />
                    <label htmlFor='repeat' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Repeater</label>
                  </div>

              </div>
                
              <div className='flex items-center justify-end gap-2 my-5'>
                <button className='default-btn' onClick={()=>optionsUpdate()}>Save</button>
              </div>
              
              <h4 className='px-4 my-2 mt-6 font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Variable Options</h4>
              <p className='text-sm text-slate-500'>create custom options for various selection fields for this category. min 2 options are required to activate an option. <br></br>
              <strong>you need to create atleast 2 job status options to proceed</strong>
              </p>
              <div className='grid items-start grid-cols-2 gap-2 my-2 md:grid-cols-3 lg:grid-cols-4'>
                  

                  {/* option */}
                  <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Job Status</p>
                        <OptionList options={jobStatus} isMathFunc={true} isEdit={true} updateData={updateVariableOptions} id={1} setOptions={setJobStatus} isStatus={true} />
                    </div>
                   
                    {/* option */}
                    {isJobType ? 
                     <div className='option-grid'>
                     <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Job Type</p>
                         <OptionList options={JobType}  isMathFunc={false} isEdit={true} updateData={updateVariableOptions} id={4} setOptions={setJobType} isStatus={false}/>
                     </div>
                    
                    : null}
                    {/* option */}
                   
                    {/* option */}
                    {isQuote ? 
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Quote</p>
                        <OptionList options={quote}  isMathFunc={false}  isEdit={true} updateData={updateVariableOptions} id={7} setOptions={setQuote} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                   

                    {/* option */}
                    {isShipment? 
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Shipment</p>
                        <OptionList options={shipment}  isMathFunc={false} isEdit={true} updateData={updateVariableOptions} id={5} setOptions={setShipment} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                   

                    {/* option */}
                    {isProd? 
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Production</p>
                        <OptionList options={production}  isMathFunc={false}  isEdit={true} updateData={updateVariableOptions} id={6} setOptions={setProduction} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                    

                     {/* option */}
                     {isPrice? 
                     <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Payment</p>
                        <OptionList options={paymentOption}  isMathFunc={true} isEdit={true} updateData={updateVariableOptions} id={2} setOptions={setPaymentOption} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                     {/* option */}
                     {isPrice? 
                     <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Payment method</p>
                        <OptionList options={paymentMethod}  isMathFunc={false} isEdit={true} updateData={updateVariableOptions} id={3} setOptions={setPaymentMethod} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                    {isChecklist? 
                      <div className='option-grid'>
                      <h4 className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Default CheckList</h4>
                       <Checklist options={checklist} setOptions={setChecklist} isEdit={true} updateData={updateVariableOptions} id={8}/>            
                      </div>
                      :null}

                  </div>

                  <div>
                    {isNote?
                    <>
                    <h4 className='px-4 my-2 font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Default note record</h4>
                    <CustomEditor data={note} updateData={updateTextDescription}/>
                    <div className='flex items-center justify-end gap-2 my-5'>
                          <button className='default-btn' onClick={()=>savenote()}>Save</button>
                    </div>
                    </>
                    :
                    null}
                  </div>
              </Tab.Panel>
              
              {category.roleId>=1?
              <Tab.Panel className={'p-4'}>
                <div className='flex items-center justify-between gap-2 border-b border-slate-300 dark:border-slate-700'>
                  <h4 className='px-4 my-2 mt-6 text-xl font-semibold text-slate-600 dark:text-slate-400 '>Accessibility</h4>
                  <div className='flex items-center justify-end gap-1'>
                    <input type={'text'} className='input-field-fat' value={searchEmp}  onChange={(e)=>{setSearchEmp(e.target.value)}} placeholder='search'/>
                    {searchEmp.length>0?
                    <button className="icon-btn" onClick={()=>setSearchEmp('')}><Close iconClass={'w-4 h-4'}/></button>
                    :
                      null
                    }
                    </div>
                  </div>
                  <p className='text-sm text-slate-500'>Give access to your employees in this category</p>
                  <div className='grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 '>

                  {searchEmployeeData.map((u)=>
                  
                  <div key={u.id} className='categoryAccessCard'>
                        <div className='flex items-start justify-between object-contain gap-1'>
                          <div className='min-w-max min-h-max'>
                        {u.image?
                            <img src={`${avatarStorage_LINK}${u.image}`} className='w-12 h-12 rounded-full' alt='avatar'/>
                            :
                            <Avatar value={`${u.Fname} ${u.Lname}`} width={'12'} height={'12'} color={'bg-blue-200 dark:bg-blue-800'}/>
                          }
                          </div>
                          <div className='w-full'>
                            <h4 className='font-bold truncate text-slate-600 dark:text-slate-400'>{u.Fname} {u.Lname}</h4>
                            <p className='text-sm font-semibold text-slate-500'>{u.designation}</p>
                          </div>

                          <button className={`tick-btn ${access.filter(e=>e.id===u.id && e.role>=0).length>0? `text-green-500 bg-green-200 dark:bg-green-900 border-green-500 border-2` : `bg-slate-300 text-slate-500 dark:bg-slate-900 border-slate-500`}`}  onClick={()=>{giveAccess(u.id)}}>
                          {access.filter(e=>e.id==u.id && e.role>=0).length>0?
                          <>
                          {access.filter(e=>e.id==u.id).map((ac)=>(
                            <div key={ac.id}>
                              {ac.role==0 &&
                                <Eye iconClass={'w-5 h-5'}/>
                              }
                              {ac.role==1&&
                                <Pencil iconClass={'w-5 h-5'}/>
                              }
                              {ac.role==2 &&
                                <Tune iconClass={'w-5 h-5'}/>
                              }
                              </div>
                              
                              ))
                            }
                            </>
                          :
                          <DotsCircle iconClass={'w-5 h-5'}/>
                          }
                           
                          </button>

                        </div>
                       
                       
                          
                         
                          {access.filter(e=>e.id==u.id).map((ac)=>(
                              <div className='flex flex-wrap items-center justify-end' key={ac.id}>
                              {ac.role>=0 &&
                              <p className='text-xs italic font-semibold text-slate-500'>view </p> 
                              }
                              {ac.role>=1 &&
                               <p className='text-xs italic font-semibold text-slate-500'>, edit/post Task </p> 
                              }
                              {ac.role>=2 &&
                                <p className='text-xs italic font-semibold text-slate-500'>, edit category</p> 
                              }
                              </div>
                              
                              ))
                          }
                        
                          
                    </div>
                 )}
                 </div>

                 <div className='flex items-center justify-end gap-2 my-5'>
                <button className='default-btn' onClick={()=>updateAccess()}> Save </button>
                </div>  
              </Tab.Panel>
              :
              null}


              {category.roleId>=1?
                <Tab.Panel className={'p-4'}>
                <h4 className='px-4 my-2 text-xl font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Delete {category.name}?</h4>  
                            <div className='p-4 m-2 border rounded-md border-slate-300 dark:border-slate-700 md:m-6'>
                              <p className='font-semibold text-rose-500'>Are you sure you want to delete category {category.name}? </p>
                              <p className='text-sm font-semibold text-rose-500'>you will loose all of the jobs/tasks linked to this category. </p>
                              <div className='flex items-center justify-end gap-2'>
                              <button className='w-12 reject-btn' onClick={()=>setAskPass(!askPass)}>Yes</button>
                              <button className='w-12 accept-btn' onClick={()=>{goback()}}>No</button>
                              </div>
                            </div>
                </Tab.Panel>
                :
                null
                }

          </Tab.Panels>
          </div>
        </div>
        </Tab.Group>


        {askPass?
          <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
            <div className='flex flex-row items-center justify-center min-h-screen'>
              <div className='card'>
              <div className='flex items-center justify-between gap-2'>
                <p className='font-semibold text-center text-slate-600 dark:text-slate-400'>Deleting {category.name}</p>
              <button className='reject-btn' onClick={()=>{setAskPass(!askPass)}}><Close iconClass={'w-4 h-4'}/></button>
              </div>
                <p className='text-slate-600 dark:text-slate-400'>Please enter your password to confirm</p>
                <form onSubmit={(e)=>deleteCategory(e)}>
                  <div className='flex items-center justify-between gap-1'>
                      <input 
                      type={`password`} 
                      value={pass}
                      className='w-full input-field-fat' 
                      onChange={(e)=>setPass(e.target.value)} 
                      placeholder='Password' required/>
                    <button className='submit-btn whitespace-nowrap'>Delete Category</button>
                  </div>
                </form>
              </div>
              </div>
          </div>
        :
        null
        }
        
    </div>
  )
  :
  null
}

export default EditCategory