import React from 'react'

const AssigneeLoader = () => {
  return (
    <div>
        <div className='flex items-center justify-between gap-1 mb-2 border-b border-slate-400 dark:border-slate-700'>
        <h4 className='text-lg font-semibold text-slate-600 dark:text-slate-400'>Assignees</h4>
        </div>
        <div className='flex items-center justify-start gap-3'>
            <div className='rounded-full w-9 h-9 bg-slate-300 ring-2 ring-offset-2 ring-slate-300 dark:ring-slate-900 dark:bg-slate-900 animate-pulse'></div>
            <div className='rounded-full w-9 h-9 bg-slate-300 ring-2 ring-offset-2 ring-slate-300 dark:ring-slate-900 dark:bg-slate-900 animate-pulse'></div>
        </div>
    </div>
  )
}

export default AssigneeLoader