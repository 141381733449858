import React,{useState} from 'react'
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Drag, Close, Duplicate } from '../Icons';
import  { BlockPicker }   from 'react-color';
import toast from 'react-hot-toast';

const Option = ({id, item, size, removeOption, setOptionName, tempColor,updateColor, isMathFunc, updateMathFunc, isStatus}) => {
  
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
      } = useSortable({id:id});

      const colors=[
        '#ef4444', '#f97316', '#f59e0b', '#facc15', '#84cc16', '#22c55e',
        '#10b981', '#14b8a6', '#06b6d4', '#3b82f6', '#6366f1', '#9333ea', 
        '#ec4899',  '#e11d48',
      ]
  
      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
      };
      const [color, setColor]=useState(tempColor);
    
      const [isOpenColor, setIsOpenColor]=useState(false);

      const copyColor = ()=>{
        var copy=`${item.color}`;
        navigator.clipboard.writeText(copy);
        toast.success('Color copied to Clipboard!')
    }

    return (
    <div ref={setNodeRef} style={style}>
        <div className='optionLine'>
        <button className='drag-handler' {...listeners} {...attributes}><Drag iconClass={'w-4 h-4'}/></button>
        <div className='relative'>
        <div className='flex items-center justify-start gap-1'>

        <button onClick={()=>setIsOpenColor(!isOpenColor)} className='relative p-1 border rounded-md border-slate-400 dark:border-slate-600'>
          <div style={{backgroundColor:color}} className='w-3 h-3 rounded-md'></div>
        </button>
        <button className='icon-btn' onClick={()=>copyColor()}>
          <Duplicate iconClass={'w-4 h-4'}/>
        </button>
        </div>
        {isOpenColor? 
        <div className='absolute z-[1024] -left-5 top-4 bg-slate-50 dark:bg-slate-700 shadow-lg shadow-slate-400 dark:shadow-slate-900 rounded-md'>
          <div className='flex items-center justify-between p-1'>
            <p className='text-sm font-semibold text-slate-600 dark:text-slate-400'>{item.name}</p>
            <button className='icon-btn' onClick={()=>setIsOpenColor(false)} ><Close iconClass={'w-4 h-4'}/></button>
          </div>
          <BlockPicker  colors={colors} className='bg-slate-50 dark:bg-slate-700' color={item.color} onChangeComplete={(color)=>{updateColor(id,color.hex); setColor(color.hex); setIsOpenColor(false)}}/>
        </div>
        :
        null
      }
      </div>
        
        <input type='text' value={item.name} placeholder='type Option' onChange={(e)=>{setOptionName(id, e.target.value)}} className='w-full input-field' readOnly={isMathFunc && item.mathFunc>0}/>
        
        {isStatus?
        <div className='relative flex items-center justify-center gap-1 group'>
        <input type='checkbox' className='checkbox-optionslist' id={`optionList${item.id}`} checked={item.mathFunc>=1} onChange={()=>{updateMathFunc(item.id)}}/>
        <label htmlFor={`optionList${item.id}`} className='absolute right-0 hidden p-1 text-sm transition-all duration-150 ease-in-out rounded-md cursor-pointer whitespace-nowrap group-hover:block -top-10 bg-slate-200 dark:bg-slate-900 text-slate-600 dark:text-slate-400'>set as Done task</label>
        </div>
        :
        null
        }

        
        {!item.default && size>2?
        <>
        {isMathFunc?
          <>
          {item.mathFunc>0?
          <button  className='opacity-50 reject-btn' disabled={true}><Close iconClass={'w-4 h-4'}/></button>
          :
          <button onClick={()=>removeOption(item.id)} className='reject-btn'><Close iconClass={'w-4 h-4'}/></button>
          }
          </>
        :
        <button onClick={()=>removeOption(item.id)} className='reject-btn'><Close iconClass={'w-4 h-4'}/></button>
        }
        </>
        :
        <button className='opacity-50 reject-btn' disabled={true}><Close iconClass={'w-4 h-4'}/></button>
        }
        </div>
    </div>
  )
}

export default Option