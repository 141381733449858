import React, {useState, useEffect} from 'react'
import { Close, Add } from '../layout/Icons'
import axios from "../../api/axios";

import { CUSTOMER_URL } from '../../api/ApiUrl';

import CustomerCard from './CustomerCard';
import AddCustomer from './AddCustomer';
const Customers = ({compId}) => {
    const [customerData, setCustomerData]=useState([]);
    const [serchCustomer, setSearchCustomer]=useState('');
    const [searchCustomerData, setSearchCustomerData]=useState([]);
    const [isAdd, setIsAdd]=useState(false);

    const getCustomer = async (compId)=>{

        axios.get(`${CUSTOMER_URL}${compId}`).then((res)=>{
          setCustomerData(res.data.customers);
          setSearchCustomerData(res.data.customers)
        })
      }
      useEffect(()=>{
          getCustomer(compId)
        return ()=>{
          setCustomerData([]);
        } 
      
      },[compId])


      useEffect(()=>{
        if(serchCustomer.length>0){
          const data=customerData.filter((n)=>{
            if(
              n.name.toLowerCase().includes(serchCustomer.toLowerCase()) ||
              (n.address!=null && n.address.toLowerCase().includes(serchCustomer.toLowerCase())) ||
              (n.postCode!=null && n.postCode.toLowerCase().includes(serchCustomer.toLowerCase())) ||
              (n.company!=null && n.company.toLowerCase().includes(serchCustomer.toLowerCase())) ||
              n.email.toLowerCase().includes(serchCustomer.toLowerCase())
            ){
              return n;
            }else{
              return null
            }
          });
          setSearchCustomerData(data);
        }else{
          setSearchCustomerData(customerData);
        }
      
      },[serchCustomer])

  return (
     <div className='w-full'>
        <div className='flex items-center justify-between gap-1'>
        <div className='flex items-center justify-start gap-2'>
        <h4 className='my-2 text-2xl font-bold text-slate-600 dark:text-slate-400'>Customers </h4>
        <button className='info-btn' onClick={()=>{setIsAdd(true)}}><Add iconClass={'w-4 h-4'}/> </button>
        </div>

        <div className='flex items-center justify-end gap-1'>
            <input type={'text'} className='input-field-fat' value={serchCustomer}  onChange={(e)=>{setSearchCustomer(e.target.value)}} placeholder='search'/>
            {serchCustomer.length>0?
            <button className="icon-btn" onClick={()=>setSearchCustomer('')}><Close iconClass={'w-4 h-4'}/></button>
            :
            null
          }
        </div>
        </div>
        {customerData.length>0?
        <div className='overflow-y-auto max-h-[90vh] min-h-[40vh]'>
            <div className='grid grid-cols-1 gap-2 lg:grid-cols-2'>
            {searchCustomerData.length>0?
            <>
            {searchCustomerData.map((cust)=><CustomerCard key={cust.id} cust={cust} />)}
            
            </>
            :
            <p className='text-sm font-semibold text-center text-slate-400 dark:text-slate-600'>No Data</p>

            }
            
            </div>
        </div>
        :
         <p className='text-sm font-semibold text-center text-slate-400 dark:text-slate-600'>No Data</p>

        }


        {isAdd?
        <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
           <div className='flex flex-row items-center justify-center min-h-screen'>
            <div className='card'>
            <div className='flex items-center justify-between gap-1 my-2'>
            <p className='font-semibold text-slate-600 dark:text-slate-300'>Add Customer </p>
            <button className='reject-btn' onClick={()=>{setIsAdd(false)}}><Close iconClass={'w-4 h-4'}/></button>
            </div>
            <AddCustomer compId={compId} setIsAdd={setIsAdd} setSearchCustomerData={setSearchCustomerData} setCustomerData={setCustomerData} customerData={customerData}/>
            </div>
            </div>
        
        </div>
        :
        null
        }

    </div>
  )
}

export default Customers