import { getDoc, onSnapshot, doc } from 'firebase/firestore'
import React,{useEffect, useState} from 'react'
import { avatarStorage_LINK } from '../../api/ApiUrl'
import {db, addWatchList, removeWatchList } from '../../config/firebaseConfig'
import Avatar from '../layout/Avatar'


const WatchBubbles = ({user,userId, taskId}) => {
    const [isWatching, setisWatching]=useState(false);
    
    const setRingColor=(watch)=>{
        if(watch){
            return 'ring-orange-500 dark:ring-orange-600'
        }else{
            return 'ring-slate-400 dark:ring-slate-600'
        }
    }


    useEffect(()=>{

        const iswatch=onSnapshot(doc(db, 'UserState', `${userId}`, 'watchlist', `${taskId}`), (doc)=>{
           var data=doc.data();
           setisWatching(data.watch);
        })

        return(()=>{
            iswatch();
        })
        
    },[])

    const watchToggle=(userId, taskId)=>{
        if(isWatching){
            removeWatchList(userId, taskId);
            setisWatching(false);
        }else{
            addWatchList(userId, taskId);
        }
    }
    
    return (
        <div onClick={()=>{watchToggle(userId, taskId)}} className={`${setRingColor(isWatching)} group relative z-50 rounded-full min-w-max min-h-max ring-2 ring-offset-1 ring-offset-slate-50 dark:ring-offset-slate-700`}>
            {user.image!=null?
            <img src={`${avatarStorage_LINK}${user.image}`} className='rounded-full w-9 h-9'/>
            :
            <Avatar value={`${user.Fname} ${user.Lname} `} width='9' height='9' color={'bg-blue-200 dark:bg-blue-800/70'} />
            }
            <p className='px-1 py-0.5 text-xs font-semibold scale-0 group-hover:scale-100 absolute transition-all duration-150 ease-in-out -top-6 left-0 origin-top-left bg-slate-700 text-white whitespace-nowrap rounded-md'>{user.Fname} {user.Lname}</p>
        </div>
      )
    }


export default WatchBubbles