import React from 'react'
import { ThemeContext } from './ThemeContext';
import { Sun, Moon } from './Icons';

const ThemeToggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);
  return (
    <div className="p-2 transition duration-500 ease-in-out rounded-full">
    {theme === 'dark' ? (
        <button
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            className="text-2xl cursor-pointer text-slate-500 dark:text-slate-400"
        > <Moon iconClass={'w-6 h-6'}/> </button>
    ) : (
            <button
                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                className="text-2xl cursor-pointer text-slate-500 dark:text-slate-400"
            ><Sun iconClass={'w-6 h-6'}/> </button>
        )}
</div>
  )
}

export default ThemeToggle