import React, {useRef, useEffect, useState,useCallback, useTransition} from 'react'
import PostTaskCustomer from './PostTaskCustomer'
import ReactPaginate from "react-paginate";
import Task from './Task';
import PostAddChildTask from './PostAddChildTask';
import TaskSideBar from './TaskSideBar';

import TaskFilters from './TaskFilters';
import axios from "../../api/axios";
import toast from 'react-hot-toast';
import { GETPROGRESSTASK } from '../../api/ApiUrl';
import { useSelector } from 'react-redux';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
const Tasklist = (
  {
    activeJob,
    addTask,
    isMyTasks,
    myTaskIds,
    setMyTaskIds,
    isCategory,
    catId,
    compId,
    tasks,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    jobTypes,
    shipments,
    productions,
    quotes,
    checklists,
    employeelist,
    fromDoneTask,
    title,
  }
) => {





  const [isFilterPending, startTransition]=useTransition();
  const user=useSelector((state)=>state.user.value);
  const [ParentTask,setParentTask]=useState([]);
  const [pinTasks,setPinTasks]=useState([]);

  const [showJob,setShowJob]=useState(null);
  const [showTaskId,setShowTaskId]=useState(null);
  const priorityOptions = [
    { value: "1", label: "low", color: "#22c55e" },
    { value: "2", label: "medium", color: "#3b82f6" },
    { value: "3", label: "high", color: "#f97316" },
    { value: "4", label: "urgent", color: "#ef4444" },
    { value: "5", label: "instant", color: "#ef4444" },
  ];
  const [watchTasks,setWatchTasks]=useState([]);

  useEffect(()=>{

    const watchtask=onSnapshot(collection(db, 'UserState', `${user.id}`, 'watchlist'), (collect)=>{
   
     const data=collect.docs.map(doc=>{return {...doc.data(), id: doc.id}})
    
     const arrayToObject = (array) =>
     array.reduce((obj, item) => {
       obj[item.id] = item.watch
       return obj
     }, {})
     setWatchTasks(arrayToObject(data));
      });

      return(()=>{
        watchtask();
      })
  },[])

const defaultTaskList=(tasks)=>{

  var defaultTasks=[];

  if(isCategory){
    var data=tasks.map(t=>({
      ...t,
      jobs:t.jobs.filter(j=>j.categoryId==catId && j.done==false)
    }))
    defaultTasks=data.filter((t)=>{return t.jobs.length>0})
    
  }else if(isMyTasks){
    var data=tasks.map(t=>({
      ...t,
      jobs:t.jobs.filter(mainJobs=>{return myTaskIds.find((taskIds)=>{return mainJobs.id==taskIds && mainJobs.done==false})})
    }))
    defaultTasks=data.filter((t)=>{return t.jobs.length>0})
  }
  
  else{
    if(!fromDoneTask){
      var data=tasks.map(t=>({
        ...t,
        jobs:t.jobs.filter(j=>j.done==false)
      }))
      defaultTasks=data.filter((t)=>{return t.jobs.length>0})
      
    }else{
      var data=tasks.map(t=>({
        ...t,
        jobs:t.jobs.filter(j=>j.done==true)
      }))
      defaultTasks=data.filter((t)=>{return t.jobs.length>0})
    }
  }
  
  var unPinData=defaultTasks.map(t=>({
    ...t,
    jobs:t.jobs.filter(j=>j.pinned==false)
  }))

  var unPinTasks=unPinData.filter((t)=>{return t.jobs.length>0})

  var pinned=defaultTasks.map(t=>({
    ...t,
    jobs:t.jobs.filter(j=>j.pinned==true)
  }))
  var pinnedTasks=pinned.filter((t)=>{return t.jobs.length>0})

  setParentTask(unPinTasks)
  setPinTasks(pinnedTasks)

}

useEffect(()=>{
defaultTaskList(tasks)
  },[tasks])

  const clearFunction=()=>{
    defaultTaskList(tasks)
  }

  const searchFuntion=(searchFilter, searchType)=>{
    startTransition(()=>{
    if(searchFilter.length>0){
      if(searchType==0){
        var data=tasks.map(t=>({
          ...t,
          jobs:t.jobs.filter(j=>{if(j.jobDesc.toLowerCase().includes(searchFilter.toLowerCase()) || j.id==searchFilter){return j}})
        }))
        defaultTaskList(data)
      }else{
        var data=tasks.filter((t)=>{
          if(t.customer!=null){
            if(t.customer.name.toLowerCase().includes(searchFilter.toLowerCase()) || 
              t.customer.email.toLowerCase().includes(searchFilter.toLowerCase()) ||
              (t.customer.company!=null && t.customer.company.toLowerCase().includes(searchFilter.toLowerCase())) ||
              (t.customer.postCode!=null && t.customer.postCode.toLowerCase().includes(searchFilter.toLowerCase())) 
              ){
              return t
            }
          }
        })
        
        setParentTask(data)
      }
      
            
      
    }else{
      defaultTaskList(tasks)
    }
  })

  }


  const [filterCompany, setFilterCompany]=useState(-1);
  const [filterCategory, setFilterCategory]=useState(-1);
  const [filterPriority, setFilterPriority]=useState(-1);
  const [filterJobStatus, setFilterJobStatus]=useState(-1);
  const [filterPaymentOption, setFilterPaymentOption]=useState(-1);
  const [filterPaymentMethod, setFilterPaymentMethod]=useState(-1);
  const [filterQuote, setFilterQuote]=useState(-1);
  const [filterJobType, setFilterJobType]=useState(-1);
  const [filterShipment, setFilterShipment]=useState(-1);
  const [filterProduction, setFilterProduction]=useState(-1);


  useEffect(()=>{
    startTransition(()=>{
    var data=tasks
    if(filterCompany>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.companyId===parseInt(filterCompany))
        }
      ))
    }
    if(filterCategory>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.categoryId===parseInt(filterCategory))
        }
      ))
    }
    if(filterPriority>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.priority===parseInt(filterPriority))
        }
      ))
    }

    if(filterJobStatus>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.jobStatus===parseInt(filterJobStatus))
        }
      ))
    }

    if(filterPaymentOption>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.paymentOption===parseInt(filterPaymentOption))
        }
      ))
    }

    if(filterPaymentMethod>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.paymentMethod===parseInt(filterPaymentMethod))
        }
      ))
    }

    if(filterQuote>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.quote===parseInt(filterQuote))
        }
      ))
    }

    if(filterJobType>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.jobType===parseInt(filterJobType))
        }
      ))
    }

    if(filterShipment>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.shipment===parseInt(filterShipment))
        }
      ))
    }

    if(filterProduction>0){
      data=data.map((t)=>(
        {
          ...t,
          jobs:t.jobs.filter(j=>j.production===parseInt(filterProduction))
        }
      ))
    }
    defaultTaskList(data)
  })

  },[filterCompany, 
    filterCategory, 
    filterPriority, 
    filterJobStatus, 
    filterPaymentOption,
    filterPaymentMethod,
    filterQuote,
    filterJobType,
    filterShipment,
    filterProduction,
    tasks,
    ])


  
    const [sideTaskId, setSideTaskId]=useState(-1);
    const [activeTask, setActiveTask]=useState(activeJob? activeJob : -1)
    const [sideTask, setSideTask]=useState([]);
    
    //pagination//
    const [pageNumber, setPageNumber]=useState(0);
  
    const tasksPerPage=20;
    const pagesVisited = pageNumber * tasksPerPage;
    const pageCount = Math.ceil(ParentTask.length/tasksPerPage);
  
    const pageTasks=ParentTask.slice(pagesVisited, pagesVisited+tasksPerPage)

    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };
    //pagination//

    const ProgressTypeTasks=async(type)=>{
      try{
        if(type>=0){
          await axios.get(`${GETPROGRESSTASK}${user.id}/${type}`).then((res)=>{
            var progressTaskIds=res.data.progressTaskIds;

            var data=tasks.map(t=>({
              ...t,
              jobs:t.jobs.filter(mainJobs=>{return progressTaskIds.find((taskIds)=>{return mainJobs.id==taskIds && mainJobs.done==false})})
            }))
            data=data.filter((t)=>{return t.jobs.length>0})
            defaultTaskList(data);
          })
        }else{
          defaultTaskList(tasks)
        }

      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }else{
          if(e.response.status==429){
            toast.error('too many requests! please try again later')
          }else{
            console.log(e);
            toast.error("Oops! An error occured!");
          }
        }
      }
    }



    // const bottom=useRef();
    // const [dropDirRev,setDropDirRev]=useState(false);
    // useEffect(()=>{
    //   const observer=new IntersectionObserver((entries)=>{
    //     if(entries[0].isIntersecting){
    //       setDropDirRev(true);
    //     }else{
    //       setDropDirRev(false);
    //     }
    //   })
  
    //   observer.observe(bottom.current);
    // },[])

  return (
    <div className='p-0 m-0'>
    
    
    <div className={`${!addTask? `block`: `hidden`} transition-all duration-150 ease-in-out relative z-[40] md:sticky top-0`}>
       
        <TaskFilters 
        isCategory={isCategory} 
        catId={catId}
        compId={compId} 
        isMyTask={isMyTasks} 
        fromDoneTask={fromDoneTask} 
        title={title} 
        isFilterPending={isFilterPending} 
        searchFunction={searchFuntion}
        clearFunction={clearFunction}
        jobStatuses={jobStatuses}
        priorityOptions={priorityOptions}
        paymentOptions={paymentOptions}
        paymentMethods={paymentMethods}
        jobTypes={jobTypes}
        shipments={shipments}
        productions={productions}
        quotes={quotes}
        setFilterCompany={setFilterCompany}
        setFilterCategory={setFilterCategory}
        setFilterPriority={setFilterPriority}
        setFilterJobStatus={setFilterJobStatus}
        setFilterPaymentOption={setFilterPaymentOption}
        setFilterPaymentMethod={setFilterPaymentMethod}
        setFilterQuote={setFilterQuote}
        setFilterJobType={setFilterJobType}
        setFilterShipment={setFilterShipment}
        setFilterProduction={setFilterProduction}
        ProgressTypeTasks={ProgressTypeTasks}
        />
      </div>
      
   
      <div className='overflow-x-auto overflow-y-hidden'>
      
      {pinTasks.length>0?
        <div className='p-1 mt-1 border border-red-400 rounded-md dark:border-red-800/50'>
      <div className='flex items-center justify-between gap-1'>
        <p className='text-xs italic font-semibold text-left text-red-500 dark:text-red-600'>Pinned Tasks</p>
      </div>
    {pinTasks.map((t, index)=>(
        <div key={index} className='mb-3'>
            {t.jobs.length>0?
              <>
              {t.customer!=null?
              <PostTaskCustomer sl={pagesVisited+index+1} key={t.referenceId} referenceId={t.referenceId} customer={t.customer}/> 
            
              :
              <div className='flex items-center justify-start gap-1 bg-slate-50 dark:bg-slate-900 rounded-t-md'>
                <p className='px-1 ml-1 text-xs font-semibold rounded-md text-slate-600 bg-slate-300 dark:bg-slate-600 dark:text-slate-200'>Sl.{pagesVisited+index+1}</p>
                <p className='text-sm text-slate-500 rounded-t-md'>unlisted</p>
              </div>

              }
             
              <div>
              {t.jobs.map((j)=>(
                <Task key={j.id}
                job={j}
                referenceId={t.referenceId}
                jobStatuses={jobStatuses}
                priorityOptions={priorityOptions}
                paymentOptions={paymentOptions}
                paymentMethods={paymentMethods}
                jobTypes={jobTypes}
                shipments={shipments}
                productions={productions}
                quotes={quotes}
                checklists={checklists}
                employeelist={employeelist}
                sideTaskId={sideTaskId}
                setSideTaskId={setSideTaskId}
                setSideTask={setSideTask}
                fromDoneTask={fromDoneTask}
                activeTask={activeTask}
                dropRev={false}
                watching={watchTasks[j.id]}
                />
              ))}
            
              {t.customer!=null && !fromDoneTask?
              <PostAddChildTask
              catId={catId}
              compId={compId}
              referenceId={t.referenceId}
              custId={t.customer.id}
              prevLength={t.jobs.length}
              isCategory={isCategory}
              jobStatuses={jobStatuses}
              priorityOptions={priorityOptions}
              paymentOptions={paymentOptions}
              paymentMethods={paymentMethods}
              jobTypes={jobTypes}
              shipments={shipments}
              productions={productions}
              quotes={quotes}
              checklists={checklists}
              employeelist={employeelist}
              isMyTask={isMyTasks}
              setMyTaskIds={setMyTaskIds}
              myTaskIds={myTaskIds}
              />
              :
              null}
        
            </div>

            </>
             :
            null
            }
        </div>
    ))}
    </div>
   :
   null
    }


    
    {pageTasks.length>0?
    <div className={`${pinTasks.length>0 ? `mt-4` : `mt-1`}`}>
       <p className='text-xs italic font-semibold text-right text-slate-400 dark:text-slate-600'>Showing {pagesVisited+1}-{pagesVisited+tasksPerPage>ParentTask.length? ParentTask.length : pagesVisited+tasksPerPage} of {ParentTask.length} tasks</p>
    {pageTasks.map((t, index)=>(
        <div key={index} className='mb-3'>
            {t.jobs.length>0?
              <>
              {t.customer!=null?
              <PostTaskCustomer sl={pagesVisited+index+1} key={t.referenceId} referenceId={t.referenceId} customer={t.customer}/> 
            
              :
              <div className='flex items-center justify-start gap-1 bg-slate-50 dark:bg-slate-900 rounded-t-md'>
                <p className='px-1 ml-1 text-xs font-semibold rounded-md text-slate-600 bg-slate-300 dark:bg-slate-600 dark:text-slate-200'>Sl.{pagesVisited+index+1}</p>
                <p className='text-sm text-slate-500 rounded-t-md'>unlisted</p>
              </div>

              }
             
              <div>
              {t.jobs.map((j, i)=>(
                <div key={`${j.id}-taskWrapper`}>
                {i<=6 &&
                <Task key={j.id}
                job={j}
                referenceId={t.referenceId}
                jobStatuses={jobStatuses}
                priorityOptions={priorityOptions}
                paymentOptions={paymentOptions}
                paymentMethods={paymentMethods}
                jobTypes={jobTypes}
                shipments={shipments}
                productions={productions}
                quotes={quotes}
                checklists={checklists}
                employeelist={employeelist}
                sideTaskId={sideTaskId}
                setSideTaskId={setSideTaskId}
                setSideTask={setSideTask}
                fromDoneTask={fromDoneTask}
                activeTask={activeTask}
                dropRev={index>=pageTasks.length-2}
                watching={watchTasks[j.id]}
                />
              }
                 </div>
                
              ))}

              {t.jobs.length>6 && 
              <>
                  {showJob!=null && index===showTaskId &&
                  <div className='border-t rounded-md border-slate-400 dark:border-slate-600'>
                  <Task key={showJob.id}
                  job={showJob}
                  referenceId={showJob.referenceId}
                  jobStatuses={jobStatuses}
                  priorityOptions={priorityOptions}
                  paymentOptions={paymentOptions}
                  paymentMethods={paymentMethods}
                  jobTypes={jobTypes}
                  shipments={shipments}
                  productions={productions}
                  quotes={quotes}
                  checklists={checklists}
                  employeelist={employeelist}
                  sideTaskId={sideTaskId}
                  setSideTaskId={setSideTaskId}
                  setSideTask={setSideTask}
                  fromDoneTask={fromDoneTask}
                  activeTask={activeTask}
                  dropRev={index>=pageTasks.length-2}
                  watching={watchTasks[showJob.id]}
                  />
                   </div>
                  }


                  <div className='flex items-center justify-start gap-2 px-2 py-1.5 bg-white dark:bg-slate-800 flex-wrap rounded-b-lg'>
                  {t.jobs.map((j, i)=>(
                    
                    i>6 &&
                      <p key={`toggleTask${i}`} onClick={()=>{setShowJob(j); setShowTaskId(index)}} className={`${showJob!=null && showJob.id==j.id? `text-orange-500 dark:text-orange-600` : `text-slate-600 dark:text-slate-400 `} inline-flex items-center gap-2 px-1 text-xs font-semibold rounded-md cursor-pointer hover:bg-orange-500 dark:hover:bg-orange-600 hover:text-slate-50 dark:hover:text-slate-50 `}>{j.id}</p>
                    
                   
                   ))}
                  </div>

                  </>
                }
            
              {t.customer!=null && !fromDoneTask?
              <PostAddChildTask
              catId={catId}
              compId={compId}
              referenceId={t.referenceId}
              custId={t.customer.id}
              prevLength={t.jobs.length}
              isCategory={isCategory}
              jobStatuses={jobStatuses}
              priorityOptions={priorityOptions}
              paymentOptions={paymentOptions}
              paymentMethods={paymentMethods}
              jobTypes={jobTypes}
              shipments={shipments}
              productions={productions}
              quotes={quotes}
              checklists={checklists}
              employeelist={employeelist}
              isMyTask={isMyTasks}
              setMyTaskIds={setMyTaskIds}
              myTaskIds={myTaskIds}
              />
              :
              null}
        
            </div>

            </>
             :
            null
            }
        </div>
    ))}
    </div>
    :
    <p className='mt-5 text-lg font-semibold text-center text-slate-400 dark:text-slate-700'>No Data</p>
    }

   

          {/* <div ref={bottom}></div> */}
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination-container'}
              previousLinkClassName={'prevLink'}
              nextLinkClassName={'nextLink'}
              pageLinkClassName={'pageLink'}
              breakLinkClassName={'breakLink'}
              disabledClassName={'disablePageLink'}
              activeLinkClassName={'activePageLink'}
              />

            <div className={`sidebarRight2 ${sideTaskId!=-1? 'right-0' : '-right-full'}`}>
                  <TaskSideBar
                  sideTaskId={sideTaskId}
                  sideTask={sideTask}
                  setSideTaskId={setSideTaskId}
                  employeelist={employeelist}
                  checklists={checklists}
                  />
            </div>

          </div>    
    </div>
  )
}

export default Tasklist