import { createSlice } from "@reduxjs/toolkit";

const initialStateValue=[];

export const customerSlice=createSlice({
    name:'customers',
    initialState:{value:initialStateValue},
    reducers:{
        setCustomer:(state,action)=>{
            state.value=action.payload
        },
        delCustomer:(state,action)=>{
            var id=action.payload
            state.value=state.value.filter(cust=>cust.id!=id)
        }
    }
})

export const {setCustomer, delCustomer}=customerSlice.actions
export default customerSlice.reducer;