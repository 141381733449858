import React from 'react'

import WatchBubbles from '../Messenger/WatchBubbles';


const WatchTask = ({assigneeList, taskId}) => {

    
  return (
    <div>
        <div className='flex items-center justify-between gap-1 mb-2 border-b border-slate-400 dark:border-slate-700'>
        <h4 className='text-lg font-semibold text-slate-600 dark:text-slate-400'>WatchList</h4>
        </div>
        {assigneeList.length>0?
            <div className='flex items-center justify-start gap-3 p-2 rounded-3xl bg-slate-50 dark:bg-slate-800'> 
                {assigneeList.map((user,i)=>(
                    <WatchBubbles key={user.id} user={user} userId={user.userId} taskId={taskId}/>
                ))}
            </div>
            :
           null
        }

    </div>
  )
}

export default WatchTask