import React from 'react'
import { MSGIMG_STORAGE_LINK } from '../../api/ApiUrl'
import { Close } from '../layout/Icons'

const ReplyMessageView = ({msg, uid,setShowReply, setReplyMsgId}) => {
  return msg?(
    <div className='absolute w-full bottom-12'>  
    <div className='flex items-start justify-between gap-1 -mb-2 dark:bg-slate-800/50 bg-slate-400/50 backdrop-blur-md rounded-l-3xl rounded-t-3xl'>
    <div className='px-0.5 opacity-80'>
        {msg.msgType===0 && 
         <div className={`${msg.uid===uid? `sender` : `reciever`}`}>
         <p className={`msgText bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-300`} style={{borderRadius:'24px'}}>
          {msg.msg}
        </p>
         </div>
        }

        {msg.msgType===5 && 
         <div className={`${msg.uid===uid? `sender-Img-bg` : `reciever-Img-bg`}`}>
            {msg.imgs.length>1?
            <div className={`flex flex-wrap items-center justify-start  gap-1`}>
                {msg.imgs.map((img,index)=>(
                <img key={index} src={`${MSGIMG_STORAGE_LINK}${img}`} className='object-cover w-16 h-16 rounded-md min-h-max min-w-max' alt='msg-image'/>
                ))}
            </div>
            :
            <div>
                {msg.imgs.map((img,index)=>(
                <img key={index} src={`${MSGIMG_STORAGE_LINK}${img}`} className='w-64 h-auto rounded-md max-w-max max-h-max'  alt='msg-image'/>
                ))}
            </div>
            }
            {msg.msg.length>0 &&
            <p>{msg.msg}</p>
            }
        </div>
        }

    </div>
    <button onClick={()=>{setShowReply(false); setReplyMsgId(null)}} className='mt-1 mr-1 msgOp-btn hover:bg-red-500 hover:text-slate-50'><Close iconClass={'w-4 h-4'}/></button>
    </div>
    </div>
  ):
  null
}

export default ReplyMessageView