import React,{ useEffect, useRef, useCallback} from 'react'
//import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from "uuid";
import { doc, updateDoc } from "firebase/firestore";
import {db} from '../../config/firebaseConfig'
import NotificationBar from './NotificationBar';
const NotificationPanel = ({notifications, fetchmoreNotification, load, setLoad, isEmpty, EndData, setShowNotification}) => {
  
  function subtractTimeFromDate(objDate, intHours) {
    var numberOfMlSeconds = objDate.getTime();
    var addMlSeconds = (intHours * 60) * 60 * 1000;
    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    return newDateObj;
  }

  const observer=useRef();

  const lastNotification=useCallback(node=>{
    if(load) return

    if(observer.current){
      observer.current.disconnect()
    }
      observer.current=new IntersectionObserver(entries=>{
        if(entries[0].isIntersecting){
          if(!isEmpty && !EndData){
            setLoad(true)
            fetchmoreNotification() 
          }
        }
      })
    if(node){
      observer.current.observe(node)
    }


  },[load]);

  
  useEffect(() => {
    let data=notifications.filter(d=>d.read==false);
    if(data.length>0){
      data.map((n,i)=>{
        const timer= setTimeout(() => {
          readNotification(n.id)
        }, 2000);
        return () => clearTimeout(timer);
        
      })
      data=[];
    }
    
  }, [notifications]);

  const readNotification=async(id)=>{
    const notifyDoc = doc(db, "Notifications", id);
    await updateDoc(notifyDoc, {
      read: true
    });
  }

  return (
    <div className='fixed z-[99] left-0 top-14 h-[98vh] md:h-[99vh] md:top-1 md:left-14 w-full md:w-1/3 lg:w-1/4 xl:w-1/5 bg-slate-50 rounded-md dark:bg-[#0B0F1C] p-1 shadow-lg shadow-slate-400 dark:shadow-slate-900'>
      
       <h4 className='mx-2 text-lg font-bold text-slate-600 dark:text-slate-300 whitespace-nowrap'>Notifications</h4>
       
       {notifications.length>0 &&
       <div className='overflow-y-auto max-h-[93vh] md:max-h-[95vh]'>
         {notifications[0].createdAt.toDate()>=subtractTimeFromDate(new Date(), 4) &&
         <h4 className='mx-2 font-bold text-slate-600 dark:text-slate-300 whitespace-nowrap'>Recent</h4>
         }
        {notifications.map((n,i)=>(
          <div key={uuidv4()}>
           {n.createdAt.toDate()>=subtractTimeFromDate(new Date(), 4) &&
           <>
           {notifications.length===i+1?
            <div ref={lastNotification}>
            <NotificationBar notification={n} setShowNotification={setShowNotification}/>
           </div>
            :
          <div>
            <NotificationBar notification={n} setShowNotification={setShowNotification}/>
           </div>
            }
            </>
          }
          </div>
          ))
        }

        <h4 className='mx-2 font-bold text-slate-600 dark:text-slate-300 whitespace-nowrap'>Earlier</h4>
            {notifications.map((n,i)=>(
              <div key={uuidv4()}>
              {n.createdAt.toDate()<=subtractTimeFromDate(new Date(), 4) &&
                <>

                {notifications.length===i+1?
                <div ref={lastNotification}>
                  <NotificationBar  notification={n} setShowNotification={setShowNotification}/>
                </div>
                :
                <div>
                <NotificationBar  notification={n} setShowNotification={setShowNotification}/>
                </div>
               }
                </>

              }
              </div>

              ))
            }
        {load &&
       <div className='w-full h-12 bg-slate-200 dark:bg-slate-800 animate-pulse'>

       </div>
        }
       
        </div>
        }
    
        {isEmpty &&
        <h4 className='mx-2 font-bold text-center text-slate-500 whitespace-nowrap'>No Notification</h4>
        }
    </div>
  )
}

export default NotificationPanel