import React from 'react'

const CategoryCardLoader = () => {
  return (
   
        <div className='flex items-center justify-start gap-2 group'>
        <div className='w-10 h-10 rounded-full bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
        <div className='w-24 h-5 bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
        </div> 

  )
}

export default CategoryCardLoader