import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from '@headlessui/react'
import { useNavigate} from "react-router-dom";

import { Tune, Template, Group, Image, ChevronRight, Eye, ChevronLeft, Pencil, Close, DotsCircle  } from '../layout/Icons';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import Avatar from '../layout/Avatar';
import Select from "react-select";
import { v4 as uuidv4 } from 'uuid';
import {ThemeContext} from '../layout/ThemeContext';

import OptionList from '../layout/sortableOptions/OptionList';
import Checklist from '../layout/sortableChecklist/Checklist';
import toast from 'react-hot-toast';
import axios from "../../api/axios";

import { avatarStorage_LINK,  REGISTER_URL, EMPLOYEES_URL} from '../../api/ApiUrl';
import { setCategory } from '../../features/categories';
import CustomEditor from '../layout/CustomEditor';
import { requireRefresh } from '../../config/firebaseConfig';


const CreateCategory = () => {
    const user=useSelector((state)=>(state.user.value))
    const categs=useSelector((state)=>state.category.value)
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const { theme } = React.useContext(ThemeContext); //to apply darkmode in elements
    
    const [employees,setEmployees]=useState([]);
    const [access, setAccess]=useState([{id:user.id, role:2}])

const [searchEmp, setSearchEmp]=useState('');
const [searchEmployeeData, setSearchEmployeeData]=useState([]);

useEffect(()=>{
    if(searchEmp.length>0){
      const data = employees.filter((em) => {
        if (
          em.Fname.toLowerCase().includes(searchEmp.toLowerCase()) ||
          em.Lname.toLowerCase().includes(searchEmp.toLowerCase()) ||
          em.email.toLowerCase().includes(searchEmp.toLowerCase()) ||
          em.designation.toLowerCase().includes(searchEmp.toLowerCase())
          ) {
            return em;
          } else {
            return null;
          }
        });
        setSearchEmployeeData(data);
    }else{
      setSearchEmployeeData(employees);
    }
  
  }, [searchEmp])

  const [selectCompanyData, setSelectCompanyData]=useState([]);
  const [selectedCompany, setSelectedCompany]=useState(null);

  const getEmployees = async (id)=>{
    await axios.get(`${EMPLOYEES_URL}${id}`).then((res)=>{
        setEmployees(res.data.employees);
        setSearchEmployeeData(res.data.employees);
    })
  }
  
  useEffect(()=>{
    getEmployees(selectedCompany);

    return ()=>{
      setEmployees([]);
      setSearchEmployeeData([]);
    }
  },[selectedCompany])


  const giveAccess=(id)=>{
    if(id==user.id){
      toast('can not change owner roles', {icon:'🙅‍♂️'});
      return
    }
    const find=access.filter((ac)=>ac.id==id)
    

    if(find.length>0){
      var change=null;
      if(find[0].role===0){
        change = access.map((i)=>
        i.id===id?
        {
          ...i,
          role:1,
  
        }:i
        )
        setAccess(change)
      }else if(find[0].role===1){
        change = access.map((i)=>
        i.id===id?
        {
          ...i,
          role:2,
  
        }:i
        )
        setAccess(change)
      }else if(find[0].role===2){
        change = access.map((i)=>
        i.id===id?
        {
          ...i,
          role:-1,
  
        }:i
        )
        setAccess(change)
      }else if(find[0].role===-1){
        change = access.map((i)=>
        i.id===id?
        {
          ...i,
          role:0,
  
        }:i
        )
        setAccess(change)
      }
    }else{
      const data ={id:id, role:0}
      setAccess([...access, data]);
    }

  }

  useEffect(()=>{
    const getCompany=async()=>{
        const res=await axios.get(`/api/getCompany/${user.id}`)
        const selectData = res.data.companies.map((c)=>(
          {value:c.id,
          label:c.name}
        ))
        setSelectCompanyData(selectData);
      }
      getCompany();
      
      return ()=>{
        setSelectCompanyData([])
      }
  },[])


  const [tab, setTab]=useState(0);
  
  //category information//
  const [icon, setIcon]=useState(null);
  const [iconUrl,setIconUrl]=useState(null);
  const [catName,setCatName]=useState('');
  const [catDesc, setCatDesc]=useState('')
  const [isPrice,setIsprice]=useState(false);
  const [isNote, setIsnote]=useState(false);

  const [isChecklist, setIschecklist]=useState(false);

  const [isRepeat,setIsrepeat]=useState(false);
  const [isJobType, setIsjobtype]=useState(false);
  const [isShipment, setIsshipment]=useState(false);
  const [isProd,setIsprod]=useState(false);
  const [isQuote, setIsquote]=useState(false);
  
  const [note,setNote]=useState(EditorState.createEmpty());
  
  const [rawNote, setRawNote]=useState(null);
  
  const updateTextDescription = async (state) => {
    await setNote(state);
    setRawNote(draftToHtml(convertToRaw(note.getCurrentContent())));

  };
  
  const uploadFile=(e)=>{
    if(e.target.files && e.target.files[0]){
      setIcon(e.target.files[0]);
      setIconUrl(URL.createObjectURL(e.target.files[0]))
      
    }
  }


  const [status, setStatus]=useState([
    {id:uuidv4(), name:'Pending', color:'#f97316', mathFunc:-1, default:false},
    {id:uuidv4(), name:'Collected', color:'#22c55e', mathFunc:1, default:false},

  ])
  
  const [jobType, setJobType]=useState([
    {id:uuidv4(), name:'', color:'#f97316'},
    {id:uuidv4(), name:'', color:'#22c55e'},
  ])

  const [shipment, setShipment]=useState([
    {id:uuidv4(), name:'Delivery', color:'#f97316'},
    {id:uuidv4(), name:'Collection', color:'#22c55e'},
  ])

  const [production, setProduction]=useState([
    {id:uuidv4(), name:'Inhouse', color:'#f97316'},
    {id:uuidv4(), name:'Outsource', color:'#22c55e'},
  ])

  const [quote, setQuote]=useState([
    {id:uuidv4(), name:'QT Sent', color:'#f97316'},
    {id:uuidv4(), name:'QT accepted', color:'#22c55e'},
  ])

  const [payment, setPayment]=useState([
    {id:uuidv4(), name:'Due', color:'#e11d48', mathFunc:1, default:true},
    {id:uuidv4(), name:'Partial', color:'#f97316', mathFunc:2, default:true},
    {id:uuidv4(), name:'Full', color:'#22c55e', mathFunc:3, default:true},
    {id:uuidv4(), name:'Complimentary', color:'#0c797d', mathFunc:4, default:true},
  ])

  const [method, setMethod]=useState([
    {id:uuidv4(), name:'BACs', color:'#2563eb'},
    {id:uuidv4(), name:'Card', color:'#f97316'},
    {id:uuidv4(), name:'Cash', color:'#22c55e'},
  ])

  const [checks, setChecks]=useState([
    {id:uuidv4(), name:'', checked:false, default:true},
  ])

  const customStyles = {
    menu: (provided, state) => {
      let backgroundColor = "#f1f5f9";
      if (theme === "dark") {
        backgroundColor = "#334155";
      }

      return {
        ...provided,
        padding: 0,
        fontSize: 12,
        margin: 2,
        backgroundColor,
      };
    },
    option: (provided, state) => ({
      ...provided,
      padding: 3,
      color: state.isSelected ? "orangered" : "#64748b",
      backgroundColor: state.isSelected ? "#cbd5e1" : "",
      backgroundColor: state.isFocused ? "#cbd5e1" : "",
    }),

    control: (provided) => ({
      ...provided,
      fontSize: 13,
      padding: 1,
      // height: 24,
      minHeight: 14,
      border: 1,
      borderRadius: "0.375rem",
      boxShadow: "none",
      backgroundColor: "transparent",
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgb(148 163 184)",
    }),
    valueContainer: () => ({
      fontSize: 13,
      padding: 0,
      margin: 0,
      minHeight: 17,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    }),

    IndicatorsContainer: () => ({
      padding: 0,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: () => () => null,

    dropdownIndicator: () => ({
      paddingLeft: 2,
      opacity: 0.5,
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: 1,
      padding: 1,
      height: 24,
      backgroundColor: "#cbd5e1",
      justifyContent: "space-between",
    }),
    MultiValueContainer: (provided) => ({
      ...provided,
      minHeight: 14,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: 1,
      height: 20,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: 0,
      height: 20,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      let color = "rgb(71 85 105)";

      if (theme === "dark") {
        color = "rgb(148 163 184)";
      }

      return { ...provided, opacity, transition, color };
    },
  };


  const optionValidCheck=()=>{

    //job Status//
    var checkEmptyStatus=status.filter((st)=>st.name.length==0);
      
      if(checkEmptyStatus.length>0){
        toast.error('There are empty options in Job Status!')
        return
      }
    //job Status//

    
    //job Type//
    if(isJobType){
      var checkEmptyJobType=jobType.filter((st)=>st.name.length==0);
      
      if(checkEmptyJobType.length>0){
        toast.error('There are empty options in Job Type!')
        return
      }
    }
    //Job Type//
      

     //Shipment//
     if(isShipment){
       var checkEmptyShipment=shipment.filter((st)=>st.name.length==0);
       if(checkEmptyShipment.length>0){
         toast.error('There are empty options in Shipment!')
         return
        }
      }
      //Shipment//

     //Production//
     if(isProd){

       var checkEmptyProduction=production.filter((st)=>st.name.length==0);
       
       if(checkEmptyProduction.length>0){
         toast.error('There are empty options in Production!')
         return
        }
      }
     //Production//

    //Quotation//
    if(isQuote){

      var checkEmptyQuote=quote.filter((st)=>st.name.length==0);
      
      if(checkEmptyQuote.length>0){
        toast.error('There are empty options in Quote!')
        return
      }
    }
      //Quotation//


      if(isPrice){
  //Payment//
    var checkEmptyPayment=payment.filter((st)=>st.name.length==0);
    
    if(checkEmptyPayment.length>0){
      toast.error('There are empty options in Payment!')
      return
    }
    //Payment//
    
    //method//
    var checkEmptyMethod=method.filter((st)=>st.name.length==0);
    
    if(checkEmptyMethod.length>0){
      toast.error('There are empty options in Payment method!')
      return
    }
    //method//
  }

  if(isChecklist){
    //checks//
    var checkEmptyChecklist=checks.filter((st)=>st.name.length==0);
    
    if(checkEmptyChecklist.length>0){
      toast.error('There are empty options in Checklist!')
      return
    }
    //checks//
  }

  if(isNote && rawNote==null){
      toast.error('The notes are empty!')
      return
  }

    setTab(tab+1)
    
  }

  const registerCompany =async ()=>{

    const validate = toast.loading('validating data...', {icon: '📃'});

    try{
      const formData = new FormData();
      formData.append("companyId", selectedCompany);
      formData.append("userId", user.id)
      formData.append("name", catName);
      formData.append("description", catDesc);
      //icon
      if(icon!=null){
        formData.append("icon", icon);
      }else{
        formData.append("icon", '');
      }

      //icon
      var uploadStatus=status.map((data)=>({
          name:data.name,
          color:data.color,
          mathFunc:data.mathFunc,
      }))
      formData.append("jobStatus", JSON.stringify(uploadStatus));
      if(isPrice){
        formData.append("isPrice", isPrice);
        var uploadPayment=payment.map((data)=>({
          name:data.name,
          color:data.color,
          mathFunc:data.mathFunc,
      }))
        formData.append("payment", JSON.stringify(uploadPayment));
        var uploadMethod=method.map((data)=>({
          name:data.name,
          color:data.color,
         
      }))
        formData.append("method", JSON.stringify(uploadMethod));
      }

      if(isJobType){
        formData.append("isJobType", isJobType);
        var uploadJobType=jobType.map((data)=>({
          name:data.name,
          color:data.color
        }))
        formData.append("jobType", JSON.stringify(uploadJobType));
      }

      if(isShipment){
        formData.append("isShipment", isShipment);
        var uploadShipment=shipment.map((data)=>({
          name:data.name,
          color:data.color
        }))
        formData.append("shipment", JSON.stringify(uploadShipment));
      }

      if(isProd){
        formData.append("isProduction", isProd);
        var uploadProduction=production.map((data)=>({
          name:data.name,
          color:data.color
        }))
        formData.append("production", JSON.stringify(uploadProduction));
      }

      if(isQuote){
        formData.append("isQuote", isQuote);
        var uploadQuote=quote.map((data)=>({
          name:data.name,
          color:data.color
      }))
        formData.append("quote",JSON.stringify(uploadQuote));
      }

      if(isNote){
        formData.append("isNote", isNote);
        formData.append("defNotes",rawNote);
      }

      if(isChecklist){
        formData.append("isChecklist", isChecklist);
        var uploadCheck=checks.map((data)=>({
          name:data.name,
          checked:data.checked,
          default:data.default
        }))
        formData.append("defChecks", JSON.stringify(uploadCheck));
      }

      if(isRepeat){
        formData.append("isRepeat", isRepeat);
      }
      
      formData.append("access", JSON.stringify(access));

      toast.dismiss(validate);
      const reg = toast.loading('Registering category...', {icon: '📝'});

      await axios.post(REGISTER_URL, formData).then((res)=>{
        toast.dismiss(reg);
        toast.success('Category Created!');
        var category=res.data.category;
        const refresh=res.data.needsRefresh;
   
        refresh.filter(r=>r!=user.id).map(ref=>{
          requireRefresh(ref, true, true, true)
        })     
 
        category={...category, roleId:2}
        dispatch(setCategory([...categs, category]))

        navigate(`/category/${selectedCompany}/${category.id}`);
      })
      

    }catch(e){
      if(!e?.response){
        toast.error("No Server Response!");
        toast.dismiss(validate);
        console.log(e);
    }else{
        console.log(e);
        toast.error("Oops! An error occured!");
        toast.dismiss(validate);
    }
    }
    
  }

  return (
    <div>
   
        <div className='relative main-bodyLayout'>
           
            <Tab.Group manual vertical selectedIndex={tab}>
            <div className='grid grid-cols-1 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8'>
              <Tab.List className={'tabList'}>
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'}><Template iconClass={'w-4 h-4'}/> <span className='hidden md:block'>General</span> </Tab>
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'} ><Tune iconClass={'w-4 h-4'}/> <span className='hidden md:block'>Options</span></Tab>
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'} ><Group iconClass={'w-4 h-4'}/> <span className='hidden md:block'>Accessibility</span></Tab>
              </Tab.List>
              <div className='md:col-span-4 lg:col-span-5 xl:col-span-6 2xl:col-span-7'>
              <Tab.Panels className={'tabPanel'}>
                <Tab.Panel>
                <h4 className='px-4 my-2 text-xl font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>General</h4>
                <div className='p-2 my-2 '>
                <h4 className='mb-4 text-lg font-semibold text-slate-600 dark:text-slate-400'>Create new Category</h4>
                <div className='flex flex-col items-center justify-center mb-2'>
                <input type='file' accept='image/jpg, image/png, image/jpeg, image/gif' onChange={(e)=>uploadFile(e)} className='hidden' id="imgUpload"/>
                <label htmlFor='imgUpload' className='w-16 h-16 transition-all duration-150 ease-in-out scale-100 border rounded-md cursor-pointer border-slate-400 text-slate-400 dark:border-slate-600 dark:text-slate-600 hover:scale-105'>
                  {iconUrl!=null?
                  <img src={iconUrl}/>
                  :
                  <Image iconClass={'w-14 h-14 min-h-full m-auto'}/>
                  }

                </label>
                  <span className='text-xs font-semibold text-slate-400 dark:text-slate-600'>Upload icon</span>
                  <span className='text-xs font-semibold text-slate-400 dark:text-slate-600'>image (200x200) - .jpg, .png, .jpeg, .gif </span>
                </div>

                <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                  <p className='font-semibold lg:text-right text-slate-600 dark:text-slate-400'>*Title:</p>
                <input type='text' value={catName} onChange={(e)=>setCatName(e.target.value)} className='input-field' placeholder='Category Title' required/>
                </div>

                <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                  <p className='font-semibold lg:text-right text-slate-600 dark:text-slate-400'>*Company:</p>
                  <Select onChange={(op)=>{setSelectedCompany(op.value)}} className='input-field' isSearchable={false} styles={customStyles} options={selectCompanyData} required/>
                </div>

                <div className='grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:w-2/3 xl:1/3'>
                  <p className='font-semibold lg:text-right text-slate-600 dark:text-slate-400'>Description:</p>
                <textarea type='text' value={catDesc} onChange={(e)=>setCatDesc(e.target.value)} className='input-field' placeholder='description'/>
                </div>

                <div className='flex items-center justify-center gap-2 my-5'>
                <button className='default-btn' disabled={true}><ChevronLeft iconClass={'w-4 h-4'} /> Previous</button>
                <button className='default-btn' onClick={()=>setTab(tab+1)} disabled={ !(catName.length>2 && selectedCompany!=null) }>Next <ChevronRight iconClass={'w-4 h-4'}/></button>
        
                </div>  
                </div>
                </Tab.Panel>
                <Tab.Panel className={'p-4'}>
                <h4 className='px-4 my-2 text-xl font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Options</h4>
                <h4 className='px-4 my-2 font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Primary Options</h4>
                <p className='text-sm text-slate-500'>Please check the options you need for this category to create a task.</p>
                <div className='grid items-center grid-cols-2 gap-2 p-2 border rounded-md md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 border-slate-300 dark:border-slate-700'>
                  
                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='jobType' onChange={()=>setIsjobtype(!isJobType)} checked={isJobType} />
                    <label htmlFor='jobType' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Job Type</label>
                  </div>
                 
                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='shipment' onChange={()=>setIsshipment(!isShipment)} checked={isShipment} />
                    <label htmlFor='shipment' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Shipment</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='prod' onChange={()=>setIsprod(!isProd)} checked={isProd} />
                    <label htmlFor='prod' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Production</label>
                  </div>
                  
                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='quote' onChange={()=>setIsquote(!isQuote)} checked={isQuote}/>
                    <label htmlFor='quote' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Quote</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='price' onChange={()=>setIsprice(!isPrice)} checked={isPrice}/>
                    <label htmlFor='price' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Price</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='note' onChange={()=>setIsnote(!isNote)} checked={isNote}/>
                    <label htmlFor='note' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Notes</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='check' onChange={()=>setIschecklist(!isChecklist)} checked={isChecklist}/>
                    <label htmlFor='check' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>checklist</label>
                  </div>

                  <div className='flex items-center justify-start gap-1'>
                    <input type='checkbox' className='checkbox-checklist' id='repeat' onChange={()=>setIsrepeat(!isRepeat)} checked={isRepeat}/>
                    <label htmlFor='repeat' className='m-0 text-sm cursor-pointer text-slate-600 dark:text-slate-400'>Repeater</label>
                  </div>

                </div>
                <h4 className='px-4 my-2 mt-6 font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Variable Options</h4>
                <p className='text-sm text-slate-500'>create custom options for various selection fields for this category. min 2 options are required to activate an option. <br></br>
                <strong>you need to create atleast 2 job status options to proceed</strong>
                </p>
                  <div className='grid items-start grid-cols-1 gap-2 my-2 md:grid-cols-2 lg:grid-cols-4'>
                    
                    {/* option */}
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Job Status</p>
                        <OptionList options={status} setOptions={setStatus} isMathFunc={true} isStatus={true}/>
                    </div>
                   
                    {/* option */}
                    {isJobType ? 
                     <div className='option-grid'>
                     <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Job Type</p>
                         <OptionList options={jobType} setOptions={setJobType} isMathFunc={false} isStatus={false}/>
                     </div>
                    
                    : null}
                    {/* option */}
                   
                    {/* option */}
                    {isQuote ? 
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Quote</p>
                        <OptionList options={quote} setOptions={setQuote} isMathFunc={false} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                    {/* option */}
                    {isShipment? 
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Shipment</p>
                        <OptionList options={shipment} setOptions={setShipment} isMathFunc={false} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                    {/* option */}
                    {isProd? 
                    <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Production</p>
                        <OptionList options={production} setOptions={setProduction} isMathFunc={false} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                    {/* option */}
                    {isPrice? 
                     <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Payment</p>
                        <OptionList options={payment} setOptions={setPayment} isMathFunc={true} isStatus={false}/>
                    </div>
                    :null}

                    {/* option */}

                     {/* option */}
                     {isPrice? 
                     <div className='option-grid'>
                    <p className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Payment method</p>
                        <OptionList options={method} setOptions={setMethod} isMathFunc={false} isStatus={false}/>
                    </div>
                    :null}
                    {/* option */}

                    {isChecklist? 
                      <div className='option-grid'>
                      <h4 className='p-1 font-semibold text-center text-slate-600 dark:text-slate-400 bg-slate-300 dark:bg-slate-700 rounded-t-md'>Default CheckList</h4>
                       <Checklist options={checks} setOptions={setChecks} isCreate={true}/>             
                      </div>
                      :null}

                  </div>

                    {isNote?
                    <div className='grid grid-cols-1 gap-2 md:grid-cols-2'>
                      
                      <div>
                      <h4 className='px-4 my-2 font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>Set Default note record</h4>
                      <CustomEditor data={note} updateData={updateTextDescription}/>
                      </div>
                      
                    </div>
                      :
                      null}


                <div className='flex items-center justify-center gap-2 my-5'>
                <button className='default-btn' onClick={()=>setTab(tab-1)}><ChevronLeft iconClass={'w-4 h-4'}/> Previous</button>
                <button className='default-btn' onClick={()=>optionValidCheck()}>Next <ChevronRight iconClass={'w-4 h-4'}/></button>
                </div>  
                </Tab.Panel>
                <Tab.Panel className={'p-4'}>

                <div className='flex items-center justify-between gap-2 border-b border-slate-300 dark:border-slate-700'>
                  <h4 className='px-4 my-2 mt-6 text-xl font-semibold text-slate-600 dark:text-slate-400 '>Accessibility</h4>
                  <div className='flex items-center justify-end gap-1'>
                    <input type={'text'} className='input-field-fat' value={searchEmp}  onChange={(e)=>{setSearchEmp(e.target.value)}} placeholder='search'/>
                    {searchEmp.length>0?
                    <button className="icon-btn" onClick={()=>setSearchEmp('')}><Close iconClass={'w-4 h-4'}/></button>
                    :
                      null
                    }
                    </div>
                  </div>
                  
                  <p className='text-sm text-slate-500'>Give access to your employees in this category</p>
                  <div className='grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4'>

                  {searchEmployeeData.map((u)=>
                  
                  <div key={u.id} className='categoryAccessCard'>
                        <div className='flex items-start justify-between object-contain gap-1 lex'>
                        <div className='min-w-max min-h-max'>

                        {u.image?
                            <img src={`${avatarStorage_LINK}${u.image}`} className='w-12 h-12 rounded-full' alt="avatar"/>
                            :
                            <Avatar value={`${u.Fname} ${u.Lname}`} width={'12'} height={'12'}/>
                          }
                          </div>
                          <div className='w-full'>
                            <h4 className='font-bold text-slate-600 dark:text-slate-400'>{u.Fname} {u.Lname}</h4>
                            <p className='text-sm font-semibold text-slate-500'>{u.designation}</p>
                        </div>

                        <button className={`tick-btn ${access.filter(e=>e.id===u.id && e.role>=0).length>0? `text-green-500 bg-green-200 dark:bg-green-900 border-green-500 border-2` : `bg-slate-300 text-slate-500 dark:bg-slate-900 border-slate-500`}`}  onClick={()=>{giveAccess(u.id)}}>
                                  {access.filter(e=>e.id==u.id && e.role>=0).length>0?
                                  <>
                                  {access.filter(e=>e.id==u.id).map((ac)=>(
                                    <div key={ac.id}>
                                    {ac.role==0 &&
                                      <Eye iconClass={'w-5 h-5'}/>
                                    }
                                    {ac.role==1&&
                                      <Pencil iconClass={'w-5 h-5'}/>
                                    }
                                    {ac.role==2 &&
                                      <Tune iconClass={'w-5 h-5'}/>
                                    }
                                    </div>
                                    ))
                                  }
                                  </>
                                  :
                                  <DotsCircle iconClass={'w-5 h-5'}/>
                                }
                              </button>

                        </div>
                        
                        {access.filter(e=>e.id==u.id).map((ac)=>(
                              <div className='flex flex-wrap items-center justify-end' key={ac.id}>
                              {ac.role>=0 &&
                              <p className='text-xs italic font-semibold text-slate-500'>view </p> 
                              }
                              {ac.role>=1 &&
                               <p className='text-xs italic font-semibold text-slate-500'>, edit/post Task </p> 
                              }
                              {ac.role>=2 &&
                                <p className='text-xs italic font-semibold text-slate-500'>, edit category</p> 
                              }
                              </div>
                              
                              ))
                          }
                    
                    </div>
                 )}
                 </div>

                 <div className='flex items-center justify-center gap-2 my-5'>
                <button className='default-btn' onClick={()=>setTab(tab-1)}><ChevronLeft iconClass={'w-4 h-4'}/> Previous</button>
                <button className='default-btn' onClick={()=>registerCompany()}>Save </button>
                </div>  

                </Tab.Panel>
              </Tab.Panels>
              </div>
              </div>
            </Tab.Group>

        </div>
        
    </div>
  )
}

export default CreateCategory