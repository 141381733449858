import React, {useState} from 'react'
import { useSelector } from 'react-redux';

import { User, Phone, Mail, Telephone, Map, Home, Globe } from '../layout/Icons';
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { ADDCUSTOMER_URL } from '../../api/ApiUrl';

const AddCustomer = ({compId, customerData, setCustomerData,setSearchCustomerData, setIsAdd }) => {
  
    const [custName, setCustName] = useState('');
    const [address, setAddress] = useState("");
    const [company, setCompany] = useState("");
    const [postCode, setPostCode] = useState("");
    const [mobile, setMobile] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");

    const addCustomer = async(e)=>{
        e.preventDefault();
        try{
      
       if(!custName.length>0 || !email.length>0 || !mobile.length>0){
          toast.error('Customer Information required!');
          return
        }
        var data=JSON.stringify({
          companyId:compId,
          custName:custName,
          email:email,
          mobile:mobile,
          address:address,
          company:company,
          postCode:postCode,
          telephone:telephone,
          website:website,
        });
        await axios.post(ADDCUSTOMER_URL, data).then((res)=>{
          toast.success('customer Added');
          setCustomerData([res.data.customer, ...customerData]);
          setSearchCustomerData([res.data.customer, ...customerData])
          setIsAdd(false);
        })
      
      
        }catch(e){
          if(!e?.response){
            toast.error("No Server Response!");
           
            console.log(e);
        }else{
            console.log(e);
            toast.error("Oops! An error occured!");
        }
        }
      }

  return (
    <div className=''>
        <form onSubmit={(e)=>{addCustomer(e)}}>
        <div className="flex items-center justify-start w-full">
          <p className="input-field-prepend-icon">
          <User iconClass='w-4 h-4'/>
          </p>
            <input
            type="text"
            value={custName}
            onChange={(e) => {setCustName(e.target.value);}}
            className="w-full input-field-group"
            placeholder="*Cust. name"
            required
            />
        </div>


        <div className="flex items-center justify-start w-full">
            <p className="input-field-prepend-icon"><Phone iconClass={'w-4 h-4'}/></p>
            <input
                type="tel"
                value={mobile}
                onChange={(e) => {setMobile(e.target.value);}}
                className="w-full input-field-group"
                placeholder="*Mobile"
                required
            />
        </div>

        <div className="flex items-center justify-start w-full">
            <p className=" input-field-prepend-icon">
            <Telephone iconClass='w-4 h-4' />
            </p>
            <input
            type="tel"
            value={telephone}
            onChange={(e) => {setTelephone(e.target.value)}}
            className="w-full input-field-group"
            placeholder="Telephone"
           
            />
        </div>

        <div className="flex items-center justify-start w-full">
            <p className=" input-field-prepend-icon">
            <Mail iconClass='w-4 h-4'/>
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => {setEmail(e.target.value);}}
              className="w-full input-field-group"
              placeholder="Email"
              required
            />
        </div>

        <div className="flex items-center justify-start w-full">
            <p className="input-field-prepend-icon">
            <Map iconClass='w-4 h-4' />
            </p>
        <input
          type="text"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          className="w-11/12 input-field-group"
          placeholder="Address"
          />

        <input
            type="text"
            value={postCode}
            onChange={(e) => {
              setPostCode(e.target.value);
            }}
            className="w-4/12 input-field"
            placeholder="PostCode"
          />
        </div>

        <div className="flex items-center justify-start w-full">
        <p className=" input-field-prepend-icon">
        <Home iconClass='w-4 h-4'/>
        </p>
        <input
          type="text"
          value={company}
          onChange={(e) => {setCompany(e.target.value);}}
          className="w-full input-field-group"
          placeholder="Company"
        />
        </div>

        <div className="flex items-center justify-start w-full">
        <p className=" input-field-prepend-icon">
        <Globe iconClass='w-4 h-4'/>
        </p>
        <input
          type="text"
          value={website}
          onChange={(e) => {setWebsite(e.target.value);}}
          className="w-full input-field-group"
          placeholder="website"
        />
        </div>

        <div className='flex items-center justify-end gap-1'>
          <button className='accept-btn' type='submit'>Add Customer</button>
        </div>
        </form>
    </div>
  )
}

export default AddCustomer