import React,{useState,useEffect, useRef} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import Logo from '../../Logo';
import axios from '../../api/axios';
import toast from 'react-hot-toast';
import { Eye } from '../layout/Icons';
import { RESETPASSWORD_URL } from '../../api/ApiUrl';



const pwd_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z-@!.\/#&+\w\s\d]{8,}$/;
const email_REGEX =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



const PasswordReset = () => {

const navigate = useNavigate();

let {token} = useParams();
const [email, setEmail] = useState("");
const [validEmail, setValidEmail] = useState(false);
const [emailFocus, setEmailFocus] = useState(false);

const [password, setPassword] = useState("");
const [validPass, setValidPass] = useState(false);
const [passFocus, setPassFocus] = useState(false);

const [confirm, setConfirm] = useState("");
const [validConfirm, setValidConfirm] = useState(false);
const [confirmFocus, setConfirmFocus] = useState(false);

const [showPass, setShowPass] = useState(false);
const userRef = useRef();

useEffect(() => {
    userRef.current.focus();
  }, []);


  useEffect(() => {
    setValidEmail(email_REGEX.test(email.toLowerCase()));
  }, [email]);


  useEffect(() => {
    setValidPass(pwd_REGEX.test(password));
    const match = password === confirm;
    setValidConfirm(match);
  }, [password, confirm]);

  const resetPassword=async(e)=>{
      e.preventDefault();
      try{
        var data = JSON.stringify({ 
            email:email,
            password:password,
            password_confirmation: confirm,
            token:token
        }); 

        await axios.get('/sanctum/csrf-cookie').then(()=>{
            axios.post(RESETPASSWORD_URL, data).then((res)=>{
               if(res.data.error){
                   toast.error(res.data.error)
               }else{
                   toast.success(res.data.message);
                   navigate('/login');
               }

            })
        })

      }catch(e){
        if (!e?.response) {
            toast.error("No Server Response!");
          } else {
            if(!e?.response){
                toast.error('No Server Response!');
            }
            if(e.response.status==401){
                toast.error(e.response.data.message)
            }   
          }
      }
  }

  return (
    <div>
        <div className='container h-screen mx-auto pt-[10em] lg:pt-[12em]  place-items-center'>
        <Logo className="w-16 h-16 mx-auto" fill={"fill-slate-400 dark:fill-slate-600"}></Logo>
        <p className='my-2 font-semibold text-center text-slate-600 dark:text-slate-400'>escapetask®</p>
       
        <div className='w-3/4 mx-auto mt-10 md:w-2/3 lg:w-1/3 card'>
        <p className='text-lg font-bold text-center text-slate-600 dark:text-slate-400'>Reset Password</p>
            <form onSubmit={(e)=>{resetPassword(e)}}>
                
                <div className="relative">
              <p className="text-sm text-slate-500">Email:</p>
              <input
                defaultValue={email}
                ref={userRef}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full input-field-fat"
                aria-invalid={validEmail ? "false" : "true"}
                style={validEmail ? { borderColor: "#22c55e" } : {}}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                required
              />
              <p
                id="emailnote"
                className={`${
                  emailFocus && email && !validEmail ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - must include '<span aria-label="at symbol">@</span>' <br /> -
                must have a domain <br /> - must be a valid email <br />
              </p>
            </div>

            <div className="relative">
            <p className="text-sm text-slate-500">Password:</p>
            <div className="relative">
              <input
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPass ? `text` : `password`}
                className="w-full input-field-fat"
                aria-invalid={validPass ? "false" : "true"}
                style={validPass ? { borderColor: "#22c55e" } : {}}
                aria-describedby="passnote"
                onFocus={() => setPassFocus(true)}
                onBlur={() => setPassFocus(false)}
                required
              />
              <i
                className={`${
                  showPass
                    ? `text-slate-50 bg-orange-600`
                    : `text-slate-400 dark:text-slate-600 bg-slate-200 dark:bg-slate-900`
                } showPass`}
                onClick={() => {
                  setShowPass(!showPass);
                }}
              >
                <Eye iconClass={'w-5 h-5'}/>
              </i>
            </div>
            <p
              id="passnote"
              className={`${
                passFocus && !validPass ? `visible` : `invisible`
              } validBox`}
            >
              {" "}
              - min 8 characters <br/> - must include uppercase and lowercase
              letters <br/> - must include a number <br/> 
            </p>
          </div>

          <div className="relative mb-4">
            <p className="text-sm text-slate-500">Re-type Password:</p>
            <input
              defaultValue={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              type="password"
              className="w-full input-field-fat"
              aria-invalid={validConfirm && confirm ? "false" : "true"}
              style={validConfirm && confirm ? { borderColor: "#22c55e" } : {}}
              aria-describedby="confirmnote"
              onFocus={() => setConfirmFocus(true)}
              onBlur={() => setConfirmFocus(false)}
            />
            <p
              id="confirmnote"
              className={`${
                confirmFocus && !validConfirm ? `visible` : `invisible`
              } validBox`}
            >
              {" "}
              - must match the password
            </p>
          </div>
            <button className='inline-flex items-center justify-center w-full gap-2 submit-btn'>Reset Password</button>

            </form>

            <Link to="/login" className="link">Back to Login </Link>
            
        </div>
    </div>
    </div>
  )
}

export default PasswordReset