import React, {useState, useEffect} from 'react'
import { ThemeContext } from '../layout/ThemeContext'
import { Note, Delete, Close, Check, Minus, Eye } from '../layout/Icons';
import Select from "react-select";
import DatePicker from "react-datepicker";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html";

import { useSelector } from 'react-redux';
import Checklist from '../layout/sortableChecklist/Checklist';
import CustomEditor from '../layout/CustomEditor';
import { avatarStorage_LINK, CategorylogoStorage_LINK } from '../../api/ApiUrl';
import Avatar from '../layout/Avatar';
import { Transition } from '@headlessui/react';


const ChildtaskForm = ({
    fromPost, 
    index, 
    catId, 
    compId,
    length, 
    child,
    isCategory,  
    categories, 
    childData, 
    removeChild,
    warningId,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    jobTypes,
    shipments,
    productions,
    quotes,
    checklists,
    employeelist,
    setChild}) => {

    const { theme } = React.useContext(ThemeContext);
    
    const customStyles = {
        menu: (provided, state) => {
            let backgroundColor = "#f1f5f9";
            if (theme === "dark") {
            backgroundColor = "#334155";
            }
            
            return {
            ...provided,
            padding: 0,
            fontSize: 12,
            margin: 2,
            backgroundColor,
            minWidth:'max-content',
            maxWidth:'300px',
            zIndex:'99',
            };
        },
        option: (provided, state,) => ({
            ...provided,
            padding: 3,
            color: state.isSelected ? "orangered" : "#64748b",
            backgroundColor: state.isSelected ? "#cbd5e1" : '',
            backgroundColor: state.isFocused ? "#cbd5e1" : "",
        }),

        control: (provided) => ({
            ...provided,
            fontSize: 13,
            padding: 1,
            // height: 24,
            minHeight: 14,
            border: 1,
            borderRadius: "0.375rem",
            boxShadow: "none",
            backgroundColor: "transparent",
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "rgb(148 163 184)",
        }),
        valueContainer: () => ({
            fontSize: 13,
            padding: 0,
            margin: 0,
            minHeight: 17,
            display: "flex",
            flexWrap: "no-wrap",
            alignItems: "center",
        }),

        IndicatorsContainer: () => ({
            padding: 0,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: 0,
        }),
        indicatorSeparator: () => () => null,

        dropdownIndicator: () => ({
            paddingLeft: 2,
            opacity: 0.5,
        }),
        multiValue: (provided) => ({
            ...provided,
            margin:0,
            padding:0,
            height: 24,
            border:'1px solid',
            borderRadius:'8px',
            backgroundColor:`${theme === "dark" ? `#0f172a` : `#f8fafc`}`,
            borderColor: `${theme === "dark" ? `#334155` : `#9ca3af`}`,
            justifyContent: "space-between",
        }),
        MultiValueContainer: (provided) => ({
            ...provided,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            padding: 1,
            height: 20,
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            padding: 0,
            height: 20,
            width:'auto',
            borderRadius:'100%',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            
            let color = "rgb(71 85 105)";

            if (theme === "dark") {
            color = "rgb(148 163 184)";
            }

            return { ...provided, opacity, transition, color };
        },
    };
    const user=useSelector((state)=>(state.user.value));
    const [cat, setCat]=useState(null);
    const [categs, setCategs]=useState([]);
    const [categoryOptions, setCategoryOptions]=useState([]);
    const [jobStatus, setJobStatus]=useState([]);
    const [paymentOption, setPaymentOption]=useState([]);
    const [paymentMethod, setPaymentMethod]=useState([]);
    const [JobType, setJobType]=useState([]);
    const [shipment, setShipment]=useState([]);
    const [production, setProduction]=useState([]);
    const [quote, setQuote]=useState([]);
    const [checklist, setChecklist]=useState([]);
    const [employees,setEmployees]=useState([]);
    const [loadCateg, setLoadCateg]=useState(true);
    const [notestate, setNoteState]=useState(null);
    const [openNotes, setOpenNotes]=useState(false);
    const [showrcvd,setShowrcvd]=useState(false);

    const getDefaultData= (id)=>{
        var cat=categories.filter((cat)=>cat.id==id)[0]
        setCat(cat)
        if(cat.defNotes!=null){
            const contentBlock = htmlToDraft(cat.defNotes);
            if(contentBlock){
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              childData.isNote=true;
              childData.notes=cat.defNotes;
              setNoteState(EditorState.createWithContent(contentState));
            }
          }else{
            childData.isNote=false;
            setNoteState(EditorState.createEmpty());
          }
          setJobStatus(jobStatuses.filter((data)=>(data.catId==cat.id)))
          if(cat.isPrice){
            setPaymentOption(paymentOptions.filter((data)=>(data.catId==cat.id)))
            setPaymentMethod(paymentMethods.filter((data)=>(data.catId==cat.id)))
          }
          if(cat.isJobType){
            setJobType(jobTypes.filter((data)=>(data.catId==cat.id)))
      
          }
          if(cat.isShipment){
            setShipment(shipments.filter((data)=>(data.catId==cat.id)))
          }
          if(cat.isProduction){
            setProduction(productions.filter((data)=>(data.catId==cat.id)))
          }
          if(cat.isQuote){
            setQuote(quotes.filter((data)=>(data.catId==cat.id)))
          }
          if(cat.isChecklist){
            var checks=checklists.filter((data)=>(data.catId==cat.id))
            setChecklist(checks);
            // childData.checklist=checks;
          }else{
            setChecklist([]);
          }
          setEmployees(employeelist.filter((data)=>(data.catId==cat.id)))
          setLoadCateg(false)
    }


    useEffect(()=>{
        if(isCategory && catId!=null){
          getDefaultData(catId);
        }else{
          setCategs(categories);
        }
        return ()=>{
            setCat(null);
            setJobStatus([]);
            setPaymentOption([]);
            setPaymentMethod([]);
            setJobType([]);
            setShipment([]);
            setProduction([]);
            setQuote([]);
            setChecklist([]); 
            setEmployees([]);
            setLoadCateg(false);
            setNoteState(null);
            setOpenNotes(false);
            setShowrcvd(false)
        }
    },[catId])
    
    useEffect(()=>{
      var catOps=[];
      if(compId!=null){
        catOps = categs.filter(c=>c.companyId===parseInt(compId)).map((cat) => ({
          value: cat.id,
          label: cat.name,
          roleId:cat.roleId,
          icon:cat.icon,
        }));
      }else{
        catOps = categs.map((cat) => ({
          value: cat.id,
          label: cat.name,
          roleId:cat.roleId,
          compId:cat.companyId,
          icon:cat.icon,
        }));
      }
     
      setCategoryOptions(catOps);
      setLoadCateg(false)
    },[categs])

 const priorityOptions = [
        { value: "1", label: "low", color: "#22c55e" },
        { value: "2", label: "medium", color: "#3b82f6" },
        { value: "3", label: "high", color: "#f97316" },
        { value: "4", label: "urgent", color: "#ef4444" },
        { value: "5", label: "instant", color: "#ef4444" },
      ];
      
      const setDefaultTurnaround = (id, value)=>{
        const date=new Date();
        var checkDay=date.getDay();
        var d=null;

        var ukTime=date.toLocaleString('en-US', { timeZone: 'Europe/London' })
        ukTime=new Date(ukTime);
        var time=ukTime.getHours();

        if(value==1){
          if(time>=12){
            d=new Date(date.setDate(date.getDate()+14))
          }else{
            d=new Date(date.setDate(date.getDate()+12))
          }
        }else if(value==2){

          if(checkDay>=3){
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+7))
            }else{
              d=new Date(date.setDate(date.getDate()+6))
            }
          }else{
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+5))
            }else{
              d=new Date(date.setDate(date.getDate()+4))
            }
          }

        }else if(value==3){

          if(checkDay>=3){
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+5))
            }else{
              d=new Date(date.setDate(date.getDate()+3))
            }

          }else{
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+3))
            }else{
              d=new Date(date.setDate(date.getDate()+2))
            }

          }

        }else if(value==4){
          if(time>=12){
            if(checkDay>=5){
              d=new Date(date.setDate(date.getDate()+3))
            }else{
              d=new Date(date.setDate(date.getDate()+2))
            }
          }else{
            if(checkDay>=5){
              d=new Date(date.setDate(date.getDate()+3))
            }
            else{
              d=new Date(date.setDate(date.getDate()+1))
            }
          }
        }
        else if(value==5){
          if(time>=12){
            if(checkDay>=5){
              d=new Date(date.setDate(date.getDate()+2))
            }else{
              d=new Date(date.setDate(date.getDate()+1))
            }

          }else{
            d=new Date(date.setDate(date.getDate()))
          }
          
        }
        setTurnaround(id, d)

      }

      const setTurnaround = (id, date) => {
        var update = child.map((c) =>
          c.id === id
            ? {
                ...c,
                turnaround: date,
              }
            : c
        );
        setChild(update);
      };
    
    
    
      //price
      const updatePrice = (id) => {
       
        var update = child;
        var set=childData.taskSet;
        var price=0;
        var discount=0;
        var charge=0;
        var total=0;
        if(childData.price>0){
          price=parseFloat(childData.price);
        }else{
          price=0;
        }
        if(childData.disc>0){
          discount=parseFloat(childData.disc);
        }else{
          discount=0;
        }
        if(childData.charge>0){
          charge=parseFloat(childData.charge)
        }else{
          charge=0;
        }
        if(childData.vat){
          total=(price*set) - discount+charge
          total=total + total*(20/100)
        }else{
          total=(price*set) - discount+charge
        }
        update = child.map((c) =>
            c.id === id
              ? {
                  ...c,

                  total: parseFloat(total).toFixed(2),
                }
              : c
          );
          setChild(update);
      }
        
      //repeater
    
      const addrepeater = (id) => {
        var updaterepeat = child.map((c) =>
          c.id === id
            ? {
                ...c,
                repeat: !c.repeat,
                openrepeat: !c.openrepeat,
              }
            : c
        );
        setChild(updaterepeat);
      };
    
      const updaterepeatType = (id, type) => {
        var updaterepeat = child.map((c) =>
          c.id === id
            ? {
                ...c,
                repeatType: type,
              }
            : c
        );
        setChild(updaterepeat);
      };
    
      const repeatDone = (id) => {
        var updaterepeat = child.map((c) =>
          c.id === id
            ? {
                ...c,
                openrepeat: !c.openrepeat,
              }
            : c
        );
        setChild(updaterepeat);
      };
      const cancelrepeat = (id) => {
        var updaterepeat = child.map((c) =>
          c.id === id
            ? {
                ...c,
                repeat: false,
                openrepeat: false,
                repeatDate: null,
                repeatType: 1,
              }
            : c
        );
        setChild(updaterepeat);
      };
    
      const setrepeatDate = (id, date) => {
        var updaterepeat = child.map((c) =>
          c.id === id
            ? {
                ...c,
                repeatDate: date,
              }
            : c
        );
        setChild(updaterepeat);
      };
    
      //repeater
    
      const setNoteData = (id, state) => {
        var st = draftToHtml(convertToRaw(state.getCurrentContent()));
        var update = child.map((c) =>c.id === id?
            {
            ...c,
            isNote:true,
            notes: st,
            }
            :c
        );
        setChild(update);
        setNoteState(state);
      };
    
      const updateTextDescription = async (id, state) => {
        await setNoteData(id, state);
    
        // const data = convertToRaw(noteEditor.getCurrentContent());
      };
    


      const updatePaid=(id, option)=>{
        var func=option.mathFunc;
        var paid=0;
        if(func===1){
          setShowrcvd(false);
        }
        else if(func===2){
          setShowrcvd(true);
        }
        else if(func===3){
          paid=childData.total;
          setShowrcvd(false);
        }
        else if(func===4){
          paid=-1;
          setShowrcvd(false);
        }else{
          setShowrcvd(true);
        }
        var update = child.map((c) =>
          c.id === id
            ? {
                ...c,
                paymentOption: option,
                paid:paid
              }
            : c
        );
        setChild(update);

      }

      useEffect(()=>{
        childData.checklist=checklist
      },[checklist])

  return !loadCateg?(
    <div className={`childJob ${warningId==childData.id? `border-red-500` : `border-slate-300 dark:border-slate-700`}`}>
        
        <div className='flex flex-wrap items-center justify-start'>
        <p className="mr-2 font-semibold text-slate-600 dark:text-slate-300">
        {index + 1}.
        </p>
        {isCategory? null :(
          <Select
          menuPlacement="auto"
          onChange={(option) => {
            childData.category = option.value;
            if(compId==null){
              childData.companyId=option.compId;
            }
            getDefaultData(option.value);
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={categoryOptions.filter(c=>c.roleId>=1)}
          placeholder="Category"
          formatOptionLabel={cat=>(
            <div className='flex items-center justify-start gap-1'>
                {cat.icon?
                <img src={`${CategorylogoStorage_LINK}${cat.icon}`} className={'w-5 h-5 rounded-full'} alt='icon'/>
                :
                <Avatar value={cat.label} width={'5'} height={'5'}/>
                }

                <p className='font-semibold'>{cat.label}</p>
            </div>
          )}
        />
        )}
        <div className="flex flex-wrap items-center justify-start">
    
        <p className="input-field-prepend">qty</p>
          <input
            type="number"
            defaultValue={childData.qty}
            onChange={(e) => {
              childData.qty = e.target.value;
              updatePrice(childData.id)
            }}
            min="1"
            step="1"
            className="w-14 input-field-group"
            placeholder="Qty"
          />
        </div>
        
        <input
        type="text"
        onChange={(e) => {
          childData.jobdesc = e.target.value;
        }}
        className="w-2/6 input-field"
        placeholder="Job Description"
        />

    <div className="flex flex-wrap items-center justify-start">
      <p className="input-field-prepend">set</p>
      <input
        type="number"
        defaultValue={childData.taskSet}
        onChange={(e) => {
          childData.taskSet = e.target.value;
          updatePrice(childData.id)
        }}
        min="1"
        step="1"
        className="w-10 input-field-group"
        placeholder="Set"
      />
    </div>


    <Select
    menuPlacement="auto"
        onChange={(option) => {
          childData.priority = option.value;
          setDefaultTurnaround(childData.id, option.value);
        }}
        className="input-field-select"
        isSearchable={false}
        styles={customStyles}
        options={priorityOptions}
        placeholder="Priority"
        formatOptionLabel={ option=>(
          <div className='flex items-center justify-start gap-1'>
            <div className={`w-2 h-2 rounded-full`} style={{backgroundColor:option.color}}></div>
            <p className='font-semibold'>{option.label}</p>
          </div>
        )
        }
      />
      <DatePicker
        selected={childData.turnaround}
        minDate={new Date()}
        dateFormat="dd,MMM-yy"
        className="w-24 input-field"
        onChange={(date) => {
          setTurnaround(childData.id, date);
        }}
        placeholderText="Turnaround"
      />
      
    {cat!=null && cat.isPrice ? (
        <>
        <div className="flex items-center justify-start w-28">
          <p className="input-field-prepend">
            <strong className="px-0.5">£</strong>
          </p>
          <input
            onChange={(e) => {
              childData.price = e.target.value;
              updatePrice(childData.id);
            }}
            type="number"
            min="1"
            step="0.01"
            className="w-full input-field-group"
            placeholder="Price"
          />
        </div>
          
        <div className="flex items-center justify-start w-24">
          <p className="input-field-prepend">
            <strong className="px-0.5">+</strong>
          </p>
          <input
            onChange={(e) => {
              childData.charge = e.target.value;
              updatePrice(childData.id);
            }}
            type="number"
            min="0"
            step="0.01"
            className="w-full input-field-group"
            placeholder="Charge"
          />
        </div>
        
        <div className="flex items-center justify-start w-24">
          <p className="input-field-prepend">
            <strong className="px-0.5">-</strong>
          </p>
          <input
            onChange={(e) => {
              childData.disc = e.target.value;
              updatePrice(childData.id);
            }}
            type="number"
            min="0"
            step="0.01"
            className="w-full input-field-group"
            placeholder="Discount"
          />
        </div>

        

        {childData.price>0?
        <div className="flex justify-start gap-1 align-middle m-0.5 p-1">
          <input
            type="checkbox"
            value="1"
            className="checkbox"
            onChange={(e) => {childData.vat=!childData.vat; updatePrice(childData.id)}}
            id={`vat.${childData.id}`}
            checked={childData.vat}
            />
          <label htmlFor={`vat.${childData.id}`} className="checkbox-label">
            Vat
          </label>
        </div>
        :
        null
        }
        <div className="flex items-center justify-start w-28">
          <p className="input-field-prepend">Total</p>
          <input
            type="number"
            value={parseFloat(childData.total).toFixed(2)}
            min="1"
            step="0.01"
            className="w-full input-field-group text-semibold"
            placeholder="Total"
            readOnly={true}
          />
        </div>
      </>
    )
    : 
    null
    } 

{cat!=null? (
      <Select
      menuPlacement="auto"
        onChange={(option) => {
          childData.status = option.value;
        }}
        className="input-field-select"
        isSearchable={false}
        styles={customStyles}
        options={jobStatus}
        placeholder="Job Status"
        formatOptionLabel={ option=>(
          <div className='flex items-center justify-start gap-1'>
            <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
            <p className='font-semibold whitespace-nowrap'>{option.label}</p>
          </div>
        )
        }
        
      />
      ) : 
      null
    }
      
      {cat!=null && cat.isQuote ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            childData.quote = option.value;
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={quote}
          placeholder="quote"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }

        />
      ) :

      null}


      {cat!=null && cat.isJobType ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            childData.jobType = option.value;
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={JobType}
          placeholder="Job type"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }
        />
      ) : 
      null
      }

      {cat!=null && cat.isShipment ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            childData.shipment = option.value;
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={shipment}
          placeholder="Shipment"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }
        />
      ) : null}

      {cat!=null && cat.isProduction ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            childData.production=option.value
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={production}
          placeholder="Production"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }
        />
      ) : null
      }

      {cat!=null && cat.isPrice ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            updatePaid(childData.id, option.value);
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={paymentOption}
          placeholder="payment"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
              <p className='font-semibold whitespace-nowrap'>{option.label}</p>
            </div>
          )
          }
        />
      ) : 
      null
      }
      {childData.paymentOption !=null ? (
        
        <Transition
        show={showrcvd}
        enter="transition-all translate duration-150"
        enterFrom="opacity-0 -translate-x-6"
        enterTo="opacity-100 translate-x-0"
        leave="transition-all translate duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 -translate-x-6"
        >
        <div className="flex items-center justify-start w-28">
          <p className="input-field-prepend">rcvd</p>
          <input
            type="number"
            defaultValue={childData.paid}
            onChange={(e) => {
              childData.paid = e.target.value;
            }}
            min="0"
            step="0.01"
            className="w-full input-field-group"
            placeholder="paid"
            />
        </div>
        </Transition>
      ) : null}

    {cat!=null && cat.isPrice ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            childData.paymentMethod = option.value;
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={paymentMethod}
          placeholder="method"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
              <p className='font-semibold whitespace-nowrap'>{option.label}</p>
            </div>
          )
          }
        />
      ) : null
      }
    
    {cat!=null?
      <div className='flex items-center justify-start w-auto'>
      <p className='input-field-prepend'>Assign</p>
      <Select
      menuPlacement="auto"
        onChange={(selectedOptions) => {
          childData.assignee = selectedOptions.map((s) => s.value);
        }}
        className="w-full input-field-select-append"
        closeMenuOnSelect={false}
        isSearchable={false}
        defaultValue={employeelist.filter(e=>e.value==user.id)[0]}
        styles={customStyles}
        isMulti={true}
        options={employees}
        placeholder="Asignee"
        formatOptionLabel={ employee=>(
          <div className='flex items-center justify-start py-0 space-x-1'>
             {employee.image?
             
                <img src={`${avatarStorage_LINK}${employee.image}`} className={'w-3 h-3 rounded-full'} alt='avatar'/>
                :
                <Avatar value={`${employee.Fname} ${employee.Lname}`} width={'3'} height={'3'} color={'bg-blue-200 dark:bg-blue-800'} small={true}/>
                }

            <p className='font-semibold whitespace-nowrap'>{employee.Fname} {employee.Lname}</p>
          </div>
        )
        }

        />
      </div>
      :
      null}

      <div className='flex items-center justify-end gap-1 ml-auto'>
{cat!=null && cat.isRepeat ? (
        <div className="flex items-center justify-start gap-1">
          {childData.repeat?
          <>
          <button
          className="icon-btn"
          onClick={() => {
            repeatDone(childData.id);
          }}
          type={'button'}
          >
          <Eye iconClass={'w-4 h-4'}/>
        </button>
        <p className="checkbox-label">repeat</p>
        </>
          :
          <>
          <input
          type="checkbox"
          value="1"
          className="checkbox"
          onChange={() => {
            if (childData.repeatDate !== null) {
              cancelrepeat(childData.id);
            } else {
              addrepeater(childData.id);
            }
          }}
          checked={childData.repeat}
          id={`renew.${childData.id}`}
        />
        <label htmlFor={`renew.${childData.id}`} className="checkbox-label mx-0.5">
          repeat
        </label>
        </>
          }

         

          <div
            className={`popUpBox ${
              childData.openrepeat
                ? `scale-100 opacity-100`
                : `scale-0 opacity-0`
            }`}
          >
            <div className="flex items-center justify-between">
              <p className="font-semibold text-center">Set repeater</p>
              <div className="flex items-center justify-end gap-1">
                <button
                  onClick={() => {
                    if (childData.repeatDate !== null) {
                      repeatDone(childData.id);
                    }
                  }}
                  className={`accept-btn ${
                    childData.repeatDate !== null
                      ? `cursor-pointer`
                      : `cursor-not-allowed`
                  }`}
                  type={'button'}
                >
                <Check iconClass={'w-4 h-4'} />
                </button>
                <button
                  onClick={() => {
                    repeatDone(childData.id);
                  }}
                  className="info-btn"
                  type={'button'}
                >
                <Minus iconClass={'w-4 h-4'}/>
                </button>
                <button
                  onClick={() => {
                    cancelrepeat(childData.id);
                  }}
                  className="reject-btn"
                  type={'button'}
                >
                <Close iconClass={'w-4 h-4'}/>
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between gap-3 mt-1">
              <DatePicker
                selected={childData.repeatDate}
                dateFormat="dd,MMM-yy"
                className="min-w-full input-field"
                onChange={(date) => {
                  setrepeatDate(childData.id, date);
                }}
                placeholderText="repeater date"
              />
              <div className="flex items-center justify-end gap-1">
                <button
                  onClick={() => {
                    updaterepeatType(childData.id, 1);
                  }}
                  className={`repeater-btn ${
                    childData.repeatType === 1
                      ? `bg-orange-500 border-orange-600 text-slate-50`
                      : `bg-transparent text-slate-700 dark:text-slate-400`
                  }`}
                  type={'button'}
                >
                  Once
                </button>
                <button
                  onClick={() => {
                    updaterepeatType(childData.id, 2);
                  }}
                  className={`repeater-btn ${
                    childData.repeatType === 2
                      ? `bg-orange-500 border-orange-600 text-slate-50`
                      : `bg-transparent text-slate-700 dark:text-slate-400`
                  }`}

                  type={'button'}
                >
                  Monthly
                  
                </button>
                <button
                  onClick={() => {
                    updaterepeatType(childData.id, 3);
                  }}
                  className={`repeater-btn ${
                    childData.repeatType === 3
                      ? `bg-orange-500 border-orange-600 text-slate-50`
                      : `bg-transparent text-slate-700 dark:text-slate-400`
                  }`}
                  type={'button'}
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>
        </div>
      ) 
: null}

      <button
        className={`icon-btn  ${
            openNotes ? "ring-1 ring-inset  ring-orange-500" : ""
        }`}
        onClick={() => {
            setOpenNotes(!openNotes);
        }}
        type={'button'}
      >
        <Note iconClass={'w-4 h-4'}/>
      </button>
      {length > 1 || fromPost? (
        <button
          className="ml-2 mr-1 delete-btn"
          onClick={() => {
            removeChild(childData.id);
          }}
          type={'button'}
        >
          <Delete iconClass={'w-4 h-4'}/>
        </button>
      ) : null}
    </div>
      
      
      </div>
      
      {/* end of child */}
      <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 ${
            openNotes ? "block" : "hidden"
        } mt-1 gap-1 w-full`}
        
        >
        <div className="md:col-span-2 lg:col-span-4">
          <CustomEditor data={notestate} updateData={(e)=>{updateTextDescription(childData.id, e)}}/>
        </div>
        <div className='lg:col-span-2'>
          <Checklist options={checklist} setOptions={setChecklist} isCreate={true}/>
        </div>
      </div>

    </div>
  )
  :
  null
}

export default ChildtaskForm