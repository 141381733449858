import React from 'react'

import { MSGIMG_STORAGE_LINK } from '../../api/ApiUrl';
const ReplymsgLink = ({msg, uid, isSender}) => {

  return msg!=null?(
    <div className={`flex items-center ${isSender? `justify-end` : `justify-start ml-7` }`}>
           <div className='px-0.5'>
        {msg.msgType===0 && 
         
         <p className={`mx-0.5 text-sm px-2 py-1 my-0.5 ${isSender? `rounded-l-3xl rounded-r-md` : `rounded-r-3xl rounded-l-md`} ${msg.uid===uid? `bg-orange-600/80 dark:bg-orange-800/50 text-white dark:text-white` : `bg-slate-400/50 dark:bg-slate-600/50 text-slate-800 dark:text-slate-300`} `}>
          {msg.msg}
        </p>
         
        }

        {msg.msgType===5 && 
         <div className={`${msg.uid===uid? `sender-Img-bg` : `reciever-Img-bg`}`}>
            {msg.imgs.length>1?
            <div className={`flex flex-wrap items-center justify-start  gap-1`}>
                {msg.imgs.map((img,index)=>(
                <img key={index} src={`${MSGIMG_STORAGE_LINK}${img}`} className='object-cover w-16 h-16 rounded-md min-h-max min-w-max' alt='msg-image'/>
                ))}
            </div>
            :
            <div>
                {msg.imgs.map((img,index)=>(
                <img key={index} src={`${MSGIMG_STORAGE_LINK}${img}`} className='w-64 h-auto rounded-md max-w-max max-h-max'  alt='msg-image'/>
                ))}
            </div>
            }
            {msg.msg.length>0 &&
            <p>{msg.msg}</p>
            }
        </div>
        }

    </div>

    </div>
  ):
  null
}

export default ReplymsgLink