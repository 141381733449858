import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import CircleCategoryFilterButton from './CircleCategoryFilterButton';
import CircleCompanyFilterButton from './CircleCompanyFilterButton';
import { Transition } from '@headlessui/react';
import { ThemeContext } from '../layout/ThemeContext';
import Select from 'react-select'
import { Ban, CheckCircle, Refresh, User, Work } from '../layout/Icons';

const TaskFilters = ({
    isCategory,
    catId,
    compId, 
    isMyTask, 
    fromDoneTask, 
    title, 
    searchFunction,
    isFilterPending,
    clearFunction,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    priorityOptions,
    jobTypes,
    shipments,
    productions,
    quotes,
    setFilterCompany,
    setFilterCategory,
    setFilterPriority,
    setFilterJobStatus,
    setFilterPaymentOption,
    setFilterPaymentMethod,
    setFilterQuote,
    setFilterJobType,
    setFilterShipment,
    setFilterProduction,
    ProgressTypeTasks
}) => {
    const { theme } = React.useContext(ThemeContext);

const customStyles = {
  menu: (provided, state) => {
      let backgroundColor = "#f1f5f9";
      if (theme === "dark") {
      backgroundColor = "#334155";
      }

      return {
      ...provided,
      padding: 0,
      fontSize: 12,
      margin: 2,
      backgroundColor,
      minWidth:'max-content',
      maxWidth:'300px',
      zIndex: 99,
      };
  },
  option: (provided, state,) => {
      return{
          ...provided,
          padding: 3,
          color: state.isSelected ? "orangered" : "#64748b",
          backgroundColor: state.isSelected ? "#cbd5e1" : '',
          backgroundColor: state.isFocused ? "#cbd5e1" : "",
          
    }
  },

  control: (provided) => ({
      ...provided,
      fontSize: 13,
      padding: 1,
      // height: 24,
      minHeight: 14,
      border: 1,
      borderRadius: "0.375rem",
      boxShadow: "none",
      backgroundColor: "transparent",
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
  }),
  placeholder: (provided) => ({
      ...provided,
      color: "rgb(148 163 184)",
  }),
  valueContainer: () => ({
      fontSize: 13,
      padding: 0,
      margin: 0,
      minHeight: 17,
      display: "flex",
      flexWrap: "no-wrap",
      alignItems: "center",
  }),

  IndicatorsContainer: () => ({
      padding: 0,
  }),
  clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
  }),
  indicatorSeparator: () => () => null,

  dropdownIndicator: () => ({
      paddingLeft: 2,
      opacity: 0.5,
  }),
  multiValue: (provided) => ({
      ...provided,
      margin:0,
      padding:0,
      height: 24,
      border:'1px solid',
      borderRadius:'8px',
      backgroundColor:`${theme === "dark" ? `#0f172a` : `#f8fafc`}`,
      borderColor: `${theme === "dark" ? `#334155` : `#9ca3af`}`,
      justifyContent: "space-between",
  }),
  MultiValueContainer: (provided) => ({
      ...provided,
  }),
  multiValueLabel: (provided) => ({
      ...provided,
      padding: 1,
      height: 20,
  }),
  multiValueRemove: (provided) => ({
      ...provided,
      padding: 0,
      height: 20,
      width:'auto',
      borderRadius:'100%',
  }),
  singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      
      let color = "rgb(71 85 105)";

      if (theme === "dark") {
      color = "rgb(148 163 184)";
      }

      return { ...provided, opacity, transition, color };
  },
};
const companies=useSelector(state=>state.company.value);
const [categoryId, setCategoryId]=useState(-1);
const [searchType, setSearchType]=useState(0);    
const [companyId, setCompanyId]=useState(-1);
const categories=useSelector(state=>state.category.value);
const [isClearable, setIsclearable]=useState(false);
const [cat, setCat]=useState([]);

const [progessType, setProgressType]=useState([-1]);
    
    useEffect(()=>{
        if(isCategory && categories.length>0){
            setCategoryId(parseInt(catId))
            setCat(categories.filter(c=>c.id===parseInt(catId))[0])
            
        }
    },[catId])

    useEffect(()=>{
        if(compId){
            setCompanyId(compId);
        }
        if(catId){
            setCategoryId(catId);
           
        }

    },[])


    const filterByProgress=(type)=>{
        setProgressType(type)
        ProgressTypeTasks(type)
    }

    const clearAll=()=>{
        clearFunction(); 
        setIsclearable(false)
        setFilterCompany(-1)
        setFilterPriority(-1)
        setFilterJobStatus(-1)
        setFilterPaymentOption(-1)
        setFilterPaymentMethod(-1)
        setFilterQuote(-1)
        setFilterJobType(-1)
        setFilterShipment(-1)
        setFilterProduction(-1)
        if(!isCategory){
            setCategoryId(-1)
            setFilterCategory(-1)
        }
    }



  return (
    <div className='transition-all duration-150 ease-in-out bg-slate-50 dark:bg-slate-800 rounded-b-3xl hover:shadow-lg hover:shadow-slate-400 dark:hover:shadow-slate-900'>
    <div className='relative'>
        <div className='top-0 flex items-center justify-start w-full gap-1 left-1 md:absolute lg:w-1/4'>

            {title!=null?
            <h4 className='mx-2 text-lg font-bold text-slate-600 dark:text-slate-300 whitespace-nowrap'>{title}</h4>
            :
            null
            }

            <button className={`taskSearch-btn ${searchType===1? `active` : ``}`} onClick={()=>setSearchType(1)}><User iconClass={'w-5 h-5'}/></button>
            <button className={`taskSearch-btn ${searchType===0? `active` : ``}`} onClick={()=>setSearchType(0)}><Work iconClass={'w-5 h-5'}/></button>

            <input 
            type={'text'}
            placeholder={`search by ${searchType==0? 'task' : 'customer'}`}
            className={'search-field w-full'}
            onChange={(e)=>{searchFunction(e.target.value, searchType)}}
            />
           
            
            {isClearable?
            <button className='reject-btn' onClick={()=>{clearAll()}}>
                Reset
            </button>
            :
            null}
            <div className='absolute w-5 h-5 ml-1 mr-3 right-11 lg:-right-10'>
            {isFilterPending && 
                <svg className="w-5 h-5 animate-spin text-slate-400 dark:text-slate-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            }
            </div>

        </div>

        {isMyTask || fromDoneTask?





            <div className='flex flex-wrap items-center justify-end w-full gap-2 p-1 rounded-md'>
                
            <div className='flex items-center justify-end gap-1'>
            {progessType>=0 &&
            <button onClick={()=>{filterByProgress(-1)}} 
            className={`text-slate-500 bg-slate-200 hover:bg-slate-300 dark:hover:bg-slate-700/70 p-1 text-xs font-semibold  transition-all duration-150 ease-in-out border border-slate-500 rounded-full `}>
                <Refresh iconClass={'w-4 h-4'}/>
            </button>
            }

            <button onClick={()=>{filterByProgress(0)}} 
            className={`${progessType==0? `text-white bg-red-500`: `text-red-500 bg-transparent hover:bg-red-200 dark:hover:bg-red-900/70`} p-1 text-xs font-semibold  transition-all duration-150 ease-in-out border border-red-500 rounded-full `}>
                <Ban iconClass={'w-4 h-4'}/>
            </button>
            <button onClick={()=>{filterByProgress(1)}} 
            className={`${progessType==1? `text-white bg-sky-500` : `text-sky-500 bg-transparent hover:bg-sky-200 dark:hover:bg-sky-900/70`} p-1 text-xs font-semibold transition-all duration-150 ease-in-out border rounded-full  border-sky-500 `}>
                <Work iconClass={'w-4 h-4'}/>
            </button>
            <button  onClick={()=>{filterByProgress(2)}} 
            className={`${progessType==2? `text-white bg-green-500` : `text-green-500 bg-transparent hover:bg-green-200 dark:hover:bg-green-900/70`} p-1 text-xs font-semibold transition-all duration-150 ease-in-out border border-green-500 rounded-full `}>
                <CheckCircle iconClass={'w-4 h-4'}/>
            </button>

            </div>
            {companies.filter(c=>c.id==companyId).map(c=>(
                <p key={c.id} className='text-sm font-semibold text-slate-500 dark:text-slate-600'>{c.name}</p>
            ))}

          
                {companies.map((co)=>(
                    <CircleCompanyFilterButton 
                    key={co.id} data={co} 
                    setCompanyId={setCompanyId} 
                    companyId={companyId} 
                    setFilterCompany={setFilterCompany}
                    setIsclearable={setIsclearable}
                    />
                ))}
            </div>
                   
        :
        null
        }
            </div>
   
        <Transition
            show={companyId>0}
            enter="transition-all transform duration-75 ease-in"
            enterFrom="opacity-0 -translate-y-6"
            enterTo="opacity-100 translate-y-0"
            leave="transform transition-all duration-75 ease-out" 
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-6"
            >
       
        <div className={`flex flex-wrap items-center ${(isMyTask || fromDoneTask)? `justify-end`: `justify-between`} border-b border-slate-400 dark:border-slate-700 gap-2 p-1 rounded-b-3xl bg-slate-50 dark:bg-slate-800`}>
        {isMyTask || fromDoneTask?
        null
        :
        <div className='hidden w-full h-7 md:block lg:w-1/4'></div>
        }
        <div className='flex flex-wrap items-center justify-between gap-2 md:flex-row-reverse'>

        <div className='flex items-center justify-end gap-2'>

        {!isCategory?
        <>
        {categories.filter(com=>com.companyId==companyId).map((c)=>(
            <CircleCategoryFilterButton 
            key={c.id} 
            data={c} 
            setFilterCategory={setFilterCategory} 
            categoryId={categoryId} 
            setCategoryId={setCategoryId}
            setCat={setCat}
            setIsclearable={setIsclearable}
            />
            ))}
        </>
        :
        null
        }

        </div>
        
        <div>
            {cat!=null && categoryId>0?
            <div key={categoryId} className='flex flex-wrap items-center justify-end gap-1'>
                
                <Select
                    onChange={(option) => {if(option){setFilterPriority(option.value)}else{setFilterPriority(-1)}}}
                    className="input-field-select"
                    isSearchable={false}
                    styles={customStyles}
                    isClearable={true}
                    options={priorityOptions}
                    placeholder="Priority"
                    formatOptionLabel={ option=>(
                    <div className='flex items-center justify-start gap-1'>
                        <div className={`w-2 h-2 rounded-full`} style={{backgroundColor:option.color}}></div>
                        <p className='font-semibold'>{option.label}</p>
                    </div>
                    )
                    }
                />

                <Select
                onChange={(option) => {if(option){setFilterJobStatus(option.value)}else{setFilterJobStatus(-1)}}}
                className="input-field-select"
                isSearchable={false}
                isClearable={true}
                styles={customStyles}
                options={jobStatuses.filter(op=>op.catId==categoryId)}
                placeholder="Job Status"
                formatOptionLabel={ option=>(
                <div className='flex items-center justify-start gap-1'>
                    <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
                    <p className='font-semibold whitespace-nowrap'>{option.label}</p>
                </div>
                )
                }
                />

                {cat!=null && cat.isPrice?
                <>
                <Select
                onChange={(option) => {if(option){setFilterPaymentOption(option.value)}else{setFilterPaymentOption(-1)}}}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                isClearable={true}
                options={paymentOptions.filter(op=>op.catId==categoryId)}
                placeholder="payment"
                formatOptionLabel={ option=>(
                  <div className='flex items-center justify-start gap-1'>
                    <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
                    <p className='font-semibold whitespace-nowrap'>{option.label}</p>
                  </div>
                )
                }
                />

                <Select
                onChange={(option) => {if(option){setFilterPaymentMethod(option.value)}else{setFilterPaymentMethod(-1)}}}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                isClearable={true}
                options={paymentMethods.filter(op=>op.catId==categoryId)}
                placeholder="method"
                formatOptionLabel={ option=>(
                    <div className='flex items-center justify-start gap-1'>
                    <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
                    <p className='font-semibold whitespace-nowrap'>{option.label}</p>
                    </div>
                )
                }
                />
                </>
                :
                null}

                {cat!=null && cat.isQuote?
                <Select
                onChange={(option) => {if(option){setFilterQuote(option.value)}else{setFilterQuote(-1)}}}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                isClearable={true}
                options={quotes.filter(op=>op.catId==categoryId)}
                placeholder="quote"
                formatOptionLabel={ option=>(
                  <div className='flex items-center justify-start gap-1'>
                    <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
                  </div>
                )
                }
      
              />
                :
                null}
                {cat!=null && cat.isJobType?
                <Select
                onChange={(option) => {if(option){setFilterJobType(option.value)}else{setFilterJobType(-1)}}}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                isClearable={true}
                options={jobTypes.filter(op=>op.catId==categoryId)}
                placeholder="Job type"
                formatOptionLabel={ option=>(
                  <div className='flex items-center justify-start gap-1'>
                    <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
                  </div>
                )
                }
                />
                :
                null}

                {cat!=null && cat.isShipment?
                <Select
                onChange={(option) => {if(option){setFilterShipment(option.value)}else{setFilterShipment(-1)}}}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                isClearable={true}
                options={shipments.filter(op=>op.catId==categoryId)}
                placeholder="Shipment"
                formatOptionLabel={ option=>(
                  <div className='flex items-center justify-start gap-1'>
                    <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
                  </div>
                )
                }
                />
                :
                null}

                {cat!=null && cat.isProduction?
                <Select
                onChange={(option) => {if(option){setFilterProduction(option.value)}else{setFilterProduction(-1)}}}
                className="input-field-select"
                isSearchable={false}
                styles={customStyles}
                isClearable={true}
                options={productions.filter(op=>op.catId==categoryId)}
                placeholder="Production"
                formatOptionLabel={ option=>(
                  <div className='flex items-center justify-start gap-1'>
                    <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
                  </div>
                )
                }
                />
                :
                null}

            </div>
            :
            null
            }
        </div>

        
        </div>
        </div>
        </Transition>

    </div>
  )
}

export default TaskFilters