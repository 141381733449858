import React, {useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Avatar from '../layout/Avatar';
import axios from "../../api/axios";
import toast from 'react-hot-toast';
import moment from 'moment';
import { Duplicate, Pencil, Work, Delete, Resend} from '../layout/Icons';

import { CompanylogoStorage_LINK, DELETECOMPANY_URL, RESEND_URL } from '../../api/ApiUrl';
import { delCompany, setCompany } from '../../features/companies';
import CompanyEdit from './CompanyEdit';
import { deleteRoom } from '../../config/firebaseConfig';

const Company = ({company, setActiveCompany, isReg, setIsReg}) => {
    const dispatch=useDispatch();
    const user=useSelector((state)=>state.user.value)
    const [del,setDel]=useState(false);
    const [editCo,setEditCo]=useState(false);
    
    const copyCode = ()=>{
        var copy=`${company.code}${company.id}`;
        navigator.clipboard.writeText(copy);
        toast.success('Copied to Clipboard!')
    }

    const deleteCompany = async(id)=>{
        const waitToast = toast.loading('Removing...', {icon: '⏳'});
        try{
    
            await axios.delete(`${DELETECOMPANY_URL}${id}`).then((res)=>{
                toast.error(res.data.message);
                toast.dismiss(waitToast);
                dispatch(delCompany(id));
                deleteRoom(id)
                localStorage.removeItem('activeCo');
                setActiveCompany(null)
                setDel(false);

            });
        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
                toast.dismiss(waitToast);
            }
        }
    }

    const resend = async ()=>{
        var data=JSON.stringify({
            id:user.id,
            companyId:company.id,
        })
        try{
            await axios.post(RESEND_URL,data).then((res)=>{
                dispatch(setCompany(res.data.companies));
                toast.success('Requsent sent!')
            })
        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
            }else{
                console.log(e)
            }
        }
    }
  return (
    <>
        <div className={`w-full my-1 border-slate-300 dark:border-slate-700`}>
            <div className='flex items-center justify-between gap-1'>
            <div className='flex items-center justify-start gap-2'>
            <div className='w-16 h-16 rounded-full shadow-md bg-slate-50'>
                {company.logo?
                <img src={`${CompanylogoStorage_LINK}${company.logo}`} className='w-16 h-16 p-1 avatar'/>   
                :
                <Avatar value={company.name} width={'16'}  height={'16'}  color="bg-orange-200"/>   

                }
            </div>
            <div className='group'>
                <div className='flex items-center justify-start gap-1'>
                <h4 className='font-bold md:text-lg text-slate-600 dark:text-slate-400'>{company.name}</h4>
                {user.userType==1?<>
                <button className='visible icon-btn lg:invisible group-hover:visible' onClick={()=>{setEditCo(true)}}><Pencil iconClass={'w-4 h-4'}/></button>
                <button className='visible icon-btn lg:invisible group-hover:visible' onClick={()=>{setDel(!del)}}><Delete iconClass={'w-4 h-4'}/></button>
                </>
                
                :
                null
                }
                </div>
                <p className='inline-flex items-center gap-1 font-semibold text-slate-500'>
                    <Work iconClass={'w-4 h-4'}/> 
                    {company.verified>=0? `${company.designation}` : null }
                    {company.verified==-2?`Declined` : null }
                </p>
               
            </div>
            </div>

            <div className=''>
            <p className='gap-1 text-sm font-semibold text-right text-slate-500'>{company.address}, {company.postcode}</p>
            <p className='mt-1 text-xs text-right text-slate-400 dark:text-slate-400/50'>created: {moment(company.created_at).format('D,MMM-YY')}</p>   
            </div>
            </div>
           
            <div>

           
            <div className='p-3 mx-auto mt-6 border rounded-md border-slate-300 dark:border-slate-900 bg-slate-100 dark:bg-slate-700'>

            <div className='flex items-center justify-center gap-1'>
                <p className='text-sm text-slate-500 dark:text-slate-500'>Code:</p>
                <h4 className='p-1 font-bold rounded-md bg-slate-300 dark:bg-slate-900 text-slate-600 dark:text-slate-400'>{company.code}{company.id}</h4>
                <button onClick={()=>copyCode()} className='px-2 py-1 transition-all duration-150 ease-in-out rounded-md bg-slate-300 dark:bg-slate-900 text-slate-600 dark:text-slate-400 hover:bg-orange-500 dark:hover:bg-orange-500 dark:hover:text-slate-50 hover:text-slate-50'>
                    <Duplicate iconClass={'w-5 h-5 m-0.5'}/>
                </button> <br/>
            
            </div>
            <p className='text-sm text-center text-slate-500'>Copy this code and send it to your employess, they will use this code to join your company space</p>
            </div>
            
        {company.verified==-2?
        <button className='inline-flex items-center justify-end gap-2 submit-btn' onClick={()=>{resend()}}><Resend/>Resend</button>
        :
        null
            
        }
      
        
        </div>
       
    </div>

    {del?
    <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
    <div className='flex flex-row items-center justify-center min-h-screen'>
        <div className='w-11/12 md:w-1/2 lg:w-1/3 card'>
            <p className='text-slate-600 dark:text-slate-400'>Are you sure you want to delete <strong> {company.name}</strong> ?</p>
            <p className='text-sm text-rose-500'>All of its jobs and employees will also be cleared and cannot be undone.</p>

            <div className='flex items-center justify-end gap-2'>
                <button className='reject-btn w-14' onClick={()=>{deleteCompany(company.id)}}>Yes</button>
                <button className='accept-btn w-14' onClick={()=>setDel(false)}>No</button>
            </div>
        </div>
    </div>
    </div>
    :
    null}
    
    {editCo?
      <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
      <div className='flex flex-row items-center justify-center min-h-screen'>
      <div className='w-full m-2 lg:w-1/3'>
      <CompanyEdit company={company} setEditCo={setEditCo} setActiveCompany={setActiveCompany}/>
      
      </div>
      </div>
      </div>
      :
      null
    }

   


    </>
  )
}

export default Company