import React,{useEffect, useState} from 'react'
import { User, Phone, Mail, ChevronUp, Telephone, Map, Home, Globe, Pencil } from '../layout/Icons';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { UPDATECUSTOMER_URL } from '../../api/ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskCustomer } from '../../features/Tasks';

const PostTaskCustomer = ({sl,customer, referenceId}) => {

const dispatch=useDispatch();

const [cust, setCust]=useState([]);
useEffect(()=>{
setCust(customer);

return(()=>{
    setCust([])
})

},[])

const [editCustomer, setEditCustomer]=useState(false);
const [isChanged,setChange]=useState(false);

const updateCustomer= async (e)=>{
    e.preventDefault();
    try{
        var data=JSON.stringify({cust:cust})
        await axios.post(UPDATECUSTOMER_URL, data).then((res)=>{
            setCust(res.data.customer);
           
            dispatch(updateTaskCustomer({reference:referenceId, customer:res.data.customer}))
            toast.success('Customer information Updated!');
            setChange(false);
            setEditCustomer(false);

        })

    }catch(e){
    if(!e?.response){
        toast.error("No Server Response!");
        console.log(e);
    }else{
        console.log(e);
        toast.error("Oops! An error occured!");
        }
    }
}

const rating =(rate)=>{
    if(rate==0){
        return ( <p className='text-sm'>😐</p> )
    }else if(rate==1){
        return ( <p className='text-sm'>😁</p> )
    }
    else if(rate==2){
        return ( <p className='text-sm'>💖</p> )
    }else if(rate==-1){
    return ( <p className='text-sm'>😡</p> )
    }else{
        return ( <p className='text-sm'>👻</p> )
    }
}


  return cust!=null?(
    <div className='pt-1 text-slate-700 dark:text-white rounded-t-md group bg-gradient-to-r from-orange-200/50 via-slate-300 to-slate-300 dark:from-orange-700/50 dark:via-slate-800 dark:to-slate-800 '>
            <div className='flex items-center justify-between gap-1 '>

            <div className='flex flex-wrap items-center justify-start gap-2 '>
                    <p className='px-1 ml-1 text-xs font-semibold rounded-md '>Sl.{sl}</p>
                    <p className='inline-flex items-center gap-1 text-xs font-bold '><User iconClass={'w-3 h-3'}/>{cust.name} {cust.company? `|  ${cust.company}` : null}</p> 
                    <p className='inline-flex items-center gap-1 text-xs font-semibold '><Phone iconClass={'w-3 h-3'}/>{cust.mobile}  {cust.telephone? `, ${cust.telephone}` : ``}</p>
                    <p className='inline-flex items-center gap-1 text-xs font-semibold '><Mail iconClass={'w-3 h-3'}/> {cust.email}</p>
                    <p className={`hidden items-center gap-1 text-xs font-semibold  xl:inline-flex`}><Map iconClass={'w-3 h-3'}/>{cust.address} {cust.postCode} </p>
                    {cust.website!=null?
                        <p className={`hidden items-center gap-1 text-xs font-semibold  xl:inline-flex`}><Globe iconClass={'w-3 h-3'}/> {cust.website}</p>
                        :
                        null
                    }
            </div>
            <div className='flex items-center justify-end gap-1 mr-14'>
            {rating(cust.rating)}
            <button className='icon-btn' onClick={()=>{setEditCustomer(!editCustomer)}}>{editCustomer? <ChevronUp iconClass={'w-4 h-4'}/> : <Pencil iconClass={'w-4 h-4'}/>}</button>
            </div>
            </div>

            <Transition
            show={editCustomer}
            enter="transition-all transform  duration-150"
            enterFrom="opacity-0 -translate-y-6"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all transform duration-150"
            leaveFrom="opacity-50 translate-y-0"
            leaveTo="opacity-0 -translate-y-6"
            >
     

          
            <form onSubmit={(e)=>{updateCustomer(e)}}>
            <div className="flex items-center justify-between gap-0.5 w-full bg-slate-200 dark:bg-slate-800">

            <div className="flex flex-wrap items-center justify-start gap-0.5">
            
            <div className="flex items-center justify-start w-24 xl:w-32">
            <p className="input-field-prepend-icon">
            <User iconClass={'w-4 h-4'}/>
            </p>

                <input
                type="text"
                value={cust.name}
                onChange={(e) => {
                    setCust(cust=>({...cust, name:e.target.value}));
                    setChange(true);
                }}
                className="w-full input-field-group"
                placeholder="*Cust. name"
                required
                />

            </div>


            <div className="flex items-center justify-start w-32">
            <p className=" input-field-prepend-icon">
            <Phone iconClass={'w-4 h-4'}/>
            </p>
                <input
                type="tel"
                value={cust.mobile}
                onChange={(e) => {
                    setCust(cust=>({...cust, mobile:e.target.value}));
                    setChange(true);
                }}
                className="w-full input-field-group"
                placeholder="*Mobile"
                required
                />
            </div>


            <div className="flex items-center justify-start w-32">
            <p className=" input-field-prepend-icon">
            <Telephone iconClass={'w-4 h-4'}/>
            </p>
                <input
                type="tel"
                value={cust.telephone? cust.telephone:''}
                onChange={(e) => {
                    setCust(cust=>({...cust, telephone:e.target.value}));
                    setChange(true);
                }}
                className="w-full input-field-group"
                placeholder="Telephone"
                />
            </div>

            <div className="flex items-center justify-start w-auto">
            <p className=" input-field-prepend-icon">
            <Mail iconClass={'w-4 h-4'}/>
            </p>
                <input
                type="email"
                value={cust.email}
                onChange={(e) => {
                    setCust(cust=>({...cust, email:e.target.value}));
                    setChange(true);
                }}
                className="w-full input-field-group"
                placeholder="Email"
                required
                />
            </div>


            <div className="flex items-center justify-start w-40">
            <p className="input-field-prepend-icon">
            <Map iconClass={'w-4 h-4'}/>
            </p>
                <input
                type="text"
                value={cust.address? cust.address:''}
                onChange={(e) => {
                    setCust(cust=>({...cust, address:e.target.value}));
                    setChange(true);
                }}
                className="w-full input-field-group"
                placeholder="Address"
                />

            </div>
                <input
                type="text"
                value={cust.postCode? cust.postCode:''}
                onChange={(e) => {
                    setCust(cust=>({...cust, postCode:e.target.value}));
                    setChange(true);
                }}
                className="w-16 input-field"
                placeholder="PostCode"
                />

            
                <div className="flex items-center justify-start w-32">
                <p className="input-field-prepend-icon">
                <Home iconClass={'w-4 h-4'}/>
                </p>
                    <input
                    type="text"
                    value={cust.company? cust.company:''}
                    onChange={(e) => {
                        setCust(cust=>({...cust, company:e.target.value}));
                        setChange(true);
                    }}
                    className="w-full input-field-group"
                    placeholder="Company"
                    />
                </div>

                <div className="flex items-center justify-start w-32">
                <p className=" input-field-prepend-icon">
                <Globe iconClass={'w-4 h-4'}/>
                </p>
                    <input
                    type="text"
                    value={cust.website? cust.website:''}
                    onChange={(e) => {
                        setCust(cust=>({...cust, website:e.target.value}));
                        setChange(true);
                    }}
                    className="w-full input-field-group"
                    placeholder="Website"
                    />
                </div>
                   
                <div className='flex items-center justify-between gap-1'>

                <input type={'checkbox'} className='hidden' id={'fav'} value={2} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value})); setChange(true)}}/>
                <label htmlFor='fav' className='relative w-5 cursor-pointer h-7' >
                  <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>💖</p> 
                </label>

                <input type={'checkbox'} className='hidden' id={'happy'} value={1} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value})); setChange(true)}}/>
                <label htmlFor='happy' className='relative w-5 cursor-pointer h-7' >
                  <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>😁</p> 
                </label>

                <input type={'checkbox'} className='hidden' id={'avg'}  value={0} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value})); setChange(true)}}/>
                <label htmlFor='avg' className='relative w-5 cursor-pointer h-7'>
                  <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>😐</p> 
                </label>

                <input type={'checkbox'} className='hidden' id={'angry'} value={-1} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value})); setChange(true)}}/>
                <label htmlFor='angry' className='relative w-5 cursor-pointer h-7' >
                  <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>😡</p>
                </label>

                <input type={'checkbox'} className='hidden' id={'ghost'} value={-2} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value})); setChange(true)}}/>
                <label htmlFor='ghost' className='relative w-5 cursor-pointer h-7' >
                  <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>👻</p>
                </label>

                </div>   

            </div>

            <div className='flex items-center justify-end'>
                <Transition
                show={isChanged}
                enter="transition-all translate duration-150"
                enterFrom="opacity-0 translate-x-6"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all translate duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-6"
                >
                    <button type='submit' className='inline-flex items-center justify-center accept-btn'> save</button>
                </Transition>
            </div>
            </div>
            </form>   
            
           

            </Transition>
    </div>
  )
:
null
}

export default PostTaskCustomer