import React,{useState, useEffect} from 'react'

const StopWatch = () => {

const [time, setTime] = useState(0);
const [running, setRunning] = useState(true);
const [diff, setDiff]=useState(0)

useEffect(()=>{
    var t1= new Date(localStorage.getItem('startTime'));
    var t2 = new Date();
    var diff=0;
    if(t1!=null){
       diff=(t2.getTime()-t1.getTime());   
    }
    setDiff(diff)

},[running])


useEffect(() => {

    
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10 );
        
    }, 10);
    } else if (!running) {
      clearInterval(interval);
    }

    return () => {
    clearInterval(interval)
    setTime(0);
    };
   

  }, [running]);


  return (
    <div className="stopwatch">
      <div className="numbers-Stopwatch">
        <span>{("0" + Math.floor(((time+diff) / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor(((time+diff) / 1000) % 60)).slice(-2)}</span>
       
        {/* <span>{("0" + ((time / 10) % 100)).slice(-2)}</span> */}
      </div>
      {/* <div className="buttons">
        <button onClick={() => setRunning(true)}>Start</button>
        <button onClick={() => setRunning(false)}>Stop</button>
        <button onClick={() => setTime(0)}>Reset</button>       
      </div> */}
    </div>
  )
}

export default StopWatch