import React, {useRef, useCallback,useEffect, useState} from 'react'
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import MessageBar from './MessageBar'
import MsgForm from './MsgForm'
import useSound from 'use-sound';
import tone from '../../msgTone.mp3';
import mike from '../../TrumpetTone.mp3'
import ReplyMessageView from './ReplyMessageView';
import { Transition } from '@headlessui/react'
import { delMsg } from '../../config/firebaseConfig';
const MsgBox = ({messages, uid,load,EndData,isEmptyMsg, setLoad, fetchMoreMsg, roomId, compLength}) => {

  const observer=useRef();
  const scrollHere=useRef();
  const user=useSelector((state)=>(state.user.value));

  const [playTone]=useSound(tone, { volume: 0.50 });
  const [playMike]=useSound(mike, { volume: 0.50 });

  const [replyMsg, setReplyMsg]=useState(-1);
  const [replymsgId, setReplyMsgId]=useState(null);
  const [showReply, setShowReply]=useState(false);
  const compareTime=(newTime,oldTime)=>{
    var newTimeToDate=new Date(newTime.seconds*1000)
    var oldTimeTodate=new Date(oldTime.seconds*1000)
    var hours = Math.floor(Math.abs(oldTimeTodate - newTimeToDate) / 360000);
    if(hours>1){
      return(
        <p className='text-xs italic text-center text-slate-500 dark:text-slate-400'><Moment date={oldTimeTodate} format={"h:mmA, DD,MMM'YY"}/></p>
      )
    }
  }

  useEffect(()=>{
  const shouldPlaySound=(check)=>{
    if(check.read){

      var data=check.read
      var read=true;
      if(data.length>0){
        read=data.includes(user.id)
      }
      if(check && check.msgType!=1 && check.uid!=uid && !read){
        if(check.msgType==4){
          playMike()
        }else{
          playTone()
        }
        
      }
    }
    localStorage.setItem('msgLength',check.id)
  }
  
  if(localStorage.getItem('msgLength')==null){
    localStorage.setItem('msgLength',0);
  }
  
  if(messages.length>0){
    var check=localStorage.getItem('msgLength');
    if(check!=messages[0].id){
      shouldPlaySound(messages[0])
    }
   
  }

  },[messages])


  const lastMsg=useCallback(node=>{
    if(load) return

    if(observer.current){
      observer.current.disconnect()
    }

    observer.current=new IntersectionObserver(entries=>{
      if(entries[0].isIntersecting){
        if(!isEmptyMsg && !EndData && messages.length>=10){
          setLoad(true)
          fetchMoreMsg()
        }
      }
    })

  if(node){
      observer.current.observe(node)
    }
  },[load, messages])


  const [deleteMsg,setDeleteMsg]=useState('');

  const deletingMsg=(msgId,roomId)=>{
    delMsg(msgId,roomId)
    setDeleteMsg('');
  }

  return (
    <div className='relative'>
    {load && !isEmptyMsg &&
      <div className='absolute flex items-center justify-center top-4 left-[46%]  z-[99] rounded-full'>
     <svg className="w-5 h-5 text-slate-400 animate-spin dark:text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
     <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
     </svg>
      </div>
    }
      {deleteMsg.length>0 &&
       <div className='absolute top-0 left-0 z-[99] w-full'>
         <div className='p-4 rounded-md bg-slate-50/80 dark:bg-slate-800/80 backdrop-blur-md '>
           <p className='font-semibold text-slate-600 dark:text-slate-400'>Are you sure you delete this message?</p>
           <div className='flex items-center justify-end gap-2 mt-6'>
           <button className='w-16 reject-btn' onClick={()=>{deletingMsg(deleteMsg, roomId)}}>Yes</button>
           <button onClick={()=>{setDeleteMsg('')}} className='w-16 accept-btn'>No</button>
           </div>
     
         </div>
      
     </div>
      }
    <div className={`${compLength>1 ? `h-[73vh] max-h-[73vh]` : `h-[78vh] max-h-[78vh]`} overflow-y-auto flex overflow-x-hidden flex-col-reverse`}>
        <div ref={scrollHere}></div>
        {messages.length>0 && messages.map((msg, i)=>(
          <div key={uuidv4()} >
            {i+1==messages.length && i>2 &&
            <p className='text-xs italic text-center text-slate-500 dark:text-slate-400'><Moment date={msg.createdAt.toDate()} format={"h:mmA, DD,MMM'YY"}/></p>
            }
           

          {messages.length===i+1?
          <div ref={lastMsg}>
            <MessageBar
             setReplyMsg={setReplyMsg} 
             setReplyMsgId={setReplyMsgId} 
             setShowReply={setShowReply} 
             setDeleteMsg={setDeleteMsg}
             index={i} msg={msg} uid={uid} user={user} nextUid={i+1!=messages.length? messages[i+1].uid : ''} prevType={i>1? messages[i-1].msgType : ''}/>
            
          </div>
          :
          <div>
            <MessageBar 
            setReplyMsg={setReplyMsg} 
            setReplyMsgId={setReplyMsgId} 
            setShowReply={setShowReply} 
            setDeleteMsg={setDeleteMsg}
            index={i}  msg={msg} uid={uid} user={user} nextUid={i+1!=messages.length? messages[i+1].uid : ''} prevType={i>1? messages[i-1].msgType : ''}/>
           
          </div>
          }

          {i>1 && msg.msgType==0 && msg.createdAt && messages[i-1].createdAt &&
              <>
             {compareTime(msg.createdAt, messages[i-1].createdAt)}
              </>
          }
         
        </div>

          ))}
      
    </div>
  
    <Transition
    show={showReply}
    enter="transform transition duration-150"
    enterFrom="opacity-0 translate-y-0 -translate-x-12"
    enterTo="opacity-100 translate-y-12 translate-x-0"
    leave="transform-opacity duration-150 ease-in-out"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"     
    >
    <ReplyMessageView msg={messages[replyMsg]} uid={uid} setShowReply={setShowReply} setReplyMsgId={setReplyMsgId}/>
    </Transition>
   
    <MsgForm 
    roomId={roomId} 
    scrollHere={scrollHere} 
    replymsgId={replymsgId} 
    setShowReply={setShowReply} 
    setReplyMsg={setReplyMsg} 
    setReplyMsgId={setReplyMsgId}/>
</div>
  )
}

export default MsgBox