import React,{useState} from 'react'
import { v4 as uuidv4 } from "uuid";
import { Add } from '../layout/Icons';
import ChildtaskForm from './ChildtaskForm';
import toast from "react-hot-toast";
import axios from "../../api/axios";
import { useDispatch, useSelector } from 'react-redux';
import { ADDJOB_URL } from '../../api/ApiUrl';
import { updateTaskStatus } from '../../features/Tasks';
import { isIdle, notify, requireRefresh } from '../../config/firebaseConfig';

const PostAddChildTask = ({
    catId,
    compId,
    referenceId,
    custId,
    prevLength,
    isCategory,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    jobTypes,
    shipments,
    productions,
    quotes,
    checklists,
    employeelist,
    isMyTask,
    setMyTaskIds,
    myTaskIds,
}) => {

const [child, setChild] = useState([]);
const [warningId, setWarningId]=useState(-1);

const user=useSelector(state=>state.user.value)
const categories=useSelector((state)=>state.category.value)
const dispatch=useDispatch();
    var childData = {
        id: uuidv4(),
        category:catId,
        companyId:compId,
        qty: 1,
        jobdesc: "",
        taskSet:1,
        priority: 1,
        price: 0,
        disc: 0,
        charge: 0,
        vat: false,
        total: 0,
        paid: 0,
        status: 0,
        jobType: null,
        quote: null,
        shipment:null,
        production: null,
        paymentOption: null,
        paymentMethod: null,
        turnaround: null,
        isNote: false,
        notes: null,
        assignee: [user.id],
        checklist:[],
        repeat: false,
        openrepeat: false,
        repeatDate: null,
        repeatType: 1,
        done:false,
      };

    const newChild = (id) => {
    isIdle(user.id, true);
    setChild([...child, childData]);

    // if((prevLength+child.length)<=9){
    // setChild([...child, childData]);
    // }
    // else{
    //     toast.error('Cannot create more than 10 nested tasks!');
    // }
    };

    const addPostTask=async(e)=>{
        e.preventDefault();
        const validate = toast.loading('validating data...', {icon: '📃'});
        try{
            //check job validity//
            var checkCateg=child.filter((ch)=>(!ch.category==null));
            if(checkCateg.length>0){
            toast.error('please select a category');
            setWarningId(checkCateg[0].id);
            toast.dismiss(validate);
            return
            }

            var checkDesc=child.filter((ch)=>(!ch.jobdesc.length>0))
            if(checkDesc.length>0){
            toast.error('please type task description');
            setWarningId(checkDesc[0].id);
            toast.dismiss(validate);
            return
            }
            
            var checkStatus=child.filter((ch)=>(ch.status==0))
            if(checkStatus.length>0){
            toast.error('please select task status');
            setWarningId(checkStatus[0].id);
            toast.dismiss(validate);
            return
            }

            var checkData=child.filter((ch)=>(ch.turnaround==null))
            if(checkData.length>0){
            toast.error('please enter turnaround Date');
            setWarningId(checkData[0].id);
            toast.dismiss(validate);
            return
            }
            
            var checkAssinge=child.filter((ch)=>(ch.assignee.length==0));
            if(checkAssinge.length>0){
            toast.error('please assign your task!');
            setWarningId(checkAssinge[0].id);
            toast.dismiss(validate);
            return
            }
            //check job validity//

            var data=JSON.stringify({
                referenceId:referenceId,
                custId:custId,
                child:JSON.stringify(child),
                userId:user.id,
            });
            const work = toast.loading('Adding Job...');
            await axios.post(ADDJOB_URL, data).then((res)=>{
                dispatch(updateTaskStatus({reference:referenceId, task:res.data.tasks, multiple:true}))
                let ids=res.data.tasks.map(t=>t.id);

                if(isMyTask){
                    setMyTaskIds([...ids, ...myTaskIds])
                }
                const toSend=res.data.assignees;
                const refresh=res.data.needsRefresh;
            
                toSend.filter(a=>a.userId!==user.id).map(s=>{
                var title=`New Task [#${s.jobId}] Assigned to you`
                var body=`you have been assigned to a new task by ${user.Fname} ${user.Lname}`
                var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
                var receiverId=s.userId
                var cat=categories.filter(c=>c.id==s.catId)[0]
                var iconLink=''
                var iconType=-1
                if(cat.icon!=null){
                    iconLink=`${cat.icon}`
                    iconType=0
                }else{
                    iconLink=`${cat.name}`
                    iconType=1
                }
                notify(title, body, onClickLink, receiverId, iconLink, iconType)
                })
                refresh.filter(r=>r!=user.id).map(ref=>{
                requireRefresh(ref, true, false, false)
                })    
              
                toast.dismiss(validate);
                toast.dismiss(work);
                toast.success('job posted'); 
                setChild([]);
                setWarningId(-1);
                 
            })


        }catch(e){
            if(!e?.response){
                toast.error("No Server Response!");
                toast.dismiss(validate);
                console.log(e);
            }else{
                console.log(e);
                toast.error("Oops! An error occured!");
                toast.dismiss(validate);
            } 
        }
    }



    const removeChild = (id) => {
        var filterChild = child.filter((c) => c.id !== id);
        setChild(filterChild);
        if(child.length<=0){
            isIdle(user.id, false);
        }
    };




  return (
    <div className='relative rounded-b-md bg-slate-50 dark:bg-slate-900'>
        <form onSubmit={(e)=>{addPostTask(e)}}>
            {child.length>0?
            <div className='childs-wrapper'>
                {child.map((c,index)=>(
                    <ChildtaskForm
                    key={c.id} 
                    fromPost={true} 
                    catId={catId}
                    compId={compId}
                    index={index}
                    length={child.length}
                    child={child}
                    isCategory={isCategory}
                    categories={categories}
                    removeChild={removeChild} 
                    childData={c}
                    setChild={setChild}
                    jobStatuses={jobStatuses}
                    paymentOptions={paymentOptions}
                    paymentMethods={paymentMethods}
                    jobTypes={jobTypes}
                    shipments={shipments}
                    productions={productions}
                    quotes={quotes}
                    checklists={checklists}
                    employeelist={employeelist}
                    warningId={warningId}
                    />
                ))}
            </div>
            :
            null
            }

            {child.length>0?
            <div className='flex items-center justify-end mt-1 pb-0.5'>
                <button type='submit' className='addTask-btn'>Add task</button>
            </div>
            :
            null
            }

        </form>
        
        <div className='absolute right-0 z-0 -bottom-8'>
          <button
              className="addChild-wrapper"
              onClick={() => {
                newChild(uuidv4());
              }}
              type="button"
              >
              <Add iconClass={'w-5 h-5 addchild'}/>
            </button>
      </div>

    </div>
  )
}

export default PostAddChildTask