import React from 'react'
import Avatar from '../layout/Avatar'
import { CompanylogoStorage_LINK } from '../../api/ApiUrl'

const CompanyBubble = ({co, active, setActive}) => {

  return (
    <div>
    <button onClick={()=>{setActive(co)}}
    className={`min-w-max min-h-max ring-2 ring-offset-1 ring-offset-slate-50 dark:ring-offset-slate-700
    ${active==co.id? `ring-orange-500` : `ring-transparent`}   w-10 h-10 rounded-full bg-slate-50 dark:bg-slate-900 transition-all duration-150 ease-in-out`}>
    {co.logo?
        <img src={`${CompanylogoStorage_LINK}${co.logo}`} className='w-10 h-10 rounded-full'/>
        :
        <Avatar value={co.name} width='10' height='10' color={'bg-orange-300 dark:bg-orange-800/70'}/>   
      }
    </button>
      </div>

  )
}

export default CompanyBubble