const axios = require('axios');

// http://127.0.0.1:8000
// https://escapetask.com

 const escapeAxios = axios.create({
   baseURL: 'https://escapetask.com',
   //baseURL: 'http://127.0.0.1:8000',
    timeout:20000,
    headers: {
        post:{
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        common: {
            'X-Requested-With': 'XMLHttpRequest',
            
        }
    },
    withCredentials:true,
});

escapeAxios.interceptors.request.use(function (config){
    const token=localStorage.getItem('auth_Token');
    config.headers.Authorization = token ? `Bearer ${token}` : ``;
    return config;
})

export default escapeAxios