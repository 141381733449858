import React,{ useTransition} from 'react'
import Avatar from '../layout/Avatar'

import { CategorylogoStorage_LINK } from '../../api/ApiUrl'
const CircleCategoryFilterButton = ({data, setFilterCategory, categoryId, setCategoryId, setIsclearable, setCat}) => {

  const [pending, startTransition]=useTransition();

  const clickFuntion=()=>{
    startTransition(()=>{
      setCategoryId(data.id); 
      setFilterCategory(data.id); 
      setCat(data); 
      setIsclearable(true)
    })
  }

  return (
    <div>
        <button className={`rounded-full transition-all duration-150 ease-in-out border-2 ${categoryId==data.id? `border-blue-500 dark:border-blue-600`:`border-slate-400 dark:border-slate-700`}`}
        onClick={()=>clickFuntion()}
        >
        
        {data.icon?
            <img src={`${CategorylogoStorage_LINK}${data.icon}`} className='w-6 h-6 rounded-full bg-slate-400 dark:bg-slate-900' alt='icon'/>
            :
            <Avatar value={data.name} width={'6'} height={'6'} color={'bg-orange-400 dark:bg-orange-900 text-slate-50 '}/>
        }
        </button>
    </div>
  )
}

export default CircleCategoryFilterButton