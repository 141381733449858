import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import { Add, User, Phone, Mail, Close, Telephone, Map, Home, Globe } from '../layout/Icons';
import { CategorylogoStorage_LINK, CUSTOMER_URL, POSTJOB_URL } from '../../api/ApiUrl';
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import ChildtaskForm from './ChildtaskForm';
import { addTask } from '../../features/Tasks';
import { notify, requireRefresh } from '../../config/firebaseConfig';

const CreateTaskForm = ({
    isCategory,
    compId, 
    catId,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    jobTypes,
    shipments,
    productions,
    quotes,
    checklists,
    employeelist,
    setAddTask,
    customerData,
    }) => {

const user=useSelector((state)=>state.user.value)
const categories=useSelector((state)=>state.category.value)
//const customerData=useSelector((state)=>state.customers.value);
const dispatch=useDispatch();
const [custName, setCustName] = useState('');
const [address, setAddress] = useState("");
const [company, setCompany] = useState("");
const [postCode, setPostCode] = useState("");
const [mobile, setMobile] = useState("");
const [telephone, setTelephone] = useState("");
const [email, setEmail] = useState("");
const [website, setWebsite] = useState("");
const [oldCust, setOldCust]=useState(-1);

const [child, setChild] = useState([]);


const [filterCustomer, setFilterCustomer]=useState([]);
const [onFocusCust, setOnFocusCust]=useState(false);

useEffect(()=>{
    if(custName.length>0){
      const data=customerData.filter((n)=>{
        if(
          n.name.toLowerCase().includes(custName.toLowerCase()) ||
          (n.address!=null && n.address.toLowerCase().includes(custName.toLowerCase())) ||
          (n.postCode!=null && n.postCode.toLowerCase().includes(custName.toLowerCase())) ||
          (n.company!=null && n.company.toLowerCase().includes(custName.toLowerCase())) ||
          n.email.toLowerCase().includes(custName.toLowerCase())
        ){
          return n;
        }else{
          return null
        }
      });
      setFilterCustomer(data);
    }else{
      setFilterCustomer([]);
    }
  
  },[custName])


  const setCustomer = (data)=>{
    setOldCust(data.id);
    setCustName(data.name);
    setAddress(data.address)
    setCompany(data.company);
    setPostCode(data.postCode);
    setMobile(data.mobile);
    setTelephone(data.telephone);
    setEmail(data.email);
    setWebsite(data.website);
    setFilterCustomer([]);
    setOnFocusCust(false);
    }


    var childData = {
        id: uuidv4(),
        category:catId,
        companyId:compId,
        qty: 1,
        jobdesc: "",
        taskSet:1,
        priority: 1,
        price: 0,
        disc: 0,
        charge: 0,
        vat: false,
        total: 0,
        paid: 0,
        status: 0,
        jobType: null,
        quote: null,
        shipment:null,
        production: null,
        paymentOption: null,
        paymentMethod: null,
        turnaround: null,
        isNote: false,
        notes: null,
        assignee: [user.id],
        checklist:[],
        repeat: false,
        openrepeat: false,
        repeatDate: null,
        repeatType: 1,
        done:false,
      };

      useEffect(()=>{
        setChild([childData]);
      },[compId, catId])

      const newChild = (id) => {

        if(child.length<=9){
        var childData = {
          id: uuidv4(),
          category:catId,
          companyId:compId,
          qty: 1,
          jobdesc: "",
          taskSet:1,
          priority: 1,
          price: 0,
          disc: 0,
          charge: 0,
          vat: false,
          total: 0,
          paid: 0,
          status: 0,
          jobType: null,
          quote: null,
          shipment:null,
          production: null,
          paymentOption: null,
          paymentMethod: null,
          turnaround: null,
          isNote: false,
          notes: null,
          assignee: [user.id],
          checklist:[],
          repeat: false,
          openrepeat: false,
          repeatDate: null,
          repeatType: 1,
          done:false,
          
        };
        setChild([...child, childData]);
        }
        else{
            toast.error('Cannot create more than 10 nested tasks!');
        }
      };
    
      const removeChild = (id) => {
        var filterChild = child.filter((c) => c.id !== id);
        setChild(filterChild);
      };
    
    //child job //
    
    //job validate and post//
    const [warningId, setWarningId]=useState(-1);


    const clearCustomer=()=>{
        setCustName('')
        setAddress("")
        setCompany("")
        setPostCode("")
        setMobile("")
        setTelephone("")
        setEmail("")
        setWebsite("")
        setOldCust(-1)
      }


      const rating =(rate)=>{
        if(rate==0){
            return ( <p className='text-sm'>😐</p> )
        }else if(rate==1){
            return ( <p className='text-sm'>😁</p> )
        }
        else if(rate==2){
            return ( <p className='text-sm'>💖</p> )
        }else if(rate==-1){
        return ( <p className='text-sm'>😡</p> )
        }else{
            return ( <p className='text-sm'>👻</p> )
        }
      }
      
  const PostTask = async (e)=>{
        e.preventDefault();
        const validate = toast.loading('validating data...', {icon: '📃'});
        try{
          //check customer//
  if(!custName.length>0 || !email.length>0 || !mobile.length>0){
    toast.error('Customer Information required!');
    toast.dismiss(validate);
    return
  }
    //check customer//

    //check job validity//
    var checkCateg=child.filter((ch)=>(!ch.category==null));
    if(checkCateg.length>0){
      toast.error('please select a category');
      setWarningId(checkCateg[0].id);
      toast.dismiss(validate);
      return
    }
    
    var checkDesc=child.filter((ch)=>(!ch.jobdesc.length>0))
    if(checkDesc.length>0){
      toast.error('please type task description');
      setWarningId(checkDesc[0].id);
      toast.dismiss(validate);
      return
    }
    var checkStatus=child.filter((ch)=>(ch.status==0))
    if(checkStatus.length>0){
      toast.error('please select task status');
      setWarningId(checkStatus[0].id);
      toast.dismiss(validate);
      return
    }

    var checkData=child.filter((ch)=>(ch.turnaround==null))
    if(checkData.length>0){
      toast.error('please enter turnaround Date');
      setWarningId(checkData[0].id);
      toast.dismiss(validate);
      return
    }

    var checkAssinge=child.filter((ch)=>(ch.assignee.length==0));
    if(checkAssinge.length>0){
      toast.error('please assign your task!');
      setWarningId(checkAssinge[0].id);
      toast.dismiss(validate);
      return
    }
    //check job validity//
    var data=JSON.stringify({
      userId:user.id,
      oldCust:oldCust,
      companyId:compId,
      custName:custName,
      email:email,
      mobile:mobile,
      address:address,
      company:company,
      postCode:postCode,
      telephone:telephone,
      website:website,
      child:JSON.stringify(child),
    });
    const work = toast.loading('Posting Task...');
    await axios.post(POSTJOB_URL, data).then((res)=>{
      toast.dismiss(validate);
      toast.dismiss(work);
      toast.success('Task posted');
      dispatch(addTask(res.data.task))
      
      const toSend=res.data.assignees;
      const refresh=res.data.needsRefresh;
      toSend.filter(a=>a.userId!==user.id).map(s=>{
        var title=`New Task [#${s.jobId}] Assigned to you`
        var body=`you have been assigned to a new task by ${user.Fname} ${user.Lname}`
        var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
        var receiverId=s.userId
        var cat=categories.filter(c=>c.id==s.catId)[0]
        var iconLink=''
        var iconType=-1
        if(cat.icon!=null){
          iconLink=`${cat.icon}`
          iconType=0
        }else{
          iconLink=`${cat.name}`
          iconType=1
        }
        notify(title, body, onClickLink, receiverId, iconLink, iconType)
      })
      refresh.filter(r=>r!=user.id).map(ref=>{
        requireRefresh(ref, true, false, false)
      })      
      setChild([childData]);    
      setCustName('')
      setAddress("")
      setCompany("")
      setPostCode("")
      setMobile("")
      setTelephone("")
      setEmail("")
      setWebsite("")
      setOldCust(-1);
      setAddTask(false);
      
    })


        }catch(e){
          if(!e?.response){
            toast.error("No Server Response!");
            toast.dismiss(validate);
            console.log(e);
        }else{
            console.log(e);
            toast.error("Oops! An error occured!");
            toast.dismiss(validate);
        }
        }
      }


  return (
    <div className='relative p-1 rounded-b-md bg-slate-50 dark:bg-slate-900'>
        <form onSubmit={(e)=>PostTask(e)}>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-9'>

            <div className='relative'>
                <div className="flex items-center justify-start">
                    <p className="input-field-prepend-icon">
                        <User iconClass={'w-4 h-4'}/>
                    </p>
                    <input
                    type="text"
                    value={custName}
                    onChange={(e) => {
                    setCustName(e.target.value);
                    if(oldCust!=-1){
                        setOldCust(-1);
                    }
                    }}
                    className="w-full input-field-group"
                    placeholder="*Cust. name"
                    onFocus={()=>setOnFocusCust(true)}
                    required
                    />
                    <div className='shadow-lg shadow-slate-400 dark:shadow-slate-900'>
                        <div className='absolute left-1 top-7 z-[99] h-[30vh] overflow-y-auto'>
                            {filterCustomer.length>0 && onFocusCust? 
                                <ul className='ul-wrapper'>
                                    {filterCustomer.map((fc)=>(
                                        <li key={fc.id} onClick={()=>{setCustomer(fc)}} className='li-wrapper' >
                                            <div className='flex items-center justify-start gap-1'>
                                                <p className='text-sm font-semibold'>{fc.name}</p>
                                                {rating(fc.rating)}
                                            </div>
                                            <p className='text-xs'>{fc.email} </p>
                                            <p className='text-xs'>{fc.mobile} {fc.telephone? `| ${fc.telephone}`: ''}</p>
                                            <p className='text-xs'>{fc.company} {fc.website? `| ${fc.website}`: ''}</p>
                                            <p className='text-xs'>{fc.address} {fc.postCode}</p>
                                        
                                        </li>
                                    ))}
                                </ul>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>

             <div className="flex items-center justify-start">
                <p className="input-field-prepend-icon">
                <Phone iconClass={'w-4 h-4'}/>
                </p>
                <input
                type="tel"
                value={mobile}
                onChange={(e) => {
                setMobile(e.target.value);
                if(oldCust!=-1){
                setOldCust(-1);
                }
                }}
                className="w-full input-field-group"
                placeholder="*Mobile"
                required
                />
            </div>  
            
            <div className="flex items-center justify-start">
                <p className=" input-field-prepend-icon">
                    <Telephone iconClass={'w-4 h-4'}/>
                </p>
                <input
                type="tel"
                value={telephone}
                onChange={(e) => {
                setTelephone(e.target.value);
                if(oldCust!=-1){
                    setOldCust(-1);
                }
                }}
                className="w-full input-field-group"
                placeholder="Telephone"
                />
            </div>
            
            <div className="flex items-center justify-start w-auto">
                <p className=" input-field-prepend-icon">
                    <Mail iconClass={'w-4 h-4'}/>
                </p>
                <input
                type="email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    if(oldCust!=-1){
                    setOldCust(-1);
                    }
                }}
                className="w-full input-field-group"
                placeholder="Email"
                required
                />
            </div>
            
            <div className="flex items-center justify-start col-span-2">
                <p className="input-field-prepend-icon">
                    <Map iconClass={'w-4 h-4'}/>
                </p>
                <input
                type="text"
                value={address}
                onChange={(e) => {
                    setAddress(e.target.value);
                    if(oldCust!=-1){
                    setOldCust(-1);
                    }
                }}
                className="w-full input-field-group"
                placeholder="Address"
                />

                <input
                type="text"
                value={postCode}
                onChange={(e) => {
                    setPostCode(e.target.value);
                    if(oldCust!=-1){
                    setOldCust(-1);
                    }
                }}
                className="w-20 input-field"
                placeholder="PostCode"
                />
            </div>
            
            <div className="flex items-center justify-start">
            <p className=" input-field-prepend-icon">
            <Home iconClass={'w-4 h-4'}/>
            </p>
            <input
            type="text"
            value={company}
            onChange={(e) => {
                setCompany(e.target.value);
                if(oldCust!=-1){
                setOldCust(-1);
                }
            }}
            className="w-full input-field-group"
            placeholder="Company"
            />
            </div>

            <div className="flex items-center justify-start w-32">
            <p className=" input-field-prepend-icon">
                <Globe iconClass={'w-4 h-4'}/>
            </p>
            <input
            type="text"
            value={website}
            onChange={(e) => {
                setWebsite(e.target.value);
                if(oldCust!=-1){
                setOldCust(-1);
                }
            }}
            className="w-full input-field-group"
            placeholder="website"
            />
            </div>

            <div className='flex items-center justify-end col-span-2 gap-1 ml-auto md:col-span-1'>
            {oldCust!=-1?
            <button
            className="reject-btn"
            onClick={() => {
                clearCustomer();

            }}
            type="button"
            >
                <Close iconClass={'w-4 h-4'}/>
            </button>
            :
            null
            }
            
            <button className="jobPost-btn" type='submit'>Post task</button>
            </div>

            </div>
          
        
         
            {child.map((c, index) => (
              <ChildtaskForm
              key={c.id} 
              fromPost={false} 
              catId={catId}
              compId={compId}
              index={index}
              length={child.length}
              child={child}
              isCategory={isCategory}
              categories={categories}
              removeChild={removeChild} 
              childData={c}
              setChild={setChild}
              jobStatuses={jobStatuses}
              paymentOptions={paymentOptions}
              paymentMethods={paymentMethods}
              jobTypes={jobTypes}
              shipments={shipments}
              productions={productions}
              quotes={quotes}
              checklists={checklists}
              employeelist={employeelist}
              warningId={warningId}
              />
            ))}

          
           
        </form>
          
        <div className='absolute right-0 -bottom-9'>
          <button
              className="addChild-wrapper"
              onClick={() => {
                newChild(uuidv4());
              }}
              type="button"
              >
              <Add iconClass={'w-5 h-5 addchild'}/>
            </button>
      </div>

    </div>
  )
}

export default CreateTaskForm