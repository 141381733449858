import React, {useState, useEffect} from 'react'
import { GETREQEMPLOYEES_URL } from '../../api/ApiUrl';
import axios from '../../api/axios';
import EmployeeCard from './EmployeeCard';

import { Close } from '../layout/Icons';
const Employee = ({compId, activeCompany}) => {

    const [employees, setEmployees]=useState([]);
    const [searchEmp, setSearchEmp]=useState('');
    const [searchEmployeeData, setSearchEmployeeData]=useState([]);
    
    const getEmployees= async (id)=>{
        await axios.get(`${GETREQEMPLOYEES_URL}${id}`).then((res)=>{
            setEmployees(res.data.employees);
            setSearchEmployeeData(res.data.employees);
        })
    }

    useEffect(()=>{
        getEmployees(compId)
    
        return(()=>{
            setEmployees([])
            setSearchEmp('')
            setSearchEmployeeData([])
        })
    
      },[compId])


      useEffect(()=>{
        if(searchEmp.length>0){
          const data = employees.filter((em) => {
            if (
              em.Fname.toLowerCase().includes(searchEmp.toLowerCase()) ||
              em.Lname.toLowerCase().includes(searchEmp.toLowerCase()) ||
              em.email.toLowerCase().includes(searchEmp.toLowerCase())
              ) {
                return em;
              } else {
                return null;
              }
            });
            setSearchEmployeeData(data);
        }else{
          setSearchEmployeeData(employees);
        }  
      },[searchEmp])



  return (
    <div>
        <div>
            <div className='w-full'>
            <div className='flex items-center justify-between gap-2'>
            <h4 className='my-2 text-2xl font-bold text-slate-600 dark:text-slate-400'>Employees </h4>
            <div className='flex items-center justify-end gap-1'>
            <input type={'text'} className='input-field-fat' value={searchEmp}  onChange={(e)=>{setSearchEmp(e.target.value)}} placeholder='search'/>
            {searchEmp.length>0?
            <button className="icon-btn" onClick={()=>setSearchEmp('')}><Close iconClass={'w-4 h-4'}/></button>
            :
            null
          }
            </div>
            </div>

          {employees.length>0?
            <div className='overflow-y-auto max-h-[90vh] min-h-[40vh]'>
              <div className='grid grid-cols-1 gap-2 lg:grid-cols-2'>
            
            {searchEmployeeData.length>0?
            <>{searchEmployeeData.map((em)=>
            <EmployeeCard key={em.emId} 
            user={em} 
            setEmployees={setEmployees}  
            setSearchEmployeeData={setSearchEmployeeData} 
            activeCompany={activeCompany}
            />)}</>
            :
            <p className='text-sm font-semibold text-center text-slate-400 dark:text-slate-600'>No Data</p>
             }
          </div>

            </div>
            :
            <p className='text-sm font-semibold text-center text-slate-400 dark:text-slate-600'>No Data</p>

            }
            </div>
    </div>
    </div>
  )
}

export default Employee