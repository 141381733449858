import React from 'react'
import { Tab } from '@headlessui/react'
import { Template } from '../layout/Icons';
import Toggle from '../layout/ThemeToggle';
const Settings = () => {
  return (
    <div className='relative main-bodyLayout'>
    <Tab.Group manual vertical >
    <div className='grid grid-cols-1 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8'>
        <Tab.List className={'tabList'}>
                <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'}><Template iconClass={'w-4 h-4'}/> <span className='hidden md:block'>General</span> </Tab>
                
        </Tab.List>

        <div className='md:col-span-4 lg:col-span-5 xl:col-span-6 2xl:col-span-7'>
            <Tab.Panels className={'tabPanel'}>
                <Tab.Panel>
                <h4 className='px-4 my-2 text-xl font-semibold border-b text-slate-600 dark:text-slate-400 border-slate-300 dark:border-slate-700'>General</h4>
                <div className='flex items-center justify-start gap-2'>
                    <p className='text-sm font-semibold text-slate-600 dark:text-slate-400'> Skin</p>
                    <Toggle/>
                    
                </div>

              
                </Tab.Panel>
            </Tab.Panels>
        </div>
        </div>
    </Tab.Group>
</div>
  )
}

export default Settings