import React, {useState} from 'react'
import { useDispatch } from 'react-redux';

import Logo from '../../Logo'
import axios from '../../api/axios';
import toast from 'react-hot-toast';

import {Link, useNavigate} from "react-router-dom";
import { Eye, LoginIcon } from '../layout/Icons'
import { LOGIN_URL } from '../../api/ApiUrl';
import { login } from '../../features/user';
import { setCompany } from '../../features/companies';
import { setCategory } from '../../features/categories';
import { SignInFirebaseUser } from '../../config/firebaseConfig';
import '../../helmet.css'
import {ReactComponent as Helmet} from '../../helmet.svg'
const Login = () => {
const navigate = useNavigate();
const [email, setEmail]=useState('');
const [password,setPassword]=useState('');
const [showPass, setShowPass]=useState(false)
const dispatch = useDispatch();


const loginUser= async(e)=>{
    e.preventDefault();
    var data = JSON.stringify({ 
                    email:email,
                    password:password
    }); 
    await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(LOGIN_URL, data).then(res =>{
        if(res.data.error){
          console.log(res.data.error)
        }else{
          localStorage.setItem('auth_Token', res.data.token);
          localStorage.setItem('auth_user', JSON.stringify(res.data.user));
          var user=res.data.user
          dispatch(login(user))
          if(user.f_tokenId!=null){
            SignInFirebaseUser(email,user.f_tokenId, user.id)
          }
          dispatch(setCompany(res.data.company));
          dispatch(setCategory(res.data.category))
          toast.success(`Hi, ${user.Lname}!`, {icon: '👋'})
          navigate('/')
        }
      }).catch((err)=>{
        if(!err?.response){
          toast.error('No Server Response!');
        }
        if(err.response.status==401){
          toast.error(err.response.data.message)
        }
      });
    });

  }
  return (
    <div className='container mx-auto h-screen xl:mr-auto pt-[5em] xl:pt-[5em] place-items-center'>
              <div className='grid grid-cols-1 lg:grid-cols-2 ipadPro:grid-cols-1'>
              <div className='hidden mx-auto xl:w-11/12 sm:block ipadPro:w-2/3 ipad:hidden ipad:mx-auto ipadPro:mx-auto'>
              <Helmet/>
              </div>


              <div className='xl:mt-32 ipadPro:mt-16 ipadPro:w-3/4 ipadPro:mx-auto ipad:mt-2 ipad:w-8/12 ipad:mx-auto '>
              <div className="w-11/12 mx-auto xl:w-2/3 card">
              <div className='flex items-center justify-center gap-1'>

                <Logo
                className="w-8 h-8"
                fill={"fill-slate-400 dark:fill-slate-600"}
                />
              
              <h4 className="mb-2 text-2xl font-bold text-center text-slate-600 dark:text-slate-300">
                Login
              </h4>
                </div>
                <form onSubmit={(e)=>{loginUser(e)}}>
                  <input 
                  type="email"
                  value={email} 
                  className='w-full input-field-fat' 
                  onChange={(e)=>setEmail(e.target.value)} 
                  placeholder='Email Address'
                  required/>
                  <div className='relative'>
                  <input 
                  type={showPass? `text`:`password`} 
                  value={password}
                  className='w-full input-field-fat' 
                  onChange={(e)=>setPassword(e.target.value)} 
                  placeholder='Password' required/>
                  <i className={`${showPass? `text-slate-50 bg-orange-600`: `text-slate-400 dark:text-slate-600 bg-slate-200 dark:bg-slate-900`} showPass`} onClick={()=>{setShowPass(!showPass)}}><Eye iconClass={'w-5 h-5'} /></i>
                  </div>
                  <Link to="/forgot-password" className='link'>Forgot Password?</Link>
                  <button className='inline-flex items-center justify-center w-full gap-2 submit-btn' type='submit'><LoginIcon iconClass={'w-5 h-5'}/>Sign In</button>
                </form>

                  <Link to="/signUp" className='link'>Don't have an account? Sign up</Link>
                  
              

              </div>
              </div>
              
              </div>

              
    </div>
  )
}

export default Login