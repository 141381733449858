import React from 'react'
import Moment from 'react-moment';
import { CategorylogoStorage_LINK } from '../../api/ApiUrl';
import Avatar from './Avatar';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Logo'


const NotificationBar = ({notification, setShowNotification}) => {

  const navigate = useNavigate();

  const goTo=(link)=>{
    if(link.length>3){
      navigate(link)
      setShowNotification(false)
    }
  }

  return (
    <button onClick={()=>goTo(notification.onClickLink)} className="w-full">
    <div className={`group notificationBar ${notification.read? `border-slate-400 dark:border-slate-700 bg-slate-50 border  dark:bg-slate-800` :  `bg-orange-200/30 dark:bg-orange-800/30 border-orange-500 dark:border-orange-800`}`}>
        <div className='flex items-center justify-start gap-2'>
          <div className='rounded-full min-w-max min-h-max ring-2 w-7 h-7 ring-slate-400 dark:ring-slate-600'>
            {notification.iconType==0 &&
              <img src={`${CategorylogoStorage_LINK}${notification.icon}`} className="rounded-full w-7 h-7" alt={'category'}/>
            }
             {notification.iconType==1 &&
            <Avatar value={notification.icon} width='7' height={'7'}/>
             }
              {notification.iconType==3 &&
             <Logo className='p-0.5 mx-auto w-7 h-7' fill={"fill-slate-400 dark:fill-slate-600"}/>
             }
          </div>

          
          <div>
          <p className='text-sm font-semibold text-left text-slate-600 dark:text-slate-400'>{notification.title}</p>
          <p className='text-sm text-left text-slate-500 dark:text-slate-400'>{notification.body}</p>
         
          {notification.createdAt !=null &&
          <Moment className='absolute bottom-0 right-0 hidden p-1 text-xs italic rounded-md group-hover:block text-slate-500 dark:text-slate-500 bg-slate-50 dark:bg-slate-900' date={notification.createdAt.toDate()} fromNow />
          }
          </div>
        </div>
        
    </div>
    </button>
  )
}

export default NotificationBar