import React from 'react'

export const Add =({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
</svg>
    )
}

export const UserAdd=({iconClass})=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
        </svg>
    )
}

export const Ban=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clipRule="evenodd" />
        </svg>
    )
}

export const Work=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
        </svg>
    )
}

export const CheckCircle=({iconClass})=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
    )
}

export const UserRemove=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
        </svg>
    )
}

export const Note=({iconClass})=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
        </svg>
    )
}

export const Delete=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
        </svg>
    )
}

export const Close=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
    )
}
export const Check=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
    )
}
export const Minus=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
        </svg>
    )
}
export const User=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
        </svg>
    )
}
export const Phone=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
        </svg>
    )
}

export const Mail=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clipRule="evenodd" />
        </svg>
    )
}
export const Telephone=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path  d="M10,18.9c-2.1,0-4.2,0-6.2,0c-1.4,0-2.4-1.2-2.2-2.6C1.8,15,2,13.7,2.1,12.4c0.1-0.8,0.6-1.4,1.4-1.6
		c0.5-0.1,0.9-0.3,1.4-0.5c1.3-0.6,2-1.7,2.2-3.2c0-0.1,0-0.3,0-0.4s0-0.2,0.1-0.2c0.8,0,1.5-0.1,2.2-0.1c1.1,0,2.1,0,3.2,0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.2C13,7.1,13,7.5,13.1,7.8c0.3,1.2,1.1,2,2.1,2.5c0.4,0.2,0.8,0.3,1.3,0.4
		c0.8,0.2,1.3,0.7,1.4,1.6c0.2,1.3,0.4,2.6,0.5,4c0.2,1.4-0.8,2.5-2.1,2.5C14.2,18.9,12.1,18.9,10,18.9z M10,16
		c1.5,0,2.8-1.3,2.8-2.9s-1.2-2.8-2.7-2.8s-2.8,1.3-2.8,2.8C7.2,14.7,8.5,16,10,16z"/>
	    <path  d="M10.1,3.8c-1,0-2,0-3,0.2c-0.4,0.1-0.7,0.2-1,0.4C5.9,4.6,5.8,4.8,5.8,5c0,0.3,0,0.7,0,1C5.7,6.5,5.4,6.9,5,7.1
		C4.2,7.5,3.4,7.6,2.5,7.5c-0.7,0-1.1-0.4-1.3-1.1C1,5.9,1,5.4,1,4.9C1,4.5,1.1,4.2,1.3,4C1.5,3.7,1.8,3.5,2,3.3
		c0.7-0.6,1.6-1,2.4-1.3c2-0.7,4.1-0.9,6.2-0.9c1.4,0,2.8,0.2,4.1,0.6c1,0.3,1.9,0.6,2.8,1.2C18,3.2,18.4,3.6,18.7,4
		C18.9,4.3,19,4.5,19,4.8c0,0.5,0,1-0.1,1.4S18.6,7,18.2,7.3c-0.3,0.1-0.5,0.2-0.8,0.2c-0.7,0-1.5-0.1-2.2-0.4
		c-0.5-0.2-0.9-0.7-0.9-1.3c0-0.3,0-0.5,0-0.8c0-0.4-0.2-0.6-0.4-0.7C13.5,4.1,13,4,12.6,3.9C11.7,3.8,10.9,3.8,10.1,3.8z"/>
        </svg>
    )
}

export const Map=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
    )
}

export const Home=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
    )
}

export const Globe=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
        </svg>
    )
}
export const Eye=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>
    )
}
export const Link=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
        </svg>
    )
}

export const Forward=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
    )
}

export const Pencil=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
    )
}
export const ChevronUp=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
        </svg>
    )
}
export const Drag=({iconClass})=>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
<g>
	<circle cx="6.5" cy="3.5" r="1.7"/>
</g>
<g>
	<circle cx="6.5" cy="16.5" r="1.7"/>
</g>
<g>
	<circle cx="6.5" cy="10" r="1.7"/>
</g>
<g>
	<circle cx="13.5" cy="3.5" r="1.7"/>
</g>
<g>
	<circle cx="13.5" cy="16.5" r="1.7"/>
</g>
<g>
	<circle cx="13.5" cy="10" r="1.7"/>
</g>

</svg>

    )
}

export const RevertIcon=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
</svg>

    )
}

export const SideBarIcon=({iconClass})=>{
    return(
    <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
    <path d="M16.8,18.5h-4.3c-0.4,0-0.8-0.4-0.8-0.8V2.3c0-0.4,0.4-0.8,0.8-0.8h4.3c0.4,0,0.8,0.4,0.8,0.8v15.4
    C17.6,18.1,17.3,18.5,16.8,18.5z"/>
    <path d="M3.3,14.5L3.3,14.5c-0.4-0.4-0.4-1.2,0-1.6l2.4-2.4c0.3-0.3,0.3-0.7,0-1L3.3,7.1c-0.4-0.4-0.4-1.2,0-1.6l0,0
    C3.8,5,4.5,5,4.9,5.5l3.7,3.7c0.4,0.4,0.4,1.2,0,1.6l-3.7,3.7C4.5,15,3.8,15,3.3,14.5z"/>
    </svg>
    )
}
export const SideBarShrink=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M4.9,18.5H3.2c-0.4,0-0.8-0.4-0.8-0.8V2.3c0-0.4,0.4-0.8,0.8-0.8h1.7c0.4,0,0.8,0.4,0.8,0.8v15.4
        C5.7,18.1,5.3,18.5,4.9,18.5z"/>
        <path d="M12,14.5L12,14.5c-0.4-0.4-0.4-1.2,0-1.6l2.4-2.4c0.3-0.3,0.3-0.7,0-1L12,7.1c-0.4-0.4-0.4-1.2,0-1.6l0,0
        c0.4-0.4,1.2-0.4,1.6,0l3.7,3.7c0.4,0.4,0.4,1.2,0,1.6l-3.7,3.7C13.2,15,12.4,15,12,14.5z"/>
        </svg>
    )
}

export const ChevronRight=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>
    )
}
export const ChevronLeft=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
    )
}
export const UserCircle=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
        </svg>
    )
}

export const Settings=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
    )
}

export const LoginIcon=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
        </svg>
    )
}

export const LogoutIcon=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
    )
}

export const Tune=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
        </svg>
    )
}

export const Template=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
        </svg>
    )
}

export const Group=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
        </svg>
    )
}
export const Image=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
        </svg>
    )
}

export const DotsCircle=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

export const BackSpace=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z" clipRule="evenodd" />
        </svg>
    )
}

export const Sun=({iconClass})=>{
    return(
    <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
    </svg>
    )
}
export const Moon=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
</svg>
    )
}

export const Duplicate=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
        <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
        </svg>

    )
}

export const Resend=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
        </svg>
    )
}


export const EmojiHappy=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clipRule="evenodd" />
</svg>
    )
}

export const ChevronDown=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    )
}
export const CheckDone=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
        <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
    )
}

export const Bell=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
        </svg>
    )
    
}

export const Smile=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

export const MsgIcon=({iconClass})=>{
    return (
    <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
    </svg>
    )
}

export const Washroom=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
        
        <path d="M2,17.4c0-3.7,0-7.3,0-11c0.2-0.8,0.6-1.3,1.3-1.7c0.5-0.3,1-0.5,1.5-0.7C6.2,3.7,7.6,3.5,9,3.5c4.1,0,8.2,0,12.3,0
                c0.5,0,0.7,0.2,0.7,0.7c0,4.2,0,8.5,0,12.7c0,0.5-0.2,0.7-0.7,0.7c-1.7,0-3.5,0-5.2,0c-0.1,0-0.2,0-0.2,0c-0.4,0.8-0.8,1.2-1.6,1.7
                c-1.1,0.6-2.3,0.9-3.6,1.1c-1.2,0.1-2.4,0.1-3.5,0c-1.2-0.1-2.3-0.4-3.3-0.9C2.9,19,2.2,18.4,2,17.4z M14.7,8.5
                c-1.8,1-3.8,1.3-5.8,1.3c-2,0-4-0.3-5.8-1.3c0,0.1,0,0.2,0,0.2c0,2.8,0,5.5,0,8.3c0,0.3,0.1,0.5,0.3,0.8c0.1,0.1,0.2,0.2,0.4,0.3
                C4.5,18.6,5.2,18.8,6,19c1.3,0.3,2.6,0.3,3.9,0.3c1.1-0.1,2.2-0.3,3.2-0.7c0.6-0.2,1.1-0.5,1.5-1c0.1-0.2,0.2-0.3,0.2-0.5
                c0-2.8,0-5.6,0-8.3C14.7,8.6,14.7,8.6,14.7,8.5z M15.9,16.4c1.7,0,3.3,0,4.9,0c0-3.9,0-7.8,0-11.7c-2.1,0-4.2,0-6.3,0c0,0,0,0,0,0
                c0.1,0,0.1,0.1,0.2,0.1c0.8,0.5,1.2,1.2,1.2,2.1c0,3.1,0,6.1,0,9.2C15.9,16.3,15.9,16.4,15.9,16.4z M8.9,8.6c1.3,0,2.6-0.1,3.8-0.5
                c0.6-0.2,1.3-0.4,1.8-0.9c0.4-0.4,0.4-0.7,0-1.1C14,5.7,13.5,5.5,13,5.3C12,5,10.9,4.8,9.8,4.7c-1.5-0.1-3.1,0-4.6,0.5
                C4.6,5.4,4.1,5.6,3.6,6C3,6.4,3,6.9,3.6,7.3C4,7.6,4.4,7.8,4.8,7.9C6.1,8.5,7.5,8.6,8.9,8.6z"/>
        <path d="M8.9,6.1c0.5,0,1.1,0,1.5,0.2c0.2,0.1,0.4,0.2,0.4,0.4c0,0.2-0.2,0.3-0.4,0.4c-0.6,0.2-1.3,0.3-2,0.2c-0.3,0-0.7-0.1-1-0.2
                C7.2,7,7,6.9,7,6.7c0-0.2,0.2-0.3,0.4-0.4C7.9,6.1,8.5,6.1,8.9,6.1z"/>
        </svg>
    )
}

export const ShortBreak=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
        
        <path d="M6.2,22.5c-0.2,0-0.4-0.1-0.7-0.1C4,22,2.9,21.2,2.1,19.8c-0.3-0.5,0-1,0.6-1c0.8,0,1.6,0,2.4,0c0.1,0,0.1,0,0.2,0
            c-0.2-0.2-0.4-0.4-0.5-0.6c-1.3-1.7-2.1-3.6-2.5-5.6C2.1,11.6,2,10.6,2,9.7c0-0.5,0.2-0.7,0.7-0.7c5.3,0,10.6,0,15.9,0
            c0.5,0,0.7,0.2,0.7,0.7c0,0.2,0,0.3,0,0.5c0.1,0,0.1,0,0.2,0c0.6,0,1.2,0,1.9,0c0.8,0.1,1.4,0.7,1.6,1.5c0,0,0,0.1,0,0.1
            c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.2,1.1-0.4,1.6c-1,2.1-2.7,3.3-5,3.3c-0.4,0-0.6,0.1-0.8,0.4
            c-0.2,0.3-0.4,0.6-0.7,0.9c0.1,0,0.2,0,0.2,0c0.8,0,1.6,0,2.4,0c0.6,0,0.9,0.5,0.6,1c-0.6,1.2-1.6,2-2.9,2.5
            c-0.4,0.1-0.8,0.2-1.1,0.3C12.1,22.5,9.2,22.5,6.2,22.5z M3.3,10.2C3.3,10.2,3.2,10.2,3.3,10.2c0.1,1.8,0.4,3.4,1.1,5
            C5,16.6,5.8,17.8,7,18.7c0.1,0.1,0.2,0.1,0.3,0.1c2.2,0,4.5,0,6.7,0c0.1,0,0.2,0,0.2-0.1c0.3-0.3,0.6-0.5,0.9-0.8
            c1.1-1.2,1.9-2.7,2.3-4.2c0.4-1.1,0.5-2.3,0.6-3.5C13.1,10.2,8.2,10.2,3.3,10.2z M17.5,20.1c-4.6,0-9.1,0-13.7,0
            c0,0,0.1,0.1,0.1,0.1c0.8,0.8,1.7,1.1,2.8,1.1c2.4,0,4.8,0,7.2,0c0.4,0,0.7,0,1.1,0C15.9,21.2,16.8,20.8,17.5,20.1z M19.3,11.4
            c-0.2,0.9-0.3,1.7-0.6,2.5c-0.2,0.8-0.6,1.6-0.9,2.4c0.2,0,0.4-0.1,0.7-0.1c0.2-0.1,0.5-0.1,0.7-0.2c1.5-0.7,2.6-2.2,2.6-3.9
            c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.3,0-0.5,0C20.2,11.4,19.7,11.4,19.3,11.4z"/>
        <path d="M6.9,1.5c0.3,0,0.5,0.1,0.6,0.4c0.1,0.2,0.1,0.5-0.1,0.7C7.2,2.8,7.1,3,7.1,3.3c0,0.4,0.1,0.7,0.4,0.9
            C7.9,4.7,8.1,5.3,8.1,6C8,6.6,7.8,7,7.4,7.5C7.2,7.7,6.8,7.7,6.6,7.5c-0.3-0.2-0.3-0.6,0-0.9c0.4-0.4,0.4-1,0.2-1.4
            C6.6,5.1,6.5,5,6.4,4.9C5.6,4,5.6,2.6,6.5,1.8C6.6,1.6,6.8,1.6,6.9,1.5z"/>
        <path d="M10.6,1.5c0.3,0,0.5,0.1,0.6,0.4c0.1,0.2,0.1,0.5-0.1,0.7c-0.2,0.2-0.3,0.5-0.4,0.8c0,0.4,0.1,0.6,0.4,0.9
            c0.5,0.5,0.7,1.1,0.6,1.8c0,0.6-0.3,1.1-0.7,1.5c-0.2,0.3-0.6,0.3-0.9,0c-0.3-0.2-0.3-0.6,0-0.9c0.3-0.4,0.4-0.7,0.3-1.2
            c-0.1-0.2-0.2-0.4-0.4-0.6C9.3,4,9.3,2.6,10.2,1.8C10.3,1.6,10.5,1.6,10.6,1.5z"/>
        <path d="M14.3,1.5c0.5,0,0.8,0.5,0.6,0.9c0,0-0.1,0.1-0.1,0.1c-0.5,0.6-0.5,1.1,0.1,1.7c0.8,0.9,0.8,2.3,0,3.2
            c-0.3,0.3-0.6,0.3-0.9,0.1c-0.3-0.2-0.3-0.6-0.1-0.9c0.5-0.6,0.5-1.1,0-1.7C13,4,13,2.7,13.9,1.8C14,1.6,14.2,1.6,14.3,1.5z"/>
        </svg>
    )
}



export const LongBreak=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
        
        <path d="M4.5,19.4c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1,0-0.1,0-0.1c-0.4-1.6,0.3-3.3,1.8-4c1.5-0.7,3.3-0.3,4.3,1
        c0.2,0.2,0.3,0.5,0.5,0.7c0.4-0.2,0.7-0.3,1.1-0.5c-0.2-0.3-0.3-0.6-0.5-0.9c-1.2-1.5-2.7-2.1-4.6-1.8c-0.6,0.1-1,0.4-1.6,0.5
        c-1.1,0.4-2,0.2-2.9-0.5c-0.3-0.2-0.4-0.5-0.7-0.8c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.4,0.2-0.6,0.5-0.7c1.3-0.6,2.6-1.1,3.9-1.7
        C6,9.6,6.2,9.5,6.4,9.6C6.7,9.6,7,9.6,7.3,9.7c0-0.1,0-0.2,0.1-0.3c0.4-1.6,1.7-2.8,3.3-3c0.8-0.1,1.5,0,2.3,0.3c0.1,0,0.2,0,0.2,0
        c0.2-0.2,0.5-0.4,0.7-0.6c-0.1-0.3-0.3-0.7-0.4-1c-0.4,0.2-0.7,0.3-1.1,0.5c-0.3,0.1-0.6,0-0.7-0.3c-0.1-0.3,0-0.6,0.3-0.7
        c0.5-0.2,1-0.4,1.6-0.6c2.4-0.5,4.8,0.7,5.8,2.9c0.3,0.7,0.3,1.3-0.2,1.9c-0.2,0.2-0.4,0.4-0.7,0.5c-0.6,0.3-1.2,0.7-1.6,1.3
        c0.4,0.2,0.7,0.4,1,0.6c0,0,0.1-0.1,0.1-0.1c1.1-1.2,2.8-1.3,4-0.2c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.2,0.2,0.4,0.2,0.6
        c0,0,0,0.1,0,0.1c0.4,1.6-0.5,3.1-2.1,3.5c-1.5,0.4-3.2-0.6-3.5-2.2c-0.1-0.5-0.1-0.9,0.1-1.4c-0.3-0.2-0.7-0.4-1-0.5c0,0,0,0,0,0
        c0,0,0,0,0,0.1c-0.3,0.9-0.3,1.8,0.1,2.7c0.2,0.5,0.1,0.7-0.4,0.9c-1.6,0.7-3.1,1.4-4.7,2C11,17.4,11,17.5,11,17.7
        c0,1.4-0.9,2.7-2.1,3.2C7.5,21.4,6,21.1,5,20.1C4.8,19.9,4.6,19.6,4.5,19.4z M15,14.5C15,14.4,15,14.4,15,14.5c0-0.1,0-0.2-0.1-0.3
        c-0.2-0.8-0.2-1.6,0-2.5c0.4-1.7,1.4-2.8,3-3.5c0.4-0.2,0.5-0.5,0.3-0.8c-0.5-1.1-1.3-1.8-2.4-2.2C15.5,5,15.1,5,14.7,5
        c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.3,0,0.6-0.3,0.8c-0.1,0-0.1,0-0.2,0.1c-0.6,0.3-1,0.7-1.2,1.4c-0.2,0.9-0.4,1.8-0.5,2.7
        c-0.1,0.3-0.2,0.5-0.5,0.6c-0.6,0.3-1,0.8-1.3,1.4c0,0.1,0,0.2,0.1,0.3c0.4,0.4,0.8,0.8,1.1,1.3c0.1,0.2,0.3,0.5,0.4,0.7
        C13.5,15.1,14.2,14.8,15,14.5C15,14.5,15,14.5,15,14.5z M9.6,16.8c-0.4-1.1-1.7-1.7-2.8-1.3c-1.2,0.4-1.8,1.6-1.5,2.9
        C5.6,19.5,6.8,20.2,8,20c1-0.2,1.9-1.2,1.8-2c-0.4,0.2-0.8,0.3-1.2,0.5c-0.9,0.4-1.8,0.1-2.3-0.8c-0.1-0.3-0.1-0.5,0.1-0.7
        c0.3-0.3,0.7-0.2,0.9,0.2c0.2,0.3,0.4,0.4,0.7,0.3C8.5,17.3,9.1,17,9.6,16.8z M12,10.8C11.9,10.7,11.9,10.7,12,10.8
        c-0.1,0-0.1,0-0.1,0c-1.2,0.3-2.5,0.4-3.7,0.2c-0.6-0.1-1.1-0.2-1.7-0.2c-0.1,0-0.2,0-0.3,0C5,11.2,4,11.7,2.9,12.1
        c0,0-0.1,0-0.2,0.1c0.4,0.4,0.8,0.5,1.2,0.6c0.4,0,0.8-0.2,1.2-0.3c1.4-0.6,2.9-0.6,4.4-0.1c0.2,0.1,0.5,0.2,0.7,0.3
        C10.6,11.8,11.2,11.2,12,10.8z M8.5,9.8C8.5,9.8,8.5,9.8,8.5,9.8c1.3,0.2,2.5,0,3.6-0.4c0,0,0.1-0.1,0.1-0.1c0.1-0.5,0.2-1,0.3-1.5
        C11,7,8.8,8,8.5,9.8z M18.3,12.7c-0.2,0.7,0.3,1.6,1.1,1.9c0.9,0.3,1.8-0.1,2.2-0.9c0.4-0.7,0.1-1.7-0.6-2.2
        c-0.7-0.4-1.6-0.3-2.1,0.2c0,0,0.1,0,0.1,0.1c0.4,0.2,0.8,0.4,1.3,0.7c0.3,0.2,0.4,0.5,0.3,0.8c-0.2,0.3-0.5,0.4-0.8,0.2
        c-0.2-0.1-0.3-0.2-0.5-0.3C18.9,13,18.6,12.9,18.3,12.7z"/>
        </svg>
    )
}

export const Salah=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
        
        <path d="M8.4,7.6c-0.5-0.5-1-1.1-1.3-1.7c-0.5-1-0.7-2.1-0.4-3.2c0.4-1.6,2-2.7,3.6-2.6c1.7,0.1,3.1,1.3,3.3,3
        c0.2,1.5-0.3,2.7-1.2,3.9c-0.1,0.2-0.3,0.3-0.5,0.5c1.4,0.7,2.1,1.8,2.3,3.4c0.1,0,0.1,0,0.2,0c0.8-0.3,1.6-0.5,2.4-0.8
        c0.2-0.1,0.5-0.2,0.7-0.4c0.4-0.3,0.8-0.6,1.1-0.9c0.4-0.3,0.9-0.4,1.3-0.1c0.4,0.2,0.6,0.7,0.5,1.2c-0.1,0.3-0.1,0.7-0.2,1
        c-0.1,0.7-0.5,1.2-1,1.6c-1.3,0.9-2.7,1.9-4,2.8C15,15.3,15,15.4,15,15.6c0.1,0.7,0.4,1.1,1,1.4c1.3,0.7,2.6,1.4,3.9,2.1
        c1.5,0.8,1.8,2.7,0.6,4C20,23.8,19.3,24,18.5,24c-4.7,0-9.5,0-14.2,0c-0.1,0-0.3,0-0.4,0c-1-0.2-1.5-1.3-0.8-2.1
        c0.5-0.6,1.1-1.1,1.7-1.6c0.2-0.2,0.4-0.2,0.7-0.4c0.1,0,0.1-0.1,0.2-0.2c0.2-2.4,0.4-4.8,0.6-7.2c0.1-0.7,0.1-1.3,0.2-1.9
        c0.2-1.2,0.8-2.1,1.8-2.8C8.3,7.7,8.3,7.7,8.4,7.6C8.4,7.6,8.4,7.6,8.4,7.6z M14,16c-0.1,0-0.2,0.1-0.2,0.1
        c-1.4,0.5-2.7,0.2-3.8-0.8c-1.2-1-1.3-2.4-1.2-3.9c0-0.3,0.3-0.4,0.6-0.4c0.3,0,0.5,0.2,0.5,0.5c0,0.2,0,0.3,0,0.5
        c0,0.4,0,0.9,0.1,1.3c0.5,1.7,2.5,2.5,4.1,1.5c1.1-0.8,2.3-1.6,3.4-2.3c0,0,0.1-0.1,0.1-0.1c-0.2-0.3-0.4-0.6-0.5-0.9
        c-0.1-0.2-0.2-0.2-0.4-0.1c-1.3,0.4-2.5,0.9-3.8,1.3c-0.4,0.1-0.7,0-0.8-0.3c-0.1-0.3,0.1-0.6,0.4-0.7c0.2-0.1,0.5-0.2,0.7-0.2
        c0-0.2-0.1-0.4-0.1-0.6c-0.2-1.4-1.4-2.4-2.8-2.4c-1.3,0-2.6,0.9-2.8,2.3c-0.1,0.7-0.2,1.5-0.2,2.3c-0.1,1.7-0.3,3.4-0.4,5
        c-0.1,0.6-0.1,1.2-0.2,1.8c0.1,0,0.2,0,0.3,0c1.1,0,2.2,0,3.3,0c0.6,0,1.2,0,1.8,0c0.4,0,0.6,0.2,0.6,0.5c0,0.3-0.2,0.5-0.6,0.5
        c-0.1,0-0.1,0-0.2,0c-1.4,0-2.9,0-4.3,0c-0.1,0-0.2,0-0.3,0c0,0.7,0,1.4,0,2.2c0.1,0,0.1,0,0.1,0c3.8,0,7.5,0,11.3,0
        c0.1,0,0.3,0,0.4,0c0.6-0.1,1.1-0.6,1.2-1.3c0.1-0.6-0.2-1.2-0.8-1.5c-1.3-0.7-2.6-1.4-3.9-2.1c-0.6-0.4-1.1-0.9-1.3-1.6
        C14.1,16.2,14,16.1,14,16z M12.6,3.9c-1.7,0-3.3,0-4.9,0c0.2,1.1,0.6,2.1,1.5,2.9c0.6,0.5,1.2,0.5,1.8,0c0.6-0.5,1-1.2,1.3-1.9
        C12.4,4.6,12.5,4.3,12.6,3.9z M12.4,2.8c-0.3-1-1.3-1.7-2.4-1.6C9,1.3,8,2,7.9,2.8C9.4,2.8,10.8,2.8,12.4,2.8z M6,22.9
        c0-0.7,0-1.4,0-2.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.5-1,1-1.5,1.5c-0.1,0.1-0.1,0.4,0.1,0.4C4.7,22.9,5.4,22.9,6,22.9z M19.4,9.6
        c-0.5,0.4-1,0.8-1.5,1.2c0.2,0.3,0.4,0.6,0.5,0.9C19.3,11.3,19.2,10.4,19.4,9.6z"/>
        </svg>

    )
}

export const Pin=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
        <path d="M16.3,0.9c0.1,0.1,0.3,0.2,0.4,0.3c2,2,4,4,6,5.9c0.4,0.4,0.4,0.7-0.1,1.1c-0.6,0.5-1.3,0.6-2.1,0.3c-0.1,0-0.2,0-0.3,0.1
	c-1.8,2.1-3.6,4.2-5.4,6.3c0,0,0,0-0.1,0.1c0.3,0.4,0.5,0.8,0.6,1.3c0.2,1-0.1,1.8-0.8,2.5c-0.3,0.3-0.6,0.2-0.9,0
	c-1.1-1.1-2.2-2.2-3.3-3.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.1-0.2,0.2c-1.8,1.8-3.6,3.7-5.5,5.3c-0.9,0.8-1.8,1.5-2.7,2.3
	c-0.3,0.2-0.5,0.2-0.7,0.1c-0.2-0.1-0.3-0.4-0.2-0.6c0-0.1,0.1-0.2,0.2-0.3c1.6-2,3.3-4,5.2-5.8c0.8-0.8,1.6-1.6,2.4-2.4
	c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1c-0.1,0-0.1-0.1-0.2-0.1c-1.1-1.1-2.3-2.3-3.4-3.4C5,9.7,5,9.4,5.4,9.1
	c1-0.9,2.4-1,3.5-0.1C8.9,9,8.9,9,9,9c2.2-1.8,4.3-3.7,6.4-5.5c-0.1-0.3-0.2-0.5-0.2-0.7c-0.1-0.7,0.1-1.2,0.6-1.7
	C15.9,1,16,1,16.1,0.9C16.2,0.9,16.3,0.9,16.3,0.9z"/>
     </svg>
    )
}

export const Food=({iconClass})=>{
    return(

    <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
        <path d="M5.2,22.9c-0.4-0.1-0.7-0.1-1-0.4c-0.8-0.6-1-1.8-0.2-2.6c3.5-3.6,6.9-7.1,10.4-10.7c0.5-0.5,0.7-1.1,0.7-1.9
		c0-0.5-0.1-0.9,0-1.4c0-0.8,0.4-1.4,0.9-1.9c0.7-0.6,1.5-1.3,2.3-1.9c1-0.7,2.3-1,3.5-0.9c0.6,0,1.1,0.5,1.2,1.2
		c0.2,1.6-0.1,3-1,4.4c-0.4,0.6-0.8,1.1-1.2,1.7c-0.5,0.7-1.3,1.1-2.2,1.2c-0.4,0-0.9,0.1-1.3,0.1c-0.6,0.1-1,0.3-1.4,0.8
		c-1.7,2.1-3.4,4.2-5.1,6.3c-1.4,1.8-2.9,3.6-4.3,5.4c-0.3,0.3-0.6,0.6-1.1,0.6c0,0-0.1,0-0.1,0C5.3,22.9,5.2,22.9,5.2,22.9z
		 M22.3,3.3c0-0.2,0-0.4-0.1-0.6C22.2,2.2,22,2,21.6,2c-1.1,0-2.2,0.2-3.1,0.8c-0.8,0.6-1.5,1.2-2.2,1.9c-0.4,0.4-0.6,0.9-0.6,1.5
		c0,0.4,0,0.9,0.1,1.3c0.1,0.9-0.2,1.6-0.8,2.2c-3.5,3.6-6.9,7.1-10.4,10.7c-0.5,0.5-0.3,1.4,0.2,1.7c0.5,0.3,1.1,0.2,1.5-0.3
		c3.1-3.9,6.3-7.8,9.4-11.6c0.4-0.4,0.8-0.8,1.3-0.9c0.5-0.1,1.1-0.1,1.6-0.2c0.9,0,1.5-0.4,2-1.1C20.7,7.5,21,7,21.4,6.6
		C22,5.6,22.4,4.5,22.3,3.3z"/>
	<path d="M1,4.6c0-0.1,0.1-0.1,0.1-0.2C1.3,4,1.8,3.8,2.2,4.1c0.1,0.1,0.1,0.1,0.2,0.2c1.1,1.1,2.1,2.1,3.2,3.2
		c0.2,0.2,0.3,0.2,0.6-0.1C6.1,7.4,6.1,7.3,6,7.3C4.9,6.1,3.7,5,2.6,3.8c0,0-0.1-0.1-0.1-0.1C2.3,3.5,2.2,3.3,2.4,3.2
		C2.5,3,2.7,3,2.9,3.2c1.2,1.2,2.4,2.4,3.6,3.6C6.5,6.8,6.6,6.9,6.7,7c0.1-0.1,0.1-0.2,0.2-0.3c0,0-0.1-0.1-0.1-0.1
		C5.6,5.4,4.4,4.2,3.2,3C3.2,3,3.1,2.9,3.1,2.9C3,2.7,3,2.6,3.1,2.4c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.1,0.1,0.2,0.2
		C4.9,3.7,6,4.9,7.2,6.1c0.1,0.1,0.1,0.1,0.1,0.2C7.7,6,7.7,5.9,7.4,5.7C6.4,4.6,5.3,3.5,4.2,2.5C4,2.3,3.9,2.1,3.9,1.8
		c0-0.3,0.1-0.5,0.4-0.7C4.6,1,4.8,1,5.1,1.2c0.1,0,0.1,0.1,0.2,0.1c1.4,1.2,2.8,2.4,4.2,3.5c0.7,0.6,1,1.3,1,2.2c0,0.4,0,0.8,0,1.2
		c0,0.6,0.2,1.1,0.7,1.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.2,0.4,0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0c-0.4-0.3-0.8-0.6-1-1.1
		C9.9,9.2,9.8,8.7,9.8,8.3c0-0.5,0-0.9,0-1.4c0-0.7-0.3-1.2-0.8-1.6C7.6,4.2,6.3,3,4.9,1.9c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0
		c0,0.1,0,0.1,0,0.2C4.6,2,4.7,2,4.7,2.1c1,1,2.1,2.1,3.1,3.1c0.5,0.5,0.5,1,0,1.5C7.5,7.1,7,7.5,6.6,8C6.1,8.4,5.6,8.4,5.2,8
		c-1-1-2.1-2.1-3.1-3.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0.1,0,0.2,0,0.2c0.5,0.6,1,1.2,1.5,1.8C4,7.5,4.6,8.3,5.2,9
		c0.5,0.6,1.1,0.8,1.8,0.9c0.5,0,1,0,1.5,0c0.6,0,1.1,0.3,1.5,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.2,0.4,0,0.5
		c-0.1,0.1-0.3,0.1-0.5-0.1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.4-0.5-1-0.7-1.6-0.7c-0.5,0-1.1,0-1.6-0.1c-0.7-0.1-1.2-0.4-1.7-1
		C3.7,8.2,2.5,6.8,1.4,5.5C1.2,5.3,1.1,5.1,1,4.9C1,4.8,1,4.7,1,4.6z"/>
	<path d="M21,22.9c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.3-0.8-0.5c-2.1-2.3-4.1-4.5-6.2-6.8c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.1-0.1-0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.1,0.1,0.2,0.2c2.1,2.3,4.1,4.5,6.2,6.8c0.4,0.5,1.1,0.4,1.5,0.1
		c0.5-0.3,0.6-1.2,0.1-1.7c-2.3-2.1-4.6-4.2-6.9-6.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4-0.1
		c0.1,0,0.1,0.1,0.2,0.1c2.3,2.1,4.6,4.2,7,6.3c0.6,0.5,0.7,1.2,0.5,1.9c-0.2,0.7-0.8,1.1-1.5,1.2c-0.1,0-0.1,0-0.2,0
		C21.1,22.9,21,22.9,21,22.9z"/>

    </svg>

    )
}

export const InCall=({iconClass})=>{
return(
    <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
</svg>
)

}

export const Refresh=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
</svg>
    )
}

export const Mike=({iconClass})=>{
    return(
        
<svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke='none'>
      <path d="M12.2,22c-0.6-0.1-0.9-0.5-1.3-0.9c-0.8-0.8-1.6-1.6-2.4-2.4c0,0-0.1-0.1-0.2-0.2C8.1,18.7,8,18.8,7.9,19
	c-0.7,0.7-1.8,0.7-2.5,0c-0.5-0.5-1.1-1.1-1.6-1.6c-0.7-0.8-0.7-1.8,0-2.6c1.1-1.1,2.1-2.1,3.2-3.2c1.6-1.7,2.7-3.7,3.1-6
	c0-0.1,0-0.2-0.1-0.3c-0.6-1.2,0.3-2.6,1.6-2.6c0.5,0,0.9,0.2,1.2,0.5c2.2,2.2,4.4,4.4,6.6,6.6c0.5,0.5,0.7,1.2,0.4,1.9
	c-0.3,0.7-0.8,1.1-1.5,1.1c-0.3,0-0.7,0-1-0.2c-0.1,0-0.2-0.1-0.3-0.1c-1.6,0.3-3.1,1-4.4,1.9c0,0-0.1,0-0.1,0.1
	c0.1,0.1,0.1,0.1,0.2,0.2c0.8,0.8,0.8,1.8,0,2.6c-0.1,0.1-0.2,0.2-0.4,0.4c0.4,0.4,0.9,0.8,1.3,1.3c0.8,0.9,0.5,2.3-0.6,2.8
	C13,21.9,12.8,22,12.7,22C12.5,22,12.3,22,12.2,22z M8.3,11.9c0.9,0.9,1.7,1.7,2.5,2.5c1.6-1.3,3.4-2.3,5.4-2.8
	c-1.7-1.7-3.4-3.4-5.1-5.1C10.6,8.5,9.6,10.3,8.3,11.9z M7.4,12.8c0,0-0.1,0.1-0.1,0.1c-0.9,0.9-1.8,1.8-2.7,2.7
	c-0.3,0.3-0.3,0.6,0,1c0.5,0.5,1,1,1.5,1.5c0.4,0.4,0.7,0.4,1,0c0.9-0.9,1.8-1.8,2.7-2.7c0,0,0.1-0.1,0.1-0.1
	C9.1,14.5,8.3,13.6,7.4,12.8z M18.8,11.2c0-0.3-0.1-0.4-0.2-0.6c-2.2-2.2-4.3-4.3-6.5-6.5c0,0-0.1-0.1-0.1-0.1
	c-0.2-0.1-0.4-0.2-0.6-0.1C11.1,4.1,11,4.2,11,4.5c0,0.2,0.1,0.4,0.2,0.5c2.2,2.2,4.3,4.3,6.5,6.5c0,0,0.1,0.1,0.1,0.1
	c0.2,0.1,0.4,0.2,0.6,0.1C18.7,11.5,18.8,11.4,18.8,11.2z M9.1,17.8c1,1,1.9,1.9,2.9,2.9c0.2,0.2,0.3,0.2,0.6,0.2
	c0.5-0.1,0.6-0.7,0.2-1c-0.9-0.9-1.8-1.8-2.8-2.8c0,0-0.1,0-0.1-0.1C9.7,17.2,9.4,17.5,9.1,17.8z M11.6,16.9c0.9-0.7,0.7-1.1,0-1.6
	c-0.3,0.3-0.5,0.5-0.8,0.8C11.1,16.4,11.3,16.6,11.6,16.9z"/>
<path d="M19.7,3.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4,0.4-0.7,0.8-1.1,1.1c-0.3,0.3-0.6,0.3-0.9,0c-0.2-0.2-0.2-0.6,0-0.9
	c0.4-0.4,0.8-0.8,1.1-1.1c0.2-0.2,0.4-0.2,0.7-0.1C19.5,3.3,19.6,3.5,19.7,3.8z"/>
<path d="M19.6,7.9c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.4,0,0.8,0,1.2,0c0.3,0,0.6,0.3,0.6,0.6
	c0,0.3-0.3,0.6-0.6,0.6C20,7.9,19.8,7.9,19.6,7.9z"/>
<path d="M16.1,3.2c0,0.2,0,0.4,0,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.4,0-0.8,0-1.2C15,2.2,15.2,2,15.5,2
	c0.3,0,0.6,0.3,0.6,0.6C16.1,2.8,16.1,3,16.1,3.2z"/>
    </svg>

    )
}

export const AddImage=({iconClass})=>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" className={iconClass} fill="currentColor" viewBox="0 0 24 24" stroke='none'>
<path d="M0,10.3c0-2.5,0-5,0-7.5C0,1.1,1.1,0,2.7,0c5,0,10.1,0,15.1,0c1.6,0,2.7,1.1,2.7,2.7c0,3.3,0,6.7,0,10
	c0,0.7-0.6,1.1-1.1,0.9c-0.3-0.1-0.6-0.4-0.6-0.8c0-0.2,0-0.5,0-0.7c0-3.1,0-6.3,0-9.4c0-0.7-0.3-1-1-1c-5,0-10,0-15.1,0
	c-0.7,0-1,0.3-1,1c0,5,0,10.1,0,15.1c0,0.7,0.3,1,1,1c3.3,0,6.7,0,10,0c0.7,0,1.1,0.6,0.9,1.1c-0.1,0.3-0.4,0.5-0.7,0.6
	c-0.1,0-0.2,0-0.2,0c-3.3,0-6.6,0-9.9,0c-1.7,0-2.7-1.1-2.7-2.7C0,15.3,0,12.8,0,10.3z"/>
<path d="M17.1,17.1c-4.6,0-9.1,0-13.7,0c0-0.1,0-0.2,0-0.2c0-1.7,0-3.3,0-5c0-0.1,0.1-0.3,0.2-0.4c0.3-0.3,0.6-0.6,0.9-0.9
	c0.4-0.4,0.9-0.4,1.3,0c0.6,0.6,1.1,1.1,1.7,1.7c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2c1.4-1.4,2.8-2.8,4.2-4.2
	c0.5-0.5,0.9-0.5,1.4,0c1.1,1.1,2.3,2.3,3.4,3.4c0.1,0.1,0.1,0.2,0.1,0.3c0,1.7,0,3.5,0,5.2C17.1,17,17.1,17.1,17.1,17.1z"/>
<path d="M18.8,18.8c0-0.8,0-1.6,0-2.4c0-0.1,0-0.2,0-0.3c0.1-0.4,0.5-0.7,0.9-0.7c0.4,0,0.8,0.4,0.8,0.8c0,0.8,0,1.5,0,2.3
	c0,0.1,0,0.2,0,0.3c0.3,0,0.5,0,0.8,0c0.6,0,1.2,0,1.8,0c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.8-0.9,0.9c-0.8,0-1.7,0-2.5,0
	c0,0.1,0,0.2,0,0.3c0,0.7,0,1.5,0,2.2c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.8,0-1.7,0-2.5c-0.1,0-0.2,0-0.3,0
	c-0.7,0-1.5,0-2.2,0c-0.5,0-0.9-0.4-0.9-0.8c0-0.5,0.4-0.9,0.9-0.9C17.2,18.8,18,18.8,18.8,18.8z"/>
<path d="M7.7,7.7c-1.2,0-2.1-1-2.1-2.2c0-1.2,1-2.1,2.2-2.1c1.2,0,2.1,1,2.1,2.1C9.9,6.8,8.9,7.7,7.7,7.7z"/>
    </svg>

    )
}

export const ReplyIcon=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 24 24" fill="currentColor">
        <path d="M24,15.9c-0.1,0.5-0.2,1-0.3,1.5c-0.4,1.6-1,3.2-1.7,4.7c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.3,0.2-0.5,0.2
	c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0-0.3c0.1-1.5,0.2-3-0.1-4.4c-0.3-1.5-1-2.8-2.3-3.7c-0.9-0.6-1.8-0.9-2.8-1.1
	c-1.4-0.3-2.8-0.3-4.3-0.3c-0.9,0-1.7,0-2.6,0c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c0,1,0,2.1,0,3.1c0,0.6-0.5,1-1,0.9
	c-0.2,0-0.3-0.1-0.5-0.3c-2.3-2.3-4.5-4.5-6.8-6.8c-0.4-0.4-0.4-0.9,0-1.2c2.3-2.3,4.5-4.5,6.8-6.8C7.4,1.3,7.7,1.2,8,1.4
	c0.4,0.1,0.5,0.4,0.5,0.8c0,1,0,2,0,3.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c1.2,0,2.3,0,3.5,0c1.8,0,3.5,0.2,5.2,0.7
	c1.4,0.4,2.7,1,3.9,2c1.2,1.1,1.9,2.4,2.3,3.9c0.2,0.7,0.2,1.5,0.3,2.2c0,0.1,0,0.2,0,0.3C24,15.1,24,15.5,24,15.9z"/>
        </svg>
    )
}

export const VopIcon=({iconClass})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={iconClass} viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
    )
}