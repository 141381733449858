import React from 'react'

const Avatar = ({value, width, height, color, small}) => {
    const name=value.split(' ').map(word => word[0]).join('');
  return (
    <div className={`cursor-pointer font-bold  ${small? `text-[6px] leading-[7px]`: `text-xs`}  ${color? color :` bg-slate-100 dark:bg-slate-700`} transition-all duration-150 ease-in-out uppercase flex text-center text-slate-600  dark:text-slate-300  rounded-full w-${width} h-${height}`}>
        <div className='m-auto'>
        {name}
        </div>
    </div>
  )
}

export default Avatar