import React, { useState, useRef,useCallback } from 'react'
import Moment from 'react-moment'
import { avatarStorage_LINK, MSGIMG_STORAGE_LINK } from '../../api/ApiUrl'
import { ReadMsg } from '../../config/firebaseConfig'
import Avatar from '../layout/Avatar'
import parse from 'html-react-parser'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { ChevronLeft, ChevronRight, Delete, Food, InCall, LoginIcon, LogoutIcon, LongBreak, ReplyIcon, Salah, ShortBreak, Washroom} from '../layout/Icons'
import ReplymsgLink from './ReplymsgLink'
const MessageBar = ({msg, uid, nextUid, user, prevType, setReplyMsg, index, setShowReply, setReplyMsgId, setDeleteMsg}) => {

  const [lightBoxImg,setLightBoxImg]=useState([]);
  const [photoIndex, setphotoIndex]=useState(0);

  const setLightbox=(imgs, index)=>{
    var data=imgs.map((img)=>`${MSGIMG_STORAGE_LINK}${img}`)
    setLightBoxImg(data);
    setphotoIndex(index);
  }
//   const setActivityColor=(id)=>{
//     if(id==0){
//       return 'text-green-600 dark:text-green-500 bg-green-200 dark:bg-green-700/50'
//     }else if(id==1){
//       return 'text-indigo-800 dark:text-indigo-500 bg-indigo-200 dark:bg-indigo-900/40'
//     }else if(id==2){
//       return 'text-orange-500 dark:text-orange-500 bg-orange-200 dark:bg-orange-800/50'
//     }else if(id==3){
//       return 'text-red-600 dark:text-red-500 bg-red-200 dark:bg-red-800/60'
//     }else if(id==4){
//       return 'text-cyan-600 dark:text-cyan-500 bg-cyan-200 dark:bg-cyan-800/50'
//     }
// }

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank" className="underline" >' + url + '</a>';
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

const observer=useRef();

const readingMsg=useCallback(node=>{
  var readData=msg.read;
  if(msg.msgType!=1 && readData.filter(r=>r==user.id).length>0){
    return
  }

  if(observer.current){
    observer.current.disconnect()
  }

  observer.current=new IntersectionObserver(entries=>{
    if(entries[0].isIntersecting){
      if(msg.msgType!=1){
        const read=msg.read
        if(!read.filter(r=>r==user.id).length>0){
          const timer=setTimeout(()=>{
            var data=[...read, user.id];
            ReadMsg(msg.roomId, data, msg.id);
          },1000);

          return () => clearTimeout(timer);
          
        }
      }
    }
  })

  if(node){
    observer.current.observe(node)
  }

},[])

  // useEffect(()=>{
  //   if(msg.msgType!=1){
  //     const read=msg.read
  //     if(!read.filter(r=>r==user.id).length>0){
  //       var data=[...read, user.id];
  //       ReadMsg(msg.roomId, data, msg.id);
  //     }
  //   }
      
  // },[])
  return (
    <div ref={readingMsg}>
    {msg.msgType===0 &&
    <>

    {msg.uid!==nextUid && msg.uid!==uid?
     <p className={`msg-profile-name ml-8`}>{msg.uName}</p>
     :
     null}

     {msg.replyMsg!=null &&
     <ReplymsgLink msg={msg.replyMsg}  uid={uid} isSender={msg.uid===uid}/>
     }
    <div className={`${msg.uid===uid? `sender-wrapper` : `reciever-wrapper`} group ${msg.replyMsg!=null? `-mt-2` : ``}`}>
     {(msg.uid!==nextUid || prevType!==msg.msgType) && msg.uid!==uid ?
     <div className='msg-profile-wrapper'>
        {msg.photoUrl!==null?
        <img src={`${avatarStorage_LINK}/${msg.photoUrl}`}  className='msg-profile-img' alt='profile'/>
        :
        <Avatar value={msg.uName} width={'7'} height={'7'} color={'bg-blue-200 dark:bg-blue-800'} />
      }
      </div>
       :
      <>
      {msg.uid!==uid&&
      <div className='bg-transparent w-7 h-7'></div> 
      }
      </>
      } 
      
      <div className={`${msg.uid===uid? `sender` : `reciever`}`}>
       
        <p className={`msgText ${msg.read.filter(r=>r==user.id).length==0? `bg-slate-600 dark:bg-slate-300 dark:text-slate-700 text-white font-semibold` : `bg-slate-300 dark:bg-slate-700 text-slate-600 dark:text-slate-300`}`}>
          {parse(urlify(msg.msg))}
        </p>
        <button className='hidden group-hover:block msgOp-btn hover:bg-slate-400 hover:text-slate-50' onClick={()=>{setReplyMsg(index); setReplyMsgId(msg); setShowReply(true)}}><ReplyIcon iconClass={'w-4 h-4'}/></button>
        {msg.uid===uid&& 
        <button className='hidden group-hover:block msgOp-btn hover:bg-red-500 hover:text-slate-50' onClick={()=>{setDeleteMsg(msg.id)}}><Delete iconClass={'w-4 h-4'}/></button>
        }

        {msg.createdAt !=null &&
          <Moment className='hidden p-1 text-[10px] italic rounded-md group-hover:block text-slate-500 dark:text-slate-500' date={msg.createdAt.toDate()} format={'hh:mmA'}/>
        }
      </div>

      </div>
  </>
  }
  {msg.msgType===1 &&
    <p className='text-xs italic text-center text-slate-500 dark:text-slate-400'>{msg.msg}</p>
  }

  {msg.msgType===2 &&
  
      <div className={`flex items-center gap-1 my-1 px-1 rounded-md text-sm font-semibold italic max-w-max mx-auto text-center text-slate-500  dark:text-slate-400`}>
        {msg.photoUrl!==null?
        <img src={`${avatarStorage_LINK}/${msg.photoUrl}`}  className='msg-profile-type-img' alt='profile'/>
        :
        <Avatar value={msg.uName} width={'6'} height={'6'} color={'bg-blue-200 dark:bg-blue-800'}/>  
      }
        {msg.completeType?
        <p className='font-bold text-lime-600'><ChevronLeft iconClass={'w-5 h-5'}/></p>
        :
        <p className='font-bold text-red-500'><ChevronRight iconClass={'w-5 h-5'}/></p>
        }
        <LoadActivityIcon id={msg.typeId}/>
        
        {msg.msg}

        </div>
      
    }

    {msg.msgType==4 &&
    <div className='flex flex-col items-center'>
    {msg.photoUrl!==null?
        <img src={`${avatarStorage_LINK}/${msg.photoUrl}`}  className='msg-profile-type-img' alt='profile'/>
        :
        <Avatar value={msg.uName} width={'6'} height={'6'} color={'bg-blue-200 dark:bg-blue-800'}/>  
    }
      <div className='announcement-bg'>
      {msg.msg}
      </div>
    </div>
    }

    {msg.msgType==5 &&
    <div className='group'>
    <div className={`${msg.uid===uid? `senderImg-wrapper` : `recieverImg-wrapper`}`}>
    {(msg.uid!==nextUid || prevType!==msg.msgType) && msg.uid!==uid ?
     <div className='msg-profile-wrapper'>
        {msg.photoUrl!==null?
        <img src={`${avatarStorage_LINK}/${msg.photoUrl}`}  className='msg-profile-img' alt='profile'/>
        :
        <Avatar value={msg.uName} width={'7'} height={'7'} color={'bg-blue-200 dark:bg-blue-800'} />
      }
      </div>
       :
      <>
      {msg.uid!==uid&&
      <div className='bg-transparent w-7 h-7'></div> 
      }
      </>
      } 
      <div className={`${msg.uid===uid? `sender-Img-bg` : `reciever-Img-bg`}`}>
      {msg.imgs.length>1?
      <div className={`flex flex-wrap items-center justify-start gap-1 max-w-[16rem]`}>
        {msg.imgs.map((img,index)=>(
          <img key={index} src={`${MSGIMG_STORAGE_LINK}${img}`} onClick={()=>{setLightbox(msg.imgs, index)}} className='object-cover w-16 h-16 rounded-md cursor-pointer min-h-max min-w-max' alt='msg-image'/>
        ))}
      </div>
      :
      <div>
        {msg.imgs.map((img,index)=>(
          <img key={index} src={`${MSGIMG_STORAGE_LINK}${img}`} onClick={()=>{setLightbox(msg.imgs, index)}} className='w-64 h-auto rounded-md cursor-pointer max-w-max max-h-max'  alt='msg-image'/>
        ))}
      </div>
      }
      {msg.msg.length>0 &&
       <p className='px-1'>{parse(urlify(msg.msg))}</p>
      }
    </div>
    </div>
    <div className={`flex items-center ${msg.uid===uid? `justify-end mr-7` : `justify-start ml-7` }`}>
      {msg.createdAt !=null &&
       <Moment className='hidden p-1 text-[10px] italic rounded-md group-hover:block text-slate-500 dark:text-slate-500' date={msg.createdAt.toDate()} format={'hh:mmA'}/>
      }
        <button className='hidden group-hover:block msgOp-btn hover:bg-slate-400 hover:text-slate-50' onClick={()=>{setReplyMsg(index); setReplyMsgId(msg); setShowReply(true)}}><ReplyIcon iconClass={'w-4 h-4'}/></button>
        {msg.uid===uid&& 
        <button className='hidden group-hover:block msgOp-btn hover:bg-red-500 hover:text-slate-50' onClick={()=>{setDeleteMsg(msg.id)}}><Delete iconClass={'w-4 h-4'}/></button>
        }
    </div>
    

   </div>
    }


    {lightBoxImg.length>0 &&
      <Lightbox 
      mainSrc={lightBoxImg[photoIndex]}
      nextSrc={lightBoxImg[(photoIndex + 1) % lightBoxImg.length]}
      prevSrc={lightBoxImg[(photoIndex + lightBoxImg.length - 1) % lightBoxImg.length]}
      onCloseRequest={()=>{setLightBoxImg([]); setphotoIndex(-1)}}
      onMovePrevRequest={()=>{setphotoIndex((photoIndex + lightBoxImg.length - 1) % lightBoxImg.length)}}
      onMoveNextRequest={()=>{setphotoIndex((photoIndex + 1) % lightBoxImg.length)}}
      />
    }
    </div>
    )
}

const LoadActivityIcon=({id})=>{
  return(
    <>
    {id==0 &&
    <div className='p-0.5 text-white bg-green-500 rounded-full w-6 h-6'>
      <LoginIcon iconClass={'w-5 h-5'}/>
    </div>
    }

    {id==1 &&
    <div className='p-0.5 text-white bg-indigo-800 rounded-full w-6 h-6'>
      <Washroom iconClass={'w-5 h-5'}/>
    </div>
    }

    {id==2 &&
    <div className='p-0.5 text-white bg-orange-500 rounded-full w-6 h-6'>
      <ShortBreak iconClass={'w-5 h-5'}/>
    </div>
    }

    {id==3 &&
    <div className='p-0.5 text-white bg-red-500 rounded-full w-6 h-6'>
      <LongBreak iconClass={'w-5 h-5'}/>
    </div>
    }


    {id==4 &&
    <div className='p-0.5  text-white bg-cyan-500 rounded-full w-6 h-6'>
      <Salah iconClass={'w-5 h-5'}/>
    </div>
    }

  {id==5 &&
    <div className='p-0.5 text-white bg-purple-500 rounded-full w-6 h-6'>
      <Food iconClass={'w-5 h-5'}/>
    </div>
    }

  {id==6 &&
    <div className='p-0.5  text-white bg-lime-500 rounded-full w-6 h-6'>
      <InCall iconClass={'w-5 h-5'}/>
    </div>
    }


    {id==-1 &&
    <div className='p-0.5 text-white bg-red-500 rounded-full w-6 h-6'>
      <LogoutIcon iconClass={'w-5 h-5'}/>
    </div>
    }

    </>

  )
}
export default MessageBar