import React,{useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import Logo from '../../Logo';
import axios from '../../api/axios';
import toast from 'react-hot-toast';

import { REQUESTFORGOTPASSWORD_URL } from '../../api/ApiUrl';

const ForgotPassword = () => {
const navigate = useNavigate();
const [email, setEmail]=useState('');
const [reqSent, setReqSent]=useState(false);

const sendRequest=async(e)=>{
    e.preventDefault();
    const load=toast.loading('please wait! sending you mail');

    await axios.get('/sanctum/csrf-cookie').then(Response=>{
        axios.post(REQUESTFORGOTPASSWORD_URL, {email:email}).then(res=>{
            if(res.data.error){
                toast.dismiss(load);
                toast.error(res.data.error);
            }else{
                toast.dismiss(load);
                toast.success(res.data.status)
                setReqSent(true);
            }
        }).catch((e)=>{
            if(!e?.response){
                toast.error('No Server Response!');
            }else{
                toast.error('Something went wrong!');
                console.log(e);
            }
        })
    })
}
  return (
    <div className='container h-screen mx-auto pt-[10em] lg:pt-[12em] xl:pt-[16em] place-items-center'>
        <Logo className="w-16 h-16 mx-auto" fill={"fill-slate-400 dark:fill-slate-600"}></Logo>
        <p className='my-2 font-semibold text-center text-slate-600 dark:text-slate-400'>escapetask®</p>
        <div className='w-3/4 mx-auto mt-10 md:w-2/3 lg:w-1/3 card'>
            {!reqSent?
            <form onSubmit={(e)=>sendRequest(e)}>
                
                <div className='flex flex-col items-center justify-between gap-2 md:flex-row'>
                <input 
                  type="email"
                  value={email} 
                  className='w-full md:w-3/5 input-field-fat' 
                  onChange={(e)=>setEmail(e.target.value)} 
                  placeholder='Email Address'
                  required/>
                    <button className='inline-flex items-center justify-center w-full gap-2 md:w-2/5 submit-btn'>Send Request</button>
                  </div>
            </form>

            :
        
             <p className='link' onClick={()=>{setReqSent(false)}}>Didn't get email? resend.</p>
            }
            
            <Link to="/login">

            <button className='inline-flex items-center justify-center w-full gap-2 submit-btn'>Back to Login</button>

            </Link>
            
        </div>
    </div>
  )
}

export default ForgotPassword