import { createSlice } from "@reduxjs/toolkit";

const initialStateValue=[];

export const categorySlice=createSlice({
    name:"category",
    initialState:{value:initialStateValue},
    reducers:{
        setCategory:(state,action)=>{
            state.value=action.payload
        },
    }
})

export const {setCategory}=categorySlice.actions
export default categorySlice.reducer;