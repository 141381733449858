import React,{useState, useEffect} from 'react'
import { CheckCircle } from './Icons'


const DoneTaskButton = ({jobStatuses, paymentOptions, status, payment, isPrice, doneTask}) => {
    const [done, setDone]=useState(false);

    useEffect(()=>{
        const checkDone=()=>{
            var checkStatus=jobStatuses.filter(j=>j.value===status)[0]
            var checkPayment=paymentOptions.filter(p=>p.value===payment)[0]
            if(isPrice){
                if(checkStatus!=null && checkPayment!=null){
                    if(checkStatus.mathFunc>0 && checkPayment.mathFunc>=3){
                        setDone(true);
                    }else{
                        setDone(false);
                    }
                   
                }
            }
            else{

                if(checkStatus!=null ){
                    if(checkStatus.mathFunc>0){
                        setDone(true);
                    }else{
                        setDone(false);
                    }
                }
            }
        }
        checkDone()

    },[jobStatuses, paymentOptions, status, payment])


  return done?(
        
        <button onClick={()=>doneTask()}>
        <span className="relative flex w-5 h-5">
            <span className="absolute inline-flex w-full h-full bg-green-400 rounded-full opacity-75 animate-ping"></span>
            <span className="done-btn"><CheckCircle iconClass={'w-full h-full'}/></span>
        </span>
        </button>
  ):
  null
}

export default DoneTaskButton