// Import the functions you need from the SDKs you need

import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from 'firebase/messaging'
import { getAuth, signOut,  createUserWithEmailAndPassword , signInWithEmailAndPassword, updatePassword   } from "firebase/auth";
import { getFirestore,  collection, addDoc, serverTimestamp, doc, setDoc, getDoc,updateDoc, deleteDoc } from "firebase/firestore";
import axios from "../api/axios";
import { DELETE_MSG_IMAGE } from "../api/ApiUrl";

//production config
const firebaseConfig = {
  apiKey: "AIzaSyBIL9BPIYvGCe0LTiWZXK9ud1pqL0-EDvY",
  authDomain: "ezescapetask.firebaseapp.com",
  projectId: "ezescapetask",
  storageBucket: "ezescapetask.appspot.com",
  messagingSenderId: "510732318488",
  appId: "1:510732318488:web:5cd85b4e08dedf3533506d",
  measurementId: "G-YW6H4X5WPL"
};


// const firebaseConfig = {
//   apiKey: "AIzaSyCWQeJkZurDRjVJsyDR55Qw1zkN2p9-Do4",
//   authDomain: "etv3-local.firebaseapp.com",
//   projectId: "etv3-local",
//   storageBucket: "etv3-local.appspot.com",
//   messagingSenderId: "655619661476",
//   appId: "1:655619661476:web:5fb4648c979d7638719031"
// };

// Initialize Firebase
const fbapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fbapp);
export const auth = getAuth(fbapp);

export const db = getFirestore(fbapp);

//const publicKey="BObEVueJ3iXDmo9syAEREWdgBfy0umPg3U9r43Z1dIIZdFALyNvu9YpBIjngQURm3ZQaq548HchO5i80rHamwUk"

//production key
const publicKey="BMjQPXe0Plg761Awzi52jVx73Q9HUbXX6dO-VwvkkJavkCfLYAV-AoKGghyy3MyVh_r_BUnCKFReEk82nealNB8"





export const getMyToken=async(setTokenFound)=>{
  let currentToken='';

  try{

    await getToken(messaging, { vapidKey:publicKey })
    .then((res) => {currentToken= res})
    .catch((err) => console.error(err));

    if(currentToken){
      setTokenFound(true);
      return currentToken
    }else{
      setTokenFound(false);
    }
  }catch(e){
    console.log('an erro while getting firebase token', e)
  }
}

export const OnMessageListener=()=>
  new Promise((resolve)=>{
    messaging.onMessage((payload)=>{
      resolve(payload);
    })
  })

 export const RegisterFireBaseUser=async(email, password, userId, userName) =>{

   createUserWithEmailAndPassword(auth, email, password)
   .then((userCredential) => {
    const user = userCredential.user;
      setDoc(doc(db, "UserState", `${userId}`),{
        email:email,
        status:1,
        uid:user.uid,
        updateCategory:false,
        updateComp:false,
        updateTask:false,
        idle:true,
        userName:userName,
        photoUrl:null,
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.warn(errorCode, errorMessage)
    });

  }

  export const firebaseUpdatePassword=(email, oldpassword, newPassword)=>{

    signInWithEmailAndPassword(auth, email, oldpassword)
    .then((userCredential) => {
      const user = userCredential.user;
      updatePassword(user, newPassword).then(() => {
        // Update successful.
      }).catch((error) => {
       console.log('an error occured while reseting password');
      });
    })

  }

    
  export const SignInFirebaseUser=async(email, password, userId)=>{

    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const uesrOffline = doc(db, "UserState", `${userId}`);
         updateDoc(uesrOffline, {
          status: 1,
          idle:true,
          updateCategory:false,
          updateComp:false,
          updateTask:false,
        });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.warn(errorCode, errorMessage)
    });

  }

  export const SignOutFirebaseuser=async(id)=>{
    const userOffline = doc(db, "UserState", `${id}`);
        await updateDoc(userOffline, {
          status: 0,
          idle:true,
        });
    signOut(auth).then(() => {
      
    }).catch((error) => {
     console.log('an error occurred while signning out', error)
    });
  }

  export const requireRefresh=async(id,updateTask, updateCategory,updateComp)=>{
    const uState=doc(db, "UserState", `${id}`);
    await updateDoc(uState, {
      updateCategory:updateCategory,
      updateComp:updateComp,
      updateTask:updateTask,
    });
  }

  export const isIdle=async(id, idle)=>{
    const uState=doc(db, "UserState", `${id}`);
    await updateDoc(uState, {
      idle:idle,
    });
  }


export const notify= async (title, body, onClickLink, receiverId, iconLink, iconType)=>{

  try {
    const {uid}=auth.currentUser;

    const userState = doc(db, "UserState", `${receiverId}`);
    const userSnap = await getDoc(userState);
    var isOnline=false;
    if (userSnap.exists()) {
      const uState=userSnap.data();
      if(uState.status==1){
        isOnline=true;
      }
    } 

    await addDoc(collection(db, "Notifications"), {
      title: title,
      body: body,
      onClickLink: onClickLink,
      receiverId:receiverId,
      uid:uid,
      createdAt:serverTimestamp(),
      delivered:!isOnline,
      read:false,
      iconType:iconType,
      icon:iconLink,
    });


  } catch (e) {
    console.error("Error adding notification: ", e);
  }

}

export const submitMsg=async(roomId,data)=>{
  try{
    const {uid}=auth.currentUser;
    await addDoc(collection(doc(db, 'Rooms', `${roomId}`), 'messages'),data)

  }
  catch(e){
    console.error("Error sending message: ", e);
  }
}

export const ReadMsg=async(roomId, read, docId)=>{
  try{
    const docRef=doc(collection(doc(db, 'Rooms', `${roomId}`), 'messages'), docId)
   
    await updateDoc(docRef, {
      read:read,
    })
  }
  catch(e){
    console.error("Error sending message: ", e);
  }
}

const createUserRoom=async(roomRef, ownerId)=>{
  const userRoomRef=doc(roomRef, "users", `${ownerId}`);
  await setDoc(userRoomRef,{
   activity:0,
   userLink:`/UserState/${ownerId}`
  })

}

const createMessageRoom=async(roomRef, roomId,uid,ownerId)=>{
  const messageRef=collection(roomRef, "messages");
  await addDoc(messageRef,{
    createdAt:serverTimestamp(),
    msg:"welcome to your company Chat",
    roomId:roomId,
    userLink:`/UserState/${ownerId}`,
    uid:uid,
    msgType:1,
  })

}

export const addRoom=async(roomId, name, ownerId)=>{
  try{
    const {uid}=auth.currentUser;
    const roomRef=doc(db, 'Rooms', `${roomId}`)
    await setDoc(roomRef,{
      name:name,
      ownerId:ownerId
    })

    createUserRoom(roomRef, ownerId)
    createMessageRoom(roomRef, roomId,uid,ownerId,)
    
  }
  catch(e){
    console.error("Error sending message: ", e);
  }
}

export const addUserToRoom=async(roomId, userId)=>{
  const roomRef=doc(db, 'Rooms', `${roomId}`);
  const userRoomRef=doc(roomRef, "users", `${userId}`);
  await setDoc(userRoomRef,{
   activity:-1,
   userLink:doc(db, 'UserState', `${userId}`)
  })

}

export const deleteRoom=async(roomId)=>{
  await deleteDoc(doc(db, "Rooms", `${roomId}`));
}

export const updateActivity=async(roomId, userId, activityId)=>{
  const roomRef=doc(db, 'Rooms', `${roomId}`);
  const userRoomRef=doc(roomRef, "users", `${userId}`);
  await updateDoc(userRoomRef,{
   activity:activityId,
  })
}

export const updateProfilePhoto=async(userId, photoUrl)=>{
  try{
    const userRef=doc(db, 'UserState', `${userId}`);
    await updateDoc(userRef,{
      photoUrl:photoUrl
    });
  
  }catch(e){
    console.error("Error changing photo: ", e);
  }

}

export const delMsg=async(msgId, roomId)=>{
  try{
    const docRef=doc(db, 'Rooms', `${roomId}`, 'messages', `${msgId}`)

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const msg=docSnap.data();

      if(msg.msgType==5){
        console.log(msg.imgs);
        await axios.post(DELETE_MSG_IMAGE, JSON.stringify({imageNames:msg.imgs})).then(
          await deleteDoc(docRef)
        );
      }else{
        await deleteDoc(docRef);
      }

    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
   
   
  }catch(e){
    console.error("Error deleteing message: ", e);
  }
}

export const addWatchList=async( userId, taskId)=>{
  try{
    const watchRef=doc(db, 'UserState', `${userId}`, 'watchlist', `${taskId}`);
    await setDoc(watchRef,{
      watch:true,
    });

  }catch(e){
    console.error("Error making watch: ", e);
  }
}


export const removeWatchList=async( userId, taskId)=>{
  try{
    const watchRef=doc(db, 'UserState', `${userId}`, 'watchlist', `${taskId}`);
    await deleteDoc(watchRef);

  }catch(e){
    console.error("Error making watch: ", e);
  }
}
