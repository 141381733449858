import React, {useState} from 'react'
import { RevertIcon } from './Icons'
const RevertTaskButton = ({revertTask}) => {
  const [isRevert, setIsRevert]=useState(false);
  return (
    <>
    <button onClick={()=>setIsRevert(!isRevert)}>
    <span className="relative flex w-5 h-5">
        <span className="revert-btn"><RevertIcon iconClass={'w-full h-full p-1'}/></span>
    </span>
    </button>
    {isRevert?
    <div className='fixed top-0 left-0 w-screen h-full bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
    <div className='flex flex-row items-center justify-center min-h-screen'>
      <div className='p-4 rounded-md shadow-xl bg-gradient-to-b from-slate-50 to-slate-200 dark:from-slate-700 dark:to-slate-900 shadow-slate-400 dark:shadow-slate-900'>
        <p className='font-semibold text-slate-600 dark:text-slate-400'>Are you sure you want to Revert this task?</p>
        <div className='flex items-center justify-end gap-2 mt-6'>
        <button onClick={()=>{revertTask()}} className='w-16 reject-btn'>Yes</button>
        <button onClick={()=>{setIsRevert(!isRevert)}} className='w-16 accept-btn'>No</button>
        </div>
  
      </div>
    </div>
  </div>
    :
    null}
    </>
  )
}

export default RevertTaskButton