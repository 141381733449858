import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { Tab } from '@headlessui/react';
import {CompanylogoStorage_LINK } from '../../api/ApiUrl';
import Avatar from '../layout/Avatar';
import { Add, Work, Group, EmojiHappy, Close} from '../layout/Icons';
import Company from './Company';
import Employee from './Employee';
import FindCompany from './FindCompany';
import CompanyReg from './CompanyReg';
import Customers from './Customers';

const CompanyTabs = ({activeComp}) => {

    const companies=useSelector((state)=>state.company.value)
    const [isReg,setIsReg]=useState(false);
    const user=useSelector((state)=>state.user.value)
    const [activeCompany, setActiveCompany]=useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0)
    useEffect(()=>{
      if(activeComp!=null){
        setActiveCompany(companies.filter(c=>c.id==activeComp)[0])
        setSelectedIndex(1)
      }
    },[])
  return (
      <>
    <div className='grid grid-cols-1 gap-3 mt-2 md:grid-cols-4 lg:grid-cols-6'>
    <div>
    <h4 className='my-2 ml-1 text-2xl font-bold text-slate-600 dark:text-slate-400'>Companies </h4>
    <div className='flex flex-row flex-wrap items-center justify-start md:items-start md:flex-col'>
    {companies.length>0?
    <>
        {companies.map((co)=>
        <button onClick={()=>{ setActiveCompany(co)}} key={co.id} className={`companyBtn ${activeCompany!=null && activeCompany.id===co.id? 'activeCompanyBtn': ''}`}>
        {co.logo?
        <img src={`${CompanylogoStorage_LINK}${co.logo}`} className='w-6 h-6 rounded-full' alt={'logo'}/>   
        :
        <Avatar value={co.name} width={'6'} height={'6'} color={'bg-slate-300 dark:bg-slate-700'}/>   
        }
        {co.name}
        </button>
        )
        }
    </>
    :
    null
    }
    <button className='companyBtn-add' onClick={()=>setIsReg(!isReg)}><Add iconClass={'w-6 h-6'}/></button>
    </div>
    </div>
   

    {activeCompany!=null?
     <div className='md:col-span-3 lg:col-span-5'>
     <div className='relative'>
     <Tab.Group vertical selectedIndex={selectedIndex} onChange={setSelectedIndex}>
       <div className='grid grid-cols-1 md:grid-cols-5'>
     <Tab.List className={'tabList'}>
     <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'}><Work iconClass={'w-4 h-4'}/><span className='hidden lg:block'> Company </span></Tab>
      <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'}><Group iconClass={'w-4 h-4'}/>  <span className='hidden lg:block'> Employees </span></Tab>
     <Tab className={({ selected })=> selected ? 'tabOptions tab-active' : 'tabOptions'}><EmojiHappy iconClass={'w-4 h-4'}/> <span className='hidden lg:block'> Customers </span></Tab>
     </Tab.List>
     <div className='md:col-span-4'>

     <Tab.Panels className={'tabPanel'}>

     <Tab.Panel>
     <Company key={activeCompany.id} company={activeCompany}  setActiveCompany={setActiveCompany} isReg={isReg} setIsReg={setIsReg}/>
     </Tab.Panel>

     <Tab.Panel>
      <Employee compId={activeCompany.id} activeCompany={activeCompany}/> 
     </Tab.Panel>
     <Tab.Panel>
      <Customers compId={activeCompany.id} />
     </Tab.Panel>
     </Tab.Panels>
     </div>
     </div>
     </Tab.Group>
     </div>
    </div>
    :
    null}
      </div>
    
      {isReg? <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
      <div className='flex flex-row items-center justify-center min-h-screen'>
        <div className='card'>
          <div className='flex items-center justify-end gap-1'>
          <button className='info-btn' onClick={()=>setIsReg(!isReg)}><Close iconClass={'w-4 h-4'}/></button>
          </div>
        {user.userType==0?
          <FindCompany  setIsreg={setIsReg}/>
          :
          <div>
          <CompanyReg  setIsreg={setIsReg}/>
          <p className='my-2 text-base font-semibold text-center text-slate-600 dark:text-slate-400'>or</p>
          <FindCompany  setIsreg={setIsReg}/>
        </div>
        }
        </div>

      </div>
    </div>

    :
    null
    }
    </>
  )
}

export default CompanyTabs