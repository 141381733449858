import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../api/axios";
import toast from 'react-hot-toast';
import Avatar from '../layout/Avatar';
import { Close, Pencil } from '../layout/Icons';

import { CompanylogoStorage_LINK,  UPDATECOMPANY_URL} from '../../api/ApiUrl';
import { setCompany } from '../../features/companies';

const CompanyEdit = ({company, setEditCo, setActiveCompany}) => {
const dispatch=useDispatch();
const user=useSelector((state)=>state.user.value)
const [logoUpload, setLogoUpload]=useState(null);
const [logoDisplay, setLogoDisplay]=useState(null);

const [name,setName]=useState(company.name);
const [address,setAddress]=useState(company.address);
const [postcode, setPostcode]=useState(company.postcode);
const [reg,setReg]=useState(company.regNo);
const [web,setWeb]=useState(company.website);

const UpdateCompany=async (e)=>{
    e.preventDefault();
    
    const toastReg2 = toast.loading('Updating...', {icon: '⏳'});
    try{
        const formData = new FormData();
        formData.append("userId", user.id);
        formData.append("companyId", company.id);
        formData.append("name", name);
        formData.append("address", address);
        formData.append("postcode", postcode);
        formData.append("regNo", reg);
        formData.append("website", web);
        
        if(logoUpload!=null){
        formData.append("logo", logoUpload);
        }
        
        else{
            formData.append("logo", ''); 
        }
   
        await axios.post(UPDATECOMPANY_URL,formData).then((res)=>{
            toast.success('Updated Company!');
            toast.dismiss(toastReg2);
            var com= res.data.companies.filter((co)=>co.id===company.id)[0];
            setActiveCompany(com)
            setName('');
            setAddress('');
            setPostcode('');
            setReg('');
            setWeb('');
            setLogoUpload(null);
            setLogoDisplay(null);
            setEditCo(false);
            dispatch(setCompany(res.data.companies))
        });
        
        }catch(e){
            if(!e?.response){
                toast.error("No Server Response!");
                toast.dismiss(toastReg2);
            }else{
                console.log(e);
            }
        }
}


const updatelogo=(e)=>{
    if(e.target.files && e.target.files[0]){
        setLogoUpload(e.target.files[0])
        setLogoDisplay( URL.createObjectURL(e.target.files[0]));
    }
}

  return (
    <div className='w-full card'>
    <div className='flex items-center justify-end mb-4'>
    <button className='reject-btn' onClick={()=>{setEditCo(null)}}><Close iconClass={'w-4 h-4'}/></button>
    </div>
    <form onSubmit={(e)=>{UpdateCompany(e)}}>
    <div className='flex items-center justify-between gap-2'>
   
    <h4 className='mb-4 text-xl font-bold text-slate-600 dark:text-slate-400'>Edit Company</h4>
   
    <div className='flex items-center justify-end gap-2'>
    <p className='text-sm text-slate-500'>Logo:</p>
    <div className='relative w-16 h-16 p-1 rounded-full bg-slate-300 dark:bg-slate-600 '>
        {logoDisplay?
            <img src={logoDisplay} className='absolute z-10 top-1 left-1 w-14 h-14 avatar' alt="logo"/>                  
        :
        <>
        {company.logo!=null?
         <img src={`${CompanylogoStorage_LINK}${company.logo}`} className='absolute z-10 top-1 left-1 w-14 h-14 avatar' alt="logo"/> 
        :
        <Avatar value={company.name} className='absolute z-10 top-1 left-1' width={'14'} height={'14'} />
        }
        </>
        }
        <input accept='image/jpg, image/png, image/jpeg, image/gif' type='file' onChange={(e)=>updatelogo(e)} className='hidden' id='logoUpdate'/>
            <label htmlFor='logoUpdate' className='absolute z-20 p-2 rounded-full opacity-0 cursor-pointer top-1 left-1 w-14 h-14 dark:bg-slate-600/70 bg-slate-50/70 text-slate-600 dark:text-slate-100 hover:opacity-100'>
            <Pencil iconClass={'w-6 h-6 min-h-full m-auto'} />
            </label>
        </div> 
    </div>
   
    </div>   
    <div className='grid items-end grid-cols-1 gap-2 md:grid-cols-2'>
       
        <div>
        <p className="text-sm text-slate-500">Company Name:</p>
        <input
        type='text'
        className='w-full input-field-fat'
        value={name}
        onChange={(e)=>setName(e.target.value)}
        required
        />
        </div>

        <div>
        <p className="text-sm text-slate-500">Address:</p>
        <input
        type='text'
        className='w-full input-field-fat'
        value={address}
        onChange={(e)=>setAddress(e.target.value)}
        required
        />
        </div>

        <div>
        <p className="text-sm text-slate-500">PostCode:</p>
        <input
        type='text'
        className='w-full input-field-fat'
        value={postcode}
        onChange={(e)=>setPostcode(e.target.value)}
        required
        />
        </div>

        <div>
        <p className="text-sm text-slate-500">Registration Number:</p>
        <input
        type='text'
        className='w-full input-field-fat'
        value={reg}
        onChange={(e)=>setReg(e.target.value)}
        required
        />
        </div>

        <div>
        <p className="text-sm text-slate-500">Website: (Optional)</p>
        <input
        type='text'
        className='w-full input-field-fat'
        value={`${web!=null? web : ''}`}
        onChange={(e)=>setWeb(e.target.value)}
        
        />
        </div>
        <button 
        className='submit-btn'>
            Update
        </button>
        </div>
        </form>
</div>
  )
}

export default CompanyEdit