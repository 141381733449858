import React from 'react'
import Logo from '../../Logo';
import {Link } from "react-router-dom";
import '../../rocket-styles.css'
import { ReactComponent as Rocket } from '../../rocket-not-css.svg'
const Welcome = () => {

  return (
    <div className='container mx-auto h-screen xl:mr-auto pt-[5em] xl:pt-[5em] place-items-center'>
      <div className='grid items-center grid-cols-1 lg:grid-cols-2 ipadPro:grid-cols-1'>

      <div className='flex flex-col items-center p-4 text-center ipadPro:order-1'>
        <Logo className="w-16 h-16" fill={"fill-slate-400 dark:fill-slate-600"}></Logo>
        <p className='inline-flex items-start my-2 text-4xl font-semibold text-slate-600 dark:text-slate-400'>escapetask <span className='text-[20px]'>®</span></p>

        <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>Monday-Sunday, escape it</h4>

        {/* <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>Your friendly task manager, connecting around the globe</h4>
        <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>Escape from your tasks</h4>
        <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>Task it? escape it!</h4>
        <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>Visualise your tasks</h4>
        <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>Virtualise your tasks</h4>
        
      <h4 className='mb-6 text-xl font-semibold text-slate-600 dark:text-slate-400'>All your tasks at once</h4> */}
       

        <div className='w-11/12 mx-auto lg:mx-0 lg:w-3/5 xl:w-2/4 '>
        <Link to="/login"><button className='w-full login-btn'>Sign In</button></Link>
        <p className='or-option'>or</p>
        <Link to="/signUp"><button className='w-full signUp-btn'>Create Account</button></Link>
        </div>
      </div>

      <div className='w-11/12 mx-auto ipadPro:mx-auto ipad:w-1/3 ipadPro:w-1/3 lg:mx-0 lg:w-11/12 md:w-3/5'>
       <Rocket/>
      </div>
         
      </div>
          
    </div>
  )
}

export default Welcome