import React from 'react'
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import { Close, Drag } from '../Icons';

const Checks = ({id, item, setChecked, removeCheck, isCreate, setIsChecklistModified}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
      } = useSortable({id:id});
      
      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        
      };

  return (
      <div ref={setNodeRef} style={style} >
      <div className='check-item group'>

      <div className='flex items-center justify-start w-11/12 gap-1'>
      <button className='drag-handler' {...listeners} {...attributes} type='button'><Drag iconClass={'w-4 h-4'}/></button>
      <input type='checkbox' className='mr-1 checkbox-checklist'  onChange={()=>{setChecked(id, item.checked)}} checked={item.checked} />
      <input type='text' className='magic-input' defaultValue={item.name} placeholder='type here' onChange={(e)=>{item.name=e.target.value; if(!isCreate){setIsChecklistModified(true)} }} />
      </div>
      {item.default?
      null
      :
      <button onClick={()=>removeCheck(item.id)} type="button" className='opacity-0 reject-btn group-hover:opacity-100'><Close iconClass={'w-4 h-4'}/></button>
      }
      </div>
       
    </div>
  )
}

export default Checks