import { createSlice } from "@reduxjs/toolkit";

const initialStateValue=JSON.parse(localStorage.getItem('auth_user'));

export const userSlice=createSlice({
    name:"user",
    initialState:{value:initialStateValue},
    reducers:{
        login:(state,action)=>{
            state.value=action.payload
        },
        setUser:(state,action)=>{
            state.value=action.payload
        }
    }
})

export const {login, setUser}=userSlice.actions
export default userSlice.reducer;