import React, {useEffect, useState} from 'react'
import Avatar from '../Avatar'
import { Menu } from '@headlessui/react'
import { Ban, Work, CheckCircle, UserRemove } from '../../layout/Icons';
import { avatarStorage_LINK } from '../../../api/ApiUrl';

const AssigneeList = ({
    assignee, removeAsignee, setChangedAssignee,jobId, user, tempActivity, setTempActivity, notifications, setNotifications
}) => {

    const [textColor, setTextColor]=useState('');
    const [ pIcon, setpIcon]=useState(null);
    const [ prog, setProg]=useState(null);
    const [ring,setRing]=useState('')
    const [oldProgress,setOldProgress]=useState('');

    const color = (progress)=>{
        if(progress==0){
            setTextColor('text-red-500')
            setRing('ring-red-500')
        }else if (progress==1){
          setTextColor('text-sky-500')
          setRing('ring-sky-500')
        }else if (progress==2){
          setTextColor('text-green-500')
          setRing('ring-green-500')
        }
    }  
  
    const text = (progress)=>{
      if(progress==0){
        setProg('No Update')
      }else if (progress==1){
        setProg('Working')
      }else if (progress==2){
        setProg('Complete')
      }
  }
  
  const icon = (progress)=>{
      if(progress==0){
  
        setpIcon(<Ban iconClass={'w-3 h-3'}/>)
  
      }else if (progress==1){
  
        setpIcon(<Work iconClass={'w-3 h-3'}/>)
  
      }else if (progress==2){
  
        setpIcon(<CheckCircle iconClass={'w-3 h-3'}/>)
  
      }
  }

    const updateProgress = (progress)=>{
        color(progress);
        text(progress);
        icon(progress);

      }

      useEffect(()=>{
        updateProgress(assignee.progress);

        if(assignee.progress==0){
          setOldProgress('No Update')
        }else if (assignee.progress==1){
          setOldProgress('Working')
        }else if (assignee.progress==2){
          setOldProgress('Complete')
        }

      },[])

      const recordActivity=(progress, receiverId)=>{
        var prog="No Update"
        if(progress==0){
          prog='No Update'
        }else if (progress==1){
          prog='Working'
        }else if (progress==2){
          prog='Complete'
        }

        if(assignee.userId==user.id){
          var act={
            jobId:jobId,
            userName:`${user.Fname} ${user.Lname}`,
            action:'set progress to',
            activity:prog
          }
          
          setTempActivity([act,...tempActivity])
        }else{
       
          var act={
            jobId:jobId,
            userName:`${user.Fname} ${user.Lname}`,
            action:`set progress of ${assignee.Fname} ${assignee.Lname} from ${oldProgress} to`,
            activity:prog
          }
          const filterDups=notifications.filter(f=>f.receiverId!=receiverId)
          
          var ntfn={
            receiverId:receiverId,
            title:`Progress for Task [#${jobId}] is changed to ${prog}`,
            body:`${user.Fname} ${user.Lname} changed your progress from ${oldProgress} to ${prog}`
          }
     
          setNotifications([...filterDups, ntfn])
         
          setTempActivity([act,...tempActivity])
        }
      }

  return (
    <div className=''>
         <Menu>
        <div className='relative p-1'>
         <Menu.Button>
         <div className={`object-contain group ring-2 rounded-full ring-offset-2 ring-offset-bg-slate-50 dark:ring-offset-slate-700 ${ring}`}>
         {assignee.image?
         <img src={`${avatarStorage_LINK}${assignee.image}`} className='w-8 h-8 rounded-full' alt='avatar'/>
         :
         <Avatar value={`${assignee.Fname} ${assignee.Lname}`} width={'8'} height={'8'} color={'bg-blue-200 dark:bg-blue-800'} />
        }
            <div className='absolute z-10 p-1 transition-all duration-150 ease-in-out origin-bottom-left scale-0 rounded-md shadow-lg group-hover:scale-100 -top-10 left-3 bg-slate-50 dark:bg-slate-700 shadow-slate-400 dark:shadow-slate-900 text-slate-600 dark:text-slate-300'>
                <p className='text-sm font-semibold whitespace-nowrap'>{assignee.Fname} {assignee.Lname}</p>
                <p className={`${textColor} font-bold text-xs  whitespace-nowrap  flex items-center justify-start`}>{pIcon} {prog}</p>
            </div>
        </div>
        </Menu.Button>
        
        <Menu.Items>
            <div className='absolute left-0 w-32 p-1 rounded-md shadow-lg z-[99] top-8 bg-slate-100 dark:bg-slate-700 shadow-slate-400 dark:shadow-slate-900'>
            <div className='assigneeMenuArrow'></div>
            <Menu.Item>
                <button onClick={()=>{assignee.progress=0; updateProgress(0); recordActivity(0, assignee.userId); setChangedAssignee(true)}} className='flex items-center justify-between w-full px-2 py-2 text-sm font-semibold text-red-500 transition-all duration-150 ease-in-out rounded-md hover:bg-slate-200 dark:hover:bg-slate-900'><span> No Update</span> <Ban iconClass={'w-4 h-4'}/></button>
                </Menu.Item>
            <Menu.Item>
                <button onClick={()=>{assignee.progress=1;  updateProgress(1);  recordActivity(1, assignee.userId); setChangedAssignee(true)}} className='flex items-center justify-between w-full px-2 py-2 text-sm font-semibold transition-all duration-150 ease-in-out rounded-md text-sky-500 hover:bg-slate-200 dark:hover:bg-slate-900'> <span> Working </span> <Work iconClass={'w-4 h-4'}/></button>
                </Menu.Item>
            <Menu.Item>
                <button  onClick={()=>{assignee.progress=2;   updateProgress(2); recordActivity(2, assignee.userId); setChangedAssignee(true)}} className='flex items-center justify-between w-full px-2 py-2 text-sm font-semibold text-green-500 transition-all duration-150 ease-in-out rounded-md hover:bg-green-200 dark:hover:bg-green-900'><span> Completed </span> <CheckCircle iconClass={'w-4 h-4'}/></button>
            </Menu.Item>
            <Menu.Item>
            <button  onClick={()=>{removeAsignee(assignee)}} className='flex items-center justify-between w-full px-2 py-2 text-sm font-semibold text-red-600 transition-all duration-150 ease-in-out rounded-md hover:bg-red-200 dark:hover:bg-red-900'><span> Remove </span> <UserRemove iconClass={'w-4 h-4'}/></button>
            </Menu.Item>
            </div>
        </Menu.Items> 
       
            </div>
        </Menu>
    </div>
  )
}

export default AssigneeList