import { configureStore } from "@reduxjs/toolkit";


import userReducer from '../features/user';
import companyReducer from '../features/companies';
import categoryReducer from '../features/categories';
import customerReducer from '../features/customers';
import tasksReducer from '../features/Tasks';


const store=configureStore({
    reducer:{
        user:userReducer,
        company:companyReducer,
        category:categoryReducer,
        customers:customerReducer,
        allTasks:tasksReducer,
 
    },
    
})

export default store;