import React, {useState} from 'react'
import { Add } from '../Icons'
import Option from './Option'
import { v4 as uuidv4 } from 'uuid';

import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    
  } from '@dnd-kit/sortable';
import { Transition } from '@headlessui/react';

const OptionList = ({options, setOptions, isEdit, updateData, id, isMathFunc, isStatus}) => {
  
    const [canSave, setCanSave]=useState(false);
  
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    function handleDragEnd(event) {
      const {active, over} = event;
      
      if (active.id !== over.id) {
        setOptions((options) => {
          const oldIndex = options.findIndex((item) => item.id === active.id);
          const newIndex = options.findIndex((item) => item.id === over.id);
          return arrayMove(options, oldIndex, newIndex);
        });
        setCanSave(true);
      }
    }
  
  
    const addOption = ()=>{
    var newOption={};
      if(isEdit){
        if(isMathFunc){
          newOption= {id:uuidv4(), name:'', color:'#3b82f6',track:-1, mathFunc:-1};
        }else{
           newOption= {id:uuidv4(), name:'', color:'#3b82f6', track:-1};
        }
        
      }else{
        if(isMathFunc){
           newOption= {id:uuidv4(), name:'', color:'#3b82f6', mathFunc:-1};
        }else{
             newOption= {id:uuidv4(), name:'', color:'#3b82f6'};
        }
      }
   
  
      setOptions([...options, newOption])
      setCanSave(true)
    }
  
    const setOptionName =(id,name)=>{
      var update=options.map((o)=>
      o.id===id?{
      ...o,
      name:name,
      }:o
      );
      setOptions(update)
      setCanSave(true)
    }
  
    const removeOption = (id)=>{
      var ops=options.filter((c)=>c.id !==id);
  
      setOptions(ops)
      setCanSave(true)
      
    }
    const updateColor =(id,color)=>{
      var update=options.map((o)=>
      o.id===id?{
        ...o,
        color:color,
      }:o
      );
      setOptions(update);
      setCanSave(true)
    }

    const updateMathFunc=(id)=>{
      
      var checkValue=options.filter((c)=>c.id===id)[0];
      var update=null
      if(checkValue.mathFunc>=1){
        update=options.map((o)=>
        o.id===id?{
        ...o,
        mathFunc:-1,
      }:o
      )
      }else{
        update=options.map((o)=>
        o.id===id?{
        ...o,
        mathFunc:1,
      }:o
      )
    }
      
      setOptions(update);
      setCanSave(true)
    }
    
    return (
        <div>
        <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        >
          <SortableContext  items={options} strategy= {verticalListSortingStrategy}>
              {options.map(i=><Option key={i.id} item={i} id={i.id} isMathFunc={isMathFunc} isStatus={isStatus} updateMathFunc={updateMathFunc} setOptionName={setOptionName} removeOption={removeOption} size={options.length} tempColor={i.color} updateColor={updateColor}/>)}
          </SortableContext>
          <div className='flex items-center justify-between gap-2 px-1'>
          <button onClick={()=>addOption()} className='add-check-btn'><Add iconClass={'w-4 h-4'}/></button>
          {isEdit?
           <Transition
           show={canSave}
            enter="transition-all translate duration-150"
            enterFrom="opacity-0 translate-x-6"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all translate duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-6"
           >
          <button className='flex items-center justify-center gap-1 save-btn' onClick={()=>{updateData(options, id, isMathFunc); setCanSave(!canSave)}} disabled={!canSave}> Save</button>
          </Transition>
          :
          null
          }
          </div>
        </DndContext>
    </div>
  )
}

export default OptionList