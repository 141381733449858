import { createSlice } from "@reduxjs/toolkit";

const initialStateValue=[];

export const companySlice=createSlice({
    name:"company",
    initialState:{value:initialStateValue},
    reducers:{
        setCompany:(state,action)=>{
            state.value=action.payload
        },
        delCompany:(state,action)=>{
            var id=action.payload
            state.value=state.value.filter(co=>co.id!==id);
        }
    }
})

export const {setCompany, delCompany}=companySlice.actions
export default companySlice.reducer;