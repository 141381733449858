import React,{useState, useEffect} from 'react'
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { useSelector } from 'react-redux';
import Tasklist from '../Tasks/Tasklist';
import { v4 as uuidv4 } from "uuid";
import { GETDATA_URL } from '../../api/ApiUrl';
const DoneTasks = () => {

  const user=useSelector(state=>state.user.value);
  const categs=useSelector((state)=>state.category.value);
  const tasks=useSelector((state)=>state.allTasks.value); 

  const [jobStatuses,setJobStatuses]=useState([]);
  const [paymentOptions,setPaymentOptions]=useState([]);
  const [paymentMethods,setPaymentMethods]=useState([]);
  const [jobTypes,setJobTypes]=useState([]);
  const [shipments,setShipments]=useState([]);
  const [productions,setProductions]=useState([]);
  const [quotes, setQuotes]=useState([]);
  const [checklists, setChecklists]=useState([]);
  const [employeelist,setEmployeelist]=useState([]);

  const [isLoad, setLoad]=useState(true);

  const getDefaultData= async(ids)=>{
    try{
       await axios.post(GETDATA_URL, {ids:ids}).then((res)=>{
        setJobStatuses(res.data.statuses)
        setPaymentOptions(res.data.paymentOptions)
        setPaymentMethods(res.data.paymentMethods)
        setJobTypes(res.data.jobTypes)
        setShipments(res.data.shipments)
        setProductions(res.data.productions)
        setQuotes(res.data.quotes)
        setChecklists(res.data.checklists)
        setEmployeelist(res.data.employees)    
        setLoad(false)
       })
  
      }catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
      }else{
        if(e.response.status===429){
          toast.error('too many requests! please try again later')
        }else{
          console.log(e);
          toast.error("Oops! An error occured!");
        }
      }
    }
}


useEffect(()=>{
  var ids=categs.map((c)=>c.id);
  getDefaultData(ids);

},[])

  return !isLoad?(
    <div className='main-bodyLayout'>
       
        {tasks.length>0?
        <Tasklist
        key={uuidv4()}
        isCategory={false}
        tasks={tasks}
        jobStatuses={jobStatuses}
        paymentOptions={paymentOptions}
        paymentMethods={paymentMethods}
        jobTypes={jobTypes}
        shipments={shipments}
        productions={productions}
        quotes={quotes}
        checklists={checklists}
        employeelist={employeelist}
        fromDoneTask={true}
        title={'Done Tasks'}
        />
        :
        null}
    </div>
  ):
  null
}

export default DoneTasks