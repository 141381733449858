import React, {useState} from 'react'
import { Phone, Mail, Telephone, Map, Pencil, Globe, Close } from '../layout/Icons'
import EditCustomer from './EditCustomer';

const CustomerCard = ({cust}) => {
    
    const [customer, setCustomer]=useState(cust);
    const [isEdit, setIsEdit]=useState(false);

    const rating =(rate)=>{
        if(rate==0){
            return ( <p className='text-lg'>😐</p> )
        }else if(rate==1){
            return ( <p className='text-lg'>😁</p> )
        }
        else if(rate==2){
            return ( <p className='text-lg'>💖</p> )
        }else if(rate==-1){
        return ( <p className='text-lg'>😡</p> )
        }else{
            return ( <p className='text-lg'>👻</p> )
        }
    }
 
  return (
   <div className='p-2 my-1 transition-all duration-150 ease-in-out border rounded-md shadow-lg bg-slate-100 dark:bg-slate-700 shadow-transparent dark:hover:bg-slate-800 hover:bg-slate-50 hover:shadow-slate-300 dark:hover:shadow-slate-900 border-slate-400 dark:border-slate-600'>
        <div className='flex items-center justify-start gap-2 group'>
        {rating(customer.rating)}
        <p className='text-lg font-bold text-slate-600 dark:text-slate-300'>
            {customer.name} {customer.company ? `| ${customer.company}` : '' } 
        </p>
        <button onClick={()=>{setIsEdit(true)}} className='hidden info-btn group-hover:block'><Pencil iconClass={'w-4 h-4'}/></button>
        </div>
        <p className='inline-flex items-center justify-start gap-1 font-semibold text-normal text-slate-600 dark:text-slate-300'><Mail iconClass={'w-4 h-4'} /> {customer.email} </p> <br/>
        <p className='inline-flex items-center justify-start gap-1 text-sm font-semibold text-slate-600 dark:text-slate-300'><Phone iconClass={'w-4 h-4'}/> {customer.mobile} {customer.telephone? <> <Telephone iconClass={'w-4 h-4'}/>  {customer.telephone} </>: ''}</p> <br/>
        {customer.website? <> <p className='inline-flex items-center justify-start gap-1 text-xs font-semibold text-slate-600 dark:text-slate-300'> <Globe iconClass={'w-4 h-4'} /> {customer.website}</p> <br/> </>: null}
        {customer.address?
        <p className='inline-flex items-center justify-start gap-1 text-xs font-semibold text-slate-600 dark:text-slate-300'><Map iconClass={'w-4 h-4'} /> {customer.address} <br/> {customer.postCode}</p>
        :
        
        null}
    {isEdit?
    <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
        <div className='flex flex-row items-center justify-center min-h-screen'>
            <div className='card'>
            <div className='flex items-center justify-between gap-1 my-2'>
            <p className='font-semibold text-slate-600 dark:text-slate-300'>Edit Customer </p>
            <button className='reject-btn' onClick={()=>{setIsEdit(false)}}><Close iconClass={'w-4 h-4'} /></button>
            </div>
            <EditCustomer cust={customer} setCust={setCustomer} setIsEdit={setIsEdit} />
            </div>
        </div>
    </div>

    :
    null
    }
    </div>
  )
}

export default CustomerCard