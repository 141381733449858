import React from "react";

function Logo({className, fill}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      className={className}
      
    >
      <linearGradient
        id="SVGID_1_"
        x1="12.396"
        x2="12.396"
        y1="11.381"
        y2="6.099"
        gradientTransform="matrix(1 0 0 -1 0 24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#EA580C"></stop>
        <stop offset="0.437" stopColor="#F27322"></stop>
        <stop offset="1" stopColor="#FB923C"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M12.37 19.04c-.51 0-.93-.39-.98-.89l-.64-6.81a.98.98 0 01.98-1.07h1.32c.58 0 1.04.5.98 1.08l-.68 6.81c-.05.49-.47.88-.98.88z"
      ></path>
      <path
        className={fill}
        d="M20.11,15.59c-1.15,2.79-3.66,4.58-6.89,4.9c-2.27,0.23-4.48-0.44-6.25-1.88c-1.76-1.44-2.86-3.48-3.09-5.75
        C3.67,10.6,4.34,8.38,5.78,6.62c1.44-1.76,3.48-2.86,5.75-3.09c2.85-0.29,5.62,0.9,7.4,3.03c0.28,0.34,0.15,0.85-0.25,1.02
        l-5.92,2.4c-0.22,0.09-0.46,0.1-0.68,0.02L9,9.31C8.47,9.14,7.89,9.39,7.66,9.9l0,0c-0.23,0.51-0.04,1.11,0.45,1.39l3.81,2.39
        c0.26,0.15,0.58,0.17,0.86,0.06l9.77-3.97v0c0.89-0.36,1.32-1.37,0.96-2.26l0-0.01c-0.96-2.36-2.68-4.39-4.86-5.73
        c-2.25-1.38-4.84-1.97-7.47-1.71c-3.19,0.32-6.07,1.86-8.1,4.35c-2.03,2.48-2.97,5.61-2.65,8.8s1.86,6.07,4.35,8.1
        c2.17,1.77,4.82,2.71,7.58,2.71c0.41,0,0.81-0.02,1.22-0.06c2.18-0.22,4.2-0.95,5.87-2.12c1.72-1.22,3.07-2.92,3.9-4.92l0.02-0.06
        c0.35-0.86-0.06-1.84-0.92-2.2l-0.04-0.02C21.5,14.28,20.48,14.7,20.11,15.59C20.11,15.59,20.11,15.59,20.11,15.59z"
      ></path>
    </svg>

  );
}

export default Logo;