import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../api/axios';
import toast from 'react-hot-toast';
import Avatar from '../layout/Avatar';
import { Mail, Pencil, CheckCircle, LogoutIcon } from '../layout/Icons';
import { avatarStorage_LINK, avatarUpload_URL } from '../../api/ApiUrl';
import { setUser } from '../../features/user';
import { useNavigate } from 'react-router-dom';
import { SignOutFirebaseuser, updateProfilePhoto } from '../../config/firebaseConfig';

const ProfileCard = ({isEdit, setIsEdit}) => {
    const dispatch=useDispatch();
    const user=useSelector((state)=>state.user.value)
    const [imgUpload, setImgUpload]=useState(null);
    const [imgDisplay, setImgDisplay]=useState(null);

    const navigate = useNavigate();
    const [islogout, setIslogout]=useState(false)

    const uploadFile=(e)=>{
        if(e.target.files && e.target.files[0]){
            setImgUpload(e.target.files[0])
            setImgDisplay( URL.createObjectURL(e.target.files[0]));
        }
    }

    const saveAvatar = async (e)=>{
        e.preventDefault();
            const formData = new FormData();
            formData.append("id", user.id);
            formData.append("image", imgUpload);
        try{
            const res=await axios.post(avatarUpload_URL, formData, {headers: { 'content-type': 'multipart/form-data' }})
            localStorage.setItem('auth_user', JSON.stringify(res.data.user));
            dispatch(setUser(JSON.parse(localStorage.getItem('auth_user'))))
            const u=res.data.user
            updateProfilePhoto(u.id, u.image);
            setImgDisplay(null);
            setImgUpload(null);
            toast.success('Avatar Updated!',{icon: '📷'})
        }catch(e){
            if (!e?.response) {
            toast.error(["No Server Response!"]);
            }
            console.log(e)
        }
      }


      const logout= async()=>{
        try{
          await axios.post('/api/logout').then(res=>{
              const userID=user.id;
              SignOutFirebaseuser(userID);
              localStorage.removeItem('auth_Token');
              localStorage.removeItem('auth_user');
              localStorage.removeItem('activeCo');
              dispatch(setUser(null))
              setUser(null);
              navigate('/');
    
          })
        }catch (e){
          if(!e?.response){
            toast.error('No Server Response!')
          }else{
            console.log(e.response.data)
          }
        }
      }
      

  return (

        <div className='mr-2 card'>
        <div className='flex items-start justify-between gap-2'>

        
        <div className='flex items-center justify-start gap-2'>
            <div>
            <form onSubmit={(e)=>{saveAvatar(e)}}>
            <div className='relative w-16 h-16 p-1 rounded-full bg-slate-300'>
                {!imgDisplay?
                <>
                    {user.image?
                    <img src={`${avatarStorage_LINK}${user.image}`} className='absolute z-10 rounded-full w-14 h-14 top-1 left-1' alt='avatar'/>
                    :
                    <Avatar value={`${user.Fname} ${user.Lname}`} className='absolute z-10 top-2 left-2' width={'14'} height={'14'}/>
                    }

                </>
                :
                <>
                    <img src={imgDisplay} className='absolute z-10 rounded-full w-14 h-14 top-1 left-1' />                
                {imgDisplay?
                <button  className='absolute z-30 p-1 -ml-3 text-green-500 transition-all duration-150 ease-in-out bg-green-300 rounded-full hover:bg-green-500 hover:text-slate-50 -bottom-2 left-1/2 dark:bg-green-800'><CheckCircle iconClass={'w-5 h-5'}/></button>
                :
                null
                }
                </>
                }
                <input accept='image/jpg, image/png, image/jpeg, image/gif' type='file' onChange={(e)=>uploadFile(e)} className='hidden' id='avatarUpload'/>
                <label htmlFor='avatarUpload' className='absolute top-0.5 left-0 z-20 rounded-full w-16 h-16 p-2 opacity-0 cursor-pointer dark:bg-slate-600/70 bg-slate-50/70 text-slate-600 dark:text-slate-100 hover:opacity-100'>
                <Pencil iconClass={'w-6 h-6 min-h-full m-auto'} />
                </label>
            </div>
            </form>
            
            </div>
            <div>
                <div className='flex items-center justify-start gap-2'>
                <h4 className='text-2xl font-bold text-slate-600 dark:text-slate-400'>{user.Fname} {user.Lname}</h4>
                <button className='icon-btn' onClick={()=>{setIsEdit(!isEdit)}}><Pencil iconClass={'w-4 h-4'}/></button>
                </div>
                <p className='inline-flex items-center gap-1 text-slate-500'><Mail iconClass={'w-4 h-4'}/>{user.email}</p>
            </div>
            <div>

            </div>
        </div>
        
        <button className='inline-flex items-center gap-1 reject-btn' onClick={()=>{setIslogout(!islogout)}}><LogoutIcon iconClass={'w-4 h-4'}/> logout</button>
        </div>

        {islogout?(
    <div className='fixed top-0 left-0 w-screen h-full bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
      <div className='flex flex-row items-center justify-center min-h-screen'>
        <div className='p-4 rounded-md shadow-xl bg-gradient-to-b from-slate-50 to-slate-200 dark:from-slate-700 dark:to-slate-900 shadow-slate-400 dark:shadow-slate-900'>
          <p className='font-semibold text-slate-600 dark:text-slate-400'>Are you sure you want to log out?</p>
          <div className='flex items-center justify-end gap-2 mt-6'>
          <button onClick={()=>{logout()}} className='w-16 reject-btn'>Yes</button>
          <button onClick={()=>{setIslogout(!islogout)}} className='w-16 accept-btn'>No</button>
          </div>
    
        </div>
      </div>
    </div>

  ):null}

    </div>


  )
}

export default ProfileCard