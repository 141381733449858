import React,{useState, useEffect} from 'react'

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../api/axios';
import toast from 'react-hot-toast';
import { RegisterFireBaseUser } from '../../config/firebaseConfig';
import { setUser } from '../../features/user';
import Messenger from '../Messenger/Messenger';
import {MsgIcon} from '../layout/Icons'

import { addRoom } from '../../config/firebaseConfig';
import NotificationCircle from './NotificationCircle';

const SideBar = () => {

const [uid,setuid]=useState(null); 
const auth = getAuth();
const dispacth=useDispatch();
const user=useSelector((state=>state.user.value));
const [password,setPassword]=useState('');
const [unread,setUnread]=useState(-1);
const [showSidebar, setShowSidebar]=useState(false);

const checkUid=()=>{
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setuid(uid);
    }else{
      setuid(null);
    }
  });
}

useEffect(()=>{
  checkUid()
},[])


  const regFBUser = async(e)=>{
      e.preventDefault();
      try{
        var data=JSON.stringify({
          email:user.email,
          password:password
        })
        await axios.post('/api/firebaseRegistration', data).then((res)=>{
          if(res.data.check){
            const newUser=res.data.user
            dispacth(setUser(user));
            RegisterFireBaseUser(newUser.email,newUser.f_tokenId, user.id)
            checkUid()
          }
          
        })
      }
      catch(e){
        if(!e?.response){
          toast.error("No Server Response!");
          console.log(e);
        }
        if(e.response.status==401){
          toast.error(e.response.data.message)
        }
        else{
          console.log(e);
          toast.error("Oops! An error occured!");
        }
      }
    
  }


  
  return (
    <>
    <div className='fixed bottom-10 right-5 z-[99] 2xl:hidden block'>
      <button onClick={()=>{setShowSidebar(!showSidebar)}} className={`msgToggleBtn ${showSidebar? `scale-0` : `scale-100`} origin-center`}>
          <MsgIcon iconClass={'w-7 h-7'} />
         <NotificationCircle value={unread}/>
      </button>

    </div>
    <div className={`sidebarRight 2xl:right-0 ${showSidebar? `right-0 opacity-100` : `-right-full opacity-0`} 2xl:opacity-100`}>
        {uid==null ?
          <div className='p-1 border rounded-2 bg-slate-200 text-slate-500 darK:bg-slate-800'>
            <p className='text-sm font-semibold'>{user.email}</p>
            <form onSubmit={(e)=>{regFBUser(e)}}>
            <input onChange={(e)=>{setPassword(e.target.value)}}  type="password" className='input-field-fat' placeholder='password' />
            <button className='accept-btn' type='submit'>Reg.firebase</button>
            <p className='text-xs italic font-medium'>
              Because we have registered account prior setting up firebase, our existing accounts need to be registered into firebase;
              N.b- new users will not see this box. 
            </p>
            </form>
          </div>
          :
          <Messenger showSidebar={showSidebar} setShowSidebar={setShowSidebar} setUnread={setUnread} />
        }

        {/* <button className='info-btn' onClick={()=>{addRoom(2,'test',8, '', 'Jhon')}}>Test Room</button> */}
    </div>
    </>

  )
}

export default SideBar