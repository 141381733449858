// http://127.0.0.1:8000
// https://escapetask.com

//IMG_LINKS
export const logoStorage_LINK='https://escapetask.com/storage/companies/';
export const CategorylogoStorage_LINK='https://escapetask.com/storage/categoryIcons/';
export const avatarStorage_LINK='https://escapetask.com/storage/avatars/';
export const CompanylogoStorage_LINK='https://escapetask.com/storage/companies/';
export const MSGIMG_STORAGE_LINK='https://escapetask.com/storage/msgImgs/'

// export const logoStorage_LINK='http://127.0.0.1:8000/storage/companies/';
// export const CategorylogoStorage_LINK='http://127.0.0.1:8000/storage/categoryIcons/';
// export const avatarStorage_LINK='http://127.0.0.1:8000/storage/avatars/';
// export const CompanylogoStorage_LINK='http://127.0.0.1:8000/storage/companies/';
// export const MSGIMG_STORAGE_LINK='http://127.0.0.1:8000/storage/msgImgs/'

//app
export const CATEGORIES_URL='api/categories/';
export const COMPANIES_URL='/api/getCompany/';
export const CATEGORY_URL='/api/getCategory/';
//AddChild
export const ADDJOB_URL='/api/addJob';

//Assignee
export const UPDATEASSIGNEE_URL='/api/update/assignee';

//CreateJobForm
export const CUSTOMER_URL='/api/customers/';
export const POSTJOB_URL='/api/createJob';

//EditTask
export const UPDATETASK_API='/api/updateTask/';

//ForwardJob
export const FORWARD_URL='/api/forwardjob';

//PostJobCustomer
export const UPDATECUSTOMER_URL='/api/update/customer';

//Task
export const UPDATECHECKLIST_URL='/api/update/checklists';
export const UPDATENOTES_URL='/api/update/notes';
export const UPDATESTATUS_URL='/api/update/job/status/';
export const UPDATEPAYMENTOPTION_URL='/api/update/job/paymentOption/';
export const UPDATEPAYMENTMETHOD_URL='/api/update/job/paymentMethod/';
export const UPDATESECONDARYOPTION_URL='/api/update/job/secondaryOptions/';
export const GETEXTRATASKDETAILS_URL='/api/job/extra/details/'
export const DONETASK_URL='/api/task/done';

export const REVERTTASK_URL='/api/task/revert'
export const PIN_TASK='/api/job/pin';
//categoryJob
export const GETDATA_URL='/api/category/defaultData';
export const GETJOBS_URL='/api/category/jobs/';
//CompanyJob
export const GETALLJOB_URL='/api/alljobs/'

export const GETCOMPANYJOBS_URL='/api/company/jobs/';

//EmployeeCard
export const ASSIGN_URL='/api/assignEmployee';
export const UPDATE_URL='/api/updateEmployee';
export const DECLINE_URL='/api/declineEmployee';
export const DELETE_URL='/api/deleteEmployee/'

//user Tasks

export const GETUSER_JOB='/api/user/tasks/';
export const GETPROGRESSTASK='/api/user/tasks/progess/';

//CategoryCreate
export const REGISTER_URL='/api/register/category';
export const EMPLOYEES_URL='/api/getActiveEmployees/';

//categoryEdit
export const GENERALUPDATE_URL='/api/category/general/update';
export const UPDATEOPTION_URL='/api/category/options/update';
export const UPDATEVARIABLE_URL='/api/category/variableOption/update';
export const UPDATENOTTE_URL='/api/category/notes/update';
export const ACCESS_URL='/api/category/access/update';
export const DELETECATEG_URL='/api/category/delete';


//forgotPassword
export const REQUESTFORGOTPASSWORD_URL='/api/forgot-password';

//login
export const LOGIN_URL='/api/login';


//Profile
export const avatarUpload_URL= '/api/uploadAvatar';
export const GETREQEMPLOYEES_URL='/api/getEmployees/';

//Register
export const REGISTERUSER_URL = "/api/register";


//password reset

export const RESETPASSWORD_URL='/api/password/reset'
//Company
export const DELETECOMPANY_URL ='/api/company/delete/';
export const RESEND_URL='/api/resendEmployeeRequest';

//companyEdit
export const UPDATECOMPANY_URL='/api/updateCompany';

//CompanyReg
export const REGCOMPANY_URL='/api/registerCompany';

//editProfile
export const UPDATEPROFILEINFO_URL='/api/updateUser' ;

//findCompany
export const JOIN_URL='/api/joinCompany';

//AddCustomer
export const ADDCUSTOMER_URL='/api/add/customer';

//pendingEmployee
export const PENDINGEMPLOYEE_URL='/api/pendingEmployees';

//deleteJob
export const DELETEJOB='/api/job/delete/';

//doneJobs
export const GETALLDONETASKS_URL='/api/alljobs/done/'


//Activity

export const CHECK_CLOCK_IN='/api/check/clock-In'
export const CLOCK_IN_USER='/api/user/clock-In'
export const CLOCK_OUT_USER='/api/user/clock-Out'

export const ADD_ACTIVITY='/api/user/activity/add'
export const COMPLETE_ACTIVITY='/api/usre/activity/complete'

export const UPLOAD_MSG_IMAGE='/api/messages/save/image'
export const DELETE_MSG_IMAGE='/api/messages/delete/image'