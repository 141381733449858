import React,{ useEffect, useRef, useState } from 'react'
import axios from '../../api/axios'
import axiosDefault from 'axios';
import { useDispatch } from 'react-redux';
import { login } from '../../features/user';
import toast from 'react-hot-toast'
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";

import { ThemeContext } from '../layout/ThemeContext';
import { Eye , UserAdd } from '../layout/Icons';
import Logo from '../../Logo';

import { REGISTERUSER_URL } from '../../api/ApiUrl';
import { RegisterFireBaseUser, saveFirebaseUser } from '../../config/firebaseConfig';
import '../../astronaut-suit-styles.css'
import {ReactComponent as Astronaut} from '../../astronaut-suit-not-css.svg'

const user_REGEX =/^[a-zA-Z][a-zA-Z ]{1,23}$/;
const pwd_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z-@!.\/#&+\w\s\d]{8,}$/;
const email_REGEX =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  
const Register = () => {

    
    
    const navigate = useNavigate();
    const { theme } = React.useContext(ThemeContext); //to apply darkmode in elements
    const dispatch = useDispatch();
    const [fname, setFname] = useState("");
    const [validFname, setValidFname] = useState(false);
    const [userFFocus, setUserFFocus] = useState(false);
  
    const [lname, setLname] = useState("");
    const [validLname, setValidLname] = useState(false);
    const [userLFocus, setUserLFocus] = useState(false);
  
    const [dob, setDob] = useState(null);
  
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
  
    const [password, setPassword] = useState("");
    const [validPass, setValidPass] = useState(false);
    const [passFocus, setPassFocus] = useState(false);
  
    const [confirm, setConfirm] = useState("");
    const [validConfirm, setValidConfirm] = useState(false);
    const [confirmFocus, setConfirmFocus] = useState(false);
  
    const [showPass, setShowPass] = useState(false);

    useEffect(() => {
        userRef.current.focus();
      }, []);
    
      useEffect(() => {
        setValidFname(user_REGEX.test(fname));
      }, [fname]);
    
      useEffect(() => {
        setValidLname(user_REGEX.test(lname));
      }, [lname]);
    
      useEffect(() => {
        setValidEmail(email_REGEX.test(email.toLowerCase()));
      }, [email]);


      useEffect(() => {
        setValidPass(pwd_REGEX.test(password));
        const match = password === confirm;
        setValidConfirm(match);
      }, [password, confirm]);
    
      const userRef = useRef();
      
    
      const employeeOption = [
        { value: "0", label: "Employee"},
        { value: "1", label: "Employer"},
      ];
    
      const [userType, setUserType]=useState(0);
    
      
      const register = async (e) => {
        e.preventDefault();
        const fcheck = user_REGEX.test(fname);
        const lcheck = user_REGEX.test(lname);
        const emailCheck = email_REGEX.test(email.toLowerCase());
        const dopbCheck = dob != null;
        const typeCheck=userType!=null;
        const passCheck = pwd_REGEX.test(password);
    
        if (
          !fcheck ||
          !lcheck ||
          !emailCheck ||
          !dopbCheck ||
          !passCheck ||
          !typeCheck
        ) {
          toast.error("Invalid Entry");
          return;
        }
    
        try {
          const toastConnect = toast.loading('Connecting...', {icon: '⏳'});
          
          const resIp = await axiosDefault.get('https://geolocation-db.com/json/')
          
          var data = JSON.stringify({
            Fname: fname,
            Lname: lname,
            email: email,
            dob: moment(dob).format("YYYY-MM-DD"),
            password: password,
            password_confirmation: confirm,
            userType:userType,
            ip:resIp.data.IPv4,
            countryCode:resIp.data.country_code
          });
          
          toast.dismiss(toastConnect);
          const toastReg = toast.loading('Registering User...', {icon: '✍️'});
    
          await axios.get("sanctum/csrf-cookie").then((res) => {
            axios.post(REGISTERUSER_URL, data).then((res) => {
              localStorage.setItem("auth_Token", res.data.token);
              localStorage.setItem("auth_user", JSON.stringify(res.data.user));
              var user = res.data.user;
              dispatch(login(user))
              RegisterFireBaseUser(email, user.f_tokenId, user.id, `${user.Fname} ${user.Lname}`);
              toast.success(`Welcome, ${user.Fname} ${user.Lname}!`, { icon: "👋" });
              toast.dismiss(toastReg);
              localStorage.removeItem('activeCo');
              navigate("/profile");
            });
          });
          //clear input fields
          setFname("");
          setValidFname(false);
          setUserFFocus(false);
          setLname("");
          setValidLname(false);
          setUserLFocus(false);
          setDob(null);
          setEmail("");
          setValidEmail(false);
          setEmailFocus(false);
          setPassword("");
          setValidPass(false);
          setPassFocus(false);
          setConfirm("");
          setValidConfirm(false);
          setConfirmFocus(false);
          setShowPass(false);
        } catch (e) {
          if (!e?.response) {
            toast.error("No Server Response!");
          } else {
            var resErrors = e.response.data.errors;
            if (resErrors.email.length > 0) {
              toast.error(resErrors.email[0]);
            } else {
              toast.error(resErrors);
            }
          }
        }
      };


      const customStyles = {
        menu: (provided, state) => {
          let backgroundColor = "#f1f5f9";
          if (theme === "dark") {
            backgroundColor = "#334155";
          }
    
          return {
            ...provided,
            padding: 0,
            fontSize: 12,
            margin: 2,
            backgroundColor,
          };
        },
        option: (provided, state) => ({
          ...provided,
          padding: 6,
          color: state.isSelected ? "orangered" : "#64748b",
          backgroundColor: state.isSelected ? "#cbd5e1" : "",
          backgroundColor: state.isFocused ? "#cbd5e1" : "",
        }),
    
        control: (provided) => ({
          ...provided,
          fontSize: 13,
          padding: 6,
          // height: 24,
          minHeight: 14,
          border: 1,
          borderRadius: "0.375rem",
          boxShadow: "none",
          backgroundColor: "transparent",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "rgb(148 163 184)",
        }),
        valueContainer: () => ({
          fontSize: 13,
          padding: 0,
          margin: 0,
          minHeight: 17,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }),
    
        IndicatorsContainer: () => ({
          padding: 0,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          padding: 0,
        }),
        indicatorSeparator: () => () => null,
    
        dropdownIndicator: () => ({
          paddingLeft: 2,
          opacity: 0.5,
        }),
        multiValue: (provided) => ({
          ...provided,
          margin: 1,
          padding: 1,
          height: 24,
          backgroundColor: "#cbd5e1",
          justifyContent: "space-between",
        }),
        MultiValueContainer: (provided) => ({
          ...provided,
          minHeight: 14,
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          padding: 1,
          height: 20,
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          padding: 0,
          height: 20,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = "opacity 300ms";
    
          let color = "rgb(71 85 105)";
    
          if (theme === "dark") {
            color = "rgb(148 163 184)";
          }
    
          return { ...provided, opacity, transition, color };
        },
      };
    
  return (
    <div className="container mx-auto h-screen xl:mr-auto pt-[5em] xl:pt-[5em] place-items-center">
      <div className='grid grid-cols-1 lg:grid-cols-2 ipadPro:grid-cols-1'>

      <div className='hidden mx-auto xl:w-11/12 sm:block ipadPro:w-2/3 ipad:hidden ipad:mx-auto ipadPro:mx-auto'>
        <Astronaut/>
      </div>

    <div className='xl:mt-32 ipadPro:mt-16 ipadPro:w-3/4 ipadPro:mx-auto ipad:mt-2 ipad:w-8/12 ipad:mx-auto '>
      <div className="w-11/12 mx-auto xl:w-2/3 card">
        <div className='flex items-center justify-center gap-1'>

          <Logo
          className="w-8 h-8"
          fill={"fill-slate-400 dark:fill-slate-600"}
          />
        
        <h4 className="mb-2 text-2xl font-bold text-center text-slate-600 dark:text-slate-300">
          Register
        </h4>
          </div>
        <form onSubmit={(e) => register(e)}>
          <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
            {/* first name */}
            <div className="relative">
              <p className="text-sm text-slate-500">First Name:</p>
              <input
                defaultValue={fname}
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setFname(e.target.value)}
                type="text"
                className="w-full input-field-fat"
                aria-invalid={validFname ? "false" : "true"}
                style={validFname ? { borderColor: "#22c55e" } : {}}
                aria-describedby="uFnote"
                onFocus={() => setUserFFocus(true)}
                onBlur={() => setUserFFocus(false)}
                required
              />
              <p
                id="uFnote"
                className={`${
                  userFFocus && fname && !validFname ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - 2 to 24 characters <br /> - must begin with a letter <br /> -
                must not include numbers <br /> - avoid spaces
              </p>
            </div>

            {/* last name */}
            <div className="relative">
              <p className="text-sm text-slate-500">Last Name:</p>
              <input
                defaultValue={lname}
                autoComplete="off"
                onChange={(e) => setLname(e.target.value)}
                type="text"
                className="w-full input-field-fat"
                aria-invalid={validLname ? "false" : "true"}
                style={validLname ? { borderColor: "#22c55e" } : {}}
                aria-describedby="uLnote"
                onFocus={() => setUserLFocus(true)}
                onBlur={() => setUserLFocus(false)}
                required
              />
              <p
                id="uLnote"
                className={`${
                  userLFocus && lname && !validLname ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - 2 to 24 characters <br /> - must begin with a letter <br /> -
                must not include numbers <br /> - avoid spaces
              </p>
            </div>
            {/* email */}
            <div className="relative">
              <p className="text-sm text-slate-500">Email:</p>
              <input
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full input-field-fat"
                aria-invalid={validEmail ? "false" : "true"}
                style={validEmail ? { borderColor: "#22c55e" } : {}}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                required
              />
              <p
                id="emailnote"
                className={`${
                  emailFocus && email && !validEmail ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - must include '<span aria-label="at symbol">@</span>' <br /> -
                must have a domain <br /> - must be a valid email <br />
              </p>
            </div>

              {/* dob */}
          <div>
            <p className="text-sm text-slate-500">Date of Birth:</p>
            <DatePicker
              selected={dob}
              maxDate={new Date()}
              yearDropdownItemNumber={75}
              scrollableYearDropdown={true}
              className="input-field-fat"
              onChange={(date) => {
                setDob(date);
              }}
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
              required
            />
          </div>

          {/* user Type */}
          <div>

          <p className="text-sm text-slate-500">Register as:</p>
          <Select
            onChange={(option) => {
              setUserType(option.value);
            }}
            defaultValue={{ value: "0", label: "Employee"}}
            className="input-field-select-fat"
            isSearchable={false}
            styles={customStyles}
            options={employeeOption}
            required
            />
          </div>

          </div>


          <div className="relative">
            <p className="text-sm text-slate-500">Password:</p>
            <div className="relative">
              <input
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPass ? `text` : `password`}
                className="w-full input-field-fat"
                aria-invalid={validPass ? "false" : "true"}
                style={validPass ? { borderColor: "#22c55e" } : {}}
                aria-describedby="passnote"
                onFocus={() => setPassFocus(true)}
                onBlur={() => setPassFocus(false)}
                required
              />
              <i
                className={`${
                  showPass
                    ? `text-slate-50 bg-orange-600`
                    : `text-slate-400 dark:text-slate-600 bg-slate-200 dark:bg-slate-900`
                } showPass`}
                onClick={() => {
                  setShowPass(!showPass);
                }}
              >
                <Eye iconClass={'w-5 h-5'}/>
              </i>
            </div>
            <p
              id="passnote"
              className={`${
                passFocus && !validPass ? `visible` : `invisible`
              } validBox`}
            >
              {" "}
              - min 8 characters <br/> - must include uppercase and lowercase
              letters <br/> - must include a number <br/> 
            </p>
          </div>

          <div className="relative mb-4">
            <p className="text-sm text-slate-500">Re-type Password:</p>
            <input
              defaultValue={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              type="password"
              className="w-full input-field-fat"
              aria-invalid={validConfirm && confirm ? "false" : "true"}
              style={validConfirm && confirm ? { borderColor: "#22c55e" } : {}}
              aria-describedby="confirmnote"
              onFocus={() => setConfirmFocus(true)}
              onBlur={() => setConfirmFocus(false)}
            />
            <p
              id="confirmnote"
              className={`${
                confirmFocus && !validConfirm ? `visible` : `invisible`
              } validBox`}
            >
              {" "}
              - must match the password
            </p>
          </div>

          <button
            type="submit"
            className="inline-flex items-center justify-center w-full gap-2 submit-btn"
            disabled={
              !validFname ||
              !validLname ||
              !validEmail ||
              dob == null ||
              !validPass ||
              !validConfirm
            }
          >
            <UserAdd iconClass='w-6 h-6'/>
            Sign Up
          </button>
        </form>
        <Link to="/login" className="link">
          Have an account? Sign in
        </Link>
      </div>
      </div>

      </div>
    </div>
  )
}

export default Register