import React from 'react'

const NotificationCircle = ({value}) => {
  return value>0?(
    <span className='notificationCircle-wrapper'>
    <span className='animation-wrapper'>
    </span>
    <span className='value-wrapper'>
          {value}
    </span>
</span>
  )
  :
  null
}

export default NotificationCircle