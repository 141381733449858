import React from 'react'
import Avatar from '../layout/Avatar'
import { CategorylogoStorage_LINK } from '../../api/ApiUrl';
import { Pencil, Add } from '../layout/Icons';
import {useNavigate} from "react-router-dom";

const CategoryCard = ({category, addTask, setAddTask}) => {

  const navigate = useNavigate();  

  const categoryEdit=(id)=>{
    navigate(`/category/edit/${id}`);
}

  return (
        <div className='flex items-center justify-between gap-2 mb-1'>

        <div className='flex items-center justify-start gap-2 group'>
        {category.icon?
          <img src={`${CategorylogoStorage_LINK}${category.icon}`} className='w-10 h-10 rounded-full'/>
          :
          <Avatar value={category.name} width='10' height='10' color={'bg-orange-300 dark:bg-orange-800/70'}/>   
          
        }
        <h4 className='text-lg font-bold text-slate-600 dark:text-slate-400'>{category.name}</h4>
        {category.roleId==2?
        <button className='visible icon-btn lg:invisible group-hover:visible' onClick={()=>categoryEdit(category.id)} ><Pencil iconClass={'w-4 h-4'}/></button>
        :
        null
        }
        </div> 
        {category.roleId>=1 &&
        <button className={`plus-add-task ${addTask? `rotate-45`:`rotate-0`}`} onClick={()=>{setAddTask(!addTask)}}><Add iconClass={'w-5 h-5'}/></button>
        }
    </div>
    
  )
}

export default CategoryCard