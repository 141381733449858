import React,{useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import Avatar from '../layout/Avatar';
import CompanyBubble from './CompanyBubble';
import { CompanylogoStorage_LINK } from '../../api/ApiUrl';

import { db } from '../../config/firebaseConfig';
import { onSnapshot, query, where, collection, doc, updateDoc ,startAfter, limit, orderBy, getDocs } from "firebase/firestore";
import { auth } from '../../config/firebaseConfig';
import MsgBox from './MsgBox';
import { onAuthStateChanged } from 'firebase/auth';
import ActionMsg from './ActionMsg';
import { SideBarShrink } from '../layout/Icons';
import UserBubbles from './UserBubbles';

import UserBubbleLoader from '../loaders/UserBubbleLoader';


const Messenger = ({showSidebar, setShowSidebar, setUnread}) => {
    const companies=useSelector((state)=>(state.company.value))
    const user=useSelector((state)=>(state.user.value))

    const [activeCompany,setActiveCompany]=useState({id:-1});
    
    const [roomUsers, setRoomUsers]=useState([]);

    const [messages, setMessages]=useState([]);
    const [lastKey, setLastKey]=useState()
    const [load, setLoad]=useState(true);
    const [EndData, setEndData]=useState(false);
    const [isEmptyMsg, setIsEmptyMsg]=useState(false);
    const [uid, setUid]=useState(null);
    const co=localStorage.getItem('activeCo')
    useEffect(()=>{
       const authChecker=onAuthStateChanged(auth, (user) => {
            if (user) {
             
              const uid = user.uid;
              setUid(uid)
            } else {
              setUid(null)
            }
          });
          return()=>{
            authChecker() 
          }
    },[])
    
   

    const setActive=(co)=>{
        localStorage.setItem('activeCo', co.id)
        setActiveCompany(co);
    }


    useEffect(()=>{
        if(companies.length>0){
            if(co){
                if(companies.filter(c=>c.id==co).length>=0){
                    setActiveCompany(companies.filter(c=>c.id==co)[0]);
                }
            }else{
                setActive(companies[0])
            }
        }

        return()=>{
            setActiveCompany([])
        }
    },[companies])



 
    useEffect(()=>{
            
            const q=query(collection(doc(db, 'Rooms', `${activeCompany.id}`), 'messages'), orderBy('createdAt','desc'), limit(30));

            const roomUserRef=query(collection(doc(db, 'Rooms', `${activeCompany.id}`), 'users'), limit(30));

            const listenMessages=onSnapshot(q, (snapShot)=>{
               
                        if(snapShot.size>0 && activeCompany.id>0){
                            const data=snapShot.docs.map(doc=>{return {...doc.data(), id: doc.id} })
                            setMessages(data);
                            const last=snapShot.docs[snapShot.docs.length-1]
                            setLastKey(last)
                            var unreadData=data.filter(d=>d.msgType==0).map(m=>({
                                ...m,
                                read:m.read.filter(r=>r==user.id)
                            }))
                            unreadData=unreadData.filter((d)=>{return d.read.length==0})
                            setUnread(unreadData.length);
                           
                           
                            if(load){
                                setLoad(false)
                            }
                            setIsEmptyMsg(false)
                            setEndData(false)
                        }else{
                            setIsEmptyMsg(true)
                            setMessages([])
                        }
                    })

             const listenRoomUsers=onSnapshot(roomUserRef, (snapShot)=>{
                if(snapShot.size>0 && activeCompany.id>0){
                    const data=snapShot.docs.map(doc=>{return {...doc.data(), id: doc.id}})
                    if(data.length>0){
                        setRoomUsers(data);
                    }
                }
             })       
    
        return ()=>{
            listenMessages()
            listenRoomUsers()
            setMessages([])
        } 
    },[activeCompany])

    

    const loadmoreMsg=(snapShot)=>{
        const noData=snapShot.size===0;
        if(!noData){
            const data=snapShot.docs.map(doc=>{return {...doc.data(), id: doc.id} })
            const last=snapShot.docs[snapShot.docs.length-1]
            setMessages((messages)=>[...messages, ...data]);
            setLastKey(last)
            setEndData(false)
        }else{
            setEndData(true)

        }
        setLoad(false);
    }

    const fetchMoreMsg=async()=>{
       const q=query(collection(doc(db, 'Rooms', `${activeCompany.id}`), 'messages'), orderBy('createdAt', 'desc'), startAfter(lastKey), limit(30))
        setLoad(true);
        const loadedMsg= await getDocs(q); 
        loadmoreMsg(loadedMsg)
    }

  return (
    <div className='p-0.5'>
        
        {/* <button onClick={()=>{playTone()}}>check</button> */}

        {companies.length>1?
        <div className='flex items-center justify-start gap-2 mb-2'>
        {companies.map((co)=>(
            <CompanyBubble 
            key={co.id} 
            co={co} 
            active={activeCompany.id} 
            setActive={setActive}/>
            )
            )}

            
        </div>
        :
        null
        }
        
        {activeCompany.id>0 &&
        <div className='p-1 rounded-t-md rounded-b-3xl bg-slate-200 dark:bg-slate-900'>
        
            <div className='py-2' >
            
            <div className='flex items-center justify-between gap-1'>


            <div className='flex items-center justify-start gap-2'>
                {activeCompany.logo?
                    <img src={`${CompanylogoStorage_LINK}${activeCompany.logo}`} className='w-8 h-8 rounded-full'/>
                    :
                    <Avatar value={activeCompany.name} width='8' height='8' color={'bg-orange-300 dark:bg-orange-800/70'}/>   
                    
                }
                <h4 className='font-bold text-normal text-slate-600 dark:text-slate-400'>{activeCompany.name}</h4>
            </div> 
            
            <button className='block ml-auto transition-all duration-150 ease-in-out 2xl:hidden text-slate-600 dark:text-slate-400 hover:text-orange-500 dark:hover:text-orange-600' 
                onClick={()=>setShowSidebar(!showSidebar)}>
                <SideBarShrink iconClass={'w-6 h-6'}/>
            </button>
                </div>
             </div>

             {roomUsers.length>1?
                <div className='flex items-center justify-end gap-3 p-2 rounded-3xl bg-slate-50 dark:bg-slate-800'> 
                    {roomUsers.map((user,i)=>(
                        <UserBubbles key={user.id} user={user}/>
                    ))}
                </div>
                :
              <UserBubbleLoader/>
            }


        <MsgBox
        messages={messages} 
        uid={uid} 
        fetchMoreMsg={fetchMoreMsg}
        load={load}
        setLoad={setLoad}
        EndData={EndData}
        isEmptyMsg={isEmptyMsg}
        roomId={activeCompany.id}
        compLength={companies.length}
        />
        
        </div>
        }
         {activeCompany.id>0 &&
        <ActionMsg roomId={activeCompany.id}/>
         }

    </div>
  )

}

export default Messenger