import React, {useState, useEffect} from 'react'
import { avatarStorage_LINK, UPDATEASSIGNEE_URL } from '../../../api/ApiUrl';
import { Menu, Transition } from '@headlessui/react'
import toast from 'react-hot-toast';
import axios from "../../../api/axios";
import AssigneeList from './AssigneeList'

import { Add, UserAdd } from '../Icons';
import Avatar from '../Avatar';
import { useSelector } from 'react-redux';
import { notify } from '../../../config/firebaseConfig';

const Assignee = ({employeelist, assigneeList, jobId, catId,compId, user, setActivities, activities}) => {

  
  const [notAssignedEmployees, setNotAssignedEmployees]=useState([]);
  const [changedAssignee, setChangedAssignee]=useState(false);
  const [assinged, setAssigned]=useState(assigneeList);
  const categories=useSelector((state)=>state.category.value);
  const [tempActivity, setTempActivity]=useState([]);
  const [notifications, setNotifications]=useState([])
  useEffect(()=>{
    var employeeData=employeelist.filter(t=>{return (t.catId==catId && !assigneeList.some(e => e.userId == t.value))})
    setNotAssignedEmployees(employeeData);
    
    return()=>{
      setNotifications([]);
    }
},[])


const assignEmployee=(em)=>{
  console.log(em)
  var newAssignee={
    id:em.value,
    Fname:em.Fname,
    Lname:em.Lname,
    image:em.image,
    jobId:jobId,
    progress:0,
    userId:em.value,
  }
  var act={
    jobId:jobId,
    userName:`${user.Fname} ${user.Lname}`,
    action:'asigned',
    activity:`${em.Fname} ${em.Lname} to the task`
  }
  const filterDups=notifications.filter(f=>f.receiverId!=em.value)
  var body=''
  if(user.id==em.value){
    body=`You assigned yourself to the Task`
  }else{
    body=`${user.Fname} ${user.Lname} assigned you to the task`
  }

  var ntfn={
  receiverId:em.value,
  type:1,
  title:`Task [#${jobId}] Assigned to you`,
  body:body
  }

  setTempActivity([...tempActivity, act]);
  setAssigned([...assinged, newAssignee]);
  
  setNotifications([...filterDups, ntfn])
 
  setNotAssignedEmployees(notAssignedEmployees.filter(t=>t.value!=em.value))
  setChangedAssignee(true);

}

const removeAsignee=(em)=>{

  var setOldData={
    Fname: em.Fname,
    Lname: em.Lname,
    catId: catId,
    image: em.image,
    label: `${em.Fname} ${em.Lname}`,
    value: em.id,

  }
  var act={
    jobId:jobId,
    userName:`${user.Fname} ${user.Lname}`,
    action:'removed',
    activity:`${em.Fname} ${em.Lname} from the task`
  }

  const filterDups=notifications.filter(f=>f.receiverId!=em.userId)
  var ntfn={
    receiverId:em.userId,
    title:`Your assignment for Task [#${jobId}] is removed`,
    body:`${user.Fname} ${user.Lname} removed you from the task assignment`
  }

  setNotifications([...filterDups, ntfn])

  setTempActivity([...tempActivity, act]);

  setAssigned(assinged.filter(t=>t.id!=em.id));
  setNotAssignedEmployees([...notAssignedEmployees, setOldData]);
  setChangedAssignee(true)
}

const updateAssignee = async()=>{
  try{

    await axios.post(UPDATEASSIGNEE_URL, {assignList:JSON.stringify(assinged), taskId:jobId, acts:JSON.stringify(tempActivity)}).then((res)=>{
      setAssigned(res.data.assigneeList); 
      setActivities(res.data.activities)
      
      var cat=categories.filter(c=>c.id==catId)[0]
        var iconLink=''
        var iconType=-1
        if(cat.icon!=null){
          iconLink=`${cat.icon}`
          iconType=0
        }else{
          iconLink=`${cat.name}`
          iconType=1
        }
      var onClickLink=`/category/${compId}/${catId}/${jobId}`
      notifications.filter(n=>n.recieverId!=user.id).map(s=>{
        
        notify(s.title, s.body, onClickLink, s.receiverId, iconLink, iconType)
      })
      setNotifications([])
      toast.success('Asignee Updated!');
      setChangedAssignee(false);
    })
  }catch(e){
    if(!e?.response){
      toast.error("No Server Response!");
      console.log(e);
  }else{
      console.log(e);
      toast.error("Oops! An error occured!");
    }
  }
}

  return (
    <div>
      
        <div className='flex items-center justify-between gap-1 mb-2 border-b border-slate-400 dark:border-slate-700'>
        <h4 className='text-lg font-semibold text-slate-600 dark:text-slate-400'>Assignees</h4>
        <Transition
         show={changedAssignee}
         enter="transition-all translate duration-150"
         enterFrom="opacity-0 translate-x-6"
         enterTo="opacity-100 translate-x-0"
         leave="transition-all translate duration-150"
         leaveFrom="opacity-100 translate-x-0"
         leaveTo="opacity-0 translate-x-6"
        >
        <button className='accept-btn' onClick={()=>updateAssignee()} >Save</button>
        </Transition>

        </div>

        <div className='inline-flex flex-wrap items-start justify-start gap-1'>
          {assinged.map((i, index)=>(
            <AssigneeList key={index} 
            assignee={i} 
            jobId={jobId}
            user={user}
            removeAsignee={removeAsignee} 
            setChangedAssignee={setChangedAssignee}
            tempActivity={tempActivity}
            setTempActivity={setTempActivity}
            notifications={notifications}
            setNotifications={setNotifications}/>
          ))}
        </div>
        
        <Menu>
       <div className='inline-flex gap-1 ml-1'>
       {notAssignedEmployees.length>0?
        <Menu.Button className='p-1 transition-all duration-150 ease-in-out rounded-full bg-slate-300 dark:bg-slate-700 text-slate-400 dark:text-slate-600 hover:text-slate-600 dark:hover:text-slate-500'>
         <UserAdd iconClass={'w-8 h-8 p-1'}/>
        </Menu.Button>
        :
        null
       }
        </div>
        <Menu.Items>
        <div className='overflow-y-scroll rounded-md shadow-lg w-44 top-5 bg-slate-100 dark:bg-slate-700 shadow-slate-400 dark:shadow-slate-900 max-h-48'>
        {notAssignedEmployees.map((em)=>(
          <Menu.Item key={em.value}>
              <button onClick={()=>assignEmployee(em)} className='flex items-center justify-start w-full gap-2 px-2 py-1 text-sm font-semibold text-left transition-all duration-150 ease-in-out rounded-md text-slate-500 hover:bg-slate-200 dark:hover:bg-slate-900'>
                    {em.image?
                      <img src={`${avatarStorage_LINK}${em.image}`} className='w-5 h-5 rounded-full' alt={'avatar'} />
                        :
                        <Avatar value={`${em.label}`} width={'5'} height={'5'} color={'bg-blue-200 dark:bg-blue-800'}/>
                    }
                  {em.label}
                </button>
          </Menu.Item>
        ))}
        </div>
        </Menu.Items>
        </Menu>
          
    </div>
  )
}

export default Assignee