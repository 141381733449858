import React,{useState, useEffect} from 'react'

import { useSelector } from 'react-redux';
import { auth, submitMsg } from '../../config/firebaseConfig';
import Picker from 'emoji-picker-react';
import { AddImage, Close, Mike, Smile } from '../layout/Icons';
import { serverTimestamp } from 'firebase/firestore';
import axios from '../../api/axios';
import { UPLOAD_MSG_IMAGE } from '../../api/ApiUrl';
const MsgForm = ({roomId, scrollHere, replymsgId, setShowReply, setReplyMsg, setReplyMsgId}) => {

    const [value,setValue]=useState('');
    const user=useSelector((state)=>(state.user.value));
    const [showEmoji, setShowEmoji]=useState(false);
    const [announce, setAnnounce]=useState(false);

    const [files, setFiles]=useState([])
    const [dragActive, setDragActive] = React.useState(false);

    const inputRef = React.useRef(null);
  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
       var data=e.dataTransfer.files
       Array.from(data).forEach(file => setFiles(files=>[...files, Object.assign(file, {
        preview: URL.createObjectURL(file)})]));
    }
  };

  const uploadFiles = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
        var data=e.target.files
        Array.from(data).forEach(file => setFiles(files=>[...files, Object.assign(file, {
            preview: URL.createObjectURL(file)})]));
    }
  };


  const onButtonClick = () => {
    inputRef.current.click();
  };
  
    const removeImage=(name)=>{
        var data=files.filter(f=>f.name!=name);
        setFiles(data)
    }



    const inputFocus = React.useRef();
    useEffect(()=>{
      if(replymsgId!=null){
        inputFocus.current.focus();
      }
    },[replymsgId])




    const images=files.map((file)=>(
        <div key={file.name}>
            <div className='relative group'>
                <button onClick={()=>{removeImage(file.name)}} className='group-hover:block hidden absolute top-0.5 right-0.5 text-white bg-red-500 rounded-full'><Close iconClass={'w-3 h-3'}/></button>
                <img src={file.preview} className='object-cover w-12 h-12 rounded-md' alt='preview'/>
            </div>
        </div>
    ))



    const sendMsg=async(e)=>{
        e.preventDefault();
        
        var msgType=0;
        if(announce){
            msgType=4;
        }else if(images.length>0){
            msgType=5;
        }
  

        const uName=`${user.Fname} ${user.Lname}`
        var photoUrl=null
        if(user.image!=null){
            photoUrl=user.image
        }
        const {uid}=auth.currentUser;

        if(images.length>0){
            
            const formData = new FormData();
           
            formData.append("id", user.id);
            files.forEach((img)=>{formData.append('file[]', img)})
            // formData.append("images", files);
            const res=await axios.post(UPLOAD_MSG_IMAGE, formData, {headers: { 'content-type': 'multipart/form-data' }})
            var imagesName=res.data.imgNames;
            var data={
                createdAt:serverTimestamp(),
                roomId:roomId,
                msg:value,
                photoUrl:photoUrl,
                uName:uName,
                uid:uid,
                msgType:msgType,
                imgs:imagesName,
                read:[user.id],
                userId:user.id,
                replyMsg:replymsgId,
            }
            submitMsg(roomId,data) 
            setFiles([]);
        }else{

            var data={
            createdAt:serverTimestamp(),
            roomId:roomId,
            msg:value,
            photoUrl:photoUrl,
            uName:uName,
            uid:uid,
            msgType:msgType,
            read:[user.id],
            userId:user.id,
            replyMsg:replymsgId,
            }
        
        submitMsg(roomId,data)
        
    }
        
        
        setValue('')
        setShowEmoji(false);
        setAnnounce(false);
        setShowReply(false);
        setReplyMsg(-1);
        setReplyMsgId(null);
        scrollHere.current.scrollIntoView({behavior:'smooth'})
       
    }
    const appendEmoji=(e, emoji)=>{
        setValue(`${value}${emoji.emoji}`)
    }
  return (
    <div className='relative mt-1'>
        <div className={`flex items-center flex-wrap justify-start gap-1 ${images.length>0? 'p-1': 'p-0'}  rounded-t-md bg-slate-300 dark:bg-slate-700`}>
        {images}
        </div>
        
        <div className={`msg-input-wrapper ${images.length>0? `rounded-b-3xl `: `rounded-3xl`}`} >
        <form onSubmit={(e)=>sendMsg(e)} className='flex items-center col-span-8 gap-1' onDragEnter={handleDrag}>
        <label htmlFor='images' className='w-5 h-5'>
            <input ref={inputRef}  type={'file'} name='images' accept='image/png, image/gif, image/jpeg, image/jpg' className='hidden' onChange={uploadFiles}  multiple={true}/>
        <button className='text-slate-400 dark:text-slate-600 hover:text-orange-500' type='button' onClick={onButtonClick}> <AddImage iconClass={'w-5 h-5'}/></button>
        </label>

        {dragActive?
         <div 
         onDragEnter={handleDrag} 
         onDragLeave={handleDrag} 
         onDragOver={handleDrag} 
         onDrop={handleDrop}
         className='w-full text-sm font-semibold text-slate-400 dark:text-slate-600'>
            drop images here
        </div>
        
        :
        <input
        type="text"
        value={value}
        onChange={(e)=>{setValue(e.target.value)}}
        placeholder="Aa"
        className='msg-input'
        ref={inputFocus}
        />
        }
 
       

        <button type='submit'></button>
        </form>
        {images.length>0?
        <div></div>
        :
        <button type='button' className={`${announce? `text-orange-400 dark:text-orange-600 ` : `text-slate-400 dark:text-slate-600 `}`} onClick={()=>{setAnnounce(!announce)}}><Mike iconClass={'w-6 h-6'}/></button>
        }
        <button type='button' className={`${showEmoji? `text-orange-400 dark:text-orange-600 ` : `text-slate-400 dark:text-slate-600 `}`} onClick={()=>{setShowEmoji(!showEmoji)}}><Smile iconClass={'w-6 h-6'}/></button>
        </div>

     
        <div className={`absolute z-[99] right-0 -top-[35vh] ${showEmoji? `scale-100` : `scale-0`} origin-bottom-right transition-all duration-150 ease-in-out`}>
            <Picker 
            onEmojiClick={appendEmoji} 
            searchPlaceholder={'search emoji'} 
            disableSkinTonePicker={true}
            native={true}/>
        </div>
        
    </div>
  )
}

export default MsgForm