import React from 'react'
import Avatar from '../layout/Avatar'
import { CompanylogoStorage_LINK } from '../../api/ApiUrl'
const CircleCompanyFilterButton = ({data, setCompanyId, companyId,setFilterCompany, setIsclearable}) => {
  return (
    <div>
    <button className={`p-0.5 rounded-full transition-all duration-150 ease-in-out ${companyId==data.id? `ring-2 ring-offset-1 ring-orange-500 ring-offset-slate-200 dark:ring-offset-slate-900`:`ring-0 ring-transparent`}`} 
    onClick={()=>{setFilterCompany(data.id); setCompanyId(data.id); setIsclearable(true)}}>
    {data.logo?
        <img src={`${CompanylogoStorage_LINK}${data.logo}`} className='rounded-full w-7 h-7 bg-slate-400 dark:bg-slate-900' alt='icon'/>
        :
        <Avatar value={data.name} width={'7'} height={'7'} color={'bg-orange-400 p-1 dark:bg-orange-900 text-slate-50 '}/>
    }
    </button>
</div>
  )
}

export default CircleCompanyFilterButton