import React,{useState, useEffect}  from 'react'
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { CUSTOMER_URL, GETCOMPANYJOBS_URL, GETDATA_URL } from '../../api/ApiUrl';
import CategoryCardLoader from '../loaders/CategoryCardLoader';
import CompanyCard from '../Tasks/CompanyCard';
import { Transition } from '@headlessui/react'
import CreateTaskForm from '../Tasks/CreateTaskForm';
import Tasklist from '../Tasks/Tasklist';
import { setCustomer } from '../../features/customers';


const CompanyTasks = () => {

    let {compId} = useParams();
    const categs=useSelector((state)=>state.category.value);
    const companies=useSelector((state)=>state.company.value);
    const tasks=useSelector((state)=>state.allTasks.value); 
    const [company, setCompany]=useState(null);
    const [jobStatuses,setJobStatuses]=useState([]);
    const [paymentOptions,setPaymentOptions]=useState([]);
    const [paymentMethods,setPaymentMethods]=useState([]);
    const [jobTypes,setJobTypes]=useState([]);
    const [shipments,setShipments]=useState([]);
    const [productions,setProductions]=useState([]);
    const [quotes, setQuotes]=useState([]);
    const [checklists, setChecklists]=useState([]);
    const [employeelist,setEmployeelist]=useState([]);

    const [isLoad, setLoad]=useState(true);
    const [addTask, setAddTask]=useState(false);


    // const getJobs = async(id)=>{

    //     try{
    //      await axios.get(`${GETCOMPANYJOBS_URL}${id}`).then((res)=>{
          
    //       setTasks(res.data.tasks);
    //      })
    //      setTaskLoad(false);
    //     }catch(e){
    //       if(!e?.response){
    //               toast.error("No Server Response!");
    //               console.log(e);
    //           }else{
    //               if(e.response.status===429){
    //                 toast.error('too many requests! please try again later')
    //               }else{
    //                 console.log(e);
    //                 toast.error("Oops! An error occured!");
    //               }
    //       }
    //     }
    // }
    
    const [customerData, setCustomerData]=useState([]);

    const getCustomers = async(companyId) => {
      const res= await axios.get(`${CUSTOMER_URL}${companyId}`)
      setCustomerData(res.data.customers);
    }

    const getDefaultData= async(ids)=>{
        try{
           await axios.post(GETDATA_URL, {ids:ids}).then((res)=>{
            setJobStatuses(res.data.statuses)
            setPaymentOptions(res.data.paymentOptions)
            setPaymentMethods(res.data.paymentMethods)
            setJobTypes(res.data.jobTypes)
            setShipments(res.data.shipments)
            setProductions(res.data.productions)
            setQuotes(res.data.quotes)
            setChecklists(res.data.checklists)
            setEmployeelist(res.data.employees)    
            setLoad(false)
           })
      
          }catch(e){
            if(!e?.response){
              toast.error("No Server Response!");
              console.log(e);
          }else{
            if(e.response.status===429){
              toast.error('too many requests! please try again later')
            }else{
              console.log(e);
              toast.error("Oops! An error occured!");
            }
          }
        }
    }


    useEffect(()=>{
    var comp=companies.filter((co)=>co.id===parseInt(compId))[0];
    setCompany(comp);
    if(categs.length>0){
        var ids=categs.filter(c=>c.companyId===parseInt(compId)).map((c)=>c.id);
        getCustomers(compId);
        getDefaultData(ids);
        // getJobs(compId);
    }

    setLoad(false);
    return ()=>{
        setCompany(null);
        setJobStatuses([]);
        setPaymentOptions([]);
        setPaymentMethods([]);
        setJobTypes([]);
        setShipments([]);
        setProductions([]);
        setQuotes([]);
        setChecklists([]);
        setEmployeelist([]);
        // setTasks([])
        setLoad(true)
        // setTaskLoad(true)
    }

    },[compId, categs])


  return !isLoad?(
    <div className='main-bodyLayout'>
        <div className={`p-1 rounded-t-md bg-slate-50 dark:bg-slate-800 ${addTask? `mb-9`: `mb-0`} `}>
        {company!=null?
        <CompanyCard company={company} addTask={addTask} setAddTask={setAddTask}/>
        :
        <CategoryCardLoader/>
        }

        {company!=null?

            <div className={`${addTask? `block h-full translate-y-0`: `hidden h-0 -translate-y-6`} transition-all duration-150 ease-in-out  transform relative z-40`}
            >
              
                <CreateTaskForm
                isCategory={false}
                compId={compId}
                customerData={customerData}
                jobStatuses={jobStatuses}
                paymentOptions={paymentOptions}
                paymentMethods={paymentMethods}
                jobTypes={jobTypes}
                shipments={shipments}
                productions={productions}
                quotes={quotes}
                checklists={checklists}
                employeelist={employeelist}
                setAddTask={setAddTask}
                />

            </div>

            :
            null
        }    

        </div>
        {tasks.length>0?
            <Tasklist
            addTask={addTask}
            key={compId}
            isCategory={false}
            tasks={tasks.filter(t=>t.companyId==compId)}
            compId={compId}
            jobStatuses={jobStatuses}
            paymentOptions={paymentOptions}
            paymentMethods={paymentMethods}
            jobTypes={jobTypes}
            shipments={shipments}
            productions={productions}
            quotes={quotes}
            checklists={checklists}
            employeelist={employeelist}
            customerData={customerData}
            fromDoneTask={false}
            title={null}
            />
          :
          null
        }
            
    </div>
  )
  :
  null
}

export default CompanyTasks