import React, {useState, useEffect} from 'react'
import { getMyToken, requireRefresh } from '../config/firebaseConfig';

import { onSnapshot, query, where, collection, doc, updateDoc , limit } from "firebase/firestore";
import {db} from '../config/firebaseConfig'
import { useSelector } from 'react-redux';

import { auth } from '../config/firebaseConfig';

import { onAuthStateChanged } from 'firebase/auth';


const Notifications = ({getCompany, getCategories, getAllJobs}) => {
    const [isTokenFound,setTokenFound]=useState(false);
   
    const user=useSelector((state)=>state.user.value);
    const [uid, setUid]=useState(null);



    useEffect(()=>{
      let data;
      async function tokenFound(){
          data= await getMyToken(setTokenFound)
          return data
      }
      tokenFound()

  },[setTokenFound])


useEffect(()=>{

 const authChecker= onAuthStateChanged(auth, (user) => {
    if (user) {
      
      const uid = user.uid;
      setUid(uid)
    } else {
      setUid(null)
    }
  });

  return()=>{
    authChecker()
  }
},[])

      useEffect(()=>{
        const listenUserState = onSnapshot(doc(db, "UserState", `${user.id}`), (doc) => {
          const uState=doc.data();
          if(uState.status==1 && uState.idle==true && uState.uid!==uid){
     
            if(uState.updateTask==true){
              getAllJobs()
              requireRefresh(user.id, false, uState.updateCategory ,uState.updateComp)
              
            }
            if(uState.updateCategory==true){
              getCategories()
              requireRefresh(user.id, uState.updateTask, false ,uState.updateComp)
            }
            if(uState.updateComp==true){
              getCompany()
              requireRefresh(user.id, uState.updateTask, uState.updateCategory, false)
            }
          }
      });

      return ()=>{
        listenUserState()
      }
      },[])


        useEffect(()=>{
         
          const q = query(collection(db, "Notifications"), where("receiverId", "==", user.id), where("delivered","==", false), limit(5));

          const listenNotification=onSnapshot(q, (querySnapshot)=>{
            querySnapshot.forEach((doc) => {
                      const data=doc.data();
                      if(Notification.permission==='granted'){
                        if(data.delivered==false){
                            showNotification(data.title, data.body, data.onClickLink)
                        }
                          
                      }
                      else if(Notification.permission==='denied'){
                        Notification.requestPermission().then(permission=>{
                          if(permission==='granted'){
                            if(data.delivered==false){
                              showNotification(data.title, data.body, data.onClickLink)
                            }
                          }
                        })
                      }
                    readNotification(doc.id)
              })
              

            });

          return () => {
            listenNotification()
           
          }

        },[uid])


        

      const showNotification=(title, body, link)=>{
        const notification=new Notification(title,{
          body:body,
          icon:'/logo512.png'
        })
        notification.onclick=(e)=>{
          window.location.href=link
        }
       
      }

      const readNotification=async(id)=>{
        const notifyDoc = doc(db, "Notifications", id);
        await updateDoc(notifyDoc, {
          delivered: true
        });
      }




  return (
    <></>
  )
}

export default Notifications