import React, { useEffect,useRef, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import axios from '../../api/axios';
import toast from 'react-hot-toast';
import { Close } from '../layout/Icons';

import { UPDATEPROFILEINFO_URL } from '../../api/ApiUrl';
import { setUser } from '../../features/user';

const user_REGEX=/^[a-zA-Z][a-zA-Z]{1,23}$/;
const email_REGEX=/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const EditProfile = ({isEdit, setIsEdit}) => {

    const dispatch=useDispatch();
    const user=useSelector((state)=>state.user.value);

    const [fname,setFname]=useState(user.Fname);
    const [validFname, setValidFname]=useState(false);
    const [userFFocus, setUserFFocus]=useState(false);

    const [lname,setLname]=useState(user.Lname);
    const [validLname, setValidLname]=useState(false);
    const [userLFocus, setUserLFocus]=useState(false);


    const [dob, setDob] = useState(moment(user.dob).toDate());

    const [email, setEmail]=useState(user.email);
    const [validEmail, setValidEmail]=useState(false);
    const [emailFocus, setEmailFocus]=useState(false);
    const userRef = useRef();
    
    useEffect(()=>{
        setValidFname(user_REGEX.test(fname));
    },[fname])

    useEffect(()=>{
        setValidLname(user_REGEX.test(lname));
    },[lname])

    useEffect(()=>{
        setValidEmail(email_REGEX.test(email));
    },[email])



    const updateUser = async (e)=>{
        e.preventDefault();
        const toastId = toast.loading('Updating...', {icon: '⏳'});
        const fcheck = user_REGEX.test(fname);
        const lcheck = user_REGEX.test(lname);
        const emailCheck = email_REGEX.test(email.toLowerCase());
        const dopbCheck = dob != null;

        if (
            !fcheck ||
            !lcheck ||
            !emailCheck ||
            !dopbCheck 
          ) {
            toast.error("Invalid Entry");
            return;
          }

          try{
            var data = JSON.stringify({
                id:user.id,
                Fname: fname,
                Lname: lname,
                dob: moment(dob).format("YYYY-MM-DD"),
                email: email,
              });

              await axios.post(UPDATEPROFILEINFO_URL, data).then((res)=>{
                localStorage.setItem("auth_user", JSON.stringify(res.data.user));
                dispatch(setUser(res.data.user));
                toast.success(`information Updated!`);
                toast.dismiss(toastId);
              })

            setFname("");
            setValidFname(false);
            setUserFFocus(false);
            setLname("");
            setValidLname(false);
            setUserLFocus(false);
            setDob(null);
            setEmail("");
            setValidEmail(false);
            setEmailFocus(false);
            setIsEdit(!isEdit);

          }catch(e){
            if (!e?.response) {
                toast.error(["No Server Response!"]);
                toast.dismiss(toastId);
            }
            else {
                var resErrors = e.response.data.errors;
                if (resErrors.email.length > 0) {
                  toast.error(resErrors.email[0]);
                  toast.dismiss(toastId);
                } else {
                  toast.error(resErrors);
                  toast.dismiss(toastId);
                }
            }
          }
    }

  return (
    <div className='w-full mx-2 card lg:w-1/3'>
        <div className='flex items-center justify-between gap-2'>
        <p className='font-semibold text-center text-slate-600 dark:text-slate-400'>Edit Profile</p>
        <button className='reject-btn' onClick={()=>{setIsEdit(!isEdit)}}><Close iconClass={'w-4 h-4'}/></button>
        </div>
        
        <form onSubmit={(e)=>{updateUser(e)}}>
        <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
            {/* first name */}
            <div className="relative">
              <p className="text-sm text-slate-500">First Name:</p>
              <input
                defaultValue={fname}
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setFname(e.target.value)}
                type="text"
                className="w-full input-field-fat"
                aria-invalid={validFname ? "false" : "true"}
                style={validFname ? { borderColor: "#22c55e" } : {}}
                aria-describedby="uFnote"
                onFocus={() => setUserFFocus(true)}
                onBlur={() => setUserFFocus(false)}
                required
              />
              <p
                id="uFnote"
                className={`${
                  userFFocus && fname && !validFname ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - 2 to 24 characters <br /> - must begin with a letter <br /> -
                must not include numbers <br /> - avoid spaces
              </p>
            </div>
            {/* last name */}
            <div className="relative">
              <p className="text-sm text-slate-500">Last Name:</p>
              <input
                defaultValue={lname}
                autoComplete="off"
                onChange={(e) => setLname(e.target.value)}
                type="text"
                className="w-full input-field-fat"
                aria-invalid={validLname ? "false" : "true"}
                style={validLname ? { borderColor: "#22c55e" } : {}}
                aria-describedby="uLnote"
                onFocus={() => setUserLFocus(true)}
                onBlur={() => setUserLFocus(false)}
                required
              />
              <p
                id="uLnote"
                className={`${
                  userLFocus && lname && !validLname ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - 2 to 24 characters <br /> - must begin with a letter <br /> -
                must not include numbers <br /> - avoid spaces
              </p>
            </div>

            {/* email */}
            <div className="relative">
              <p className="text-sm text-slate-500">Email:</p>
              <input
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full input-field-fat"
                aria-invalid={validEmail ? "false" : "true"}
                style={validEmail ? { borderColor: "#22c55e" } : {}}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                required
              />
              <p
                id="emailnote"
                className={`${
                  emailFocus && email && !validEmail ? `visible` : `invisible`
                } validBox`}
              >
                {" "}
                - must include '<span aria-label="at symbol">@</span>' <br /> -
                must have a domain <br /> - must be a valid email <br />
              </p>
            </div>
            {/* dob */}
          <div>
            <p className="text-sm text-slate-500">Date of Birth:</p>
            <DatePicker
              selected={dob}
              maxDate={new Date()}
              className="w-full input-field-fat"
              isClearable
              onChange={(date) => {
                setDob(date);
              }}
              required
            />
          </div>

          </div>

          <button className='inline-flex items-center justify-center w-full gap-2 mt-4 submit-btn' disabled={
              !validFname ||
              !validLname ||
              !validEmail ||
              dob == null
            }>
             Save
              </button>
  
        </form>
    </div>
  )
}

export default EditProfile