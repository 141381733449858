import React,{useState, useEffect,lazy, Suspense} from 'react'
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import {  CUSTOMER_URL, GETDATA_URL, GETJOBS_URL } from '../../api/ApiUrl';
import CategoryCard from '../Tasks/CategoryCard';
import CategoryCardLoader from '../loaders/CategoryCardLoader';
import { Transition } from '@headlessui/react'

import CreateTaskForm from '../Tasks/CreateTaskForm';
import Tasklist from '../Tasks/Tasklist';

const CategoryTasks = () => {

  let {catId} = useParams();
  let {compId}=useParams();
  let {activeJob}=useParams();
    const categs=useSelector((state)=>state.category.value);
    const tasks=useSelector((state)=>state.allTasks.value); 

    const [category, setCategory]=useState(null);
    const [jobStatuses,setJobStatuses]=useState([]);
    const [paymentOptions,setPaymentOptions]=useState([]);
    const [paymentMethods,setPaymentMethods]=useState([]);
    const [jobTypes,setJobTypes]=useState([]);
    const [shipments,setShipments]=useState([]);
    const [productions,setProductions]=useState([]);
    const [quotes, setQuotes]=useState([]);
    const [checklists, setChecklists]=useState([]);
    const [employeelist,setEmployeelist]=useState([]);

    
    const [isLoad, setLoad]=useState(true);
    const [addTask, setAddTask]=useState(false);
 


    const [customerData, setCustomerData]=useState([]);

    const getCustomers = async(companyId) => {
      const res= await axios.get(`${CUSTOMER_URL}${companyId}`)
      setCustomerData(res.data.customers);
    }

    const getDefaultData= async(ids)=>{
        try{
           await axios.post(GETDATA_URL, {ids:ids}).then((res)=>{
            
            setJobStatuses(res.data.statuses)
            setPaymentOptions(res.data.paymentOptions)
            setPaymentMethods(res.data.paymentMethods)
            setJobTypes(res.data.jobTypes)
            setShipments(res.data.shipments)
            setProductions(res.data.productions)
            setQuotes(res.data.quotes)
            setChecklists(res.data.checklists)
            setEmployeelist(res.data.employees)   
            setLoad(false)
           })
      
          }catch(e){
            if(!e?.response){
              toast.error("No Server Response!");
              console.log(e);
          }else{
            if(e.response.status===429){
              toast.error('too many requests! please try again later')
            }else{
              console.log(e);
              toast.error("Oops! An error occured!");
            }
          }
        }
    }

    useEffect(()=>{
      
    const getCategory=(id)=>{
        if(categs!=null){
            setCategory(categs.filter((cat)=>cat.id===parseInt(id))[0])
            getCustomers(compId);
            getDefaultData([parseInt(id)]);
        }
    }
    getCategory(catId);

    return ()=>{
        setLoad(true);
        setCategory(null);
        setJobStatuses([]);
        setPaymentOptions([]);
        setPaymentMethods([]);
        setJobTypes([]);
        setShipments([]);
        setProductions([]);
        setQuotes([]);
        setChecklists([]);
        setEmployeelist([]);
    }
},[catId, categs])

  return !isLoad?(

    <div className='main-bodyLayout'>
        <div className={`p-1 rounded-t-md bg-slate-50 dark:bg-slate-900 ${addTask? `mb-9`: `mb-0`} `}>
            {category!=null?
            <CategoryCard category={category} addTask={addTask} setAddTask={setAddTask}/>
            :
            <CategoryCardLoader/>
            }
        
          {category!=null?
             <div className={`${addTask? `block h-full translate-y-0`: `hidden h-0 -translate-y-6`} transition-all duration-150 ease-in-out  transform relative z-40`}
             >
                <CreateTaskForm
                key={category.id}
                isCategory={true}
                compId={category.companyId}
                customerData={customerData}
                category={category}
                catId={catId}
                jobStatuses={jobStatuses}
                paymentOptions={paymentOptions}
                paymentMethods={paymentMethods}
                jobTypes={jobTypes}
                shipments={shipments}
                productions={productions}
                quotes={quotes}
                checklists={checklists}
                employeelist={employeelist}
                />
        
            </div>
            :
            null
            }

          </div>
          
          {category!=null && tasks.length>0?
            <Tasklist
            activeJob={activeJob}
            addTask={addTask}
            key={category.id}
            isCategory={true}
            tasks={tasks.filter(t=>t.companyId==compId)}
            compId={compId}
            catId={catId}
            jobStatuses={jobStatuses}
            paymentOptions={paymentOptions}
            paymentMethods={paymentMethods}
            jobTypes={jobTypes}
            shipments={shipments}
            productions={productions}
            quotes={quotes}
            checklists={checklists}
            employeelist={employeelist}
            fromDoneTask={false}
            title={null}
            />
          :
          null
        }

          
    </div>
  )
  :
  null
}

export default CategoryTasks