import React,{useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { CheckCircle, Food, InCall, LoginIcon, LogoutIcon, LongBreak, RevertIcon, Salah, ShortBreak, Washroom } from '../layout/Icons'
import axios from '../../api/axios'
import { ADD_ACTIVITY, CHECK_CLOCK_IN, CLOCK_IN_USER, CLOCK_OUT_USER, COMPLETE_ACTIVITY } from '../../api/ApiUrl'
import toast from 'react-hot-toast'
import moment from 'moment';
import { auth, submitMsg, updateActivity } from '../../config/firebaseConfig'
import { serverTimestamp } from 'firebase/firestore'
import StopWatch from '../layout/StopWatch'
const ActionMsg = ({roomId}) => {
  
    const [load,setLoad]=useState(true);
    const [isClockIn, setIsClockIn]=useState(false);
    const {uid}=auth.currentUser;
    const user=useSelector((state)=>state.user.value);
    const [runningActivityId, setRunningActivityId]=useState(-1);
    const [indexId, setIndexId]=useState(-1);
    useEffect(()=>{
        const checkClockIn=async()=>{
            const res=await axios.post(CHECK_CLOCK_IN, {userId:user.id, compId:roomId});
            setIsClockIn(res.data.check)
            setRunningActivityId(res.data.act)
            setIndexId(res.data.actIndex);
            setLoad(false);
        }
        checkClockIn();
    },[user])

    const clockIn=async()=>{
        try{
            
            if(isClockIn){
                toast.error('You are already clocked In for today');
                return
            }

            const res=await axios.post(CLOCK_IN_USER,{userId:user.id, compId:roomId});
            setIsClockIn(res.data.clockIn);
            updateActivity(roomId, user.id, 0)

            const time=moment(res.data.clockInTime).format('hh:mmA')
            const msg=`at ${time}`
            var img=null;
            if(user.image!=null){
                img=user.image
            }
            
            var data={
                createdAt:serverTimestamp(),
                msg:msg,
                photoUrl:img,
                roomId:roomId,
                uName:`${user.Fname} ${user.Lname}`,
                uid:uid,
                userId:user.id,
                msgType:2,
                typeId:0,
                read:[user.id],
            }
            submitMsg(roomId, data);

            toast.success('clocked-in for today');

        }catch(e){
            if(!e?.response){
                toast.error("No Server Response!");
                console.log(e);
            }else{
                console.log(e);
                toast.error("Oops! An error occured!");
            }
        }
    }



    const addActivity=async(actId, actName)=>{
        try{
            
            if(runningActivityId>0){
                toast.error('please complete current running activity');
                return
            }
           

            const res=await axios.post(ADD_ACTIVITY,{userId:user.id, actId:actId, actName:actName, compId:roomId});
            setIndexId(res.data.indexId)
            updateActivity(roomId, user.id, actId);
    
            const time=moment(res.data.startTime).format('hh:mmA')
            const msg=`at ${time}`
            var img=null;
            if(user.image!=null){
                img=user.image
            }
            localStorage.setItem('startTime', res.data.startTime)

            setRunningActivityId(res.data.actId);

            var data={
                createdAt:serverTimestamp(),
                msg:msg,
                photoUrl:img,
                roomId:roomId,
                uName:`${user.Fname} ${user.Lname}`,
                uid:uid,
                userId:user.id,
                msgType:2,
                typeId:actId, // type  for activity msgs
                read:[user.id],
            }

            submitMsg(roomId, data); 
        }catch(e){
            if(!e?.response){
                toast.error("No Server Response!");
                console.log(e);
            }else{
                console.log(e);
                toast.error("Oops! An error occured!");
            }
        }
    }

    const completeActivity=async(actId, refId)=>{
        try{
            
            const res=await axios.post(COMPLETE_ACTIVITY,{userId:user.id, actId:actId, compId:roomId});
            setRunningActivityId(res.data.actId);
            setIndexId(-1)
            updateActivity(roomId, user.id, 0);

            const startTime=moment(res.data.startTime).format('hh:mmA')
            const endTime=moment(res.data.endTime).format('hh:mmA')
            const msg=`${startTime} - ${endTime}`
            var img=null;
            if(user.image!=null){
                img=user.image
            }

            var data={
                createdAt:serverTimestamp(),
                msg:msg,
                photoUrl:img,
                roomId:roomId,
                uName:`${user.Fname} ${user.Lname}`,
                uid:uid,
                userId:user.id,
                msgType:2,
                typeId:refId,
                completeType:true,
                read:[user.id],
            }
            localStorage.removeItem('startTime');
            submitMsg(roomId, data); // type 2 for activity msgs

        }catch(e){
            if(!e?.response){
                toast.error("No Server Response!");
                console.log(e);
            }else{
                console.log(e);
                toast.error("Oops! An error occured!");
            }
        }
    }


    

    const clockOut=async()=>{
        try{
            
            if(!isClockIn){
                toast.error('You are already clocked Out for today');
                return
            }

            const res=await axios.post(CLOCK_OUT_USER,{userId:user.id, compId:roomId});
            setIsClockIn(res.data.clockIn);
            updateActivity(roomId, user.id, -1)

            const time=moment(res.data.clockOutTime).format('hh:mmA')
            const msg=`at ${time}`
            var img=null;
            if(user.image!=null){
                img=user.image
            }
            var data={
                createdAt:serverTimestamp(),
                msg:msg,
                photoUrl:img,
                roomId:roomId,
                uName:`${user.Fname} ${user.Lname}`,
                uid:uid,
                userId:user.id,
                msgType:2,
                typeId:-1,
                read:[user.id],
            }
            submitMsg(roomId, data);

            toast.success('clocked-out for today');

        }catch(e){
            if(!e?.response){
                toast.error("No Server Response!");
                console.log(e);
            }else{
                console.log(e);
                toast.error("Oops! An error occured!");
            }
        }
    }
    

  return !load?(
    <div >
        <div className='flex flex-wrap items-start justify-center gap-2 pt-2'>
            <div className='flex flex-col items-center justify-center gap-1'>

            <button className='bg-green-500 action-btn group' onClick={()=>{clockIn()}} disabled={isClockIn}>
                <LoginIcon iconClass={'w-5 h-5'}/>
                <p className='action-btn-text group-hover:scale-100'>Clock-in</p>
            </button>
           
            </div>


    

            <button className={`bg-indigo-800 action-btn group`} onClick={()=>{if(runningActivityId==1) {completeActivity(indexId,1)} else  {addActivity(1,'washroom')} }} disabled={runningActivityId>0 && runningActivityId!=1}>
            {runningActivityId==1?
                <CheckCircle iconClass={'w-5 h-5'}/>
                :
                <Washroom iconClass={'w-5 h-5'}/>
            }
                <p className='action-btn-text group-hover:scale-100'>Washroom</p>
            </button>
            
   

           

            <button className='bg-orange-500 action-btn group' onClick={()=>{if(runningActivityId==2){ completeActivity(indexId,2)}else {addActivity(2,'short-break')}}} disabled={runningActivityId>0 && runningActivityId!=2}>
                {runningActivityId==2?
                <CheckCircle iconClass={'w-5 h-5'}/>
                :
                <ShortBreak iconClass={'w-5 h-5'}/>
                }
                <p className='action-btn-text group-hover:scale-100'>Short Break</p>
            </button>

        
         

            <button className='bg-red-500 action-btn group' onClick={()=>{if(runningActivityId==3){ completeActivity(indexId,3)} else{ addActivity(3,'Long-break')}}} disabled={runningActivityId>0 && runningActivityId!=3}>
                {runningActivityId==3?
                <CheckCircle iconClass={'w-5 h-5'}/>
                :
                <LongBreak iconClass={'w-5 h-5'}/>
                }
                <p className='action-btn-text group-hover:scale-100'>Long Break</p>
            </button>

 

            

            <button className='bg-cyan-500 action-btn group' onClick={()=>{if(runningActivityId==4){completeActivity(indexId,4)} else{addActivity(4,'Salah-break')}}} disabled={runningActivityId>0 && runningActivityId!=4}>
                {runningActivityId==4?
                <CheckCircle iconClass={'w-5 h-5'}/>
                :
                <Salah iconClass={'w-5 h-5'}/>
                }
                <p className='action-btn-text group-hover:scale-100'>Salah Break</p>
            </button>

            <button className='bg-purple-500 action-btn group' onClick={()=>{if(runningActivityId==5){completeActivity(indexId,5)} else{addActivity(5,'Dinner')}}} disabled={runningActivityId>0 && runningActivityId!=5}>
                {runningActivityId==5?
                <CheckCircle iconClass={'w-5 h-5'}/>
                :
                <Food iconClass={'w-5 h-5'}/>
                }
                <p className='action-btn-text group-hover:scale-100'>Dinner</p>
            </button>

            <button className='bg-lime-500 action-btn group' onClick={()=>{if(runningActivityId==6){completeActivity(indexId,6)} else{addActivity(6,'In-Call')}}} disabled={runningActivityId>0 && runningActivityId!=6}>
                {runningActivityId==6?
                <CheckCircle iconClass={'w-5 h-5'}/>
                :
                <InCall iconClass={'w-5 h-5'}/>
                }
                <p className='action-btn-text group-hover:scale-100'>In-Call</p>
            </button>



            <div className='flex flex-col items-center justify-center gap-1'>

            <button className='bg-rose-500 action-btn group' onClick={()=>{clockOut()}} disabled={!isClockIn}>
                <LogoutIcon iconClass={'w-5 h-5'}/>
                <p className='action-btn-text group-hover:scale-100'>Clock-out</p>
            </button>
            
            </div>

        </div>

        {runningActivityId>0 && runningActivityId!=6?
         <div className='fixed top-0 left-0 w-screen h-full bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
         <div className='flex flex-row items-center justify-center min-h-screen'>
           <div className='w-11/12 p-4 rounded-md bg-slate-50 dark:bg-slate-800 md:w-1/3 lg:w-1/4'>
               <div className='flex flex-col items-center justify-center gap-2'>
                   <div className='flex items-center justify-center gap-2'>

                <div className='animate-pulse'>
                <LoadActivityIcon id={runningActivityId}/>
                </div>
                   <StopWatch/>
                   </div>
                <p className='text-sm font-semibold text-slate-500'>Click the return button once you are ready to join back in</p>
                <button className='icon-btn' onClick={()=>{completeActivity(indexId,runningActivityId)}}> <RevertIcon iconClass={'w-8 h-8'}/> </button>
               </div>
           </div>
         </div>
       </div>
   
        :
        null}


    </div>
  ):
  null
}


const LoadActivityIcon=({id})=>{
    return(
      <>
      {id==0 &&
      <div className='w-16 h-16 p-1 text-white bg-green-500 rounded-full'>
        <LoginIcon iconClass={'w-14 h-14'}/>
      </div>
      }
  
      {id==1 &&
      <div className='w-16 h-16 p-1 text-white bg-indigo-800 rounded-full'>
        <Washroom iconClass={'w-14 h-14'}/>
      </div>
      }
  
      {id==2 &&
      <div className='w-16 h-16 p-1 text-white bg-orange-500 rounded-full'>
        <ShortBreak iconClass={'w-14 h-14'}/>
      </div>
      }
  
      {id==3 &&
      <div className='w-16 h-16 p-1 text-white bg-red-500 rounded-full'>
        <LongBreak iconClass={'w-14 h-14'}/>
      </div>
      }
  
  
      {id==4 &&
      <div className='w-16 h-16 p-1 text-white rounded-full bg-cyan-500'>
        <Salah iconClass={'w-14 h-14'}/>
      </div>
      }
  
    {id==5 &&
      <div className='w-16 h-16 p-1 text-white bg-purple-500 rounded-full'>
        <Food iconClass={'w-14 h-14'}/>
      </div>
      }
  
    {id==6 &&
      <div className='w-16 h-16 p-1 text-white rounded-full bg-lime-500'>
        <InCall iconClass={'w-14 h-14'}/>
      </div>
      }
  
  
      {id==-1 &&
      <div className='w-16 h-16 p-1 text-white bg-red-500 rounded-full'>
        <LogoutIcon iconClass={'w-14 h-14'}/>
      </div>
      }
  
      </>
  
    )
  }

export default ActionMsg