import React,{useState} from 'react'
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { avatarStorage_LINK, ASSIGN_URL, UPDATE_URL, DECLINE_URL, DELETE_URL } from '../../api/ApiUrl';
import Avatar from '../layout/Avatar';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Close,Delete, Pencil, UserAdd, UserRemove, Work } from '../layout/Icons';
import { addUserToRoom, notify, requireRefresh } from '../../config/firebaseConfig';


const EmployeeCard = ({user,setEmployees, setSearchEmployeeData, activeCompany}) => {
  
    const [isAdd,setIsAdd]=useState(false);
    const [designation, setDesignation]=useState('');
    const [isEdit, setIsEdit]=useState(false);
    const [editDesignation,setEditDesignation]=useState(user.designation);
    const [isDelete, setIsdelete]=useState(false);
    const me=useSelector((state)=>(state.user.value));

    const addEmployee = async (e)=>{
        e.preventDefault();
        const toastwait = toast.loading('Assigning...', {icon: '💼'});
        if(designation==null){
            toast.error("Invalid Entry");
            return;
        }
        try{
            var data=JSON.stringify({
                    id:user.emId,
                    designation:designation,

            });
            await axios.post(ASSIGN_URL, data).then((res)=>{
                setEmployees(res.data.employees);
                setSearchEmployeeData(res.data.employees);
                toast.dismiss(toastwait);
                toast.success('Assigned successfully!');
                var title=`Welcome to  ${activeCompany.name}`
                var body=`You have been registered as - ${designation}`
                var onClickLink=`/profile`
                var receiverId=user.id
                var iconLink=''
                var iconType=3
                notify(title, body, onClickLink, receiverId, iconLink, iconType)
                requireRefresh(user.id, true, true, true)
                addUserToRoom(activeCompany.id, user.id, `${user.Fname} ${user.Lname}`, user.image)
                setIsAdd(false);
            })

        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
                toast.dismiss(toastwait);
            }else{
                console.log(e);
            }  
        }

    }

    const updateEmployee = async (e)=>{
        e.preventDefault();
        const toastwait = toast.loading('Updating...');
        if(editDesignation==null){
            toast.error("Invalid Entry");
            return;
        }
        try{
            var data=JSON.stringify({
                    id:user.emId,
                    designation:editDesignation,
            });
            await axios.post(UPDATE_URL, data).then((res)=>{
                setEmployees(res.data.employees);
                setSearchEmployeeData(res.data.employees);
                toast.dismiss(toastwait);
                setIsEdit(false);
                toast.success('Updated successfully!');
            })

        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
                toast.dismiss(toastwait);
            }else{
                console.log(e);
            }  
        }

    }


    const decline = async ()=>{
        const toastwait = toast.loading('working on it...', {icon: '⏳'});
        try{
            var data=JSON.stringify({
                id:user.emId,
            });
            await axios.post(DECLINE_URL, data).then((res)=>{
                setEmployees(res.data.employees);
                setSearchEmployeeData(res.data.employees);
                toast.dismiss(toastwait);
                toast('Declined!', {icon:'🛑'});
            })

        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
                toast.dismiss(toastwait);
            }else{
                console.log(e);
            }  
        }
    }


    const deleteEmployee = async(id)=>{
        const waitToast = toast.loading('Removing...', {icon: '⏳'});
        try{
            await axios.delete(`${DELETE_URL}${id}`).then((res)=>{
                setEmployees(res.data.employees);
                setSearchEmployeeData(res.data.employees);
                var title=`You have been removed from ${activeCompany.name}`
                var body=``
                var onClickLink=`/profile`
                var receiverId=user.id
                var iconLink=''
                var iconType=3
                notify(title, body, onClickLink, receiverId, iconLink, iconType)
                requireRefresh(user.id, true, true, true)
                toast.dismiss(waitToast);
                setIsdelete(false);
            });
        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
                toast.dismiss(waitToast);
            }
        }
    }


    return (
  <>
  <div className='flex flex-wrap items-center justify-between gap-2 px-2 py-2 my-1 transition-all duration-150 ease-in-out border rounded-md shadow-lg bg-slate-100 dark:bg-slate-700 shadow-transparent dark:hover:bg-slate-800 hover:bg-slate-50 hover:shadow-slate-300 dark:hover:shadow-slate-900 border-slate-400 dark:border-slate-600'>
        <div className='inline-flex items-center justify-start gap-2'>
            {user.image?
            <img src={`${avatarStorage_LINK}${user.image}`} className='w-12 h-12 rounded-full' alt={'avatar'} />
            :
            <Avatar value={`${user.Fname} ${user.Lname}`} width={'12'} height={'12'} color="bg-blue-200 dark:bg-blue-800"/>
            }
            <div>
                <h4 className='font-bold text-slate-600 dark:text-slate-400'>{user.Fname} {user.Lname}
                {me.id===user.id?
                (<span className='text-sm text-slate-500'> (you)</span>)
                :
                null
                }
                </h4>
                <p className='text-sm font-semibold text-slate-500'>{user.email}</p>
            </div>
        </div>
        <div>
            <h4 className='inline-flex items-center justify-end gap-1 text-sm font-bold text-right text-slate-600 dark:text-slate-400 whitespace-nowrap'>
            <Work iconClass='w-4 h-4'/>  {user.verified===0?`Not Assigned` : `${user.designation}` }
            </h4>
            <p className='text-sm font-semibold text-right text-slate-500'>{moment(user.created_at).fromNow()}</p>
        </div>
        
        {me.userType>=1?
        <>
            {user.verified===0?
            <div className='flex items-center justify-end gap-1'>
                <button className='accept-btn' onClick={()=>setIsAdd(true)}><UserAdd iconClass={'w-4 h-4'}/></button>
                <button className='reject-btn' onClick={()=>decline()}><Delete iconClass={'w-4 h-4'}/></button>
            </div>
            :
            null
            }

      
       
        {user.verified>0?
            <div className='flex items-center justify-end gap-1'>
                <button className='info-btn' onClick={()=>setIsEdit(true)}><Pencil iconClass={'w-4 h-4'}/></button>
                {user.id!==me.id?
                <button className='reject-btn' onClick={()=>setIsdelete(true)}><UserRemove iconClass={'w-4 h-4'} /></button>
                :
                null
            }
            </div>
            :
            null
        }
        </>
        :
        null
    }
    </div>

    {isAdd?
    <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
        <div className='flex flex-row items-center justify-center min-h-screen'>
            <div className='w-full m-2 md:1/3 lg:w-1/4 card'>
                        <div className='flex items-start justify-between gap-1'>
                        <p className='font-semibold text-slate-600 dark:text-slate-400'>Assign designation</p>
                        <button className='reject-btn' onClick={()=>setIsAdd(false)}><Close iconClass={'w-4 h-4'} /></button>
                        </div>
                    <form onSubmit={(e)=>addEmployee(e)}>
                        <div className='flex items-center justify-start gap-1'>
                        <input
                        type={'text'}
                        value={designation}
                        onChange={(e)=>setDesignation(e.target.value)}
                        className='w-3/4 input-field-fat'
                        required
                        />
                        <button className='submit-btn'>Assign</button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
    :
    null
    }

    {isEdit?
    <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
        <div className='flex flex-row items-center justify-center min-h-screen'>
            <div className='w-full m-2 md:1/3 lg:w-1/4 card'>
                        <div className='flex items-start justify-between gap-1'>
                        <p className='font-semibold text-slate-600 dark:text-slate-400'>Update designation</p>
                        <button className='reject-btn' onClick={()=>setIsEdit(false)}><Close iconClass={'w-4 h-4'}/></button>
                        </div>
                    <form onSubmit={(e)=>updateEmployee(e)}>
                        <div className='flex items-center justify-start gap-1'>
                        <input
                        type={'text'}
                        value={editDesignation}
                        onChange={(e)=>setEditDesignation(e.target.value)}
                        className='w-3/4 input-field-fat'
                        required
                        />
                        <button className='submit-btn'>Update</button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
    :
    null
    }

    {isDelete?
     <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
     <div className='flex flex-row items-center justify-center min-h-screen'>
     <div className='w-11/12 md:w-1/2 lg:w-1/3 card'>
            <p className='text-slate-600 dark:text-slate-400'>Are you sure you want to remove <strong>{user.Lname}</strong> ?</p>
           
            <div className='flex items-center justify-end gap-2'>
                <button className='reject-btn w-14' onClick={()=>{deleteEmployee(user.emId)}}>Yes</button>
                <button className='accept-btn w-14' onClick={()=>setIsdelete(false)}>No</button>
            </div>
        </div>
     </div>
 </div>
    :
    null
    }
  </>
  )
}

export default EmployeeCard