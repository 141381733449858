import React, {useState, useEffect} from 'react'
import axios from '../../api/axios';
import toast from 'react-hot-toast';
import { logoStorage_LINK, CategorylogoStorage_LINK } from '../../api/ApiUrl';

import {NavLink} from "react-router-dom";
import { useSelector } from 'react-redux';
import { UserCircle, Settings, Add, CheckDone, Bell, MsgIcon } from './Icons';
import Logo from '../../Logo'
import Avatar from './Avatar';

import { onSnapshot, query, where, collection, doc, updateDoc, orderBy, limit, startAfter, getDocs, writeBatch } from "firebase/firestore";
import {db} from '../../config/firebaseConfig'
import NotificationPanel from './NotificationPanel';
import NotificationCircle from './NotificationCircle';

const SideNav = () => {
    const companies=useSelector((state)=>state.company.value);
    const categs=useSelector((state)=>state.category.value)
    const user=useSelector((state)=>state.user.value);
    let activeStyle = {
        backgroundColor: "#6b7280"
      };

  const [showNotification, setShowNotification]=useState(false);


const [notifications, setNotifications]=useState([]);
const [lastKey, setLastKey]=useState()
const [isEmpty, setIsEmpty]=useState(false);
const [load, setLoad]=useState(true);
const [EndData, setEndData]=useState(false);

const [ unread, setUnread]=useState(0);

    const q=query(collection(db, "Notifications"), where("receiverId", "==", user.id), orderBy("createdAt", "desc"), limit(20))
    

    useEffect(()=>{
      const listenNotification=onSnapshot(q, (snapShot)=>{
        if(snapShot.size>0){
          const data=snapShot.docs.map(doc=>{return {...doc.data(), id: doc.id} })
          setNotifications(data)
          const last=snapShot.docs[snapShot.docs.length-1]
          setLastKey(last)
          setLoad(false)
          const countUnread=data.filter(d=>d.read==false).length
          setUnread(countUnread);
          setEndData(false)
        }else{
          setIsEmpty(true) 
        }
      })
      return ()=>{
        listenNotification()
        setLastKey()
        setIsEmpty(false)
        setEndData(false)
        setNotifications([])
      }
    },[])


    const loadMoreNotification=(snapShot)=>{
      const noData=snapShot.size===0;
      if(!noData){
        const data=snapShot.docs.map(doc=>{return {...doc.data(), id: doc.id} })
        const last=snapShot.docs[snapShot.docs.length-1]
        setNotifications((showNotification)=>[...showNotification, ...data])
        setLastKey(last)
      }else{
        setEndData(true)
      }
      setLoad(false);
    }


    const fetchmoreNotification=async()=>{
      const q=query(collection(db, "Notifications"), where("receiverId", "==", user.id), orderBy("createdAt", "desc"), startAfter(lastKey), limit(10))
      setLoad(true);
      const allNotifications = await getDocs(q);
      loadMoreNotification(allNotifications)
    
    }


  return (
    <>
    <div className='fixed top-0 left-0 z-50 flex flex-row items-center justify-between w-full px-2 py-0 md:px-0 h-14 md:py-2 bg-slate-300 dark:bg-slate-900 md:w-14 md:h-full md:flex-col'>
        <div className='flex flex-row items-center justify-start gap-2 overflow-x-auto md:overflow-y-auto md:overflow-x-none md:flex-col no-scrollbar'>
        <NavLink to='/myTasks' style={({ isActive })=>isActive ? activeStyle : undefined } className="sideicon-active"><SidebarIcon icon={<Logo className='w-6 h-6' fill={"fill-slate-600 dark:fill-slate-300 group-hover:fill-slate-50"}></Logo>} text={"tasks"}/></NavLink>

        {companies.map((co)=>
        <div className='sidenav-company-wrapper' key={co.id}>
       
        <NavLink  to={`/company/${co.id}`} style={({ isActive })=>isActive ? activeStyle : undefined } className="sideicon-active"><SidebarIcon icon={co.logo} isVariable={true} text={co.name}/></NavLink>

          {categs.map((cat)=>(
            cat.companyId==co.id?
            <NavLink key={cat.id} to={`/category/${co.id}/${cat.id}`} style={({ isActive })=>isActive ? activeStyle : undefined } className="sideicon-active"><SidebarIcon  icon={cat.icon} isVariable={true} isCateg={true} text={cat.name}/></NavLink>
            :
            null
            ))}
        </div>
        )
        }
        {companies.length>0?
        <NavLink to="/create/category" style={({ isActive })=>isActive ? activeStyle : undefined } className="sideicon-active"><SidebarIcon icon={<Add iconClass={'w-6 h-6'}/>} isVariable={false} text={"Add"}/></NavLink>
        :
        null
        }

        </div>


        <div className='flex flex-row items-center justify-start gap-2 md:flex-col'>
        
        <button onClick={()=>{setShowNotification(!showNotification)}} className={`sideicon-active relative ${showNotification? `bg-[#6b7280]` : ``}`}><SidebarIcon icon={<Bell iconClass={'w-6 h-6'}/>}/> <NotificationCircle value={unread}/></button>

        <NavLink to='/doneTasks' style={({ isActive })=>isActive ? activeStyle : undefined } className="sideicon-active"><SidebarIcon icon={<CheckDone iconClass={'w-6 h-6'}/>} text={"done Tasks"}/></NavLink>

        <NavLink to="/profile" style={({ isActive })=>isActive ? activeStyle : undefined } className="relative sideicon-active" > 
        <SidebarIcon icon={<UserCircle iconClass={'w-6 h-6'}/>} isVariable={false} text={'Profile'}/></NavLink>
        
        <NavLink to="/settings" style={({ isActive })=>isActive ? activeStyle : undefined } className="sideicon-active" ><SidebarIcon icon={<Settings iconClass={'w-6 h-6'}/>} isVariable={false} text={"Settings"}/></NavLink>
        
        </div>
    </div>

    {showNotification &&
        <NotificationPanel 
        notifications={notifications} 
        fetchmoreNotification={fetchmoreNotification} 
        load={load} 
        setLoad={setLoad}
        isEmpty={isEmpty} 
        EndData={EndData}
        setShowNotification={setShowNotification}
        />
        }
    </>
  )
}

const SidebarIcon = ({icon, text, isVariable, clickFunction, isCateg}) => (
    <div className='sidebar-icon group' onClick={clickFunction}>
        {isVariable?
        <>
         {icon?
          <>
          {isCateg?
          <img src={`${CategorylogoStorage_LINK}${icon}`} className='w-10 h-10 p-1 rounded-full avatar'/>
          :
          <img src={`${logoStorage_LINK}${icon}`} className='w-10 h-10 p-1 rounded-full avatar'/>
          }
          </>   
            :
          <Avatar value={text} width='10' height='10' color={'bg-slate-100 dark:bg-slate-700 hover:bg-orange-500 hover:text-slate-50 dark:hover:bg-orange-600 dark:hover:text-slate-50'}/>   

        }
        </>
        :
        <>
        {icon}
        </>
        }
    {/* <span className='sidebar-tooltip group-hover:scale-100'>{text}</span> */}
    </div>
);

export default SideNav