import React from 'react'
import Avatar from '../layout/Avatar'
import { CompanylogoStorage_LINK } from '../../api/ApiUrl';
import { Add } from '../layout/Icons';
const CompanyCard = ({company, addTask, setAddTask}) => {
    
  return (
    <div className='flex items-center justify-between gap-2 mb-1'>

    <div className='flex items-center justify-start gap-2 group'>
    {company.logo?
        <img src={`${CompanylogoStorage_LINK}${company.logo}`} className='w-10 h-10 rounded-full'/>
        :
        <Avatar value={company.name} width='10' height='10' color={'bg-orange-300 dark:bg-orange-800/70'}/>   
        
    }
    <h4 className='text-lg font-bold text-slate-600 dark:text-slate-400'>{company.name}</h4>
    </div> 

    <button className={`plus-add-task ${addTask? `rotate-45`:`rotate-0`}`} onClick={()=>{setAddTask(!addTask)}}><Add iconClass={'w-5 h-5'}/></button>
    </div>
  )
}

export default CompanyCard