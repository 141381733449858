import React, {useState} from 'react'
import CompanyTabs from '../Company/CompanyTabs';

import EditProfile from '../User/EditProfile'
import ProfileCard from '../User/ProfileCard';
import { useParams } from "react-router-dom";

const Profile = () => {

    const [isEdit, setIsEdit]=useState(false);

     let {compId}=useParams()

  return (
      <>
    <div className='relative main-bodyLayout'>
    <ProfileCard isEdit={isEdit} setIsEdit={setIsEdit}/>
    <CompanyTabs activeComp={compId}/>
    </div>
    {isEdit?
        <div className='fixed top-0 left-0 w-screen h-screen bg-slate-400/30 dark:bg-slate-800/30 backdrop-blur-sm z-[99]'>
            <div className='flex flex-row items-center justify-center min-h-screen'>
            <EditProfile  isEdit={isEdit} setIsEdit={setIsEdit}/>
            </div>
        </div>
    :
    null}
    </>
  )
}

export default Profile