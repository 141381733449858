import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import '../../react-draft-wysiwyg.css';
const CustomEditor = ({data, updateData}) => {
   
  return (
    <div>
     
     <Editor editorState={data} 
     onEditorStateChange={updateData} 
                                    
    toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
    }}
    toolbarClassName="toolbarNote" wrapperClassName="wrapperNote" editorClassName="editorNote"/>
    
    </div>
  )
}

export default CustomEditor