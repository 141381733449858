import React from 'react'
import { User, Phone, Mail, Telephone, Map, Home, Globe } from '../layout/Icons'
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { UPDATECUSTOMER_URL } from '../../api/ApiUrl';
const EditCustomer = ({cust, setCust, setIsEdit}) => {


    const updateCustomer = async(e)=>{
        e.preventDefault();
        try{
      
    
        var data=JSON.stringify({cust:cust})
        await axios.post(UPDATECUSTOMER_URL, data).then((res)=>{
          toast.success('customer Updated');
          setIsEdit(false);
        })
      
        }catch(e){
          if(!e?.response){
            toast.error("No Server Response!");
           
            console.log(e);
        }else{
            console.log(e);
            toast.error("Oops! An error occured!");
        }
        }
      }
    
        const rating =(rate)=>{
            if(rate==0){
                return ( <p className='text-sm'>😐</p> )
            }else if(rate==1){
                return ( <p className='text-sm'>😁</p> )
            }
            else if(rate==2){
                return ( <p className='text-sm'>💖</p> )
            }else if(rate==-1){
            return ( <p className='text-sm'>😡</p> )
            }else{
                return ( <p className='text-sm'>👻</p> )
            }
        }


  return (
    <div>
    <form onSubmit={(e)=>{updateCustomer(e)}}>
   <div className='flex items-center justify-start gap-1'>
   <p className='text-sm text-slate-500'>Rating:</p>
   <div className='scale-125'>{rating(cust.rating)}</div> 
   </div>

   <div className='flex items-center justify-start gap-1'>
   <input type={'checkbox'} className='hidden' id={'fav'} value={2} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value}));}}/>
   <label htmlFor='fav' className='relative w-5 cursor-pointer h-7' >
   <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>💖</p> 
   </label>

   <input type={'checkbox'} className='hidden' id={'happy'} value={1} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value}));}}/>
   <label htmlFor='happy' className='relative w-5 cursor-pointer h-7' >
   <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>😁</p> 
   </label>

   <input type={'checkbox'} className='hidden' id={'avg'}  value={0} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value}));}}/>
   <label htmlFor='avg' className='relative w-5 cursor-pointer h-7'>
   <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>😐</p> 
   </label>

   <input type={'checkbox'} className='hidden' id={'angry'} value={-1} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value}));}}/>
   <label htmlFor='angry' className='relative w-5 cursor-pointer h-7' >
   <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>😡</p>
   </label>

   <input type={'checkbox'} className='hidden' id={'ghost'} value={-2} onChange={(e)=>{setCust(cust=>({...cust, rating:e.target.value}));}}/>
   <label htmlFor='ghost' className='relative w-5 cursor-pointer h-7' >
   <p className='absolute top-0 left-0 z-0 text-lg transition-all duration-150 ease-in-out hover:scale-150'>👻</p>
   </label>

   </div> 


   <div className="flex items-center justify-start w-full">
     <p className="input-field-prepend-icon">
     <User iconClass={'w-4 h-4'}/>
     </p>
       <input
       type="text"
       value={cust.name}
       onChange={(e) => {setCust(cust=>({...cust, name:e.target.value}));}}
       className="w-full input-field-group"
       placeholder="*Cust. name"
       required
       />
   </div>


   <div className="flex items-center justify-start w-full">
       <p className="input-field-prepend-icon"><Phone iconClass={'w-4 h-4'}/></p>
       <input
           type="tel"
           value={cust.mobile}
           onChange={(e) => {setCust(cust=>({...cust, mobile:e.target.value}));}}
           className="w-full input-field-group"
           placeholder="*Mobile"
           required
       />
   </div>

   <div className="flex items-center justify-start w-full">
       <p className=" input-field-prepend-icon">
       <Telephone iconClass='w-4 h-4'/>
       </p>
       <input
       type="tel"
       value={cust.telephone? cust.telephone:''}
       onChange={(e) => {setCust(cust=>({...cust, telephone:e.target.value}));}}
       className="w-full input-field-group"
       placeholder="Telephone"
      
       />
   </div>

   <div className="flex items-center justify-start w-full">
       <p className=" input-field-prepend-icon">
       <Mail iconClass='w-4 h-4' />
       </p>
       <input
         type="email"
         value={cust.email}
         onChange={(e) => {setCust(cust=>({...cust, email:e.target.value}));}}
         className="w-full input-field-group"
         placeholder="Email"
         required
       />
   </div>

   <div className="flex items-center justify-start w-full">
       <p className="input-field-prepend-icon">
       <Map iconClass='w-4 h-4'/>
       </p>
   <input
     type="text"
     value={cust.address? cust.address:''}
     onChange={(e) => {
       setCust(cust=>({...cust, address:e.target.value}));
     }}
     className="w-11/12 input-field-group"
     placeholder="Address"
     />

   <input
       type="text"
       value={cust.postCode? cust.postCode:''}
       onChange={(e) => {
           setCust(cust=>({...cust, postCode:e.target.value}));
       }}
       className="w-4/12 input-field"
       placeholder="PostCode"
     />
   </div>

   <div className="flex items-center justify-start w-full">
   <p className=" input-field-prepend-icon">
   <Home iconClass='w-4 h-4' />
   </p>
   <input
     type="text"
     value={cust.company? cust.company:''}
     onChange={(e) => {setCust(cust=>({...cust, company:e.target.value}));}}
     className="w-full input-field-group"
     placeholder="Company"
   />
   </div>

   <div className="flex items-center justify-start w-full">
   <p className=" input-field-prepend-icon">
   <Globe iconClass='w-4 h-4' />
   </p>
   <input
     type="text"
     value={cust.website? cust.website:''}
     onChange={(e) => { setCust(cust=>({...cust, website:e.target.value}));}}
     className="w-full input-field-group"
     placeholder="website"
   />
   </div>

   <div className='flex items-center justify-end gap-1'>
     <button className='accept-btn' type='submit'>Update Customer</button>
   </div>
   </form>
</div>
  )
}

export default EditCustomer