import React, {useState} from 'react'
import axios from "../../api/axios";
import toast from 'react-hot-toast';
import Avatar from '../layout/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import { REGCOMPANY_URL } from '../../api/ApiUrl';
import { setCompany } from '../../features/companies';
import { Pencil } from '../layout/Icons';
import { addRoom } from '../../config/firebaseConfig';

const CompanyReg = ({setIsreg}) => {

const dispatch=useDispatch();

const user=useSelector((state)=>state.user.value)
const companies=useSelector((state)=>state.company.value)

const [logoUpload, setLogoUpload]=useState(null);
const [logoDisplay, setLogoDisplay]=useState(null);

const uploadlogo=(e)=>{
    if(e.target.files && e.target.files[0]){
        setLogoUpload(e.target.files[0])
        setLogoDisplay( URL.createObjectURL(e.target.files[0]));
    }
}
const [name,setName]=useState('');
const [address,setAddress]=useState('');
const [postcode, setPostcode]=useState('');
const [reg,setReg]=useState('');
const [web,setWeb]=useState('');


const registerCompany=async (e)=>{
    e.preventDefault();
    
    const toastReg2 = toast.loading('Registering Company...', {icon: '✍️'});
    try{
        const formData = new FormData();
        formData.append("userId", user.id);
        formData.append("name", name);
        formData.append("address", address);
        formData.append("postcode", postcode);
        formData.append("regNo", reg);
        formData.append("website", web);

        if(logoUpload!=null){
            formData.append("logo", logoUpload);
        }else{
            formData.append("logo", ''); 
        }
   
        await axios.post(REGCOMPANY_URL,formData).then((res)=>{
            toast.success('Registration Complete');
            toast.dismiss(toastReg2);
            const comp=res.data.company;
            setName('');
            setAddress('');
            setPostcode('');
            setReg('');
            setWeb('');
            setLogoUpload(null);
            setLogoDisplay(null);
            setIsreg(false);
            addRoom(comp.id, comp.name, user.id,)
            dispatch(setCompany([comp, ...companies]))
        });
        
    }catch(e){
        if(!e?.response){
            toast.error("No Server Response!");
            toast.dismiss(toastReg2);
            console.log(e)
        }else{
            console.log(e);
        }
    }
}


  return (
    <div className='w-full'>
        <form onSubmit={(e)=>{registerCompany(e)}}>
        <div className='flex items-center justify-between gap-2'>
        <h4 className='mb-4 text-xl font-bold text-slate-600 dark:text-slate-400'>Register Company</h4>
        <div className='flex items-center justify-end gap-2'>
        <p className='text-sm text-slate-500'>Logo:</p>
        <div className='relative w-16 h-16 p-1 rounded-full bg-slate-300 dark:bg-slate-600'>
            {logoDisplay?
            <img src={logoDisplay} className='absolute z-10 rounded-full top-1 left-1 w-14 h-14' alt='logo'/>                  
            :
            <Avatar value='E T' width={'14'} height={'14'} />
            }
            <input accept='image/jpg, image/png, image/jpeg, image/gif' type='file' onChange={(e)=>uploadlogo(e)} className='hidden' id='logoUpload'/>
                <label htmlFor='logoUpload' className='absolute z-20 p-2 rounded-full opacity-0 cursor-pointer top-1 left-1 w-14 h-14 dark:bg-slate-600/70 bg-slate-50/70 text-slate-600 dark:text-slate-100 hover:opacity-100'>
                    <Pencil iconClass={'w-6 h-6 min-h-full m-auto'}/>
                </label>
            </div> 
        </div>
       
        </div>   
        <div className='grid items-end grid-cols-2 gap-2'>
           
            <div>
            <p className="text-sm text-slate-500">Company Name:</p>
            <input
            type='text'
            className='w-full input-field-fat'
            value={name}
            onChange={(e)=>setName(e.target.value)}
            required
            />
            </div>

            <div>
            <p className="text-sm text-slate-500">Address:</p>
            <input
            type='text'
            className='w-full input-field-fat'
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            required
            />
            </div>

            <div>
            <p className="text-sm text-slate-500">PostCode:</p>
            <input
            type='text'
            className='w-full input-field-fat'
            value={postcode}
            onChange={(e)=>setPostcode(e.target.value)}
            required
            />
            </div>

            <div>
            <p className="text-sm text-slate-500">Registration Number:</p>
            <input
            type='text'
            className='w-full input-field-fat'
            value={reg}
            onChange={(e)=>setReg(e.target.value)}
            required
            />
            </div>

            <div>
            <p className="text-sm text-slate-500">Website: (Optional)</p>
            <input
            type='text'
            className='w-full input-field-fat'
            value={web}
            onChange={(e)=>setWeb(e.target.value)}
            
            />
            </div>
            <button 
            className='submit-btn'>
                Register
            </button>
            </div>
            </form>
    </div>
  )
}

export default CompanyReg