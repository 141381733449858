import React from 'react'

const UserBubbleLoader = () => {
  return (
    <div className='flex items-center justify-end gap-3 p-2 rounded-3xl bg-slate-50 dark:bg-slate-800'> 
    <div className='w-6 h-6 rounded-full bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
    <div className='w-6 h-6 rounded-full bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
    <div className='w-6 h-6 rounded-full bg-slate-200 dark:bg-slate-800 animate-pulse'></div>
    </div>
  )
}

export default UserBubbleLoader