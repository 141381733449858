import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from '../layout/ThemeContext';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Note, Delete, Close, Check, Minus, Eye } from '../layout/Icons';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html";
import Checklist from '../layout/sortableChecklist/Checklist';
import CustomEditor from '../layout/CustomEditor';
import Avatar from '../layout/Avatar';
import { avatarStorage_LINK, CategorylogoStorage_LINK, FORWARD_URL } from '../../api/ApiUrl';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import axios from "../../api/axios";
import { updateTaskStatus } from '../../features/Tasks';
import { notify, requireRefresh } from '../../config/firebaseConfig';

const ForwardTask = ({
    task,
    setForward,
    jobStatuses,
    paymentOptions,
    paymentMethods,
    jobTypes,
    shipments,
    productions,
    checklists,
    quotes,
    employeelist,
}) => {

    const dispatch=useDispatch();
    const user=useSelector(state=>state.user.value);
    const categories=useSelector(state=>state.category.value);
    const { theme } = React.useContext(ThemeContext);
    const customStyles = {
          menu: (provided, state) => {
              let backgroundColor = "#f1f5f9";
              if (theme === "dark") {
              backgroundColor = "#334155";
              }
              
              return {
              ...provided,
              padding: 0,
              fontSize: 12,
              margin: 2,
              backgroundColor,
              minWidth:'max-content',
              maxWidth:'300px',
              zIndex:'99',
              };
          },
          option: (provided, state,) => ({
              ...provided,
              padding: 3,
              color: state.isSelected ? "orangered" : "#64748b",
              backgroundColor: state.isSelected ? "#cbd5e1" : '',
              backgroundColor: state.isFocused ? "#cbd5e1" : "",
          }),

          control: (provided) => ({
              ...provided,
              fontSize: 13,
              padding: 1,
              // height: 24,
              minHeight: 14,
              border: 1,
              borderRadius: "0.375rem",
              boxShadow: "none",
              backgroundColor: "transparent",
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
          }),
          placeholder: (provided) => ({
              ...provided,
              color: "rgb(148 163 184)",
          }),
          valueContainer: () => ({
              fontSize: 13,
              padding: 0,
              margin: 0,
              minHeight: 17,
              display: "flex",
              flexWrap: "no-wrap",
              alignItems: "center",
          }),

          IndicatorsContainer: () => ({
              padding: 0,
          }),
          clearIndicator: (provided) => ({
              ...provided,
              padding: 0,
          }),
          indicatorSeparator: () => () => null,

          dropdownIndicator: () => ({
              paddingLeft: 2,
              opacity: 0.5,
          }),
          multiValue: (provided) => ({
              ...provided,
              margin:0,
              marginLeft:1,
              padding:0,
              height: 24,
              border:'1px solid',
              borderRadius:'8px',
              backgroundColor:`${theme === "dark" ? `#0f172a` : `#f8fafc`}`,
              borderColor: `${theme === "dark" ? `#334155` : `#9ca3af`}`,
              justifyContent: "space-between",
          }),
          MultiValueContainer: (provided) => ({
              ...provided,
          }),
          multiValueLabel: (provided) => ({
              ...provided,
              padding: 1,
              height: 20,
          }),
          multiValueRemove: (provided) => ({
              ...provided,
              padding: 0,
              height: 20,
              width:'auto',
              borderRadius:'100%',
          }),
          singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = "opacity 300ms";

              
              let color = "rgb(71 85 105)";

              if (theme === "dark") {
              color = "rgb(148 163 184)";
              }

              return { ...provided, opacity, transition, color };
          },
    };

    const [forwardTask, setForwardTask]=useState({
      category:-1,
      custId:task.customerId,
      companyId:task.companyId,
      qty: task.qty,
      jobdesc: task.jobDesc,
      taskSet:task.taskSet,
      priority: task.priority,
      price: task.price,
      disc: task.discount,
      charge: task.charge,
      vat: task.vat,
      total: task.total,
      paid: task.paid,
      status: 0,
      jobType: null,
      quote: null,
      shipment:null,
      production: null,
      paymentOption: null,
      paymentMethod: null,
      turnaround: task.turnaround,
      isNote: task.isNote,
      notes: task.notes,
      assignee: [user.id],
      checklist:[],
      repeat: false,
      openrepeat: false,
      repeatDate: null,
      repeatType: 1,
      travel:task.travel,
      history:task.history,
      done:false,
    });

    const [cat, setCat]=useState(null);
    const [categoryOptions, setCategoryOptions]=useState([]);
    const [jobStatus, setJobStatus]=useState([]);
    const [paymentOption, setPaymentOption]=useState([]);
    const [paymentMethod, setPaymentMethod]=useState([]);
    const [JobType, setJobType]=useState([]);
    const [shipment, setShipment]=useState([]);
    const [production, setProduction]=useState([]);
    const [quote, setQuote]=useState([]);
    const [checklist, setChecklist]=useState([]);
    const [employees,setEmployees]=useState([]);
    const [loadCateg, setLoadCateg]=useState(false);
    const [notestate, setNoteState]=useState(EditorState.createEmpty());
    const [openNotes, setOpenNotes]=useState(false);


    useEffect(()=>{
      var catOps = categories.filter(c=>c.companyId===task.companyId && c.id!==task.categoryId).map((cat) => ({
          value: cat.id,
          label: cat.name,
          roleId:cat.roleId,
          icon:cat.icon,
      }));
    
  setCategoryOptions(catOps);
  
  },[])


    useEffect(()=>{
      var check=checklist.map((data)=>({
        name:data.name,
        checked:data.checked,
      }))
      forwardTask.checklist=check;
    },[checklist])

    const getDefaultData= (id)=>{
      setLoadCateg(true)
      var cat=categories.filter((cat)=>cat.id==id)[0]
      setCat(cat)
      if(task.notes!=null){
        const contentBlock = htmlToDraft(task.notes);
        if(contentBlock){
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setNoteState(EditorState.createWithContent(contentState));
        }
      }else{
        if(cat.defNotes!=null){
          const contentBlock = htmlToDraft(cat.defNotes);
          if(contentBlock){
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            forwardTask.isNote=true;
            forwardTask.notes=cat.defNotes;
            setNoteState(EditorState.createWithContent(contentState));
          }
        }else{
          forwardTask.isNote=false;
          setNoteState(EditorState.createEmpty());
        }
      }
        setJobStatus(jobStatuses.filter((data)=>(data.catId==cat.id)))
        if(cat.isPrice){
          setPaymentOption(paymentOptions.filter((data)=>(data.catId==cat.id)))
          setPaymentMethod(paymentMethods.filter((data)=>(data.catId==cat.id)))
        }
        if(cat.isJobType){
          setJobType(jobTypes.filter((data)=>(data.catId==cat.id)))
        }
        if(cat.isShipment){
          setShipment(shipments.filter((data)=>(data.catId==cat.id)))
        }
        if(cat.isProduction){
          setProduction(productions.filter((data)=>(data.catId==cat.id)))
        }
        if(cat.isQuote){
          setQuote(quotes.filter((data)=>(data.catId==cat.id)))
        }
        if(cat.isChecklist){
          setChecklist(checklists.filter((data)=>(data.catId==cat.id)))
        }
        setEmployees(employeelist.filter((data)=>(data.catId==cat.id)))
        setLoadCateg(false)
      }

      const priorityOptions = [
        { value: "1", label: "low", color: "#22c55e" },
        { value: "2", label: "medium", color: "#3b82f6" },
        { value: "3", label: "high", color: "#f97316" },
        { value: "4", label: "urgent", color: "#ef4444" },
        { value: "5", label: "instant", color: "#ef4444" },
      ];

      //price
      const updatePrice = () => {
        

        var set=forwardTask.taskSet;
        var price=0;
        var discount=0;
        var charge=0;
        var total=0;
        if(forwardTask.price>0){
          price=parseFloat(forwardTask.price);
        }else{
          price=0;
        }
        if(forwardTask.disc>0){
          discount=parseFloat(forwardTask.disc);
        }else{
          discount=0;
        }
        if(forwardTask.charge>0){
          charge=parseFloat(forwardTask.charge)
        }else{
          charge=0;
        }
        if(forwardTask.vat){
          total=(price*set) - discount+charge
          total=total + total*(20/100)
        }else{
          total=(price*set) - discount+charge
        }
          setForwardTask(forwardTask=>({...forwardTask, total:parseFloat(total).toFixed(2)}))
      }
      useEffect(()=>{
        updatePrice();
      },[forwardTask.price, forwardTask.disc, forwardTask.charge, forwardTask.vat, forwardTask.taskSet])

      const setNoteData = (state) => {
        var st = draftToHtml(convertToRaw(state.getCurrentContent()));
        forwardTask.notes=st;
        setNoteState(state);
      };
    
      const updateTextDescription = async (state) => {
        await setNoteData(state);
      };

      const [showrcvd,setShowrcvd]=useState(false);

      const updatePaid=(option)=>{
        var func=option.mathFunc;
        if(func==1){
          setForwardTask(forwardTask=>({...forwardTask, paid:0}))
          setShowrcvd(false);
        }
        else if(func==2){
          setShowrcvd(true);
        }
        else if(func==3){
          setForwardTask(forwardTask=>({...forwardTask, paid:forwardTask.total}))
          setShowrcvd(false);
        }
        else if(func==4){
          setForwardTask(forwardTask=>({...forwardTask, paid:-1}))
        }
        else{
          setShowrcvd(true);
        }
      }

      const setDefaultTurnaround = (value)=>{
        const date=new Date();
        var checkDay=date.getDay();
        var d=null;

        var ukTime=date.toLocaleString('en-US', { timeZone: 'Europe/London' })
        ukTime=new Date(ukTime);
        var time=ukTime.getHours();

        if(value==1){
          if(time>=12){
            d=new Date(date.setDate(date.getDate()+14))
          }else{
            d=new Date(date.setDate(date.getDate()+12))
          }
        }else if(value==2){

          if(checkDay>=3){
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+7))
            }else{
              d=new Date(date.setDate(date.getDate()+6))
            }
          }else{
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+5))
            }else{
              d=new Date(date.setDate(date.getDate()+4))
            }
          }

        }else if(value==3){

          if(checkDay>=3){
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+5))
            }else{
              d=new Date(date.setDate(date.getDate()+3))
            }

          }else{
            if(time>=12){
              d=new Date(date.setDate(date.getDate()+3))
            }else{
              d=new Date(date.setDate(date.getDate()+2))
            }

          }

        }else if(value==4){
          if(time>=12){
            if(checkDay>=5){
              d=new Date(date.setDate(date.getDate()+3))
            }else{
              d=new Date(date.setDate(date.getDate()+2))
            }
          }else{
            if(checkDay>=5){
              d=new Date(date.setDate(date.getDate()+3))
            }
            else{
              d=new Date(date.setDate(date.getDate()+1))
            }
          }
        }
        else if(value==5){
          if(time>=12){
            if(checkDay>=5){
              d=new Date(date.setDate(date.getDate()+2))
            }else{
              d=new Date(date.setDate(date.getDate()+1))
            }

          }else{
            d=new Date(date.setDate(date.getDate()))
          }
          
        }
        setForwardTask(forwardTask=>({...forwardTask, turnaround:d}))

      }


      const forward=async()=>{
        const validate=toast.loading('validating data...', {icon: '📃'});
        try{
          if(forwardTask.category==-1){
            toast.error('please select a category');
            toast.dismiss(validate);
            return
          }
          if(forwardTask.jobdesc==''){
            toast.error('please type task description');
            toast.dismiss(validate);
            return
          }
    
          if(forwardTask.status==0){
            toast.error('please select task status');
            toast.dismiss(validate);
            return
          }
          if(forwardTask.turnaround==null){
            toast.error('please enter turnaround Date');
            toast.dismiss(validate);
            return
          }
    
          if(forwardTask.assignee.length==0){
            toast.error('please assign your task!');
            toast.dismiss(validate);
            return
          }
          
          forwardTask.checklist=checklist;
          
          var data=JSON.stringify({
            referenceId:task.referenceId,
            task:JSON.stringify(forwardTask),
            oldCategory:task.categoryId,
            oldJob:task.id,
            userId:user.id,
          })
          const work = toast.loading('Forwarding Task...');
          await axios.post(FORWARD_URL, data).then((res)=>{
            toast.dismiss(validate);
            toast.dismiss(work);
            toast.success('Forwarded Job');
            dispatch(updateTaskStatus({reference:task.referenceId, task:res.data.task, multiple:false}))
            
            const toSend=res.data.assignees;
            const refresh=res.data.needsRefresh;
           
            toSend.filter(a=>a.userId!==user.id).map(s=>{
              var title=`New Task [#${s.jobId}] Assigned to you`
              var body=`you have been assigned to a new task by ${user.Fname} ${user.Lname}`
              var onClickLink=`/category/${s.compId}/${s.catId}/${s.jobId}`
              var receiverId=s.userId
              var cat=categories.filter(c=>c.id==s.catId)[0]
              var iconLink=''
              var iconType=-1
              if(cat.icon!=null){
                iconLink=`${cat.icon}`
                iconType=0
              }else{
                iconLink=`${cat.name}`
                iconType=1
              }
              notify(title, body, onClickLink, receiverId, iconLink, iconType)
            })
            refresh.filter(r=>r!=user.id).map(ref=>{
              requireRefresh(ref, true, false, false)
            })      
           
            setForward(false);
          })
         
        }catch(e){
          if(!e?.response){
            toast.error("No Server Response!");
            toast.dismiss(validate);
            console.log(e);
        }else{
            console.log(e);
            toast.error("Oops! An error occured!");
            toast.dismiss(validate);
        }
        }
      }


  return (
    <div className='forwardJob'>
         <div className='forwardJobArrow'></div>
         
         <div className='flex items-center justify-start gap-0.5 flex-wrap'>
         <Select
         menuPlacement="auto"
          onChange={(option) => {
            forwardTask.category = option.value;
            getDefaultData(option.value);
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={categoryOptions.filter(c=>c.roleId>=1)}
          placeholder="Category"
          formatOptionLabel={cat=>(
            <div className='flex items-center justify-start gap-1'>
                {cat.icon?
                <img src={`${CategorylogoStorage_LINK}${cat.icon}`} className={'w-5 h-5 rounded-full'} alt='icon'/>
                :
                <Avatar value={cat.label} width={'5'} height={'5'}/>
                }

                <p className='font-semibold'>{cat.label}</p>
            </div>
          )}
        />

        <div className="flex flex-wrap items-center justify-start">
          <p className="input-field-prepend">qty</p>
            <input
              type="number"
              defaultValue={forwardTask.qty}
              onChange={(e) => {
                setForwardTask(forwardTask=>({...forwardTask, qty:e.target.value}))
              }}
              min="1"
              step="1"
              className="w-10 input-field-group"
              placeholder="Qty"
            />
        </div>

        <input
        type="text"
        defaultValue={forwardTask.jobdesc}
        onChange={(e) => {
          setForwardTask(forwardTask=>({...forwardTask, jobdesc:e.target.value}));
        }}
        className="w-2/6 input-field"
        placeholder="Job Description"
        />

      <div className="flex flex-wrap items-center justify-start">
        <p className="input-field-prepend">set</p>
        <input
          type="number"
          defaultValue={forwardTask.taskSet}
          onChange={(e) => {
            setForwardTask(forwardTask=>({...forwardTask, taskSet:e.target.value}))
          }}
          min="1"
          step="1"
          className="w-10 input-field-group"
          placeholder="Set"
        />
      </div>
      

      <Select
      menuPlacement="auto"
        onChange={(option) => {
          setForwardTask(forwardTask=>({...forwardTask, priority:option.value}))
          setDefaultTurnaround(option.value);
        }}
        defaultValue={priorityOptions.filter((data)=>(data.value==forwardTask.priority))}  
        className="input-field-select"
        isSearchable={false}
        styles={customStyles}
        options={priorityOptions}
        placeholder="Priority"
        formatOptionLabel={ option=>(
          <div className='flex items-center justify-start gap-1'>
            <div className={`w-2 h-2 rounded-full`} style={{backgroundColor:option.color}}></div>
            <p className='font-semibold'>{option.label}</p>
          </div>
        )
        }
      />
      <DatePicker
        selected={new Date(forwardTask.turnaround)}
        minDate={new Date()}
        dateFormat="dd,MMM-yy"
        className="w-24 input-field"
        onChange={(date) => {
          setForwardTask(forwardTask=>({...forwardTask, turnaround:date}))
        }}
        placeholderText="Turnaround"
      />
      

      {cat!=null && cat.isPrice ? (
        <>
        <div className="flex items-center justify-start w-24">
          <p className="input-field-prepend">
            <strong className="px-0.5">£</strong>
          </p>
          <input
            defaultValue={forwardTask.price}
            onChange={(e) => {
              setForwardTask(forwardTask=>({...forwardTask, price:e.target.value}))
            }}
            type="number"
            min="1"
            step="0.01"
            className="w-full input-field-group"
            placeholder="Price"
          />
        </div>
          
        <div className="flex items-center justify-start w-20">
          <p className="input-field-prepend">
            <strong className="px-0.5">+</strong>
          </p>
          <input
            defaultValue={forwardTask.charge}
            onChange={(e) => {
              setForwardTask(forwardTask=>({...forwardTask, charge:e.target.value}))
            }}
            type="number"
            min="0"
            step="0.01"
            className="w-full input-field-group"
            placeholder="Charge"
          />
        </div>
        
        <div className="flex items-center justify-start w-20">
          <p className="input-field-prepend">
            <strong className="px-0.5">-</strong>
          </p>
          <input
          defaultValue={forwardTask.disc}
            onChange={(e) => {
              setForwardTask(forwardTask=>({...forwardTask, disc:e.target.value}))
            }}
            type="number"
            min="0"
            step="0.01"
            className="w-full input-field-group"
            placeholder="Discount"
          />
        </div>

        

        {forwardTask.price>0?
        <div className="flex justify-start gap-1 align-middle m-0.5 p-1">
          <input
            type="checkbox"
            value="1"
            className="checkbox"
            onChange={(e) => {setForwardTask(forwardTask=>({...forwardTask, vat:!forwardTask.vat }))}}
            id={`vat.${forwardTask.id}`}
            checked={forwardTask.vat}
            />
          <label htmlFor={`vat.${forwardTask.id}`} className="checkbox-label">
            Vat
          </label>
        </div>
        :
        null
        }
        <div className="flex items-center justify-start w-28">
          <p className="input-field-prepend">Total</p>
          <input
            type="number"
            value={parseFloat(forwardTask.total).toFixed(2)}
            min="1"
            step="0.01"
            className="w-full input-field-group text-semibold"
            placeholder="Total"
            readOnly={true}
          />
        </div>
      </>
    )
    : 
    null
    } 

{cat!=null? (
      <Select
      menuPlacement="auto"
        onChange={(option) => {
          setForwardTask(forwardTask=>({...forwardTask, status:option.value}))
        }}
        className="input-field-select"
        isSearchable={false}
        styles={customStyles}
        options={jobStatus}
        placeholder="Job Status"
        formatOptionLabel={ option=>(
          <div className='flex items-center justify-start gap-1'>
            <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
            <p className='font-semibold whitespace-nowrap'>{option.label}</p>
          </div>
        )
        }
        
      />
      ) : 
      null
}



{cat!=null && cat.isQuote ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            setForwardTask(forwardTask=>({...forwardTask, quote:option.value}))
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={quote}
          placeholder="quote"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }

        />
      ) :

      null}


      {cat!=null && cat.isJobType ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            setForwardTask(forwardTask=>({...forwardTask, jobType:option.value}))
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={JobType}
          placeholder="Job type"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }
        />
      ) : 
      null
      }

      {cat!=null && cat.isShipment ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            setForwardTask(forwardTask=>({...forwardTask, shipment:option.value}))
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={shipment}
          placeholder="Shipment"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }
        />
      ) : null}

      {cat!=null && cat.isProduction ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            setForwardTask(forwardTask=>({...forwardTask, production:option.value}))
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={production}
          placeholder="Production"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <p className='px-1 font-semibold border-l-4 whitespace-nowrap'style={{borderColor:option.color}}>{option.label}</p>
            </div>
          )
          }
        />
      ) : null
      }

      {cat!=null && cat.isPrice ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            setForwardTask(forwardTask=>({...forwardTask, paymentOption:option.value}))
            updatePaid(option);
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={paymentOption}
          placeholder="payment"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
              <p className='font-semibold whitespace-nowrap'>{option.label}</p>
            </div>
          )
          }
        />
      ) : 
      null
      }

      {forwardTask.paymentOption !=null ? (
     
         <Transition
        show={showrcvd}
        enter="transition-all translate duration-150"
        enterFrom="opacity-0 -translate-x-6"
        enterTo="opacity-100 translate-x-0"
        leave="transition-all translate duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 -translate-x-6">
        <div className="flex items-center justify-start w-28">
          <p className="input-field-prepend">rcvd</p>
          <input
            type="number"
            defaultValue={forwardTask.paid}
            onChange={(e) => {
              setForwardTask(forwardTask=>({...forwardTask, paid:e.target.value}))
            }}
            min="0"
            step="0.01"
            className="w-full input-field-group"
            placeholder="paid"
            />
        </div>
       </Transition>
      ) : null}

    {cat!=null && cat.isPrice ? (
        <Select
        menuPlacement="auto"
          onChange={(option) => {
            setForwardTask(forwardTask=>({...forwardTask, paymentMethod:option.value}))
          }}
          className="input-field-select"
          isSearchable={false}
          styles={customStyles}
          options={paymentMethod}
          placeholder="method"
          formatOptionLabel={ option=>(
            <div className='flex items-center justify-start gap-1'>
              <div className={`w-2 h-2 border-2 bg-transparent rounded-full`} style={{borderColor:option.color}}></div>
              <p className='font-semibold whitespace-nowrap'>{option.label}</p>
            </div>
          )
          }
        />
      ) : null
      }
    

    {cat!=null?
      <div className='flex items-center justify-start w-auto'>
      <p className='input-field-prepend'>Assign</p>
      <Select
      menuPlacement="auto"
        onChange={(selectedOptions) => {
          forwardTask.assignee = selectedOptions.map((s) => s.value);
        }}
        className="w-full input-field-select-append"
        closeMenuOnSelect={false}
        isSearchable={false}
        defaultValue={employeelist.filter(e=>e.value==user.id)[0]}
        styles={customStyles}
        isMulti={true}
        options={employees}
        placeholder="Asignee"
        formatOptionLabel={ employee=>(
          <div className='flex items-center justify-start py-0 space-x-1'>
             {employee.image?
                <img src={`${avatarStorage_LINK}${employee.image}`} className={'w-3 h-3 rounded-full'} alt='avatar'/>
                :
                <Avatar value={`${employee.Fname} ${employee.Lname}`} width={'3'} height={'3'} color={'bg-blue-200 dark:bg-blue-800'} small={true}/>
                }

            <p className='font-semibold whitespace-nowrap'>{employee.Fname} {employee.Lname}</p>
          </div>
        )
        }

        />
      </div>
      :
      null}
        <div className='flex items-center justify-end gap-1 ml-auto'>
        <button
        className={`icon-btn ${
            openNotes ? "ring-1 ring-inset  ring-orange-500" : ""
        }`}
        onClick={() => {
            setOpenNotes(!openNotes);
        }}
        type={'button'}
      >
        <Note iconClass={'w-4 h-4'}/>
      </button>

      {cat!==null?
      <button className='forwardTask-btn' onClick={()=>{forward()}}>Forward Task</button>
      :
      null
      }
        </div>
       
    
      </div>

      <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 ${
            openNotes ? "block" : "hidden"
        } mt-1 gap-1 w-full`}
        
        >
        <div className="md:col-span-2 lg:col-span-4">
          <CustomEditor data={notestate} updateData={(e)=>{updateTextDescription(e)}}/>
        </div>
        <div className='lg:col-span-2'>
          <Checklist options={checklist} setOptions={setChecklist} isCreate={true}/>
        </div>
      </div>
    </div>
  )
}

export default ForwardTask