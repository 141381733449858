import { createSlice  } from "@reduxjs/toolkit";

const initialStateValue=[];

export const taskSlice=createSlice({
    name:'allTasks',
    initialState:{value:initialStateValue},
    reducers:{
        setTasks:(state, action)=>{
            state.value=action.payload
        },
        addTask:(state,action)=>{
            state.value=[action.payload,...state.value]
        },
        updateTaskCustomer:(state, action)=>{
            state.value=state.value.map((t)=>t.referenceId===action.payload.reference?
            {
            ...t,
            customer:action.payload.customer
            }:
            t)
        },
        updateTaskStatus:(state,action)=>{

            var updateJob=action.payload.task;
            var isMultiple=action.payload.multiple;
            var allTasks=state.value
            var tasklist=allTasks.filter((t)=>t.referenceId===action.payload.reference)[0]
            var oldJobs=tasklist.jobs;
            

            oldJobs=oldJobs.filter(j=>j.id!=updateJob.id)
            if(isMultiple){
                var newJob=[...updateJob, ...oldJobs]

            }else{
                var newJob=[updateJob,...oldJobs]
            }
          
            tasklist.jobs=newJob;

            var oldTaskList=allTasks.filter((t)=>t.referenceId!==action.payload.reference);
            var newTaskList=[tasklist,...oldTaskList]
            state.value=newTaskList

            // state.value=state.value.map((t)=>t.referenceId==action.payload.reference?
            //     {
            //     ...t,
            //     jobs:t.jobs.map(j=>j.id==action.payload.jobId?
            //         {
            //         ...j,
            //         jobStatus:action.payload.jobStatus
            //         }:
            //         j)
            //     }:t)
        },
        deleteTask:(state, action)=>{
            var taskId=action.payload.taskId;

            var allTasks=state.value
            var tasklist=allTasks.filter((t)=>t.referenceId===action.payload.reference)[0]

            var oldJobs=tasklist.jobs;
            var newJob=oldJobs.filter(j=>j.id!=taskId)
            var updateTask=[]
            if(newJob.length>0){
                updateTask=allTasks.map((t)=>t.referenceId===action.payload.reference?{
                    ...t,
                    jobs:newJob
                }:t);
            }else{
                updateTask=allTasks.filter((t)=>t.referenceId!==action.payload.reference)
            }
   
            state.value=updateTask

        },

    }
})

export const {setTasks, updateTaskCustomer, updateTaskStatus, deleteTask, addTask}=taskSlice.actions
export default taskSlice.reducer;