import React, {useState} from 'react'
import { Add } from '../Icons'
import { v4 as uuidv4 } from 'uuid';
import Checks from './Checks';
import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    
  } from '@dnd-kit/sortable';
import { Transition } from '@headlessui/react';

const Checklist = ({options, setOptions, isEdit, updateData, id, isCreate}) => {

const [canSave, setCanSave]=useState(false);

const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

function handleDragEnd(event) {
  const {active, over} = event;
  
  if (active.id !== over.id) {
    setOptions((options) => {
      const oldIndex = options.findIndex((item) => item.id === active.id);
      const newIndex = options.findIndex((item) => item.id === over.id);
      return arrayMove(options, oldIndex, newIndex);
    });
    setCanSave(true)
  }
}

const addOption = ()=>{
  var newOption= {id:uuidv4(), name:'',checked:false, default:false}

  if(isEdit){
  newOption= {id:uuidv4(), name:'',checked:false, default:false, track:-1};
  }

  setOptions([...options, newOption])
  setCanSave(true)
}


const removeOption = (id)=>{
  var ops=options.filter((c)=>c.id !==id);
  setOptions(ops)
  setCanSave(true)
}

const setChecked = (id,checked)=>{
  var update=options.map((i)=>
    i.id===id?
    {
    ...i,
    checked:!checked
    }:i
  );
  setOptions(update)
  setCanSave(true)
}

  return (
    <div>
         <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        >
            <div className='overflow-y-auto max-h-[30vh]'>
             <SortableContext  items={options} strategy= {verticalListSortingStrategy}>
                 {options.map(i=><Checks key={i.id} item={i} id={i.id} setChecked={setChecked} isCreate={isCreate} removeCheck={removeOption} setIsChecklistModified={setCanSave}/>)}
             </SortableContext>
            </div>

             <div className='flex items-center justify-between gap-2'>
             <button onClick={()=>addOption()} className='add-check-btn' type='button'><Add iconClass={'w-4 h-4'}/></button>
             {isEdit?
             <Transition
             show={canSave}
              enter="transition-all translate duration-150"
              enterFrom="opacity-0 translate-x-6"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all translate duration-150"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-6"
             >

               <button className='flex items-center justify-center gap-1 save-btn' onClick={()=>{updateData(options, id); setCanSave(!canSave)}} disabled={!canSave}>Save</button>
             </Transition>
              
              :
              null
              }
            </div>
        </DndContext>

    </div>
  )
}

export default Checklist