import React,{useState} from 'react'
import axios from "../../api/axios";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { JOIN_URL } from '../../api/ApiUrl';
import { notify } from '../../config/firebaseConfig';

const FindCompany = ({setIsreg}) => {

    const [code,setCode]=useState('');
    const user=useSelector((state)=>state.user.value)
    const joinCompany = async (e)=>{
        e.preventDefault();
        const toastvalid = toast.loading('Validating code...', {icon: '⏳'});
        try{
            var data= JSON.stringify({id:user.id, code:code}); 
            await axios.post(JOIN_URL,data).then((res)=>{
            const company=res.data.company
            var title=`${user.Fname} ${user.Lname} requested to join in ${company.name}`
            var body=`you can accept/reject this request`
            var onClickLink=`/profile/${company.id}`
            var receiverId=company.ownerId
            var iconLink=''
            var iconType=3
            notify(title, body, onClickLink, receiverId, iconLink, iconType)
            toast.success('Request Sent!'); 
            toast.dismiss(toastvalid);
            setIsreg(false);
            });
        }catch(e){
            if(!e?.response){
                console.log(e);
                toast.error("No Server Response!");
                toast.dismiss(toastvalid);
            }
            else if(e.response.status===406){
                toast.error(e.response.data.message)
                toast.dismiss(toastvalid);
            }
            else{
                console.log(e);
            }
        }
    }

  return (
    <div className='w-full my-1'>
        <h4 className='text-sm font-bold text-slate-600 dark:text-slate-400'>Join Company</h4>
        <p className='text-sm text-slate-500'>Please insert the code that was sent to you by your administrator or staff member in order to join</p>
        <form onSubmit={(e)=>joinCompany(e)}>
        <div className='flex items-center justify-start gap-1'>
            <input
            type='text'
            className='w-1/2 input-field-fat'
            value={code}
            onChange={(e)=>{setCode(e.target.value)}}
            required
            placeholder='Paste your code here'
            />
            <button className='submit-btn'>Join</button>
        </div>
        </form>
    </div>
  )
}

export default FindCompany